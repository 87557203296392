import React, { useState, useMemo } from 'react';
import { Calendar, ChevronLeft, ChevronRight, Filter, ArrowUpDown, Search } from 'lucide-react';
import { CalculationResults, LoanData } from '../MortgageCalculator';

interface SchedulePanelProps {
  results: CalculationResults | null;
  loanData: LoanData;
}

export default function SchedulePanel({ results, loanData }: SchedulePanelProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [yearView, setYearView] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'ascending' | 'descending';
  } | null>(null);
  
  const itemsPerPage = 12;

  // Format currency
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  // Always calculate yearly data, but only use it when yearView is true
  const yearlyData = useMemo(() => {
    if (!results) return [];

    const years = Math.ceil(results.schedule.length / 12);
    const yearlySchedule = [];

    for (let year = 1; year <= years; year++) {
      const startIdx = (year - 1) * 12;
      const endIdx = Math.min(startIdx + 11, results.schedule.length - 1);
      
      if (startIdx < results.schedule.length) {
        const yearItems = results.schedule.slice(startIdx, endIdx + 1);
        
        const yearlyBasePayment = yearItems.reduce((sum, item) => sum + (item.payment - item.overpayment), 0);
        const yearlyOverpayment = yearItems.reduce((sum, item) => sum + item.overpayment, 0);
        const yearlyPrincipal = yearItems.reduce((sum, item) => sum + item.principal, 0);
        const yearlyInterest = yearItems.reduce((sum, item) => sum + item.interest, 0);

        yearlySchedule.push({
          year,
          basePayment: yearlyBasePayment,
          overpayment: yearlyOverpayment,
          totalPayment: yearlyBasePayment + yearlyOverpayment,
          principal: yearlyPrincipal,
          interest: yearlyInterest,
          remainingBalance: results.schedule[endIdx].remainingBalance
        });
      }
    }

    return yearlySchedule;
  }, [results]);

  // Filter data based on search term
  const filteredData = useMemo(() => {
    if (!results) return [];
    
    const dataToFilter = yearView ? yearlyData : results.schedule;
    
    if (!searchTerm) return dataToFilter;
    
    return dataToFilter.filter(item => {
      const searchValue = searchTerm.toLowerCase();
      const period = yearView ? `rok ${item.year}` : `miesiąc ${item.month}`;
      
      return period.toLowerCase().includes(searchValue) ||
        item.payment.toString().includes(searchValue) ||
        item.principal.toString().includes(searchValue) ||
        item.interest.toString().includes(searchValue) ||
        item.remainingBalance.toString().includes(searchValue);
    });
  }, [results, yearView, yearlyData, searchTerm]);

  // Sort filtered data
  const sortedData = useMemo(() => {
    if (!sortConfig) return filteredData;

    return [...filteredData].sort((a, b) => {
      const key = sortConfig.key as keyof typeof a;
      if (a[key] < b[key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }, [filteredData, sortConfig]);

  if (!results) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-8 h-full flex items-center justify-center">
        <p className="text-gray-500">Obliczanie wyników...</p>
      </div>
    );
  }

  // Calculate pagination
  const totalItems = sortedData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  
  const currentItems = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSort = (key: string) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    
    setSortConfig({ key, direction });
  };

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden">
      <div className="p-6 md:p-8">
        <div className="flex items-center justify-center gap-3 mb-8">
          <div className="relative">
            <Calendar className="w-8 h-8 text-[#CBA331]" />
            <div className="absolute -top-1 -right-1 w-3 h-3 bg-[#003A30] rounded-full animate-pulse"></div>
          </div>
          <h2 className="text-2xl font-bold text-[#003A30] relative">
            Harmonogram spłat
            <div className="absolute -bottom-1 left-0 w-full h-0.5 bg-gradient-to-r from-[#CBA331]/0 via-[#CBA331] to-[#CBA331]/0"></div>
          </h2>
        </div>

        {/* Controls */}
        <div className="flex flex-col md:flex-row gap-4 mb-6">
          <div className="flex-1 relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Szukaj..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(1);
              }}
              className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#003A30] focus:border-[#003A30]"
            />
          </div>
          
          <div className="flex gap-2">
            <button
              onClick={() => {
                setYearView(!yearView);
                setCurrentPage(1);
                setSortConfig(null);
              }}
              className="inline-flex items-center px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors duration-300 text-[#003A30]"
            >
              <Filter className="w-4 h-4 mr-2" />
              {yearView ? 'Widok miesięczny' : 'Widok roczny'}
            </button>
          </div>
        </div>

        {/* Schedule Table */}
        <div className="overflow-x-auto rounded-xl border border-gray-200 shadow-sm">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-50 border-b border-gray-200">
                <th 
                  className="px-4 py-3 text-left text-sm font-semibold text-[#003A30] cursor-pointer hover:bg-gray-100 transition-colors duration-150"
                  onClick={() => handleSort(yearView ? 'year' : 'month')}
                >
                  <div className="flex items-center">
                    {yearView ? 'Rok' : 'Miesiąc'}
                    <ArrowUpDown className="w-4 h-4 ml-1" />
                  </div>
                </th>
                <th 
                  className="px-4 py-3 text-right text-sm font-semibold text-[#003A30] cursor-pointer hover:bg-gray-100 transition-colors duration-150"
                  onClick={() => handleSort('basePayment')}
                >
                  <div className="flex items-center justify-end">
                    Rata podstawowa
                    <ArrowUpDown className="w-4 h-4 ml-1" />
                  </div>
                </th>
                <th 
                  className="px-4 py-3 text-right text-sm font-semibold text-[#003A30] cursor-pointer hover:bg-gray-100 transition-colors duration-150"
                  onClick={() => handleSort('overpayment')}
                >
                  <div className="flex items-center justify-end">
                    Nadpłata
                    <ArrowUpDown className="w-4 h-4 ml-1" />
                  </div>
                </th>
                <th 
                  className="px-4 py-3 text-right text-sm font-semibold text-[#003A30] cursor-pointer hover:bg-gray-100 transition-colors duration-150"
                  onClick={() => handleSort('principal')}
                >
                  <div className="flex items-center justify-end">
                    Kapitał
                    <ArrowUpDown className="w-4 h-4 ml-1" />
                  </div>
                </th>
                <th 
                  className="px-4 py-3 text-right text-sm font-semibold text-[#003A30] cursor-pointer hover:bg-gray-100 transition-colors duration-150"
                  onClick={() => handleSort('interest')}
                >
                  <div className="flex items-center justify-end">
                    Odsetki
                    <ArrowUpDown className="w-4 h-4 ml-1" />
                  </div>
                </th>
                <th 
                  className="px-4 py-3 text-right text-sm font-semibold text-[#003A30] cursor-pointer hover:bg-gray-100 transition-colors duration-150"
                  onClick={() => handleSort('remainingBalance')}
                >
                  <div className="flex items-center justify-end">
                    Pozostało
                    <ArrowUpDown className="w-4 h-4 ml-1" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length > 0 ? (
                currentItems.map((item, index) => (
                  <tr 
                    key={index} 
                    className="border-b border-gray-100 hover:bg-gray-50 transition-colors duration-150"
                  >
                    <td className="px-4 py-3 text-sm text-gray-700">
                      {yearView ? (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-[#003A30] text-white">
                          Rok {item.year}
                        </span>
                      ) : (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                          Miesiąc {item.month}
                        </span>
                      )}
                    </td>
                    <td className="px-4 py-3 text-sm text-right font-medium text-[#003A30]">
                      {formatCurrency(yearView ? item.basePayment : item.payment - item.overpayment)}
                    </td>
                    <td className="px-4 py-3 text-sm text-right text-[#CBA331] font-medium">
                      {formatCurrency(item.overpayment)}
                    </td>
                    <td className="px-4 py-3 text-sm text-right text-emerald-600">
                      {formatCurrency(item.principal)}
                    </td>
                    <td className="px-4 py-3 text-sm text-right text-red-600">
                      {formatCurrency(item.interest)}
                    </td>
                    <td className="px-4 py-3 text-sm text-right font-medium text-[#003A30]">
                      {formatCurrency(item.remainingBalance)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="px-4 py-8 text-center text-gray-500">
                    Brak wyników dla podanych kryteriów
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        {totalPages > 1 && (
          <div className="flex items-center justify-between mt-6">
            <button
              onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
              disabled={currentPage === 1}
              className={`inline-flex items-center px-3 py-2 rounded-md ${
                currentPage === 1 
                  ? 'text-gray-400 cursor-not-allowed' 
                  : 'text-[#003A30] hover:bg-gray-100'
              }`}
            >
              <ChevronLeft className="w-5 h-5 mr-1" />
              Poprzednia
            </button>
            
            <div className="flex items-center">
              {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                let pageNumber;
                if (totalPages <= 5) {
                  pageNumber = i + 1;
                } else if (currentPage <= 3) {
                  pageNumber = i + 1;
                } else if (currentPage >= totalPages - 2) {
                  pageNumber = totalPages - 4 + i;
                } else {
                  pageNumber = currentPage - 2 + i;
                }
                
                return (
                  <button
                    key={i}
                    onClick={() => handlePageChange(pageNumber)}
                    className={`w-8 h-8 mx-1 flex items-center justify-center rounded-md ${
                      currentPage === pageNumber
                        ? 'bg-[#003A30] text-white'
                        : 'text-gray-700 hover:bg-gray-100'
                    }`}
                  >
                    {pageNumber}
                  </button>
                );
              })}
              
              {totalPages > 5 && currentPage < totalPages - 2 && (
                <>
                  <span className="mx-1">...</span>
                  <button
                    onClick={() => handlePageChange(totalPages)}
                    className="w-8 h-8 mx-1 flex items-center justify-center rounded-md text-gray-700 hover:bg-gray-100"
                  >
                    {totalPages}
                  </button>
                </>
              )}
            </div>
            
            <button
              onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
              disabled={currentPage === totalPages}
              className={`inline-flex items-center px-3 py-2 rounded-md ${
                currentPage === totalPages 
                  ? 'text-gray-400 cursor-not-allowed' 
                  : 'text-[#003A30] hover:bg-gray-100'
              }`}
            >
              Następna
              <ChevronRight className="w-5 h-5 ml-1" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}