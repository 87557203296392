import React, { useState, useEffect } from 'react';
import { Calculator as CalculatorIcon, ArrowRight, BadgePercent, Shield, Sparkles, Building2, PiggyBank } from 'lucide-react';

// Constants
const WIBOR = 0.058; // 5.8%
const YEARS_WITH_PROPERTY = 20;
const YEARS_WITHOUT_PROPERTY = 10;
const MAX_AMOUNT_WITH_PROPERTY = 10000000;
const MAX_AMOUNT_WITHOUT_PROPERTY = 2000000;
const MIN_AMOUNT_WITH_PROPERTY = 300000;
const MIN_AMOUNT_WITHOUT_PROPERTY = 100000;

export default function Calculator() {
  const [totalLoans, setTotalLoans] = useState<number>(500000);
  const [hasProperty, setHasProperty] = useState<boolean>(true);
  const [isVisible, setIsVisible] = useState(false);

  // Get margin based on amount and property status
  const getMargin = (amount: number, hasPropertyValue: boolean) => {
    if (hasPropertyValue) {
      return amount < 1000000 ? 0.020 : 0.015; // 2.0% lub 1.5%
    } else {
      return amount < 1000000 ? 0.025 : 0.020; // 2.5% lub 2.0%
    }
  };

  // Calculate monthly payment
  const calculateMonthlyPayment = (amount: number, annualRate: number, years: number) => {
    const monthlyRate = annualRate / 12;
    const numberOfPayments = years * 12;
    return amount * (monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / 
           (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
  };

  // Get monthly payment
  const getMonthlyPayment = () => {
    const margin = getMargin(totalLoans, hasProperty);
    const years = hasProperty ? YEARS_WITH_PROPERTY : YEARS_WITHOUT_PROPERTY;
    const annualRate = WIBOR + margin;
    return calculateMonthlyPayment(totalLoans, annualRate, years);
  };

  // Handle property type change
  const handlePropertyChange = (hasPropertyValue: boolean) => {
    const maxAmount = hasPropertyValue ? MAX_AMOUNT_WITH_PROPERTY : MAX_AMOUNT_WITHOUT_PROPERTY;
    const minAmount = hasPropertyValue ? MIN_AMOUNT_WITH_PROPERTY : MIN_AMOUNT_WITHOUT_PROPERTY;
    setHasProperty(hasPropertyValue);
    if (totalLoans > maxAmount) {
      setTotalLoans(maxAmount);
    } else if (totalLoans < minAmount) {
      setTotalLoans(minAmount);
    }
  };

  // Calculate results
  const monthlyPayment = getMonthlyPayment();
  const years = hasProperty ? YEARS_WITH_PROPERTY : YEARS_WITHOUT_PROPERTY;
  const margin = getMargin(totalLoans, hasProperty);

  // Format currency
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN',
      maximumFractionDigits: 0
    }).format(value);
  };

  // Format percentage
  const formatPercentage = (value: number) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'percent',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    }).format(value);
  };

  // Intersection Observer for animation
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.2 }
    );

    const element = document.getElementById('calculator-section');
    if (element) {
      observer.observe(element);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <section id="calculator-section" className="py-8 sm:py-12 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <div className={`
          max-w-6xl mx-auto bg-white rounded-2xl shadow-lg overflow-hidden
          transform transition-all duration-700 ease-out backdrop-blur-sm
          hover:shadow-xl hover:shadow-[#003A30]/5
          ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'}
        `}>
          <div className="p-4 sm:p-6 md:p-8 relative">
            {/* Decorative background elements */}
            <div className="absolute inset-0 bg-gradient-to-br from-[#003A30]/[0.02] via-transparent to-[#CBA331]/[0.02] pointer-events-none" />
            <div className="absolute -top-24 -right-24 w-48 h-48 bg-[#003A30]/5 rounded-full blur-3xl opacity-20" />
            <div className="absolute -bottom-24 -left-24 w-48 h-48 bg-[#CBA331]/5 rounded-full blur-3xl opacity-20" />

            {/* Header */}
            <div className="flex items-center justify-center gap-2 sm:gap-3 mb-6 sm:mb-8 relative">
              <div className="relative">
                <CalculatorIcon className="w-6 h-6 sm:w-8 sm:h-8 text-[#CBA331] transform transition-transform duration-300 hover:scale-110" />
                <Sparkles className="w-3 h-3 sm:w-4 sm:h-4 text-[#CBA331]/50 absolute -top-1 -right-1 animate-pulse" />
              </div>
              <h2 className="text-xl sm:text-2xl font-bold text-[#003A30] relative">
                Kalkulator kredytu firmowego
                <div className="absolute -bottom-1 left-0 w-full h-0.5 bg-gradient-to-r from-[#CBA331]/0 via-[#CBA331] to-[#CBA331]/0" />
              </h2>
            </div>

            <div className="grid md:grid-cols-2 gap-6 md:gap-8">
              {/* Left Column - Inputs */}
              <div className="space-y-6">
                {/* Total Loans Slider */}
                <div className="relative group">
                  <div className="flex justify-between items-center mb-2">
                    <label className="text-sm font-medium text-gray-700 transition-colors group-hover:text-[#003A30]">
                      Suma kredytów firmowych
                    </label>
                    <span className="text-lg sm:text-xl font-bold text-[#003A30] transition-colors duration-300 group-hover:text-[#CBA331]">
                      {formatCurrency(totalLoans)}
                    </span>
                  </div>
                  <input
                    type="range"
                    min={hasProperty ? MIN_AMOUNT_WITH_PROPERTY : MIN_AMOUNT_WITHOUT_PROPERTY}
                    max={hasProperty ? MAX_AMOUNT_WITH_PROPERTY : MAX_AMOUNT_WITHOUT_PROPERTY}
                    step="50000"
                    value={totalLoans}
                    onChange={(e) => setTotalLoans(Number(e.target.value))}
                    className="w-full h-2 appearance-none cursor-pointer bg-gray-200 rounded-lg touch-none
                      [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-6 [&::-webkit-slider-thumb]:h-6
                      [&::-webkit-slider-thumb]:bg-[#003A30] [&::-webkit-slider-thumb]:rounded-full
                      [&::-webkit-slider-thumb]:cursor-pointer [&::-webkit-slider-thumb]:transition-all
                      [&::-webkit-slider-thumb]:duration-300 [&::-webkit-slider-thumb]:hover:scale-125
                      [&::-webkit-slider-thumb]:hover:bg-[#CBA331] [&::-webkit-slider-thumb]:shadow-lg
                      [&::-moz-range-thumb]:appearance-none [&::-moz-range-thumb]:w-6 [&::-moz-range-thumb]:h-6
                      [&::-moz-range-thumb]:bg-[#003A30] [&::-moz-range-thumb]:border-0
                      [&::-moz-range-thumb]:rounded-full [&::-moz-range-thumb]:cursor-pointer
                      [&::-moz-range-thumb]:transition-all [&::-moz-range-thumb]:duration-300
                      [&::-moz-range-thumb]:hover:scale-125 [&::-moz-range-thumb]:hover:bg-[#CBA331]
                      [&::-moz-range-thumb]:shadow-lg [&::-moz-range-progress]:bg-[#003A30]
                      [&::-moz-range-progress]:h-2 [&::-moz-range-progress]:rounded-l-lg"
                  />
                  <div className="flex justify-between text-xs text-gray-500 mt-1">
                    <span>{formatCurrency(hasProperty ? MIN_AMOUNT_WITH_PROPERTY : MIN_AMOUNT_WITHOUT_PROPERTY)}</span>
                    <span>{formatCurrency(hasProperty ? MAX_AMOUNT_WITH_PROPERTY : MAX_AMOUNT_WITHOUT_PROPERTY)}</span>
                  </div>
                </div>

                {/* Property Security Selection */}
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">
                    Zabezpieczenie hipoteczne
                  </label>
                  <div className="grid grid-cols-2 gap-3">
                    <button
                      onClick={() => handlePropertyChange(true)}
                      className={`flex items-center gap-2 px-4 py-3 rounded-lg border-2 transition-all duration-300 ${
                        hasProperty
                          ? 'border-[#003A30] bg-[#003A30] text-white'
                          : 'border-gray-200 hover:border-[#003A30] text-gray-700'
                      }`}
                    >
                      <Building2 className="w-5 h-5" />
                      <span>Tak</span>
                    </button>
                    <button
                      onClick={() => handlePropertyChange(false)}
                      className={`flex items-center gap-2 px-4 py-3 rounded-lg border-2 transition-all duration-300 ${
                        !hasProperty
                          ? 'border-[#003A30] bg-[#003A30] text-white'
                          : 'border-gray-200 hover:border-[#003A30] text-gray-700'
                      }`}
                    >
                      <Shield className="w-5 h-5" />
                      <span>Nie</span>
                    </button>
                  </div>
                </div>
              </div>

              {/* Right Column - Results */}
              <div className="space-y-6">
                {/* Monthly Payment with Period and Rates */}
                <div className="group bg-gradient-to-br from-[#003A30]/5 to-transparent rounded-xl p-6 transition-all duration-300 hover:shadow-lg hover:from-[#003A30]/10">
                  <div className="flex items-center gap-2 mb-2">
                    <PiggyBank className="w-5 h-5 text-[#CBA331] transition-transform duration-300 group-hover:scale-110" />
                    <h3 className="text-sm font-semibold text-[#003A30]">Rata miesięczna</h3>
                  </div>
                  <p className="text-2xl font-bold text-[#003A30] mb-3 transition-colors duration-300 group-hover:text-[#CBA331]">
                    {formatCurrency(monthlyPayment)}
                  </p>
                  <div className="grid grid-cols-3 gap-4 text-sm text-gray-600">
                    <div>
                      <span className="block text-gray-500">Okres</span>
                      <span className="font-medium text-[#003A30]">{years} lat</span>
                    </div>
                    <div>
                      <span className="block text-gray-500">Marża</span>
                      <span className="font-medium text-[#003A30]">{formatPercentage(margin)}</span>
                    </div>
                    <div>
                      <span className="block text-gray-500">WIBOR</span>
                      <span className="font-medium text-[#003A30]">{formatPercentage(WIBOR)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* CTA Button */}
            <div className="mt-8 text-center">
              <button
                onClick={() => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })}
                className="inline-flex items-center justify-center px-6 sm:px-8 py-3 sm:py-4 bg-[#003A30] text-white rounded-lg hover:bg-[#CBA331] transition-all duration-300 group text-sm sm:text-base font-medium"
              >
                Otrzymaj szczegółową ofertę
                <ArrowRight className="w-4 h-4 sm:w-5 sm:h-5 ml-2 group-hover:translate-x-1 transition-transform duration-300" />
              </button>
            </div>

            {/* Disclaimer */}
            <p className="mt-6 text-xs text-gray-500 text-center max-w-xl mx-auto">
              * Przedstawione wyliczenia mają charakter orientacyjny. 
              Szczegółowa oferta zostanie przygotowana po analizie dokumentów finansowych.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}