import { Search, ListChecks, FileText, Building2, CheckCircle2, Clock, BadgePercent, Percent } from 'lucide-react';
import type { ProcessStepsData } from './types';

export const optimizationStepsData: ProcessStepsData = {
  title: "Prosty i przejrzysty proces",
  subtitle: "Przeprowadzimy Cię przez cały proces kredytowy, zapewniając profesjonalne wsparcie na każdym etapie",
  steps: [
    {
      number: 1,
      icon: Search,
      title: "Audyt finansowy",
      description: "Kompleksowa analiza struktury finansowania",
      features: [
        "Analiza wszystkich kredytów",
        "Identyfikacja potencjału",
        "Ocena możliwości"
      ],
      stats: {
        icon: Clock,
        value: "2-3 dni",
        label: "Dokładna analiza"
      }
    },
    {
      number: 2,
      icon: ListChecks,
      title: "Strategia optymalizacji",
      description: "Projektowanie kompleksowego rozwiązania",
      features: [
        "Plan restrukturyzacji",
        "Strategia negocjacji",
        "Harmonogram działań"
      ],
      stats: {
        icon: Percent,
        value: "42%",
        label: "Średnia optymalizacja"
      }
    },
    {
      number: 3,
      icon: FileText,
      title: "Profesjonalna dokumentacja",
      description: "Przygotowanie pełnej dokumentacji",
      features: [
        "Kompletny pakiet",
        "Profesjonalne opracowanie",
        "Zgodność prawna"
      ],
      stats: {
        icon: Clock,
        value: "5-7 dni",
        label: "Pełne przygotowanie"
      }
    },
    {
      number: 4,
      icon: Building2,
      title: "Kontrolowane wdrożenie",
      description: "Systematyczna implementacja zmian",
      features: [
        "Nadzorowany proces",
        "Kontrola ryzyka",
        "Monitoring postępów"
      ],
      stats: {
        icon: BadgePercent,
        value: "100%",
        label: "Kontroli procesu"
      }
    },
    {
      number: 5,
      icon: CheckCircle2,
      title: "Trwała poprawa",
      description: "Stabilizacja i monitoring efektów",
      features: [
        "Weryfikacja rezultatów",
        "Zabezpieczenie korzyści",
        "Plan dalszej optymalizacji"
      ],
      stats: {
        icon: Clock,
        value: "30 dni",
        label: "Do pełnej optymalizacji"
      }
    }
  ]
};