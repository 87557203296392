import { RefreshCw } from 'lucide-react';
import type { Card, ModalDetails } from '../types';

export const MORTGAGE_PLUS_CARD: Card = {
  id: "mortgage-plus",
  icon: RefreshCw,
  title: "Refinansowanie + Dodatkowa gotówka",
  description: "Optymalizuj kredyt i uzyskaj środki na Twoje cele",
  benefits: [
    "Nawet do 30% wartości nieruchomości dodatkowej gotówki",
    "Niższa rata kredytu pomimo większej kwoty",
    "Marża kredytu średnio niższa o 31%"
  ],
  stats: {
    value: "10-30%",
    label: "Dodatkowej gotówki z refinansowania"
  },
  cta: {
    label: "Sprawdź możliwości refinansowania",
    action: () => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })
  }
};

export const MORTGAGE_PLUS_DETAILS: ModalDetails = {
  title: "Refinansowanie kredytu hipotecznego z dodatkową gotówką",
  tagline: "Więcej możliwości, niższa rata, dodatkowe środki",
  description: "Skorzystaj z aktualnej sytuacji rynkowej i potencjału Twojej nieruchomości. Refinansuj kredyt i uzyskaj dodatkowe środki na dowolny cel - od 10% do 30% wartości nieruchomości.",
  cta: {
    phone: "539 211 881",
    text: "Zadzwoń i sprawdź, ile możesz zyskać dzięki refinansowaniu",
    urgencyNote: "Skorzystaj z korzystnych warunków rynkowych już teraz!"
  },
  benefitHighlights: [
    "Dodatkowa gotówka od 10% do 30% wartości nieruchomości",
    "Potencjał obniżenia miesięcznej raty",
    "Jedna wygodna rata kredytowa",
    "Możliwość negocjacji korzystniejszych warunków"
  ],
  whyUs: [
    "Eksperci w kompleksowym refinansowaniu kredytów hipotecznych",
    "Indywidualne podejście do każdego klienta",
    "Dostęp do najlepszych ofert rynkowych",
    "Pełne wsparcie na każdym etapie procesu"
  ],
  processComparison: {
    withUs: [
      {
        step: "Kompleksowa analiza",
        description: "Dokładna ocena możliwości refinansowania",
        icon: "Search",
        highlight: true
      },
      {
        step: "Precyzyjna wycena",
        description: "Profesjonalna wycena aktualnej wartości nieruchomości",
        icon: "Home"
      },
      {
        step: "Optymalizacja finansowa",
        description: "Wybór najbardziej korzystnej oferty kredytowej",
        icon: "TrendingDown"
      },
      {
        step: "Sprawna realizacja",
        description: "Szybki i przejrzysty proces refinansowania",
        icon: "CheckCircle",
        highlight: true
      }
    ],
    withoutUs: [
      {
        step: "Ograniczone opcje",
        description: "Brak dostępu do pełnej oferty rynkowej",
        icon: "AlertTriangle",
        warning: true
      },
      {
        step: "Ryzyko błędnej wyceny",
        description: "Możliwość niedoszacowania wartości nieruchomości",
        icon: "XCircle",
        warning: true
      },
      {
        step: "Brak negocjacji",
        description: "Standardowe warunki bez możliwości optymalizacji",
        icon: "AlertCircle",
        warning: true
      },
      {
        step: "Przewlekły proces",
        description: "Ryzyko długotrwałego i skomplikowanego refinansowania",
        icon: "Clock",
        warning: true
      }
    ]
  },
  calculations: {
    example: "Przykład refinansowania kredytu hipotecznego z dodatkową gotówką",
    before: "Obecny stan kredytu:\n• Kwota kredytu: 400 000 zł\n• Aktualne oprocentowanie: 5,81% + 2,3% marża (8,11%)\n• Wartość nieruchomości: 700 000 zł\n• Miesięczna rata: około 3 100 zł",
    after: "Po refinansowaniu:\n• Nowa kwota kredytu: 520 000 zł\n• Dodatkowa gotówka: 120 000 zł (ok. 17% wartości nieruchomości)\n• Nowe oprocentowanie: 5,81% + 1,6% marża (7,41%)\n• Nowa miesięczna rata: około 2 950 zł",
    savings: "Korzyści: Dodatkowe 120 000 zł gotówki przy obniżeniu miesięcznej raty o około 150 zł",
    extra: "Możliwość uzyskania stałego oprocentowania na pierwsze 5 lat kredytowania"
  },
  benefits: [
    "Dodatkowa gotówka od 10% do 30% wartości nieruchomości",
    "Potencjał obniżenia oprocentowania kredytu",
    "Jedna wygodna rata kredytowa",
    "Brak konieczności zmiany domu lub mieszkania",
    "Możliwość sfinansowania różnych celów",
    "Minimalne formalności przy refinansowaniu"
  ],
  limitedTimeOffer: {
    text: "Darmowa wycena nieruchomości przy refinansowaniu",
    expiry: "31.05.2025"
  },
  faq: [
    {
      question: "Ile dodatkowej gotówki mogę uzyskać przy refinansowaniu?",
      answer: "Kwota dodatkowej gotówki zależy od aktualnej wartości nieruchomości i Twojej zdolności kredytowej. Oferujemy refinansowanie w zakresie 10-30% wartości nieruchomości, co przy obecnych cenach rynkowych może oznaczać znaczące środki. Przykładowo, dla nieruchomości o wartości 700 000 zł możesz uzyskać od 70 000 zł do 210 000 zł dodatkowej gotówki."
    },
    {
      question: "Jak długo trwa proces refinansowania?",
      answer: "Standardowy proces refinansowania trwa 30-45 dni. Kluczowe etapy obejmują: 1) Wycenę nieruchomości (7-10 dni), 2) Analizę zdolności kredytowej (7-14 dni), 3) Decyzję kredytową (7-14 dni), 4) Przygotowanie i podpisanie umowy (7-10 dni). Dzięki naszemu wsparciu proces może być jeszcze szybszy."
    },
    {
      question: "Czy muszę mieć zgodę obecnego banku na refinansowanie?",
      answer: "Nie jest wymagana zgoda obecnego banku. Zgodnie z prawem bankowym masz pełną swobodę wyboru banku i przeniesienia kredytu. Będziemy Ci towarzyszyć w uzyskeniu zaświadczenia o zadłużeniu i przeprowadzeniu całego procesu refinansowania."
    },
    {
      question: "Jakie dokumenty są potrzebne do refinansowania?",
      answer: "Wymagane dokumenty to: 1) Dokumenty obecnego kredytu, 2) Zaświadczenia o dochodach, 3) Dokumenty nieruchomości (akt notarialny, księga wieczysta), 4) Historia spłat kredytu, 5) Operat szacunkowy nieruchomości. Pomożemy Ci w kompleksowym przygotowaniu dokumentacji."
    },
    {
      question: "Czy warto refinansować kredyt w 2025 roku?",
      answer: "Refinansowanie jest szczególnie atrakcyjne w 2025 roku ze względu na: 1) Wzrost wartości nieruchomości, 2) Konkurencyjne oferty banków, 3) Możliwość uzyskania stałego oprocentowania, 4) Prognozowane obniżki stóp procentowych. Rozważ refinansowanie, jeśli Twój obecny kredyt ma oprocentowanie powyżej 7% lub potrzebujesz dodatkowych środków."
    }
  ],
  nextSteps: [
    "Bezpłatna analiza możliwości refinansowania",
    "Profesjonalna wycena nieruchomości",
    "Kalkulacja dostępnej kwoty dodatkowej gotówki",
    "Porównanie ofert kredytowych",
    "Pomoc w przygotowaniu dokumentacji",
    "Złożenie i monitoring wniosku kredytowego",
    "Finalizacja refinansowania i wypłata środków"
  ]
};