import React, { useState, useEffect, useRef } from 'react';
import { Quote, Star, User2 } from 'lucide-react';

interface Testimonial {
  author: string;
  role: string;
  content: string;
  rating: number;
  image?: string;
}

const testimonials: Testimonial[] = [
  {
    author: "Dawid Jamroży",
    role: "Kredyt Hipoteczny",
    content: "Z pełnym przekonaniem polecam Pana Artura. Miałem przyjemność współpracować z nim już dwukrotnie przy uzyskiwaniu kredytu hipotecznego, i za każdym razem byłem w pełni usatysfakcjonowany jakością świadczonych przez niego usług. Pan Artur wyróżnia się nie tylko profesjonalizmem i ogromną wiedzą w dziedzinie finansów, ale także cierpliwością i zaangażowaniem, które sprawiają, że cały proces przebiega sprawnie i bezstresowo. Bez wątpienia będę kontynuował współpracę z Panem Arturem w przyszłości i polecam go każdemu, kto szuka rzetelnego doradcy finansowego.",
    rating: 5,
    image: "https://lh3.googleusercontent.com/a-/ALV-UjWNx9pjN7qzdihIBftvEyZ0z3tzh1xtRVn5j95PfO30oW18tAgb=w66-h66-p-rp-mo-ba2-br100"
  },
  {
    author: "Wojciech Rzadkosz",
    role: "Kredyt Inwestycyjny",
    content: "Gorąco polecam Fundamenty Majątku! Proces uzyskania kredytu firmowego był bardzo wymagający (kredyt na prognozę), z zaangażowaniem wielu banków, jednak dzięki profesjonalizmowi Artura oraz jego szerokim kontaktom z bankami, w tym z lokalnymi bankami spółdzielczymi, dopięliśmy kredyt na nadspodziewanie korzystnych warunkach. Niezwykłe zaangażowanie i pomoc na każdym etapie. Dziękuję za świetną współpracę!",
    rating: 5,
    image: "https://lh3.googleusercontent.com/a-/ALV-UjUD_5xQxPwkVZQW1KKjNaONUNRfTL5mjcMdbBcHI35mvKmNZ4k=w66-h66-p-rp-mo-br100"
  },
  {
    author: "Barbara Lew",
    role: "Kredyt Hipoteczny",
    content: "Bardzo polecam, profesjonalna obsługa, pomoc w zaplanowaniu sytuacji finansowej a następnie w koordynacji całego procesu od samego początku do końca, korzystałam i planuję nadal korzystać z usług, jestem bardzo zadowolona",
    rating: 5,
    image: "https://lh3.googleusercontent.com/a-/ALV-UjUgC71mUQ7Vn4SWgsfsp2rNXvFAzP-RzDnHtEO-SuozbbN7T-0=w66-h66-p-rp-mo-br100"
  },
  {
    author: "Michał Schabowski",
    role: "Kredyt Hipoteczny",
    content: "Polecamy - poprzedni agent przez pół roku udawał że coś robi i gdyby nie telefon do Artura, to byśmy obudzili się z ręką w nocniku. W miesiąc załatwił coś, co wydawało się już niemożliwe do zrobienia.",
    rating: 5,
    image: "https://lh3.googleusercontent.com/a-/ALV-UjUye3JFyKggm1dTh49W6O34wH63_R0ZmVu1CSI_j6qt14_MRCJODA=w66-h66-p-rp-mo-ba4-br100"
  }
];

export default function Testimonials() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <section 
      ref={sectionRef}
      className="py-16 md:py-24 bg-gradient-to-b from-gray-50 to-white overflow-hidden"
    >
      <div className="container mx-auto px-4">
        {/* Section Header */}
        <div className={`
          max-w-3xl mx-auto text-center mb-12 md:mb-16
          transform transition-all duration-1000
          ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'}
        `}>
          <span className="inline-block text-[#CBA331] font-semibold mb-2 text-sm sm:text-base">
            Opinie moich klientów
          </span>
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#003A30] mb-3">
            Co mówią o mnie klienci?
          </h2>
          <p className="text-gray-600 text-base sm:text-lg">
            Poznaj prawdziwe historie i opinie osób, którym pomogłem w realizacji marzeń
          </p>
        </div>

        {/* Testimonials Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {testimonials.map((testimonial, index) => (
            <div 
              key={index}
              className={`
                bg-white rounded-2xl shadow-lg p-5
                transform transition-all duration-700 delay-${index * 100}
                ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'}
              `}
            >
              {/* Quote Icon */}
              <div className="absolute -top-3 -left-3 w-7 h-7 bg-[#CBA331] rounded-full flex items-center justify-center shadow-lg">
                <Quote className="w-3 h-3 text-white" />
              </div>

              {/* Content */}
              <div className="flex flex-col gap-4">
                {/* Author Image */}
                <div className="flex justify-center">
                  <div className="relative w-16 h-16 rounded-full overflow-hidden shadow-lg">
                    {testimonial.image ? (
                      <img 
                        src={testimonial.image} 
                        alt={testimonial.author}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                        <User2 className="w-6 h-6 text-gray-400" />
                      </div>
                    )}
                    <div className="absolute inset-0 ring-2 ring-[#003A30]/10 rounded-full"></div>
                  </div>
                </div>

                {/* Testimonial Content */}
                <div className="text-center">
                  <div className="flex items-center justify-center gap-1 mb-3">
                    {[...Array(testimonial.rating)].map((_, i) => (
                      <Star key={i} className="w-4 h-4 text-[#CBA331] fill-current" />
                    ))}
                  </div>
                  <blockquote className="text-gray-600 text-sm mb-4 italic line-clamp-4">
                    "{testimonial.content}"
                  </blockquote>
                  <div>
                    <cite className="not-italic">
                      <span className="block text-base font-semibold text-[#003A30]">
                        {testimonial.author}
                      </span>
                      <span className="text-xs text-gray-500">
                        {testimonial.role}
                      </span>
                    </cite>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Trust Indicators */}
        <div className="mt-12 md:mt-16 text-center">
          <p className="text-gray-500 text-sm sm:text-base">
            Dołącz do grona <span className="text-[#003A30] font-semibold">1200+</span> zadowolonych klientów
          </p>
        </div>
      </div>
    </section>
  );
}