/**
 * Set meta tags for SEO
 * @param title - Page title
 * @param description - Page description
 * @param keywords - Page keywords
 */
export function setMetaTags(title: string, description: string, keywords: string[]) {
  // Set document title
  document.title = title;
  
  // Update meta description
  let metaDescription = document.querySelector('meta[name="description"]');
  if (!metaDescription) {
    metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    document.head.appendChild(metaDescription);
  }
  metaDescription.setAttribute('content', description);
  
  // Update meta keywords
  let metaKeywords = document.querySelector('meta[name="keywords"]');
  if (!metaKeywords) {
    metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    document.head.appendChild(metaKeywords);
  }
  metaKeywords.setAttribute('content', keywords.join(', '));
}

/**
 * Set Open Graph tags
 * @param data - Open Graph data
 */
export function setOpenGraphTags(data: { 
  title: string, 
  description: string, 
  image: string,
  url?: string,
  type?: string
}) {
  const ogTags = [
    { property: 'og:title', content: data.title },
    { property: 'og:description', content: data.description },
    { property: 'og:image', content: data.image },
    { property: 'og:type', content: data.type || 'article' },
    { property: 'og:url', content: data.url || window.location.href }
  ];
  
  ogTags.forEach(tag => {
    let metaTag = document.querySelector(`meta[property="${tag.property}"]`);
    if (!metaTag) {
      metaTag = document.createElement('meta');
      metaTag.setAttribute('property', tag.property);
      document.head.appendChild(metaTag);
    }
    metaTag.setAttribute('content', tag.content);
  });
}

/**
 * Set Twitter Card tags
 * @param data - Twitter Card data
 */
export function setTwitterTags(data: {
  title: string,
  description: string,
  image: string,
  card?: 'summary' | 'summary_large_image' | 'app' | 'player'
}) {
  const twitterTags = [
    { name: 'twitter:card', content: data.card || 'summary_large_image' },
    { name: 'twitter:title', content: data.title },
    { name: 'twitter:description', content: data.description },
    { name: 'twitter:image', content: data.image }
  ];
  
  twitterTags.forEach(tag => {
    let metaTag = document.querySelector(`meta[name="${tag.name}"]`);
    if (!metaTag) {
      metaTag = document.createElement('meta');
      metaTag.setAttribute('name', tag.name);
      document.head.appendChild(metaTag);
    }
    metaTag.setAttribute('content', tag.content);
  });
}

/**
 * Set all meta tags for a blog post
 * @param post - Blog post data
 */
export function setAllMetaTags(post: {
  title: string,
  excerpt: string,
  image: string,
  seo: {
    title: string,
    description: string,
    keywords: string[]
  }
}) {
  // Set basic meta tags
  setMetaTags(
    post.seo.title || post.title,
    post.seo.description || post.excerpt,
    post.seo.keywords
  );
  
  // Set Open Graph tags
  setOpenGraphTags({
    title: post.title,
    description: post.excerpt,
    image: post.image,
    type: 'article'
  });
  
  // Set Twitter Card tags
  setTwitterTags({
    title: post.title,
    description: post.excerpt,
    image: post.image,
    card: 'summary_large_image'
  });
}