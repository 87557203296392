import React from 'react';
import Hero from '@/components/shared/Hero';
import ProblemsSolutions from '@/components/shared/ProblemsSolutions';
import Process from '@/components/shared/Process';
import CaseStudyList from '@/components/shared/CaseStudyList';
import ComparisonCards from '@/components/shared/ComparisonCards';
import ServicePageNavigation from '@/components/shared/ServicePageNavigation';
import { businessProblemsData } from '@/data/ProblemsSolutionsData';
import { businessCases } from '@/data/caseStudies/business';
import { BUSINESS_CARDS, BUSINESS_DETAILS } from '@/data/modal/business';
import Calculator from './sections/Calculator';
import Concerns from './sections/Concerns';
import TrustBar from './sections/TrustBar';
import Contact from '@/components/shared/Contact';
import { Shield, Clock, BadgePercent } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function BusinessLoan() {
  const navigate = useNavigate();

  const scrollToCalculator = () => {
    document.getElementById('calculator')?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
    <div className="pt-20 bg-gray-50">
      <ServicePageNavigation />
      
      <Hero 
        title="Kredyty firmowe dla Twojego biznesu"
        highlightedWord="biznesu"
        description="Wspieramy rozwój Twojej firmy poprzez profesjonalne doradztwo w zakresie finansowania. Gwarantujemy najlepsze warunki i szybką decyzję."
        image="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?auto=format&fit=crop&q=80"
        badgeText="Ponad 500 firm skorzystało z naszych usług"
        stats={[
          {
            icon: Clock,
            value: "48h",
            label: "Na decyzję"
          },
          {
            icon: BadgePercent,
            value: "2.1 mld",
            label: "Wartość finansowania"
          },
          {
            icon: Shield,
            value: "95%",
            label: "Pozytywnych decyzji"
          }
        ]}
        primaryCta={{
          label: "Rozwiń swój biznes",
          action: () => navigate('#contact')
        }}
        secondaryCta={{
          label: "Sprawdź zdolność",
          action: scrollToCalculator
        }}
      />
      <ProblemsSolutions 
        problems={businessProblemsData.problems}
        title={businessProblemsData.title}
        subtitle={businessProblemsData.subtitle}
      />
      <section id="calculator">
        <Calculator />
      </section>
      <section id="types">
        <ComparisonCards 
          cards={BUSINESS_CARDS}
          details={BUSINESS_DETAILS}
          title="Rodzaje kredytów firmowych"
          subtitle="Poznaj różne możliwości finansowania Twojej firmy"
        />
      </section>
      <Process />
      <section id="testimonials">
        <CaseStudyList 
          cases={businessCases}
          title="Historie sukcesu kredytów firmowych"
          subtitle="Zobacz, jak pomogliśmy przedsiębiorcom rozwinąć ich biznesy"
        />
      </section>
      <Concerns />
      <TrustBar />
      <section id="contact">
        <Contact />
      </section>
    </div>
  );
}