import React from 'react';
import Hero from '@/components/shared/Hero';
import { FileText, Download, Users, Star } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function Guides() {
  const navigate = useNavigate();

  return (
    <div className="pt-20 bg-gray-50">
      <Hero 
        title="Poradniki i przewodniki kredytowe"
        highlightedWord="przewodniki"
        description="Kompleksowe przewodniki i poradniki dotyczące kredytów i finansów. Praktyczna wiedza zebrana w jednym miejscu."
        image="https://images.unsplash.com/photo-1434030216411-0b793f4b4173?auto=format&fit=crop&q=80"
        stats={[
          {
            icon: FileText,
            value: "25+",
            label: "Poradników"
          },
          {
            icon: Download,
            value: "10k+",
            label: "Pobrań"
          },
          {
            icon: Star,
            value: "4.9/5",
            label: "Średnia ocena"
          }
        ]}
        primaryCta={{
          label: "Pobierz poradniki",
          action: () => navigate('#guides')
        }}
        secondaryCta={{
          label: "Skontaktuj się z ekspertem",
          action: () => navigate('#contact')
        }}
      />

      {/* Guides Grid */}
      <section id="guides" className="py-12 md:py-20">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Example guides */}
            {[
              "Kompletny przewodnik po kredycie hipotecznym",
              "Jak wybrać najlepszy kredyt firmowy",
              "Refinansowanie kredytu krok po kroku",
              "Optymalizacja kosztów kredytowych",
              "Zdolność kredytowa - co warto wiedzieć",
              "Pierwsza nieruchomość - przewodnik"
            ].map((guide, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300">
                <FileText className="w-12 h-12 text-[#CBA331] mb-4" />
                <h2 className="text-xl font-bold text-[#003A30] mb-3">{guide}</h2>
                <p className="text-gray-600 mb-4">
                  Szczegółowy poradnik zawierający praktyczne wskazówki i porady eksperckie.
                </p>
                <button className="flex items-center gap-2 text-[#003A30] font-semibold hover:text-[#CBA331] transition-colors duration-300">
                  <Download className="w-5 h-5" />
                  Pobierz PDF
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}