import { AlertCircle } from 'lucide-react';
import type { ProblemsSolutionsData } from './types';

export const mortgageProblemsData: ProblemsSolutionsData = {
  title: "Pokonujemy przeszkody kredytowe",
  subtitle: "Odkryj, jak skutecznie eliminujemy typowe bariery na drodze do Twojego wymarzonego domu",
  problems: [
    {
      icon: AlertCircle,
      problem: "Ryzyko odmowy przez bank",
      solution: "Gwarancja poprawnego wniosku",
      details: [
        "Wstępna, bezbłędna analiza zdolności kredytowej już na starcie",
        "Perfekcyjne przygotowanie dokumentacji, której banki nie odrzucają",
        "Strategiczne eliminowanie wszystkich przeszkód kredytowych"
      ]
    },
    {
      icon: AlertCircle,
      problem: "Obawa przed zbyt długim zobowiązaniem",
      solution: "Idealnie dopasowane warunki",
      details: [
        "Precyzyjny dobór okresu kredytowania, który nie obciąży Twojego budżetu",
        "Wynegocjowanie możliwości bezkosztowej wcześniejszej nadpłaty i spłaty w każdej chwili",
        "Sprawdzone strategie uwalniające Cię od długoterminowego stresu finansowego"
      ]
    },
    {
      icon: AlertCircle,
      problem: "Ryzyko zmiennych stóp procentowych",
      solution: "Pełna ochrona Twojego budżetu",
      details: [
        "Specjalistyczny dobór najkorzystniejszej oferty zabezpieczającej przed zmianami rynkowymi",
        "Ekspercka analiza minimalizująca ryzyko niespodziewanych podwyżek rat",
        "Unikalne strategie ochronne"
      ]
    },
    {
      icon: AlertCircle,
      problem: "Brak wiedzy i zrozumienia procesu",
      solution: "Twój osobisty ekspert kredytowy",
      details: [
        "Indywidualne przeprowadzenie przez każdy etap, bez stresu i niepewności",
        "Transformacja żargonu bankowego w jasne, zrozumiałe informacje dla Ciebie",
        "Natychmiastowy dostęp do wiedzy eksperta, oszczędzający Twój cenny czas"
      ]
    }
  ]
};