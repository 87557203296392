import React from 'react';
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary';
import { AlertCircle, RefreshCw } from 'lucide-react';

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => (
  <div className="bg-red-50 border border-red-200 text-red-700 p-6 rounded-lg">
    <div className="flex items-start gap-3">
      <AlertCircle className="w-6 h-6 text-red-500 flex-shrink-0 mt-0.5" />
      <div>
        <h3 className="text-lg font-medium mb-2">Coś poszło nie tak</h3>
        <p className="text-sm mb-4">{error.message}</p>
        <button
          onClick={resetErrorBoundary}
          className="inline-flex items-center px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors duration-200"
        >
          <RefreshCw className="w-4 h-4 mr-2" />
          Spróbuj ponownie
        </button>
      </div>
    </div>
  </div>
);

export const BlogErrorBoundary = ({ children }: { children: React.ReactNode }) => (
  <ReactErrorBoundary FallbackComponent={ErrorFallback}>
    {children}
  </ReactErrorBoundary>
);