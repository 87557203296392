/**
 * Format date to locale string
 * @param dateString - Date string to format
 * @param locale - Locale to use for formatting (default: 'pl-PL')
 * @returns Formatted date string
 */
export function formatDate(dateString: string, locale: string = 'pl-PL'): string {
  return new Date(dateString).toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}

/**
 * Format date to relative time (e.g. "2 days ago")
 * @param dateString - Date string to format
 * @param locale - Locale to use for formatting (default: 'pl-PL')
 * @returns Relative time string
 */
export function formatRelativeTime(dateString: string, locale: string = 'pl-PL'): string {
  const date = new Date(dateString);
  const now = new Date();
  const diffInMs = now.getTime() - date.getTime();
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  
  if (diffInDays === 0) {
    return 'Dzisiaj';
  } else if (diffInDays === 1) {
    return 'Wczoraj';
  } else if (diffInDays < 7) {
    return `${diffInDays} dni temu`;
  } else if (diffInDays < 30) {
    const weeks = Math.floor(diffInDays / 7);
    return `${weeks} ${weeks === 1 ? 'tydzień' : weeks < 5 ? 'tygodnie' : 'tygodni'} temu`;
  } else if (diffInDays < 365) {
    const months = Math.floor(diffInDays / 30);
    return `${months} ${months === 1 ? 'miesiąc' : months < 5 ? 'miesiące' : 'miesięcy'} temu`;
  } else {
    const years = Math.floor(diffInDays / 365);
    return `${years} ${years === 1 ? 'rok' : years < 5 ? 'lata' : 'lat'} temu`;
  }
}

/**
 * Format date for schema.org (ISO format)
 * @param dateString - Date string to format
 * @returns ISO formatted date string
 */
export function formatSchemaDate(dateString: string): string {
  return new Date(dateString).toISOString();
}