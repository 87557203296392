import React from 'react';
import { Calculator, Plus, Trash2, Info } from 'lucide-react';
import type { LoanData, OneTimePayment } from '../MortgageCalculator';

interface InputPanelProps {
  loanData: LoanData;
  onLoanDataChange: (newData: Partial<LoanData>) => void;
}

export default function InputPanel({ loanData, onLoanDataChange }: InputPanelProps) {
  const [showWiborChange, setShowWiborChange] = React.useState(false);

  // Format currency
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  // Format percentage
  const formatPercentage = (value: number) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value / 100);
  };

  // Add one-time payment
  const addOneTimePayment = () => {
    const newPayment: OneTimePayment = {
      id: Date.now().toString(),
      month: 12, // Default to 1 year
      amount: 10000
    };
    
    onLoanDataChange({
      oneTimePayments: [...loanData.oneTimePayments, newPayment]
    });
  };

  // Remove one-time payment
  const removeOneTimePayment = (id: string) => {
    onLoanDataChange({
      oneTimePayments: loanData.oneTimePayments.filter(payment => payment.id !== id)
    });
  };

  // Update one-time payment
  const updateOneTimePayment = (id: string, field: 'month' | 'amount', value: number) => {
    onLoanDataChange({
      oneTimePayments: loanData.oneTimePayments.map(payment => 
        payment.id === id ? { ...payment, [field]: value } : payment
      )
    });
  };

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden h-full">
      <div className="p-6 md:p-8">
        <div className="flex items-center justify-center gap-3 mb-8">
          <div className="relative">
            <Calculator className="w-8 h-8 text-[#CBA331]" />
            <div className="absolute -top-1 -right-1 w-3 h-3 bg-[#003A30] rounded-full animate-pulse"></div>
          </div>
          <h2 className="text-2xl font-bold text-[#003A30] relative">
            Parametry kredytu
            <div className="absolute -bottom-1 left-0 w-full h-0.5 bg-gradient-to-r from-[#CBA331]/0 via-[#CBA331] to-[#CBA331]/0"></div>
          </h2>
        </div>

        <div className="space-y-8">
          {/* Kwota kredytu */}
          <div>
            <label htmlFor="amount" className="block text-sm font-medium text-gray-700 mb-2 flex items-center justify-between">
              <span>Kwota kredytu</span>
              <span className="text-[#CBA331] font-semibold">{formatCurrency(loanData.amount)}</span>
            </label>
            <input
              type="range"
              id="amount"
              name="amount"
              min="300000"
              max="2000000"
              step="10000"
              value={loanData.amount}
              onChange={(e) => onLoanDataChange({ amount: Number(e.target.value) })}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-[#003A30]"
            />
            <div className="mt-1 flex justify-between text-xs text-gray-500">
              <span>300 tys.</span>
              <span>2 mln</span>
            </div>
          </div>

          {/* Okres kredytowania */}
          <div>
            <label htmlFor="period" className="block text-sm font-medium text-gray-700 mb-2 flex items-center justify-between">
              <span>Okres kredytowania</span>
              <span className="text-[#CBA331] font-semibold">{loanData.period} lat</span>
            </label>
            <input
              type="range"
              id="period"
              name="period"
              min="5"
              max="35"
              value={loanData.period}
              onChange={(e) => onLoanDataChange({ period: Number(e.target.value) })}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-[#003A30]"
            />
            <div className="mt-1 flex justify-between text-xs text-gray-500">
              <span>5 lat</span>
              <span>35 lat</span>
            </div>
          </div>

          {/* Oprocentowanie */}
          <div>
            <label htmlFor="interestRate" className="block text-sm font-medium text-gray-700 mb-2 flex items-center justify-between">
              <span>Oprocentowanie</span>
              <span className="text-[#CBA331] font-semibold">{formatPercentage(loanData.interestRate)}</span>
            </label>
            <input
              type="range"
              id="interestRate"
              name="interestRate"
              step="0.1"
              min="3"
              max="12"
              value={loanData.interestRate}
              onChange={(e) => onLoanDataChange({ interestRate: Number(e.target.value) })}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-[#003A30]"
            />
            <div className="mt-1 flex justify-between text-xs text-gray-500">
              <span>3%</span>
              <span>12%</span>
            </div>
          </div>

          {/* Zmiana WIBOR */}
          <div>
            <div className="flex items-center justify-between mb-2">
              <label className="text-sm font-medium text-gray-700 flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={showWiborChange}
                  onChange={() => {
                    setShowWiborChange(!showWiborChange);
                    if (!showWiborChange) {
                      onLoanDataChange({ wiborChangeYear: 3, wiborChangeRate: 5.7 });
                    } else {
                      onLoanDataChange({ wiborChangeYear: undefined, wiborChangeRate: undefined });
                    }
                  }}
                  className="rounded text-[#003A30] focus:ring-[#003A30]"
                />
                Zmiana WIBOR w trakcie kredytowania
              </label>
              <button
                type="button"
                className="text-gray-400 hover:text-[#003A30] transition-colors"
                title="Informacja o zmianie WIBOR"
              >
                <Info className="w-4 h-4" />
              </button>
            </div>

            {showWiborChange && (
              <div className="grid grid-cols-2 gap-4 p-4 bg-gray-50 rounded-lg">
                <div>
                  <label htmlFor="wiborChangeYear" className="block text-sm font-medium text-gray-700 mb-1">
                    Od którego roku
                  </label>
                  <input
                    type="number"
                    id="wiborChangeYear"
                    min="1"
                    max={loanData.period - 1}
                    value={loanData.wiborChangeYear || 3}
                    onChange={(e) => onLoanDataChange({ wiborChangeYear: Number(e.target.value) })}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-[#003A30] focus:border-[#003A30]"
                  />
                </div>
                <div>
                  <label htmlFor="wiborChangeRate" className="block text-sm font-medium text-gray-700 mb-1">
                    Nowe oprocentowanie
                  </label>
                  <input
                    type="number"
                    id="wiborChangeRate"
                    step="0.1"
                    min="1"
                    max="15"
                    value={loanData.wiborChangeRate || 5.7}
                    onChange={(e) => onLoanDataChange({ wiborChangeRate: Number(e.target.value) })}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-[#003A30] focus:border-[#003A30]"
                  />
                </div>
                <p className="text-xs text-gray-500">
                  {loanData.wiborChangeYear 
                    ? `Przy oprocentowaniu ${formatPercentage(loanData.wiborChangeRate || 0)}`
                    : "Brak zmiany WIBOR"}
                </p>
              </div>
            )}
          </div>

          {/* Nadpłaty */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-3">Nadpłaty</label>
            
            {/* Miesięczna nadpłata */}
            <div className="mb-6">
              <label htmlFor="monthlyOverpayment" className="block text-sm font-medium text-gray-700 mb-2 flex items-center justify-between">
                <span>Stała miesięczna nadpłata</span>
                <span className="text-[#CBA331] font-semibold">{formatCurrency(loanData.monthlyOverpayment)}</span>
              </label>
              <input
                type="range"
                id="monthlyOverpayment"
                name="monthlyOverpayment"
                min="0"
                max="10000"
                step="100"
                value={loanData.monthlyOverpayment}
                onChange={(e) => onLoanDataChange({ monthlyOverpayment: Number(e.target.value) })}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-[#003A30]"
              />
              <div className="mt-1 flex justify-between text-xs text-gray-500">
                <span>0 zł</span>
                <span>10 000 zł</span>
              </div>
            </div>
            
            {/* Jednorazowe nadpłaty */}
            <div>
              <div className="flex items-center justify-between mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Jednorazowe nadpłaty
                </label>
                <button
                  type="button"
                  onClick={addOneTimePayment}
                  className="inline-flex items-center px-3 py-2 bg-[#003A30] text-white text-sm rounded-lg hover:bg-[#CBA331] transition-colors duration-300"
                >
                  <Plus className="w-4 h-4 mr-1" />
                  Dodaj nadpłatę
                </button>
              </div>
              
              {loanData.oneTimePayments.length > 0 ? (
                <div className="space-y-3">
                  {loanData.oneTimePayments.map((payment) => (
                    <div key={payment.id} className="flex items-center gap-3 bg-white p-4 rounded-lg shadow-sm border border-gray-100">
                      <div className="flex-grow grid grid-cols-2 gap-4">
                        <div>
                          <label htmlFor={`month-${payment.id}`} className="block text-xs font-medium text-gray-500 mb-1">
                            Miesiąc
                          </label>
                          <input
                            type="number"
                            id={`month-${payment.id}`}
                            min="1"
                            max={loanData.period * 12}
                            value={payment.month}
                            onChange={(e) => updateOneTimePayment(payment.id, 'month', Number(e.target.value))}
                            className="w-full px-3 py-2 text-sm border border-gray-300 rounded-lg focus:ring-1 focus:ring-[#003A30] focus:border-[#003A30]"
                          />
                        </div>
                        <div>
                          <label htmlFor={`amount-${payment.id}`} className="block text-xs font-medium text-gray-500 mb-1">
                            Kwota (PLN)
                          </label>
                          <input
                            type="number"
                            id={`amount-${payment.id}`}
                            min="1000"
                            step="1000"
                            value={payment.amount}
                            onChange={(e) => updateOneTimePayment(payment.id, 'amount', Number(e.target.value))}
                            className="w-full px-3 py-2 text-sm border border-gray-300 rounded-lg focus:ring-1 focus:ring-[#003A30] focus:border-[#003A30]"
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={() => removeOneTimePayment(payment.id)}
                        className="p-2 text-red-500 hover:text-red-700 hover:bg-red-50 rounded-full transition-colors duration-300"
                        aria-label="Usuń nadpłatę"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-6 bg-white rounded-lg border border-dashed border-gray-300">
                  <p className="text-sm text-gray-500">Brak jednorazowych nadpłat</p>
                  <button
                    type="button"
                    onClick={addOneTimePayment}
                    className="mt-2 text-[#003A30] hover:text-[#CBA331] text-sm font-medium transition-colors duration-300"
                  >
                    + Dodaj pierwszą nadpłatę
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}