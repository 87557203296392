import React from 'react';
import { Phone, ArrowRight } from 'lucide-react';
import NavDesktopSubmenu from './NavDesktopSubmenu';
import { MENUS, SECTION_IDS } from './navData';

interface NavDesktopMenuProps {
  onNavigate: (path: string) => void;
}

export default function NavDesktopMenu({ onNavigate }: NavDesktopMenuProps) {
  return (
    <div className="hidden md:flex items-center justify-between flex-1 pl-8">
      {/* Main menu */}
      <div className="flex items-center justify-center gap-8 flex-1">
        <NavDesktopSubmenu 
          title="Usługi" 
          items={MENUS.services} 
          sectionId={SECTION_IDS.services}
          onItemClick={onNavigate} 
        />
        <NavDesktopSubmenu 
          title="Kalkulatory" 
          items={MENUS.calculators} 
          sectionId={SECTION_IDS.calculators}
          onItemClick={onNavigate} 
        />
        <NavDesktopSubmenu 
          title="Strefa wiedzy" 
          items={MENUS.knowledge} 
          sectionId={SECTION_IDS.knowledge}
          onItemClick={onNavigate} 
        />
        
        <button
          onClick={() => onNavigate('#contact')}
          className="text-base font-medium text-gray-700 hover:text-[#003A30] transition-colors duration-300 py-2"
        >
          Kontakt
        </button>
      </div>

      {/* Contact and CTA */}
      <div className="flex items-center gap-6">
        <a 
          href="tel:+48539211881" 
          className="flex items-center gap-2 font-medium text-[#003A30] hover:text-[#CBA331] transition-colors duration-300 group whitespace-nowrap"
        >
          <Phone className="w-4 h-4 group-hover:scale-110 transition-transform duration-300" />
          +48 539 211 881
        </a>
        
        {/* Enhanced CTA Button */}
        <button 
          onClick={() => onNavigate('#contact')}
          className="group relative inline-flex items-center px-6 py-2.5 bg-[#003A30] text-white rounded-lg hover:bg-[#CBA331] transition-all duration-300 shadow-sm hover:shadow-lg overflow-hidden"
        >
          {/* Animated background gradient */}
          <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-transparent via-white/10 to-transparent -translate-x-full group-hover:translate-x-full transition-transform duration-1000 ease-out" />
          
          {/* Text with hover effect */}
          <span className="relative flex items-center gap-2">
            <span className="relative">
              Bezpłatna konsultacja
              <span className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-white to-transparent transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500" />
            </span>
            <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform duration-300" />
          </span>
        </button>
      </div>
    </div>
  );
}