export * from './optimization/cost-reduction';
export * from './optimization/structure-optimization';
export * from './optimization/debt-management';

import { COST_REDUCTION_CARD, COST_REDUCTION_DETAILS } from './optimization/cost-reduction';
import { STRUCTURE_OPTIMIZATION_CARD, STRUCTURE_OPTIMIZATION_DETAILS } from './optimization/structure-optimization';
import { DEBT_MANAGEMENT_CARD, DEBT_MANAGEMENT_DETAILS } from './optimization/debt-management';

export const OPTIMIZATION_CARDS = [
  COST_REDUCTION_CARD,
  STRUCTURE_OPTIMIZATION_CARD,
  DEBT_MANAGEMENT_CARD
];

export const OPTIMIZATION_DETAILS = {
  "cost-reduction": COST_REDUCTION_DETAILS,
  "structure-optimization": STRUCTURE_OPTIMIZATION_DETAILS,
  "debt-management": DEBT_MANAGEMENT_DETAILS
};