import React from 'react';
import { TrendingUp, CheckCircle2, Shield, ArrowRight, Sparkles } from 'lucide-react';

export default function IncreaseCapacitySection() {
  const strategies = [
    {
      title: "Indywidualna strategia",
      description: "Opracuję spersonalizowany plan zwiększenia Twojej zdolności kredytowej, dopasowany do Twojej sytuacji.",
      points: [
        "Analiza obecnej sytuacji",
        "Plan działania",
        "Monitoring postępów"
      ]
    },
    {
      title: "Sprawdzone metody",
      description: "Wykorzystuję skuteczne techniki optymalizacji zdolności kredytowej, potwierdzone wieloletnim doświadczeniem.",
      points: [
        "Optymalizacja dochodów",
        "Restrukturyzacja zobowiązań",
        "Poprawa scoringu"
      ]
    },
    {
      title: "Gwarancja skuteczności",
      description: "Moje metody są w pełni legalne i bezpieczne, a ich skuteczność potwierdza 98% zadowolonych klientów.",
      points: [
        "Legalne metody",
        "Bezpieczeństwo procesu",
        "Potwierdzona skuteczność"
      ]
    }
  ];

  return (
    <section className="py-12 md:py-16 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-12">
          <div className="flex items-center justify-center gap-3 mb-4">
            <div className="relative">
              <TrendingUp className="w-8 h-8 text-[#CBA331]" />
              <Sparkles className="w-4 h-4 text-[#CBA331] absolute -top-1 -right-1 animate-pulse" />
            </div>
            <h2 className="text-2xl md:text-3xl font-bold text-[#003A30] relative">
              Chcesz zwiększyć swoją zdolność kredytową?
              <div className="absolute -bottom-1 left-0 w-full h-0.5 bg-gradient-to-r from-[#CBA331]/0 via-[#CBA331] to-[#CBA331]/0"></div>
            </h2>
          </div>
          <p className="text-lg text-gray-600">
            Pomogę Ci zwiększyć zdolność kredytową nawet o 47% dzięki sprawdzonym strategiom optymalizacji.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {strategies.map((strategy, index) => (
            <div 
              key={index}
              className="group bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-all duration-300 relative overflow-hidden"
            >
              {/* Top Gradient Bar */}
              <div 
                className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-[#003A30] to-[#CBA331] transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100" 
                aria-hidden="true"
              />

              <div className="flex flex-col h-full">
                <h3 className="text-xl font-bold text-[#003A30] mb-4 flex items-center gap-2">
                  <CheckCircle2 className="w-6 h-6 text-[#CBA331] group-hover:scale-110 transition-transform duration-300" />
                  {strategy.title}
                </h3>
                <p className="text-gray-600 mb-6">
                  {strategy.description}
                </p>
                <ul className="space-y-3 mb-6 flex-grow">
                  {strategy.points.map((point, idx) => (
                    <li 
                      key={idx}
                      className="flex items-center gap-2 text-gray-600"
                    >
                      <div className="w-1.5 h-1.5 rounded-full bg-[#CBA331]" />
                      {point}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        {/* CTA Button */}
        <div className="mt-12 text-center">
          <div className="relative inline-block">
            {/* Pulsing background effect */}
            <div className="absolute inset-0 bg-gradient-to-r from-[#003A30] to-[#CBA331] rounded-lg opacity-70 blur-md animate-pulse" aria-hidden="true"></div>
            
            <button
              onClick={() => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })}
              className="relative inline-flex items-center justify-center px-8 py-4 bg-gradient-to-r from-[#003A30] to-[#003A30]/90 text-white rounded-lg shadow-lg hover:shadow-xl hover:from-[#CBA331] hover:to-[#CBA331]/90 transition-all duration-500 group text-lg font-bold overflow-hidden z-10"
            >
              {/* Subtle background animation */}
              <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-transparent via-white/10 to-transparent -translate-x-full group-hover:translate-x-full transition-transform duration-1000 ease-in-out" aria-hidden="true"></span>
              
              <Shield className="w-6 h-6 mr-3 text-[#CBA331] group-hover:text-white transition-colors duration-300" />
              Umów bezpłatną konsultację
              <ArrowRight className="w-5 h-5 ml-3 group-hover:translate-x-2 transition-transform duration-300" />
            </button>
            
            {/* Subtle shadow effect */}
            <div className="absolute -bottom-2 inset-x-4 h-2 bg-black/10 blur-md rounded-full" aria-hidden="true"></div>
          </div>
        </div>
      </div>
    </section>
  );
}