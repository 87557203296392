import { useState, useEffect, useRef } from 'react';

interface Heading {
  id: string;
  text: string;
  level: number;
}

export function useHeadingsObserver() {
  const [headings, setHeadings] = useState<Heading[]>([]);
  const [activeHeading, setActiveHeading] = useState<string | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  
  useEffect(() => {
    // Find all headings in the article content
    const articleContent = document.querySelector('.prose');
    if (!articleContent) return;
    
    const headingElements = articleContent.querySelectorAll('h2, h3, h4, h5, h6');
    
    // Create array of heading data
    const headingData: Heading[] = Array.from(headingElements).map(heading => {
      // Create ID if not exists
      if (!heading.id) {
        const id = heading.textContent
          ?.toLowerCase()
          .replace(/[^\w\s]/gi, '')
          .replace(/\s+/g, '-') || '';
        heading.id = id;
      }
      
      return {
        id: heading.id,
        text: heading.textContent || '',
        level: parseInt(heading.tagName.substring(1)) // Extract level from tag name (h2 -> 2)
      };
    });
    
    setHeadings(headingData);
    
    // Set up intersection observer with throttling
    let ticking = false;
    
    const observer = new IntersectionObserver(
      entries => {
        if (!ticking) {
          window.requestAnimationFrame(() => {
            // Get all headings that are currently visible
            const visibleHeadings = entries
              .filter(entry => entry.isIntersecting)
              .map(entry => entry.target.id);
            
            // Set the first visible heading as active
            if (visibleHeadings.length > 0) {
              setActiveHeading(visibleHeadings[0]);
            }
            
            ticking = false;
          });
          ticking = true;
        }
      },
      {
        rootMargin: '-100px 0px -80% 0px', // Adjust these values to control when headings are considered visible
        threshold: 0
      }
    );
    
    // Observe all headings
    headingElements.forEach(heading => {
      observer.observe(heading);
    });
    
    // Store observer reference
    observerRef.current = observer;
    
    // Clean up
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);
  
  return { headings, activeHeading };
}