import React from 'react';
import { ChevronDown } from 'lucide-react';
import { MenuItem } from './navData';

interface NavMobileSubmenuProps {
  title: string;
  items: MenuItem[];
  isOpen: boolean;
  onToggle: () => void;
  onItemClick: (path: string) => void;
}

export default function NavMobileSubmenu({
  title,
  items,
  isOpen,
  onToggle,
  onItemClick
}: NavMobileSubmenuProps) {
  return (
    <div className="relative">
      <button
        className="flex items-center justify-between w-full px-4 py-3 text-base font-medium text-gray-700 hover:text-[#003A30] transition-colors duration-300 rounded-lg hover:bg-gray-50"
        onClick={onToggle}
        aria-expanded={isOpen}
      >
        {title}
        <ChevronDown className={`w-4 h-4 transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      <div 
        className={`
          overflow-hidden transition-all duration-300 ease-in-out
          ${isOpen ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}
        `}
      >
        <div className="pl-4 py-2 space-y-1">
          {items.map((item) => (
            <button
              key={item.href}
              onClick={() => onItemClick(item.href)}
              className="flex items-center gap-3 w-full px-4 py-3 text-gray-700 hover:text-[#003A30] hover:bg-gray-50 transition-colors duration-300 rounded-lg"
            >
              <item.Icon className="w-5 h-5 text-[#CBA331]" />
              {item.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}