import type { CaseStudy } from '../types';

export const INVESTMENT_LOAN_CASE: CaseStudy = {
  client: "Mecenas Adam",
  image: "https://plus.unsplash.com/premium_photo-1664299996427-00387b7cbd16?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fGJ1c2luZXNzJTIwbWFufGVufDB8fDB8fHww",
  clientOccupation: "Adwokat",
  clientLocation: "Kraków",
  rating: 5,
  clientTypeTag: "Kancelaria adwokacka",
  title: "Finansowanie zakupu lokalu biurowego dla kancelarii adwokackiej",
  description: "Pomogliśmy sfinansować zakup i wykończenie nowego lokalu biurowego w centrum Krakowa, eliminując rosnące koszty najmu oraz budując majątek firmy. Kompleksowe finansowanie objęło zarówno zakup nieruchomości, jak i wykończenie. Analiza uwzględnia dwa scenariusze: utrzymanie obecnych wysokich stóp procentowych oraz ich spadek po 2 latach.",
  result: "Kredyt inwestycyjny 979 tys. zł netto na 15 lat + finansowanie VAT. Całkowita korzyść: 1,89-2,08 mln zł",
  creditType: "Kredyt komercyjny",
  timelineInfo: "Realizacja w 18 dni",
  financialBenefit: "Budowa majątku firmy + korzyści podatkowe",
  savingsAmount: "896000",
  savingsPercentage: "36",
  directQuote: "Przez lata odkładałem decyzję o zakupie własnego biura. Teraz żałuję tylko jednego – że nie zrobiłem tego wcześniej. Dzięki profesjonalnemu doradztwu kredytowemu mam przemyślaną strukturę finansowania, która uwzględnia zwrot VAT, korzyści podatkowe z amortyzacji, a faktyczny koszt kredytu jest znacząco niższy niż poprzedni czynsz najmu. Nawet przy obecnych wysokich stopach procentowych to była właściwa decyzja, a jeśli WIBOR spadnie jak przewidują analitycy, to korzyść będzie jeszcze większa.",
  challenges: [
    "Wysokie koszty miesięcznego najmu biura w centrum Krakowa z coroczną podwyżką o 3%",
    "Potrzeba odrębnego finansowania zakupu, wykończenia i VAT",
    "Konieczność zabezpieczenia środków na VAT (ponad 300 tys. zł) do czasu zwrotu z US",
    "Pogodzenie spłaty kredytu z bieżącą działalnością kancelarii"
  ],
  solutions: [
    "Kredyt inwestycyjny z oprocentowaniem zmiennym na 15 lat na wartość netto zakupu i wykończenia (łącznie 1,305 mln zł netto)",
    "Odrębne finansowanie pomostowe na VAT (300 tys. zł) z terminem spłaty po otrzymaniu zwrotu z US",
    "Optymalizacja wkładu własnego na poziomie 25% wartości netto inwestycji",
    "Włączenie kosztów wykończenia (225 tys. zł netto) do kredytu inwestycyjnego, eliminując potrzebę dodatkowego finansowania",
    "Ustalenie harmonogramu spłat dopasowanego do przepływów finansowych kancelarii",
    "Strategia podatkowa wykorzystująca odliczanie odsetek i amortyzację nieruchomości"
  ],
  whatIfScenario: "Kontynuacja najmu oznaczałaby wydatek ok. 9 500 zł miesięcznie (7 695 zł po uwzględnieniu korzyści podatkowej) z perspektywą corocznych podwyżek o 3%. Po 15 latach efektywny koszt najmu po odliczeniu podatku wyniósłby około 1 717 424 zł – pieniądze, które po prostu przepadają. W tym samym czasie przy zakupie własnej nieruchomości, całkowity efektywny koszt odsetek (w scenariuszu spadku WIBOR po 2 latach) wynosi 367 918 zł po uwzględnieniu korzyści podatkowych. Nawet przy utrzymaniu wysokich stóp procentowych przez cały okres, efektywny koszt kredytu jest około 3 razy niższy niż efektywny koszt najmu, a dodatkowo klient zyskuje nieruchomość o wartości ponad 2 mln zł.",
  beforeAfter: {
    before: "Miesięczny czynsz za biuro 90m² w centrum Krakowa: 9 500 zł brutto (7 695 zł netto po odliczeniu podatku dochodowego) + opłaty eksploatacyjne. Coroczne podwyżki o 3%. Brak budowania majątku firmy. Całość czynszu w kosztach podatkowych.",
    after: "Rata kredytu inwestycyjnego: 9 251 zł miesięcznie początkowo, z czego efektywny koszt po odliczeniu podatku to tylko około 6 350 zł (tylko odsetki stanowią koszt). Dodatkowa korzyść z amortyzacji nieruchomości (ok. 522 zł miesięcznie). Po spadku stóp procentowych rata spada do 7 123 zł. Po spłacie kredytu pełna własność lokalu o szacowanej wartości 2,03 mln zł (przy wzroście 3% rocznie)."
  },
  reasoning: "Decyzja o zakupie wynikała z długoterminowego myślenia o finansach kancelarii. Szczegółowa analiza wykazała, że własność to nie tylko kwestia prestiżu, ale przede wszystkim mądra inwestycja finansowa. Nawet przy konserwatywnych założeniach (3% wzrost czynszu, 3% wzrost wartości nieruchomości) i uwzględnieniu, że zarówno najem jak i odsetki kredytu są kosztami podatkowymi, łączna korzyść z zakupu zamiast najmu wynosi od 1,89 do 2,08 mln zł w okresie 15 lat. Kluczowe jest rozróżnienie między spłatą kapitału kredytu (która jest inwestycją, nie kosztem) a odsetkami (które są faktycznym kosztem), oraz uwzględnienie korzyści podatkowych z amortyzacji. Po 15 latach, zamiast kolejnych podwyżek czynszu, klient zostaje z aktywem wartym ponad 2 mln zł.",
  fullStory: {
    situation: "Właściciel prężnie działającej kancelarii prawnej wynajmował biuro w centrum Krakowa, płacąc miesięcznie 9 500 zł plus opłaty eksploatacyjne. Systematyczne podwyżki czynszu skłoniły go do rozważenia zakupu własnego lokalu jako długoterminowej inwestycji i zabezpieczenia przed rosnącymi kosztami.",
    challenge: "Znalezienie odpowiedniego lokalu było tylko pierwszym krokiem. Kluczowym wyzwaniem okazało się stworzenie optymalnej struktury finansowania, która uwzględniałaby specyfikę zakupu nieruchomości komercyjnej na rynku pierwotnym – z oddzielnym zabezpieczeniem środków na VAT (który później zostanie zwrócony przez Urząd Skarbowy), jednoczesnym finansowaniem wykończenia oraz optymalizacją wkładu własnego. Dodatkowo, należało zaplanować strukturę tak, by maksymalnie wykorzystać korzyści podatkowe.",
    solution: "Stworzyliśmy dwutorową strategię finansowania: 1) Kredyt inwestycyjny w wysokości 979 tys. zł z oprocentowaniem zmiennym na 15 lat, przeznaczony na sfinansowanie wartości netto lokalu (1,08 mln zł netto) i jego profesjonalnego wykończenia (225 tys. zł netto). 2) Finansowanie pomostowe w wysokości 300 tys. zł na pokrycie VAT, z założeniem spłaty po otrzymaniu zwrotu podatku (3-6 miesięcy). Całkowita wartość inwestycji wyniosła 1,305 mln zł netto (1,605 mln zł brutto), z czego 25% wartości netto (326 tys. zł) stanowił wkład własny klienta. Dzięki włączeniu kosztów wykończenia do kredytu inwestycyjnego, klient uniknął konieczności poszukiwania dodatkowego finansowania lub angażowania własnych środków na adaptację lokalu.",
    benefits: "Przeprowadziliśmy szczegółową analizę korzyści finansowych, uwzględniając dwa scenariusze stóp procentowych i konserwatywne założenia dotyczące wzrostu czynszu (3% rocznie) oraz wzrostu wartości nieruchomości (3% rocznie). Po 15 latach efektywny koszt najmu (z uwzględnieniem korzyści podatkowej) wyniósłby 1 717 424 zł, podczas gdy efektywny koszt odsetek kredytu to 367 918 zł (przy założeniu spadku stóp procentowych po 2 latach) lub 554 850 zł (przy utrzymaniu obecnych wysokich stóp). Dodatkowo, nieruchomość zyskałaby na wartości 728 190 zł. Łączna korzyść finansowa z zakupu zamiast najmu wynosi więc od 1,89 mln zł do 2,08 mln zł w zależności od scenariusza stóp procentowych."
  },
  details: {
    property: {
      value: "1,08 mln zł netto (1,328 mln zł brutto) za lokal, plus 225 tys. zł netto za wykończenie",
      totalValueNet: "1,305 mln zł netto całej inwestycji",
      totalValueGross: "1,605 mln zł brutto całej inwestycji (z VAT)",
      squareMeterPrice: "12 000 zł netto/m² za zakup, 2 500 zł netto/m² za wykończenie",
      type: "Lokal biurowy 90m²",
      location: "Centrum Krakowa",
      marketType: "Rynek pierwotny",
      estimatedFutureValue: "Około 2,03 mln zł po 15 latach (przy średniorocznym wzroście 3%)",
      valueGrowth: "Przyrost wartości o 728 190 zł w okresie 15 lat"
    },
    loan: {
      amount: "979 tys. zł (kredyt inwestycyjny na zakup i wykończenie) + 300 tys. zł (kredyt na VAT)",
      netFinancingStructure: "Kredyt pokrywa 75% wartości netto całej inwestycji (zakup + wykończenie)",
      period: "15 lat (kredyt inwestycyjny), 3-6 miesięcy (kredyt na VAT)",
      rate: "7,81% (WIBOR 5,81% + marża 2%) – z potencjałem spadku do 5% w przyszłości (WIBOR 3% + marża 2%)",
      payment: "9 251 zł przy początkowym oprocentowaniu",
      projectedPaymentAtLowerRates: "7 123 zł przy spadku WIBOR do 3% po 2 latach",
      ownContribution: "25% wartości netto (326 tys. zł)"
    },
    comparison: {
      rentCost: "9 500 zł miesięcznie + coroczne podwyżki (3% rocznie)",
      rentAfterTax: "7 695 zł miesięcznie po uwzględnieniu 19% oszczędności podatkowej (cały najem jest kosztem)",
      loanInitialRate: "9 251 zł miesięcznie przy początkowym oprocentowaniu 7,81% (WIBOR 5,81% + marża 2%)",
      loanReducedRate: "7 123 zł miesięcznie po spadku WIBOR do 3% (całkowite oprocentowanie 5%)",
      loanEffectiveCost: "Tylko odsetki stanowią koszt, początkowo około 6 350 zł miesięcznie netto po odliczeniu podatku",
      additionalTaxBenefit: "Amortyzacja daje dodatkową korzyść podatkową około 522 zł miesięcznie",
      totalRent15Years: "2 120 276 zł brutto / 1 717 424 zł netto po odliczeniu podatku (przy 3% wzroście rocznie)",
      totalInterestScenario1: "454 220 zł brutto / 367 918 zł netto (przy założeniu spadku stóp po 2 latach)",
      totalInterestScenario2: "685 000 zł brutto / 554 850 zł netto (przy utrzymaniu obecnych wysokich stóp przez 15 lat)",
      effectiveComparisonScenario1: "1 717 424 zł (efektywny koszt najmu) vs 367 918 zł (efektywny koszt odsetek + korzyści z amortyzacji)",
      effectiveComparisonScenario2: "1 717 424 zł (efektywny koszt najmu) vs 554 850 zł (efektywny koszt odsetek + korzyści z amortyzacji)",
      savingsScenario1: "Różnica w efektywnym koszcie: 1 349 506 zł + przyrost wartości nieruchomości 728 190 zł = 2 077 696 zł łącznej korzyści",
      savingsScenario2: "Różnica w efektywnym koszcie: 1 162 574 zł + przyrost wartości nieruchomości 728 190 zł = 1 890 764 zł łącznej korzyści",
      propertyValueGrowth: "Przyrost wartości nieruchomości o 728 190 zł przy założeniu 3% wzrostu rocznie"
    },
    taxBenefits: {
      interestDeduction: "Całość odsetek w kosztach podatkowych",
      depreciationAnnually: "Około 33 000 zł (2,5% wartości nieruchomości)",
      taxSavingsAt19Percent: "Około 6 270 zł rocznie tylko z tytułu amortyzacji",
      effectiveMonthlyBenefit: "522 zł miesięcznie z tytułu amortyzacji",
      comparisonWithRent: "Przy najmie w kosztach uwzględniamy całą kwotę czynszu, przy kredycie tylko odsetki, ale dodatkowo mamy korzyść z amortyzacji"
    },
    vatFinancing: {
      amount: "300 150 zł",
      breakdown: "248 400 zł (VAT od zakupu lokalu) + 51 750 zł (VAT od wykończenia)",
      period: "3-6 miesięcy",
      interestCost: "Około 7 500 zł (przy założeniu WIBOR + 3%)",
      repaymentSource: "Zwrot VAT z Urzędu Skarbowego",
      benefit: "Kredyt pomostowy eliminuje konieczność zamrożenia własnych środków na VAT"
    },
    interestRateAnalysis: {
      current: "7,81% (WIBOR 5,81% + marża 2%)",
      projected: "5% (WIBOR 3% + marża 2%) po 2 latach",
      interestSavings: "Przy spadku WIBOR do 3% po 2 latach, całkowite odsetki będą niższe o około 230 780 zł",
      scenarioAnalysis: "Scenariusz 1: WIBOR spada do 3% po 2 latach → całkowite odsetki 454 220 zł. Scenariusz 2: WIBOR pozostaje na wysokim poziomie przez 15 lat → całkowite odsetki 685 000 zł",
      potentialSavings: "Przy spadku WIBOR po 2 latach, rata kredytu spada z 9 251 zł do 7 123 zł miesięcznie",
      comparisonWithRent: "Nawet przy utrzymaniu wysokich stóp procentowych, całkowity koszt kredytu jest znacząco niższy niż rosnący koszt najmu"
    },
    netVsBruttoPerspective: {
      vatPerspective: "Dla firm VAT jest neutralny podatkowo - zostaje zwrócony przez Urząd Skarbowy",
      financingBenefits: "Finansowanie obejmuje całość inwestycji netto (zakup i wykończenie)",
      costEfficiency: "Dzięki kredytowaniu wykończenia, klient mógł od razu dostosować lokal do wysokiego standardu bez angażowania dodatkowych środków własnych",
      businessPerspective: "Kalkulacje dla firm zawsze powinny opierać się na wartościach netto, co zostało uwzględnione w całej strukturze finansowania"
    },
    comparisonMethodology: {
      title: "Metodologia kalkulacji korzyści finansowych",
      approach: "Porównując najem i zakup na kredyt, uwzględniamy tylko rzeczywiste koszty oraz przyrost wartości nieruchomości. Spłata kapitału kredytu nie jest kosztem, lecz zamianą gotówki na aktywo, dlatego nie jest uwzględniona jako koszt ani jako korzyść.",
      steps: [
        "Wyliczenie efektywnego kosztu najmu przez 15 lat (po odliczeniu podatku dochodowego 19%)",
        "Wyliczenie efektywnego kosztu odsetek kredytu (po odliczeniu podatku)",
        "Uwzględnienie dodatkowej korzyści podatkowej z amortyzacji nieruchomości",
        "Obliczenie przyrostu wartości nieruchomości w okresie 15 lat",
        "Wyliczenie łącznej korzyści jako sumy oszczędności na kosztach i przyrostu wartości"
      ],
      detailedCalculation: {
        effectiveRentCost: "Koszt najmu brutto przez 15 lat (2 120 276 zł) × 81% = 1 717 424 zł",
        effectiveInterestCost: {
          scenario1: "Odsetki przy spadku stóp po 2 latach (454 220 zł) × 81% = 367 918 zł",
          scenario2: "Odsetki bez spadku stóp (685 000 zł) × 81% = 554 850 zł"
        },
        costDifference: {
          scenario1: "1 717 424 zł - 367 918 zł = 1 349 506 zł",
          scenario2: "1 717 424 zł - 554 850 zł = 1 162 574 zł"
        },
        propertyValueGrowth: "2 033 190 zł (wartość końcowa) - 1 305 000 zł (wartość początkowa) = 728 190 zł",
        totalBenefit: {
          scenario1: "1 349 506 zł + 728 190 zł = 2 077 696 zł",
          scenario2: "1 162 574 zł + 728 190 zł = 1 890 764 zł"
        }
      },
      visualComparison: "Na każdą złotówkę efektywnie wydaną na odsetki kredytu, przy najmie wydalibyśmy od 3,1 do 4,7 złotych (zależnie od scenariusza stóp procentowych). Po 15 latach, przy najmie nie mamy nic, przy kredycie posiadamy nieruchomość wartą ponad 2 mln zł."
    }
  }
};