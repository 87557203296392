
import type { CaseStudy } from '../types';

export const HOUSE_BUILD_CASE: CaseStudy = {
  id: "house-build",
  client: "Rodzina Kowalskich",
  image: "https://images.unsplash.com/photo-1571624436279-b272aff752b5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmFtaWx5JTIwMiUyMGNoaWxkcmVufGVufDB8fDB8fHww",
  title: "Budowa domu jednorodzinnego dla rodziny 2+2",
  description: "Pomogliśmy rodzinie z dwójką dzieci sfinansować budowę wymarzanego domu pod Krakowem z indywidualnym harmonogramem wypłat.",
  result: "Kredyt 920 000 zł z elastycznymi transzami i karencją",
  service: "mortgage",
  challenges: [
    "Skomplikowany proces kredytowania budowy domu systemem gospodarczym",
    "Potrzeba elastycznego harmonogramu wypłat transz",
    "Wysokie koszty materiałów budowlanych w 2025 roku",
    "Konieczność utrzymania dwóch zobowiązań (najem + kredyt) w trakcie budowy",
    "Wymagane dokładne kosztorysy i projekty dla banku"
  ],
  solutions: [
    "Kredyt hipoteczny dopasowany do etapów budowy z kontrolą rzeczoznawcy",
    "Wynegocjowanie 24-miesięcznej karencji w spłacie kapitału",
    "Wnikliwa analiza kosztorysu i rekomendacje oszczędności",
    "Optymalizacja 4 transz pod główne etapy budowy",
    "Kompleksowe przygotowanie dokumentacji technicznej dla banku"
  ],
  directQuote: "Nie wyobrażaliśmy sobie, jak skomplikowane jest finansowanie budowy domu, dopóki nie skorzystaliśmy z pomocy eksperta. Uratowała nas szczególnie karencja w spłacie kapitału, dzięki której mogliśmy spokojnie dokończyć budowę nie martwiąc się o wysokie raty.",
  financialBenefit: "Oszczędność około 85 000 zł na całkowitym koszcie inwestycji",
  savingsPercentage: 9,
  savingsAmount: "680 zł miesięcznie mniej niż w standardowej ofercie",
  whatIfScenario: "Bez profesjonalnego wsparcia rodzina Kowalskich mogłaby nie otrzymać wystarczającej kwoty kredytu lub dostać gorsze warunki bez karencji. Istniało również ryzyko opóźnień w wypłacie transz, co skutkowałoby przestojami w budowie i dodatkowymi kosztami.",
  clientTypeTag: "Budowa domu",
  ctaText: "Sfinansuj budowę swojego domu",
  ctaLink: "/kredyt-na-budowe",
  difficultyLevel: 5,
  timelineInfo: "Budowa zakończona w 24 miesiące",
  rating: 5,
  date: "Marzec 2025",
  creditType: "Kredyt hipoteczny na budowę",
  clientOccupation: "Informatyk i księgowa",
  clientLocation: "Kraków/Wieliczka",
  beforeAfter: {
    before: "Mieszkanie wynajmowane 65m² w Krakowie, 3 pokoje, czynsh najmu 3600 zł miesięcznie, brak przestrzeni dla aktywnych dzieci (8 i 11 lat), długi dojazd do szkoły i pracy.",
    after:     "Własny dom 142m² z ogrodem 900m² w Wieliczce, 4 pokoje, gabinet do pracy zdalnej, rata docelowa 4200 zł, blisko natura, więcej przestrzeni dla dzieci, 20 minut dojazdu do Krakowa."
  },
  reasoning: "Zdecydowaliśmy się na współpracę z tym doradcą, ponieważ jako jedyny dokładnie wytłumaczył nam specyfikę kredytowania budowy domu. Przedstawił realistyczny plan finansowy z uwzględnieniem rosnących kosztów materiałów i robocizny. Doceniliśmy szczególnie pomoc w przygotowaniu dokumentacji technicznej i kosztorysów, które były kluczowe dla uzyskania odpowiedniej kwoty kredytu.",
  fullStory: {
    situation: "Rodzina czteroosobowa (rodzice oraz dzieci w wieku 8 i 11 lat) mieszkająca w wynajmowanym mieszkaniu w Krakowie, planująca budowę domu jednorodzinnego na zakupionej rok wcześniej działce w Wieliczce. Oboje rodzice pracują na etatach (IT i księgowość), z możliwością częściowej pracy zdalnej.",
    challenge: "Głównym wyzwaniem było uzyskanie kredytu hipotecznego na budowę domu systemem gospodarczym z odpowiednio wysoką kwotą i elastycznym harmonogramem wypłat. Dodatkowo, rodzina musiała zaplanować finansowanie równoległe - opłacanie najmu mieszkania w Krakowie podczas budowy oraz obsługę rat kredytu w okresie budowy.",
    solution: "Opracowanie kompleksowej strategii finansowania budowy domu, uwzględniającej aktualną sytuację finansową rodziny. Uzyskanie kredytu hipotecznego z 24-miesięczną karencją w spłacie kapitału i elastycznym harmonogramem wypłaty 6 transz, dostosowanych do kluczowych etapów budowy z regularnymi kontrolami rzeczoznawcy.",
    details: {
      property: {
        land: "Działka 900m², wartość 250 000 zł (zakupiona wcześniej za gotówkę)",
        house:     "Dom 142m², koszt budowy 920 000 zł",
        location: "Wieliczka, 12 km od centrum Krakowa"
      },
      loan: {
        amount: "920 000 zł",
        tranches: "4 transze (po 230k zł) zgodnie z postępem prac",
        period: "28 lat",
        graceperiod: "24 miesiące (spłata tylko odsetek)"
      },
      timeline: {
        approval: "35 dni od złożenia wniosku",
        construction: "24 miesiące (od fundamentów do wprowadzenia)",
        firstPayment: "Odsetki od wypłaconych transz (~1800 zł przy pełnej kwocie)"
      }
    }
  }
};