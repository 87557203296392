import React from 'react';
import Hero from '@/components/shared/Hero';
import ProblemsSolutions from '@/components/shared/ProblemsSolutions';
import Process from '@/components/shared/Process';
import CaseStudyList from '@/components/shared/CaseStudyList';
import Comparison from '@/components/shared/Comparison';
import ComparisonCards from '@/components/shared/ComparisonCards';
import ServicePageNavigation from '@/components/shared/ServicePageNavigation';
import { refinancingProblemsData } from '@/data/ProblemsSolutionsData';
import { refinancingCases } from '@/data/caseStudies/refinancing';
import { REFINANCING_CARDS, REFINANCING_DETAILS } from '@/data/modal/refinancing';
import Calculator from './sections/Calculator';
import Concerns from './sections/Concerns';
import TrustBar from './sections/TrustBar';
import Contact from '@/components/shared/Contact';
import { Shield, BadgePercent, Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function Refinancing() {
  const navigate = useNavigate();

  return (
    <div className="pt-20 bg-gray-50">
      <ServicePageNavigation />
      
      <Hero 
        title="Refinansowanie kredytu na lepszych warunkach"
        highlightedWord="lepszych"
        description="Zamień swój obecny kredyt na korzystniejszy. Obniż ratę, zmniejsz oprocentowanie i zacznij oszczędzać już dziś."
        image="https://images.unsplash.com/photo-1563013544-824ae1b704d3?auto=format&fit=crop&q=80"
        badgeText="Ponad 500 refinansowanych kredytów"
        stats={[
          {
            icon: BadgePercent,
            value: "-35%",
            label: "Średnia oszczędność"
          },
          {
            icon: Clock,
            value: "30 dni",
            label: "Średni czas procesu"
          },
          {
            icon: Shield,
            value: "500+",
            label: "Refinansowanych kredytów"
          }
        ]}
        primaryCta={{
          label: "Sprawdź możliwości",
          action: () => navigate('#contact')
        }}
        secondaryCta={{
          label: "Oblicz oszczędności",
          action: () => navigate('#calculator')
        }}
      />
      <ProblemsSolutions 
        problems={refinancingProblemsData.problems}
        title={refinancingProblemsData.title}
        subtitle={refinancingProblemsData.subtitle}
      />
      <section id="calculator">
        <Calculator />
      </section>
      <section id="types">
        <ComparisonCards 
          cards={REFINANCING_CARDS}
          details={REFINANCING_DETAILS}
          title="Warianty refinansowania"
          subtitle="Wybierz najlepsze rozwiązanie dopasowane do Twoich potrzeb"
        />
      </section>
      <Comparison />
      <Process />
      <section id="testimonials">
        <CaseStudyList 
          cases={refinancingCases}
          title="Historie sukcesu refinansowania"
          subtitle="Zobacz, jak pomogliśmy innym obniżyć koszty kredytów i zaoszczędzić pieniądze"
        />
      </section>
      <Concerns />
      <TrustBar />
      <section id="contact">
        <Contact />
      </section>
    </div>
  );
}