import { Search, ListChecks, FileText, Building2, CheckCircle2, Clock, BadgePercent, Percent, ShieldCheck } from 'lucide-react';
import type { ProcessStepsData } from './types';
export const homeStepsData: ProcessStepsData = {
  title: "Proces kredytowy",
  subtitle: "Profesjonalna pomoc na każdym etapie, abyś mógł spokojnie realizować swoje plany finansowe",
  steps: [
    {
      number: 1,
      icon: Search,
      title: "Bezpłatna konsultacja",
      description: "Twoje plany i możliwości finansowe",
      features: [
        "Dokładna analiza Twojej sytuacji finansowej",
        "Potrzeby i oczekiwania w zrozumiałym języku",
        "Realne możliwości kredytowe"
      ],
      stats: {
        icon: Clock,
        value: "30 min",
        label: "Oszczędność czasu"
      }
    },
    {
      number: 2,
      icon: ListChecks,
      title: "Najlepsze rozwiązanie dla Ciebie",
      description: "Oferta idealnie dopasowana do Twoich potrzeb",
      features: [
        "Przejrzyste porównanie dostępnych ofert",
        "Dokładna kalkulacja rat i całkowitych kosztów",
        "Optymalne finansowanie bez ukrytych opłat"
      ],
      stats: {
        icon: Percent,
        value: "100%",
        label: "Dopasowania"
      }
    },
    {
      number: 3,
      icon: FileText,
      title: "Minimum formalności",
      description: "Wszystkie dokumenty, oszczędność Twojego czasu",
      features: [
        "Kompletna wymagana dokumentacja",
        "Prawidłowo wypełnione i złożone wnioski",
        "Stały monitoring procesu i informacje o postępach"
      ],
      stats: {
        icon: Clock,
        value: "24h",
        label: "Na odpowiedź"
      }
    },
    {
      number: 4,
      icon: Building2,
      title: "Szybka i pozytywna decyzja",
      description: "Dzięki doświadczeniu proces sprawnie",
      features: [
        "Sprawdzone ścieżki współpracy z bankami",
        "Skuteczne negocjacje najlepszych warunków",
        "Bieżące informacje o statusie Twojego wniosku"
      ],
      stats: {
        icon: BadgePercent,
        value: "98%",
        label: "Zaakceptowanych wniosków"
      }
    },
    {
      number: 5,
      icon: CheckCircle2,
      title: "Wsparcie na każdym etapie",
      description: "Z Tobą od pierwszej rozmowy aż do finalizacji",
      features: [
        "Pomoc przy podpisaniu umowy i wyjaśnienie wszystkich zapisów",
        "Wsparcie przy uruchomieniu kredytu",
        "Dalsza opieka i doradztwo w trakcie spłaty"
      ],
      stats: {
        icon: ShieldCheck,
        value: "∞",
        label: "Trwałej współpracy"
      }
    }
  ]
};