export * from './mortgage/first-home';
export * from './mortgage/home-change';
export * from './mortgage/house-build';

import { FIRST_HOME_CARD, FIRST_HOME_DETAILS } from './mortgage/first-home';
import { HOME_CHANGE_CARD, HOME_CHANGE_DETAILS } from './mortgage/home-change';
import { HOUSE_BUILD_CARD, HOUSE_BUILD_DETAILS } from './mortgage/house-build';

export const MORTGAGE_CARDS = [
  FIRST_HOME_CARD,
  HOME_CHANGE_CARD,
  HOUSE_BUILD_CARD
];

export const MORTGAGE_DETAILS = {
  "first-home": FIRST_HOME_DETAILS,
  "home-change": HOME_CHANGE_DETAILS,
  "house-build": HOUSE_BUILD_DETAILS
};