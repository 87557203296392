import React from 'react';
import Concerns from '@/components/shared/Concerns';
import { businessConcernsData } from '@/data/ConcernsData';

export default function BusinessConcerns() {
  return (
    <Concerns 
      concerns={businessConcernsData.concerns}
      title={businessConcernsData.title}
      subtitle={businessConcernsData.subtitle}
    />
  );
}