import React from 'react';
import { Calculator, MessageSquare, Download, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

interface CTABoxProps {
  title: string;
  description: string;
  primaryButtonText: string;
  primaryButtonUrl: string;
  secondaryButtonText?: string;
  secondaryButtonUrl?: string;
  type?: 'calculator' | 'consultation' | 'download';
  variant?: 'primary' | 'secondary' | 'gradient';
}

export default function CTABox({ 
  title, 
  description, 
  primaryButtonText, 
  primaryButtonUrl,
  secondaryButtonText,
  secondaryButtonUrl,
  type = 'calculator',
  variant = 'gradient'
}: CTABoxProps) {
  // Choose icon based on CTA type
  const getIcon = () => {
    switch (type) {
      case 'calculator':
        return <Calculator className="w-10 h-10" />;
      case 'consultation':
        return <MessageSquare className="w-10 h-10" />;
      case 'download':
        return <Download className="w-10 h-10" />;
      default:
        return <Calculator className="w-10 h-10" />;
    }
  };
  
  // Get styles based on variant
  const getStyles = () => {
    switch (variant) {
      case 'primary':
        return {
          container: 'bg-[#003A30] text-white',
          icon: 'text-[#CBA331]',
          primaryButton: 'bg-[#CBA331] text-[#003A30] hover:bg-[#d4b04c]',
          secondaryButton: 'border border-white text-white hover:bg-white/10'
        };
      case 'secondary':
        return {
          container: 'bg-[#CBA331] text-[#003A30]',
          icon: 'text-[#003A30]',
          primaryButton: 'bg-[#003A30] text-white hover:bg-[#004d40]',
          secondaryButton: 'border border-[#003A30] text-[#003A30] hover:bg-[#003A30]/10'
        };
      case 'gradient':
      default:
        return {
          container: 'bg-gradient-to-r from-[#003A30] to-[#00483D] text-white',
          icon: 'text-[#CBA331]',
          primaryButton: 'bg-[#CBA331] text-[#003A30] hover:bg-[#d4b04c]',
          secondaryButton: 'border border-white text-white hover:bg-white/10'
        };
    }
  };
  
  const styles = getStyles();

  return (
    <div className={`rounded-xl p-6 md:p-8 ${styles.container}`}>
      <div className="flex flex-col md:flex-row items-start md:items-center gap-6">
        <div className="bg-white/10 p-4 rounded-full">
          <div className={styles.icon}>
            {getIcon()}
          </div>
        </div>
        
        <div className="flex-grow">
          <h3 className="text-xl md:text-2xl font-bold mb-2">{title}</h3>
          <p className="opacity-80 mb-4 md:mb-0">{description}</p>
        </div>
        
        <div className="flex flex-col sm:flex-row gap-3 w-full md:w-auto">
          <Link
            to={primaryButtonUrl}
            className={`inline-flex items-center justify-center px-6 py-3 font-bold rounded-lg transition-colors duration-300 whitespace-nowrap ${styles.primaryButton}`}
          >
            {primaryButtonText}
            <ArrowRight className="w-5 h-5 ml-2" />
          </Link>
          
          {secondaryButtonText && secondaryButtonUrl && (
            <Link
              to={secondaryButtonUrl}
              className={`inline-flex items-center justify-center px-6 py-3 font-medium rounded-lg transition-colors duration-300 whitespace-nowrap ${styles.secondaryButton}`}
            >
              {secondaryButtonText}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}