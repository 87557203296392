import { Building2, CircleDollarSign, RefreshCw, Clock } from 'lucide-react';
import type { Card, CardDetails } from '../types';

export const CREDIT_LINE_CARD: Card = {
  id: "credit-line",
  icon: Building2,
  title: "Limit w rachunku dla Twojej firmy",
  description: "Elastyczny dostęp do finansowania bieżących potrzeb Twojego biznesu",
  benefits: [
    "Dostępność środków od ręki - bez oczekiwania",
    "Płacisz odsetki tylko za faktycznie wykorzystane środki",
    "Automatyczne odnowienie limitu na kolejne okresy"
  ],
  stats: {
    value: "20%",
    label: "Limit nawet do 20% rocznych obrotów firmy"
  },
  cta: {
    label: "Sprawdź dostępny limit dla Twojej firmy",
    action: () => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })
  }
};

export const CREDIT_LINE_DETAILS: CardDetails = {
  title: "Limit w rachunku dla Twojej firmy",
  tagline: "Elastyczne finansowanie bieżących potrzeb biznesowych",
  description: "Limit w rachunku to idealne rozwiązanie na potrzeby płynnościowe Twojej firmy. Środki dostępne są od ręki, a Ty płacisz odsetki tylko za faktycznie wykorzystaną kwotę. To najlepsze rozwiązanie dla firm o sezonowych lub zmiennych przepływach finansowych.",
  cta: {
    phone: "539 211 881",
    text: "Zadzwoń i dowiedz się więcej o możliwościach finansowania bieżących potrzeb Twojej firmy"
  },
  benefitHighlights: [
    "Dostępność finansowania dokładnie wtedy, gdy jest potrzebne",
    "Optymalizacja kosztów - odsetki tylko od wykorzystanej kwoty",
    "Elastyczność w zarządzaniu bieżącą płynnością",
    "Szybkie decyzje kredytowe dzięki uproszczonej procedurze"
  ],
  whyUs: [
    "Porównujemy oferty wielu banków, aby znaleźć najkorzystniejsze warunki dla Twojej firmy",
    "Kompleksowo prowadzimy proces kredytowy, oszczędzając Twój czas",
    "Negocjujemy lepsze stawki marż i prowizji dzięki naszym relacjom z bankami",
    "Pomagamy skomponować optymalny pakiet zabezpieczeń, minimalizując koszty"
  ],
  processComparison: {
    withUs: [
      {
        step: "Analiza finansowa firmy",
        description: "Profesjonalna ocena potencjału i potrzeb Twojego biznesu",
        icon: "Search",
        highlight: true
      },
      {
        step: "Przygotowanie oferty",
        description: "Porównanie wielu banków i wybór optymalnego rozwiązania",
        icon: "FileText"
      },
      {
        step: "Kompletna dokumentacja",
        description: "Wsparcie w przygotowaniu i złożeniu dokumentów",
        icon: "ClipboardCheck"
      },
      {
        step: "Szybka decyzja kredytowa",
        description: "Decyzja zazwyczaj w ciągu 5-10 dni roboczych",
        icon: "CheckCircle",
        highlight: true
      }
    ],
    withoutUs: [
      {
        step: "Samodzielne poszukiwania",
        description: "Czasochłonne porównywanie ofert różnych banków",
        icon: "Search",
        warning: true
      },
      {
        step: "Niekorzystne warunki",
        description: "Brak znajomości aktualnych ofert i możliwości negocjacji",
        icon: "AlertTriangle",
        warning: true
      },
      {
        step: "Trudności z dokumentacją",
        description: "Ryzyko odrzucenia wniosku z powodu błędów formalnych",
        icon: "FileX",
        warning: true
      },
      {
        step: "Dłuższy czas oczekiwania",
        description: "Wydłużony proces decyzyjny, nawet do 30 dni",
        icon: "Clock",
        warning: true
      }
    ]
  },
  calculations: {
    example: "Limit w rachunku dla firmy: 300 000 zł (2025)",
    before: "Standardowy kredyt obrotowy bez naszego wsparcia:\n• Oprocentowanie: WIBOR 5,81% + marża 4,0-5,0% = 9,81-10,81%\n• Roczny koszt przy stałym wykorzystaniu: 29 430 - 32 430 zł\n• Prowizja przygotowawcza: 1,5-2,0% (4 500 - 6 000 zł)\n• Prowizja za odnowienie: 1,5% (4 500 zł)\n• Dodatkowe zabezpieczenia: często zastaw rejestrowy\n• Czas uzyskania: do 30 dni",
    after: "Limit w rachunku z naszym wsparciem:\n• Oprocentowanie: WIBOR 5,81% + marża 2,0-3,0% = 7,81-8,81%\n• Roczny koszt przy średnim wykorzystaniu 50%: 11 715 - 13 215 zł\n• Prowizja przygotowawcza: 0,8-1,2% (2 400 - 3 600 zł)\n• Prowizja za odnowienie: 0,8-1,0% (2 400 - 3 000 zł)\n• Uproszczone zabezpieczenia: zazwyczaj weksel własny\n• Czas uzyskania: 5-10 dni roboczych",
    savings: "Potencjalne korzyści: oszczędność na rocznych kosztach odsetkowych przy 50% wykorzystaniu: 3 000 - 6 000 zł, oszczędność na prowizjach: 3 000 - 5 400 zł, uproszczone zabezpieczenia, szybsza dostępność środków. Łączne oszczędności w pierwszym roku: 6 000 - 11 400 zł.",
    extra: "Dodatkowa korzyść: możliwość automatycznego odnowienia na kolejne okresy przy zachowaniu dobrych wskaźników finansowych, elastyczne zwiększanie limitu wraz z rozwojem firmy, brak harmonogramu spłat charakterystycznego dla kredytów obrotowych"
  },
  benefits: [
    "Dostępność środków od ręki - bez oczekiwania na uruchomienie",
    "Płacisz odsetki tylko za faktycznie wykorzystaną kwotę i za okres wykorzystania",
    "Elastyczne korzystanie ze środków według bieżących potrzeb",
    "Automatyczne odnowienie limitu na kolejne okresy",
    "Brak sztywnego harmonogramu spłat typowego dla kredytów obrotowych",
    "Możliwość zarządzania limitem przez bankowość elektroniczną"
  ],
  limitedTimeOffer: {
    text: "Bezpłatna analiza finansowa i symulacja dostępnego limitu dla nowych klientów",
    expiry: "31.05.2025"
  },
  faq: [
    {
      question: "Jaką maksymalną kwotę limitu może otrzymać moja firma?",
      answer: "Wysokość limitu w rachunku uzależniona jest głównie od skali działalności Twojej firmy. Banki najczęściej oferują limit w wysokości do 20% rocznych obrotów firmy. Przy dobrych wskaźnikach finansowych (rentowność, płynność, rotacja należności) i odpowiedniej historii kredytowej, limit może zostać zwiększony nawet powyżej tego poziomu. W 2025 roku minimalne obroty miesięczne, przy których banki rozważają przyznanie limitu, to zazwyczaj 20-30 tys. zł. Pomożemy przeanalizować Twoją sytuację i przedstawić realne możliwości zwiększenia dostępnego finansowania."
    },
    {
      question: "Czy muszę przenieść całą obsługę firmową do banku, który udzieli mi limitu?",
      answer: "Nie zawsze, choć banki często preferują klientów korzystających z pełnej obsługi. W 2025 roku na rynku dostępne są oferty pozwalające na uzyskanie limitu bez przenoszenia całej obsługi, szczególnie dla firm o stabilnej sytuacji finansowej. Niektóre banki wymagają jedynie przeniesienia części obrotów (np. 30-50%). Będąc Twoim doradcą kredytowym, możemy zarekomendować banki oferujące elastyczne podejście w tym zakresie, a także wynegocjować indywidualne warunki, które pozwolą Ci zachować dotychczasowe relacje bankowe przy jednoczesnym uzyskaniu atrakcyjnego limitu."
    },
    {
      question: "Czy będę musiał dać zabezpieczenia osobiste, np. hipotekę na własnym domu?",
      answer: "W większości przypadków nie jest to konieczne. Podstawowym zabezpieczeniem limitu w rachunku jest weksel własny in blanco, który nie obciąża bezpośrednio majątku osobistego. Dla limitów do 300-500 tys. zł (w zależności od banku) przy dobrych wynikach finansowych banki zazwyczaj nie wymagają dodatkowych zabezpieczeń rzeczowych. W przypadku wyższych kwot lub krótszej historii kredytowej, możemy pomóc w uzyskaniu gwarancji BGK (de minimis) lub z innych funduszy poręczeniowych, które zastępują konieczność ustanawiania zabezpieczeń na majątku osobistym właścicieli. Naszą rolą jest takie skonstruowanie wniosku, aby zminimalizować ryzyko wymagania przez bank zabezpieczeń osobistych."
    },
    {
      question: "Co się stanie, jeśli przekroczę przyznany limit?",
      answer: "Przekroczenie limitu w rachunku skutkuje naliczeniem podwyższonych odsetek od kwoty przekroczenia (zazwyczaj o 2-5 punktów procentowych wyższych). Bank może również naliczyć dodatkową opłatę karną za nieuprawnione przekroczenie (50-200 zł, w zależności od banku). Powtarzające się przekroczenia mogą skutkować wezwaniem do natychmiastowej spłaty nadwyżki, a w skrajnych przypadkach wypowiedzeniem umowy limitu. Większość banków oferuje jednak systemy powiadomień o zbliżaniu się do granicy limitu (SMS, email), które pomagają uniknąć takich sytuacji. Dodatkowo, mając dobre relacje z bankiem (które pomożemy zbudować), możliwe jest doraźne, krótkoterminowe przekroczenie limitu za zgodą banku w nagłych sytuacjach biznesowych."
    },
    {
      question: "Jak limit w rachunku wpłynie na moją zdolność kredytową przy innych kredytach?",
      answer: "Przyznany limit w rachunku jest traktowany przez banki jako istniejące zobowiązanie kredytowe, co może wpłynąć na zdolność kredytową. Zgodnie z aktualnymi (2025) wymogami Komisji Nadzoru Finansowego, przy wyliczaniu zdolności kredytowej na inne produkty finansowe banki uwzględniają od 50% do 100% kwoty przyznanego limitu, niezależnie od faktycznego wykorzystania. Możemy pomóc w optymalizacji struktury finansowania Twojej firmy tak, aby limit w rachunku nie blokował możliwości pozyskania innych kredytów. Często rekomendujemy strategiczne rozdzielenie finansowania bieżącego i inwestycyjnego między różne instytucje finansowe, co pozwala na maksymalizację łącznej dostępnej kwoty finansowania."
    },
    {
      question: "Czy mogę negocjować warunki limitu w trakcie jego trwania?",
      answer: "Tak, z naszą pomocą jest to możliwe. Zazwyczaj najlepszym momentem na renegocjację warunków jest okres 2-3 miesięcy przed odnowieniem limitu. Przy poprawie wyników finansowych, zwiększeniu obrotów lub uzyskaniu dużych kontraktów można negocjować: 1) Zwiększenie kwoty limitu, 2) Obniżenie marży oprocentowania, 3) Zmniejszenie prowizji za odnowienie, 4) Uproszczenie zabezpieczeń. Jako Twój doradca, prowadzimy monitoring warunków rynkowych i odpowiednio wcześnie przygotowujemy strategię negocjacyjną, gromadząc dane o poprawie sytuacji Twojej firmy i ofertach konkurencyjnych banków, co znacząco zwiększa szanse na sukces."
    },
    {
      question: "Czy mogę łączyć limit w rachunku z innymi produktami kredytowymi?",
      answer: "Zdecydowanie tak, i często jest to optymalna strategia finansowa. Limit w rachunku najlepiej sprawdza się jako finansowanie bieżących, zmiennych potrzeb, natomiast do finansowania stałych potrzeb obrotowych lepiej wykorzystać kredyt obrotowy nieodnawialny, a do inwestycji - kredyt inwestycyjny. Takie zróżnicowanie źródeł finansowania pozwala na optymalizację kosztów i dopasowanie struktury spłat do przepływów pieniężnych. Jako doradcy kredytowi, specjalizujemy się w projektowaniu kompleksowych rozwiązań finansowych, łączących różne produkty kredytowe w sposób maksymalizujący korzyści i minimalizujący koszty dla Twojej firmy."
    },
    {
      question: "Jakie branże mają największe szanse na uzyskanie limitu w rachunku?",
      answer: "W 2025 roku banki preferują stabilne branże o przewidywalnych przepływach pieniężnych i niskim ryzyku sezonowym. Najwyżej oceniane są: produkcja, handel hurtowy z ugruntowaną pozycją, usługi B2B, branża medyczna, IT oraz firmy z sektora energetycznego i ESG. Trudniejszy dostęp do limitów mają branże silnie uzależnione od sezonowości, narażone na dużą konkurencję czy działające w obszarach wysokiego ryzyka regulacyjnego. Niezależnie od branży, kluczowe są dobre wskaźniki finansowe i historia kredytowa. Dzięki naszej znajomości aktualnej polityki kredytowej poszczególnych banków, możemy skierować Twój wniosek do instytucji, która najlepiej ocenia specyfikę Twojej branży, co znacząco zwiększa szanse na uzyskanie finansowania."
    }
  ],
  nextSteps: [
    "Skontaktuj się z nami telefonicznie lub przez formularz",
    "Umów się na bezpłatną konsultację w zakresie finansowania",
    "Przygotuj podstawową dokumentację finansową firmy",
    "Otrzymaj od nas analizę możliwości i dostępnych opcji",
    "Wybierz najkorzystniejszą ofertę spośród przedstawionych",
    "Skorzystaj z naszego wsparcia w procesie składania wniosku",
    "Uzyskaj limit w rachunku w rekordowo krótkim czasie"
  ]
};