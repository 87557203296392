import React, { useState, useEffect } from 'react';
import Hero from '@/components/shared/Hero';
import { Calculator, BadgePercent, Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import InputPanel from './mortgage/InputPanel';
import ResultsPanel from './mortgage/ResultsPanel';
import ChartPanel from './mortgage/ChartPanel';
import SchedulePanel from './mortgage/SchedulePanel';
import InstructionPanel from './mortgage/InstructionPanel';
import LowestInterestSection from './mortgage/sections/LowestInterestSection';
import Contact from '@/components/shared/Contact';
import FloatingHelpButton from '@/components/shared/FloatingHelpButton';

// Types
export interface OneTimePayment {
  id: string;
  month: number;
  amount: number;
}

export interface LoanData {
  amount: number;
  period: number;
  interestRate: number;
  paymentType: 'equal' | 'decreasing';
  monthlyOverpayment: number;
  oneTimePayments: OneTimePayment[];
  wiborChangeYear?: number;
  wiborChangeRate?: number;
}

export interface CalculationResults {
  monthlyPayment: number;
  initialMonthlyPayment: number;
  monthlyPaymentAfterWiborChange: number;
  totalInterest: number;
  realTotalInterest: number;
  interestSavings: number;
  periodReduction: number;
  schedule: Array<{
    month: number;
    payment: number;
    principal: number;
    interest: number;
    realInterest: number;
    remainingBalance: number;
    cumulativeInterest: number;
    cumulativeRealInterest: number;
    overpayment: number;
  }>;
}

// Initial loan data
const initialLoanData: LoanData = {
  amount: 700000,
  period: 25,
  interestRate: 7.0,
  paymentType: 'equal',
  monthlyOverpayment: 0,
  oneTimePayments: []
};

// Calculate base schedule without overpayments
const calculateBaseSchedule = (loanData: LoanData) => {
  const monthlyRate = loanData.interestRate / 100 / 12;
  const totalMonths = loanData.period * 12;
  const schedule = [];
  let remainingBalance = loanData.amount;
  let totalInterest = 0;
  let totalRealInterest = 0;
  let lastMonthlyPayment = 0;
  let currentMonthlyRate = monthlyRate;
  let basePayment = 0;

  // Calculate equal payment
  if (loanData.paymentType === 'equal') {
    basePayment = calculateEqualPayment(remainingBalance, monthlyRate, totalMonths);
  }

  // Generate schedule
  for (let month = 1; month <= totalMonths && remainingBalance > 0; month++) {
    let principal = 0;

    // WIBOR rate change
    if (loanData.wiborChangeYear && loanData.wiborChangeRate && 
        month === loanData.wiborChangeYear * 12 + 1) {
      const newRate = loanData.wiborChangeRate / 100 / 12;
      currentMonthlyRate = newRate;
      
      // Recalculate equal payment for new rate and remaining balance
      if (loanData.paymentType === 'equal') {
        const remainingMonths = totalMonths - month + 1;
        basePayment = calculateEqualPayment(
          remainingBalance, 
          newRate, 
          remainingMonths
        );
      }
    }

    // Calculate interest based on current balance
    const interest = remainingBalance * currentMonthlyRate;

    // Calculate principal portion based on payment type
    if (loanData.paymentType === 'equal') {
      principal = basePayment - interest;
      lastMonthlyPayment = basePayment;
    } else {
      // For decreasing payments, principal portion is constant
      principal = loanData.amount / totalMonths;
      lastMonthlyPayment = principal + interest;
    }

    // Update balance
    remainingBalance = Math.max(0, remainingBalance - principal);
    totalInterest += interest;

    // Calculate real interest considering inflation
    const inflationFactor = Math.pow(1.03, month / 12);
    const realInterest = interest / inflationFactor;
    totalRealInterest += realInterest;

    schedule.push({
      month,
      payment: lastMonthlyPayment,
      principal,
      interest,
      realInterest,
      remainingBalance,
      cumulativeInterest: totalInterest,
      cumulativeRealInterest: totalRealInterest,
      overpayment: 0
    });
    
    if (remainingBalance === 0) break;
  }

  return { 
    totalInterest, 
    totalRealInterest,
    schedule,
    monthlyPayment: basePayment,
    lastMonthlyPayment,
    months: schedule.length 
  };
};

// Calculate equal payment
const calculateEqualPayment = (principal: number, rate: number, months: number): number => {
  return (principal * rate * Math.pow(1 + rate, months)) / (Math.pow(1 + rate, months) - 1);
};

export default function MortgageCalculator() {
  const navigate = useNavigate();
  const [loanData, setLoanData] = useState<LoanData>(initialLoanData);
  const [results, setResults] = useState<CalculationResults | null>(null);

  // Calculate results whenever loan data changes
  useEffect(() => {
    calculateResults();
  }, [loanData]);

  // Main calculation function
  const calculateResults = () => {
    const monthlyRate = loanData.interestRate / 100 / 12;
    const totalMonths = loanData.period * 12;
    const schedule = [];
    let remainingBalance = loanData.amount;
    let totalInterest = 0;
    let totalRealInterest = 0;
    let initialMonthlyPayment = 0;
    let monthlyPaymentAfterWiborChange = 0;
    let currentMonthlyRate = monthlyRate;
    let basePayment = 0;

    // Calculate initial payment for equal payments
    if (loanData.paymentType === 'equal') {
      initialMonthlyPayment = calculateEqualPayment(remainingBalance, monthlyRate, totalMonths);
      basePayment = initialMonthlyPayment;
    } else {
      // For decreasing payments, initial payment is principal + interest on full amount
      const principal = loanData.amount / totalMonths;
      const interest = loanData.amount * monthlyRate;
      initialMonthlyPayment = principal + interest;
      basePayment = initialMonthlyPayment;
    }

    // Calculate schedule with overpayments
    for (let month = 1; month <= totalMonths && remainingBalance > 0; month++) {
      let basePrincipal = 0;
      let interest = 0;
      let payment = 0;
      let overpaymentAmount = 0;
      
      // Apply WIBOR rate change
      if (loanData.wiborChangeYear && loanData.wiborChangeRate && 
          month === loanData.wiborChangeYear * 12 + 1) {
        
        // Change monthly rate
        currentMonthlyRate = loanData.wiborChangeRate / 100 / 12;
        
        // Recalculate payment for new rate, keeping original term
        if (loanData.paymentType === 'equal') {
          // For term reduction, keep same payment amount
          const remainingMonths = totalMonths - month + 1;
          basePayment = calculateEqualPayment(
            remainingBalance, 
            currentMonthlyRate, 
            remainingMonths
          );
          monthlyPaymentAfterWiborChange = basePayment;
        }
      }

      // Calculate interest based on current balance and rate
      interest = remainingBalance * currentMonthlyRate;

      // Calculate principal portion based on payment type
      if (loanData.paymentType === 'equal') {
        // For equal payments, principal is payment minus interest
        basePrincipal = basePayment - interest;
        payment = basePayment;
      } else {
        // For decreasing payments, principal portion is constant
        basePrincipal = loanData.amount / totalMonths;
        payment = basePrincipal + interest;
        
        // Update monthly payment after WIBOR change for decreasing payments
        if (loanData.wiborChangeYear && month === loanData.wiborChangeYear * 12 + 1) {
          monthlyPaymentAfterWiborChange = payment;
        }
      }

      // Calculate total overpayment (monthly + one-time)
      let totalOverpayment = loanData.monthlyOverpayment;
      
      // Add one-time overpayment
      const oneTimePayment = loanData.oneTimePayments.find(p => p.month === month);
      if (oneTimePayment) {
        totalOverpayment += oneTimePayment.amount;
      }
      
      // Apply overpayment - ensure we don't overpay more than remaining balance
      if (totalOverpayment > 0) {
        overpaymentAmount = Math.min(totalOverpayment, remainingBalance - basePrincipal);
        
        // Total principal payment this month
        const totalPrincipal = basePrincipal + overpaymentAmount;
        
        // Update remaining balance
        remainingBalance = Math.max(0, remainingBalance - totalPrincipal);
        
        // Total payment this month (base payment + overpayment)
        payment = basePayment + overpaymentAmount;
      } else {
        // Standard balance update without overpayment
        remainingBalance = Math.max(0, remainingBalance - basePrincipal);
      }

      // Calculate real interest considering inflation
      const inflationFactor = Math.pow(1.03, month / 12);
      const realInterest = interest / inflationFactor;

      // Update total sums
      totalInterest += interest;
      totalRealInterest += realInterest;

      // Add payment info to schedule
      schedule.push({
        month,
        payment,
        principal: basePrincipal + overpaymentAmount,
        interest,
        realInterest,
        remainingBalance,
        cumulativeInterest: totalInterest,
        cumulativeRealInterest: totalRealInterest,
        overpayment: overpaymentAmount
      });

      // Break if loan is fully paid
      if (remainingBalance === 0) break;
    }

    // Calculate base schedule (without overpayments) for comparison
    const baseSchedule = calculateBaseSchedule(loanData);
    
    // Calculate interest savings
    const interestSavings = baseSchedule.totalInterest - totalInterest;
    
    // Calculate term reduction (in years)
    const periodReduction = (baseSchedule.months - schedule.length) / 12;

    setResults({
      monthlyPayment: initialMonthlyPayment,
      initialMonthlyPayment,
      monthlyPaymentAfterWiborChange,
      totalInterest,
      realTotalInterest: totalRealInterest,
      interestSavings,
      periodReduction,
      schedule
    });
  };

  return (
    <div className="pt-20 bg-gray-50">
      <FloatingHelpButton />
      
      <Hero 
        title="Kalkulator kredytu hipotecznego"
        highlightedWord="hipotecznego"
        description="Oblicz wysokość raty kredytu hipotecznego i sprawdź, na jaki kredyt możesz sobie pozwolić. Zaplanuj swój budżet już dziś."
        image="https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80"
        stats={[
          {
            icon: Calculator,
            value: "2 min",
            label: "Szybka kalkulacja"
          },
          {
            icon: BadgePercent,
            value: "99%",
            label: "Dokładność wyliczeń"
          },
          {
            icon: Clock,
            value: "150k+",
            label: "Wykonanych kalkulacji"
          }
        ]}
        primaryCta={{
          label: "Oblicz ratę",
          action: () => navigate('#calculator')
        }}
        secondaryCta={{
          label: "Skonsultuj się z ekspertem",
          action: () => navigate('#contact')
        }}
      />

      <div id="calculator" className="py-8 sm:py-12 md:py-16 lg:py-20">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="max-w-7xl mx-auto space-y-6 sm:space-y-8">
            {/* 1. Input and Results */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-8">
              <div className="lg:sticky lg:top-24 lg:self-start">
                <InputPanel 
                  loanData={loanData}
                  onLoanDataChange={(newData) => setLoanData(prev => ({ ...prev, ...newData }))}
                />
              </div>
              <div>
                <ResultsPanel 
                  results={results}
                  loanData={loanData}
                  onLoanDataChange={(newData) => setLoanData(prev => ({ ...prev, ...newData }))}
                />
              </div>
            </div>

            {/* 2. Data Visualization */}
            <ChartPanel 
              results={results}
              loanData={loanData}
            />

            {/* 3. Schedule */}
            <SchedulePanel
              results={results}
              loanData={loanData}
            />

            {/* 4. How to use calculator */}
            <div id="instructions">
              <InstructionPanel />
            </div>

            {/* 5. Lowest Interest Section */}
            <LowestInterestSection />

            {/* 6. Contact Form */}
            <Contact />
          </div>
        </div>
      </div>
    </div>
  );
}