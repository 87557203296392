import { Home, BadgePercent, Clock, Shield } from 'lucide-react';
import type { SocialProofStats } from './types';

export const mortgageStats: SocialProofStats = {
  clients: {
    icon: Home,
    value: 1000,
    showPlus: true,
    label: "Sfinansowanych nieruchomości"
  },
  financing: {
    icon: BadgePercent,
    value: 5.2,
    suffix: " mld zł",
    label: "Łączna wartość kredytów"
  },
  experience: {
    icon: Clock,
    value: 24,
    suffix: "h",
    label: "Średni czas decyzji"
  },
  satisfaction: {
    icon: Shield,
    value: 98,
    suffix: "%",
    label: "Pozytywnych decyzji"
  }
};