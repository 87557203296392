import { POST_WKLAD_WLASNY_10_VS_20 } from './wklad-wlasny-10-vs-20';
import { POST_STALE_VS_ZMIENNE_OPROCENTOWANIE } from './stale-vs-zmienne-oprocentowanie';
import { POST_STRATEGIA_ZAMIANY_MIESZKANIA } from './strategia-zamiany-mieszkania';
import { POST_KREDYT_NA_BUDOWE_DOMU } from './kredyt-na-budowe-domu';
import { POST_BUDZETOWANIE_BUDOWY_DOMU } from './budzetowanie-budowy-domu';
import { POST_REFINANSOWANIE_2025 } from './refinansowanie-2025';
import { POST_REFINANSOWANIE_KREDYTU_Z_WYSOKA_MARZA } from './refinansowanie-kredytu-z-wysoka-marza';
import { POST_REFINANSOWANIE_KROK_PO_KROKU } from './refinansowanie-krok-po-kroku';
import { POST_KIEDY_REFINANSOWANIE_SIE_OPLACA } from './kiedy-refinansowanie-sie-oplaca';

// Array of blog posts
export const BLOG_POSTS = [
  POST_WKLAD_WLASNY_10_VS_20,
  POST_STALE_VS_ZMIENNE_OPROCENTOWANIE,
  POST_STRATEGIA_ZAMIANY_MIESZKANIA,
  POST_KREDYT_NA_BUDOWE_DOMU,
  POST_BUDZETOWANIE_BUDOWY_DOMU,
  POST_REFINANSOWANIE_2025,
  POST_REFINANSOWANIE_KREDYTU_Z_WYSOKA_MARZA,
  POST_REFINANSOWANIE_KROK_PO_KROKU,
  POST_KIEDY_REFINANSOWANIE_SIE_OPLACA
];

// Export post references
export {
  POST_WKLAD_WLASNY_10_VS_20,
  POST_STALE_VS_ZMIENNE_OPROCENTOWANIE,
  POST_STRATEGIA_ZAMIANY_MIESZKANIA,
  POST_KREDYT_NA_BUDOWE_DOMU,
  POST_BUDZETOWANIE_BUDOWY_DOMU,
  POST_REFINANSOWANIE_2025,
  POST_REFINANSOWANIE_KREDYTU_Z_WYSOKA_MARZA,
  POST_REFINANSOWANIE_KROK_PO_KROKU,
  POST_KIEDY_REFINANSOWANIE_SIE_OPLACA
};