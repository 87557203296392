// Interest rates
export const WIBOR = 5.81; // 5.81%
export const MARGIN = 1.60; // 1.60%
export const BUFFER_RATE = 2.50; // 2.50%
export const FIXED_RATE = 6.70; // 6.70%

// Loan parameters
export const LOAN_PERIOD_YEARS = 25;
export const DOWN_PAYMENT_PERCENT = 0.2; // 20%

// DSTI limits
export const MAX_DSTI_STANDARD = 0.5; // 50% for income < 10,000 zł
export const MAX_DSTI_HIGH_INCOME = 0.6; // 60% for income >= 10,000 zł
export const HIGH_INCOME_THRESHOLD = 10000;

// Household expenses
export const FIRST_PERSON_EXPENSE = 1200;
export const ADDITIONAL_PERSON_EXPENSE = 1000;

// Credit limits
export const CREDIT_LIMIT_BURDEN_PERCENT = 0.05; // 5%