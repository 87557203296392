import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Calculator, Users, Clock, Phone, Menu, FileText } from 'lucide-react';

interface NavigationItem {
  id: string;
  label: string;
  icon: React.ElementType;
}

interface HomePageNavigationProps {
  items?: NavigationItem[];
}

const defaultItems: NavigationItem[] = [
  {
    id: 'services',
    label: 'Usługi',
    icon: FileText
  },
  {
    id: 'calculator',
    label: 'Kalkulatory',
    icon: Calculator
  },
  {
    id: 'process',
    label: 'Proces',
    icon: Clock
  },
  {
    id: 'testimonials',
    label: 'Opinie',
    icon: Users
  },
  {
    id: 'contact',
    label: 'Kontakt',
    icon: Phone
  }
];

export default function HomePageNavigation({ items = defaultItems }: HomePageNavigationProps) {
  const [activeSection, setActiveSection] = useState<string>('');
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);

  // Własna implementacja throttle bez zależności od Lodash
  const throttleRef = useRef<{
    lastCall: number;
    timeoutId: number | null;
  }>({
    lastCall: 0,
    timeoutId: null
  });

  // Używamy useCallback dla optymalizacji wydajności funkcji obsługi przewijania
  const handleScroll = useCallback(() => {
    const now = Date.now();
    const throttleTime = 150; // 150ms throttle
    
    // Implementacja throttle
    if (now - throttleRef.current.lastCall < throttleTime) {
      // Anuluj poprzedni timeout, jeśli istnieje
      if (throttleRef.current.timeoutId !== null) {
        window.clearTimeout(throttleRef.current.timeoutId);
      }
      
      // Ustaw nowy timeout
      throttleRef.current.timeoutId = window.setTimeout(() => {
        throttleRef.current.lastCall = Date.now();
        throttleRef.current.timeoutId = null;
        
        // Właściwa logika przewijania
        const currentScrollY = window.scrollY;
        
        // Show/hide based on scroll direction
        if (currentScrollY > lastScrollY) {
          setIsVisible(false); // Scrolling down
        } else {
          setIsVisible(true); // Scrolling up
        }
        setLastScrollY(currentScrollY);
        
        // Find active section
        const sections = items.map(item => document.getElementById(item.id)).filter(Boolean);
        let currentSection = '';
        
        for (const section of sections) {
          const rect = section!.getBoundingClientRect();
          const offset = window.innerHeight * 0.3; // 30% of viewport height
          
          if (rect.top <= offset && rect.bottom >= offset) {
            currentSection = section!.id;
            break;
          }
        }
        
        if (currentSection) {
          setActiveSection(currentSection);
        }
      }, throttleTime);
      
      return;
    }
    
    // Wykonaj funkcję bezpośrednio, jeśli minął czas throttle
    throttleRef.current.lastCall = now;
    
    const currentScrollY = window.scrollY;
    
    // Show/hide based on scroll direction
    if (currentScrollY > lastScrollY) {
      setIsVisible(false); // Scrolling down
    } else {
      setIsVisible(true); // Scrolling up
    }
    setLastScrollY(currentScrollY);
    
    // Find active section
    const sections = items.map(item => document.getElementById(item.id)).filter(Boolean);
    let currentSection = '';
    
    for (const section of sections) {
      const rect = section!.getBoundingClientRect();
      const offset = window.innerHeight * 0.3; // 30% of viewport height
      
      if (rect.top <= offset && rect.bottom >= offset) {
        currentSection = section!.id;
        break;
      }
    }
    
    if (currentSection) {
      setActiveSection(currentSection);
    }
  }, [items, lastScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    handleScroll(); // Initial check

    return () => {
      // Anuluj timeout, jeśli istnieje
      if (throttleRef.current.timeoutId !== null) {
        window.clearTimeout(throttleRef.current.timeoutId);
      }
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  // Obsługa nawigacji klawiaturą
  const handleKeyDown = (e: React.KeyboardEvent, id: string) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleItemClick(id);
    }
  };

  // Implementacja focus trap dla dostępności z klawiatury
  useEffect(() => {
    const handleKeyNavigation = (e: KeyboardEvent) => {
      if (!navRef.current) return;
      
      // Obsługa Tab - tylko dla widocznej nawigacji
      if (e.key === 'Tab' && isVisible) {
        const focusableElements = navRef.current.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        
        const firstElement = focusableElements[0] as HTMLElement;
        const lastElement = focusableElements[focusableElements.length - 1] as HTMLElement;
        
        // Obsługa Tab + Shift (nawigacja wstecz)
        if (e.shiftKey && document.activeElement === firstElement) {
          lastElement.focus();
          e.preventDefault();
        }
        // Obsługa Tab (nawigacja do przodu)
        else if (!e.shiftKey && document.activeElement === lastElement) {
          firstElement.focus();
          e.preventDefault();
        }
      }
    };
    
    document.addEventListener('keydown', handleKeyNavigation);
    return () => document.removeEventListener('keydown', handleKeyNavigation);
  }, [isVisible]);

  const handleItemClick = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const headerOffset = 80;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    setShowMenu(false);
  };

  return (
    <>
      {/* Mobile Bottom Navigation */}
      <nav 
        ref={navRef}
        className={`
          fixed bottom-0 left-0 right-0 z-40 md:hidden
          transform transition-transform duration-300 ease-out
          ${isVisible ? 'translate-y-0' : 'translate-y-full'}
          pb-safe
        `}
        aria-label="Nawigacja mobilna"
        role="navigation"
      >
        {/* Gradient Background */}
        <div 
          className="absolute inset-0 bg-gradient-to-t from-white via-white/95 to-white/90 backdrop-blur-md shadow-[0_-1px_3px_rgba(0,0,0,0.1)]" 
          aria-hidden="true"
        />

        {/* Navigation Items */}
        <div className="container mx-auto px-4 py-2 relative">
          <div className="grid grid-cols-5 gap-1">
            {items.map((item) => {
              const Icon = item.icon;
              const isActive = activeSection === item.id;
              
              return (
                <button
                  key={item.id}
                  onClick={() => handleItemClick(item.id)}
                  onKeyDown={(e) => handleKeyDown(e, item.id)}
                  className={`
                    flex flex-col items-center justify-center py-2 px-1
                    transition-all duration-300 relative
                    ${isActive ? 'text-[#003A30]' : 'text-gray-500 hover:text-[#003A30]'}
                    focus:outline-none focus:ring-2 focus:ring-[#003A30] focus:ring-opacity-50 rounded-lg
                  `}
                  aria-controls={item.id}
                  aria-current={isActive ? 'true' : 'false'}
                >
                  {/* Active Indicator */}
                  {isActive && (
                    <div 
                      className="absolute -top-2 left-1/2 -translate-x-1/2 w-1 h-1 rounded-full bg-[#003A30]"
                      aria-hidden="true"
                    />
                  )}
                  
                  <Icon 
                    className={`
                      w-5 h-5 mb-1 transition-transform duration-300
                      ${isActive ? 'scale-110 text-[#CBA331]' : 'scale-100'}
                    `}
                    aria-hidden="true"
                  />
                  <span className={`
                    text-[10px] transition-all duration-300
                    ${isActive ? 'font-medium' : 'font-normal'}
                  `}>
                    {item.label}
                  </span>
                </button>
              );
            })}
          </div>
        </div>
      </nav>

      {/* Desktop Navigation */}
      <nav 
        className={`
          hidden md:block fixed bottom-8 right-8 z-40
          transform transition-all duration-300
          ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
        `}
        aria-label="Nawigacja desktopowa"
        role="navigation"
      >
        <div className="bg-white/90 backdrop-blur-md rounded-2xl shadow-lg p-2 border border-gray-100">
          <div className="space-y-1">
            {items.map((item) => {
              const Icon = item.icon;
              const isActive = activeSection === item.id;
              
              return (
                <button
                  key={item.id}
                  onClick={() => handleItemClick(item.id)}
                  onKeyDown={(e) => handleKeyDown(e, item.id)}
                  className={`
                    flex items-center gap-3 w-full px-4 py-2.5 rounded-xl
                    transition-all duration-300 group relative
                    ${isActive 
                      ? 'bg-[#003A30] text-white' 
                      : 'text-gray-600 hover:bg-gray-100'
                    }
                    focus:outline-none focus:ring-2 focus:ring-[#003A30] focus:ring-opacity-50
                  `}
                  aria-controls={item.id}
                  aria-current={isActive ? 'true' : 'false'}
                >
                  <Icon 
                    className={`
                      w-5 h-5 transition-all duration-300
                      ${isActive ? 'text-[#CBA331]' : 'group-hover:text-[#003A30]'}
                    `}
                    aria-hidden="true"
                  />
                  <span className={`
                    text-sm transition-all duration-300
                    ${isActive ? 'font-medium' : 'font-normal'}
                  `}>
                    {item.label}
                  </span>
                </button>
              );
            })}
          </div>
        </div>
      </nav>
    </>
  );
}