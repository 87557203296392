import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Home, Building2, RefreshCw, PiggyBank, ArrowRight, BadgePercent, Clock, Shield } from 'lucide-react';

export default function Services() {
  const [visibleServices, setVisibleServices] = useState<number[]>([]);
  const servicesRef = useRef<(HTMLDivElement | null)[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const serviceIndex = servicesRef.current.findIndex(ref => ref === entry.target);
            if (serviceIndex !== -1 && !visibleServices.includes(serviceIndex)) {
              setVisibleServices(prev => [...prev, serviceIndex].sort((a, b) => a - b));
            }
          }
        });
      },
      {
        threshold: 0.2,
        rootMargin: '-10% 0px -10% 0px'
      }
    );

    servicesRef.current.forEach(service => {
      if (service) observer.observe(service);
    });

    return () => observer.disconnect();
  }, []);

  const services = [
    {
      icon: Home,
      title: "Kredyty hipoteczne",
      description: "Kompleksowa pomoc w kredycie na wymarzony dom lub mieszkanie.",
      benefits: [
        "Pewność decyzji pozytywnej",
        "Maksymalne bezpieczeństwo transakcji",
        "Negocjacje warunków"
      ],
      stats: {
        icon: BadgePercent,
        value: "3 dni",
        label: "Czas decyzji kredytowej"
      },
      link: "/kredyty-hipoteczne",
      gradient: "from-emerald-500/20 to-emerald-500/5"
    },
    {
      icon: RefreshCw,
      title: "Refinansowanie",
      description: "Zamień obecny kredyt na korzystniejszy i zacznij oszczędzać już dziś.",
      benefits: [
        "Niższa rata miesięczna",
        "Marża kredytu od 1,60%",
        "30% dodatkowych środków"
      ],
      stats: {
        icon: BadgePercent,
        value: "- 153 600 zł",
        label: "Średnia oszczędność"
      },
      link: "/refinansowanie",
      gradient: "from-amber-500/20 to-amber-500/5"
    },
    {
      icon: Building2,
      title: "Kredyty firmowe",
      description: "Skuteczne wsparcie w pozyskaniu finansowania dla Twojego biznesu.",
      benefits: [
        "Do 177% wartości nieruchomości",
        "Marża kredytu od 1,40%",
        "do 2 mln z poręczniem BGK"
      ],
      stats: {
        icon: Clock,
        value: "20 lat",
        label: "Okres kredytowania"
      },
      link: "/kredyty-firmowe",
      gradient: "from-blue-500/20 to-blue-500/5"
    },
    {
      icon: PiggyBank,
      title: "Optymalizacja",
      description: "Profesjonalna optymalizacja kosztów kredytów i zobowiązań firmowych.",
      benefits: [
        "Poprawa płynności",
        "Niższe oprocentowanie i raty",
        "Dodatkowe środki na rowzój"
      ],
      stats: {
        icon: BadgePercent,
        value: "37%",
        label: "Średnia redukcja rat"
      },
      link: "/optymalizacja",
      gradient: "from-purple-500/20 to-purple-500/5"
    }
  ];

  return (
    <section id="uslugi" className="relative py-12 sm:py-16 md:py-20 lg:py-24 bg-gradient-to-b from-gray-50 to-white overflow-hidden" aria-labelledby="services-heading">
      {/* Decorative background elements */}
      <div className="absolute inset-0 pointer-events-none" aria-hidden="true">
        <div className="absolute top-0 left-1/4 w-64 h-64 bg-[#003A30]/10 rounded-full blur-3xl opacity-30 transform-gpu" />
        <div className="absolute bottom-0 right-1/4 w-64 h-64 bg-[#CBA331]/10 rounded-full blur-3xl opacity-30 transform-gpu" />
      </div>

      <div className="relative container mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header */}
        <div className="max-w-3xl mx-auto text-center mb-12 lg:mb-16">
          <span className="inline-block text-[#CBA331] font-semibold mb-2 text-sm sm:text-base">
            Nasze usługi
          </span>
          <h2 id="services-heading" className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#003A30] mb-3">
            Kompleksowe rozwiązania finansowe
          </h2>
          <p className="text-gray-600 text-base sm:text-lg md:text-xl">
            Wybierz usługę dopasowaną do Twoich potrzeb i rozpocznij współpracę.
          </p>
        </div>

        {/* Services Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 lg:gap-8 max-w-[1400px] mx-auto">
          {services.map((service, index) => (
            <div 
              key={index}
              ref={el => servicesRef.current[index] = el}
              className={`
                group relative bg-white rounded-2xl shadow-[0_4px_20px_rgba(0,0,0,0.08)] overflow-hidden
                transform transition-all duration-700 ease-out
                ${visibleServices.includes(index) 
                  ? 'translate-y-0 scale-100 opacity-100' 
                  : 'translate-y-8 scale-95 opacity-0'
                }
                hover:shadow-[0_8px_30px_rgba(0,0,0,0.12)] hover:-translate-y-1
              `}
              style={{
                transitionDelay: `${index * 100}ms`
              }}
            >
              {/* Top Gradient Bar */}
              <div 
                className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-[#003A30] to-[#CBA331] transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100" 
                aria-hidden="true"
              />
              
              <div className="p-6 sm:p-8 flex flex-col h-full">
                {/* Icon and Title Section */}
                <div className="text-center mb-6">
                  <div className={`
                    w-16 h-16 rounded-2xl bg-gradient-to-br ${service.gradient} 
                    flex items-center justify-center mx-auto mb-4 
                    transform transition-all duration-500
                    ${visibleServices.includes(index) ? 'scale-100' : 'scale-90'}
                    group-hover:scale-110
                  `}>
                    <service.icon className="w-8 h-8 text-[#003A30] group-hover:text-[#CBA331] transition-colors duration-300" />
                  </div>
                  <h3 className="text-xl font-bold text-[#003A30]">{service.title}</h3>
                </div>

                {/* Description */}
                <p className={`
                  text-gray-600 text-center mb-6
                  transform transition-all duration-500 delay-100
                  ${visibleServices.includes(index) ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
                `}>
                  {service.description}
                </p>

                {/* Benefits List */}
                <ul className="space-y-3 mb-6 flex-grow" role="list">
                  {service.benefits.map((benefit, idx) => (
                    <li 
                      key={idx} 
                      className={`
                        flex items-start gap-2 text-gray-600
                        transform transition-all duration-500
                        ${visibleServices.includes(index) ? 'translate-x-0 opacity-100' : 'translate-x-4 opacity-0'}
                      `}
                      style={{
                        transitionDelay: `${200 + idx * 100}ms`
                      }}
                    >
                      <div className="w-1.5 h-1.5 rounded-full bg-[#CBA331] mt-2 flex-shrink-0" />
                      <span>{benefit}</span>
                    </li>
                  ))}
                </ul>

                {/* Stats */}
                <div className={`
                  flex items-center justify-center gap-3 p-4 bg-gray-50 rounded-lg mb-6
                  transform transition-all duration-500 delay-500
                  ${visibleServices.includes(index) ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
                `}>
                  <service.stats.icon className="w-5 h-5 text-[#CBA331]" />
                  <div className="text-center">
                    <span className="block text-lg font-bold text-[#003A30]">{service.stats.value}</span>
                    <span className="text-sm text-gray-600">{service.stats.label}</span>
                  </div>
                </div>

                {/* CTA Button */}
                <button 
                  onClick={() => navigate(service.link)} 
                  className={`
                    group/btn relative inline-flex items-center justify-center px-6 py-3 bg-[#003A30] text-white rounded-lg 
                    overflow-hidden transition-all duration-300 w-full
                    transform transition-all duration-500 delay-600
                    ${visibleServices.includes(index) ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
                  `}
                >
                  {/* Animated background gradient */}
                  <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-transparent via-white/10 to-transparent -translate-x-full group-hover/btn:translate-x-full transition-transform duration-1000 ease-out" />
                  
                  {/* Button content */}
                  <span className="relative flex items-center gap-2">
                    <span className="relative">
                      Dowiedz się więcej
                      <span className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-white to-transparent transform scale-x-0 group-hover/btn:scale-x-100 transition-transform duration-500" />
                    </span>
                    <ArrowRight className="w-5 h-5 group-hover/btn:translate-x-1 transition-transform duration-300" />
                  </span>
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Trust Indicators */}
        <div className="mt-12 lg:mt-16 text-center">
          <p className="text-gray-500 text-sm sm:text-base">
            Zaufało nam już ponad <span className="text-[#003A30] font-semibold">150 000</span> użytkowników
          </p>
        </div>
      </div>
    </section>
  );
}