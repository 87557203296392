import React from 'react';
import Concerns from '@/components/shared/Concerns';
import { mortgageConcernsData } from '@/data/ConcernsData';

export default function MortgageConcerns() {
  return (
    <Concerns 
      concerns={mortgageConcernsData.concerns}
      title={mortgageConcernsData.title}
      subtitle={mortgageConcernsData.subtitle}
    />
  );
}