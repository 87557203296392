import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { 
  CheckCircle2, 
  XCircle, 
  TrendingDown, 
  BarChart2, 
  Clock, 
  Banknote, 
  Percent, 
  CreditCard,
  Calendar,
  Info
} from 'lucide-react';

interface ComparisonItem {
  category: string;
  before: string;
  after: string;
  difference: string;
  percentChange?: number;
  icon: React.ReactNode;
  description: string;
  tooltip?: string;
}

interface RefinancingOptions {
  lowerRate: ComparisonItem[];
  shorterTerm: ComparisonItem[];
}

export default function Comparison() {
  const location = useLocation();
  const isRefinancing = location.pathname.includes('refinansowanie');
  const [showDetails, setShowDetails] = useState<number | null>(null);
  const [refinancingOption, setRefinancingOption] = useState<'lowerRate' | 'shorterTerm'>('shorterTerm');
  
  // Deklaracja opcji dla refinansowania
  const refinancingOptions: RefinancingOptions = {
    // Opcja 1: Taki sam okres, niższa rata
    lowerRate: [
      {
        category: "Marża kredytu",
        before: "2,2%",
        after: "1,6%",
        difference: "-0,6 punktu proc.",
        percentChange: -27.3,
        icon: <Percent className="w-6 h-6 text-[#003A30]" />,
        description: "Obniżenie marży to kluczowy parametr refinansowania. Niższa marża oznacza niższe comiesięczne raty oraz mniejszy całkowity koszt kredytu.",
        tooltip: "Marża to część oprocentowania, która stanowi zysk banku. Niższa marża = niższe odsetki."
      },
      {
        category: "Rata kredytu",
        before: "3 671 zł",
        after: "3 461 zł",
        difference: "-210 zł miesięcznie",
        percentChange: -5.7,
        icon: <Banknote className="w-6 h-6 text-[#003A30]" />,
        description: "Niższa rata miesięczna to więcej środków w domowym budżecie, które możesz przeznaczyć na inne cele lub inwestycje.",
        tooltip: "Przy kredycie 500 000 zł na 28 lat, obniżka marży z 2,2% do 1,6% daje oszczędność 210 zł miesięcznie."
      },
      {
        category: "Okres kredytowania",
        before: "28 lat",
        after: "28 lat",
        difference: "Bez zmian",
        percentChange: 0,
        icon: <Clock className="w-6 h-6 text-[#003A30]" />,
        description: "W tej opcji okres kredytowania pozostaje bez zmian, co pozwala cieszyć się niższą ratą przez cały pierwotny czas trwania umowy.",
        tooltip: "Zachowanie tego samego okresu kredytowania przy niższej marży skutkuje niższą ratą miesięczną."
      },
      {
        category: "Całkowity koszt",
        before: "1 233 456 zł",
        after: "1 162 896 zł",
        difference: "-70 560 zł",
        percentChange: -5.7,
        icon: <BarChart2 className="w-6 h-6 text-[#003A30]" />,
        description: "Niższa marża przy zachowaniu tego samego okresu kredytowania oznacza znaczne oszczędności w całkowitym koszcie kredytu.",
        tooltip: "Całkowity koszt to suma wszystkich rat przez cały okres kredytowania (kapitał + odsetki)."
      }
    ],
    // Opcja 2: Ta sama rata, krótszy okres
    shorterTerm: [
      {
        category: "Marża kredytu",
        before: "2,2%",
        after: "1,6%",
        difference: "-0,6 punktu proc.",
        percentChange: -27.3,
        icon: <Percent className="w-6 h-6 text-[#003A30]" />,
        description: "Obniżenie marży to kluczowy parametr refinansowania. Niższa marża sprawia, że spłacasz mniej odsetek przez cały okres kredytowania.",
        tooltip: "Marża to część oprocentowania, która stanowi zysk banku. Niższa marża = niższe odsetki."
      },
      {
        category: "Rata kredytu",
        before: "3 671 zł",
        after: "3 671 zł",
        difference: "Bez zmian",
        percentChange: 0,
        icon: <Banknote className="w-6 h-6 text-[#003A30]" />,
        description: "Zachowanie wysokości raty przy niższym oprocentowaniu pozwala na szybszą spłatę kapitału, dzięki czemu więcej z każdej raty trafia na spłatę zadłużenia.",
        tooltip: "Przy zachowaniu tej samej raty, ale niższym oprocentowaniu, większa część raty trafia na spłatę kapitału zamiast odsetek."
      },
      {
        category: "Okres kredytowania",
        before: "28 lat",
        after: "23 lata i 4 mies.",
        difference: "-4 lata i 8 mies.",
        percentChange: -16.7,
        icon: <Clock className="w-6 h-6 text-[#003A30]" />,
        description: "Skrócenie okresu kredytowania o prawie 5 lat to ogromna oszczędność na odsetkach oraz szybsza wolność finansowa - wcześniej możesz być wolny od zobowiązań.",
        tooltip: "Krótszy okres kredytowania to znacznie mniej zapłaconych odsetek w całym okresie kredytowania."
      },
      {
        category: "Całkowity koszt",
        before: "1 233 456 zł",
        after: "1 027 880 zł",
        difference: "-205 576 zł",
        percentChange: -16.7,
        icon: <BarChart2 className="w-6 h-6 text-[#003A30]" />,
        description: "Przy zachowaniu tej samej raty i obniżeniu marży, znacząco zmniejsza się całkowity koszt kredytu. To realny zwrot z decyzji o refinansowaniu.",
        tooltip: "Oszczędność ponad 200 000 zł to efekt skrócenia okresu kredytowania przy zachowaniu tej samej raty miesięcznej."
      }
    ]
  };

  // Zaktualizowane dane dla optymalizacji biznesowej
  const optimizationData: ComparisonItem[] = [
    {
      category: "Miesięczne koszty",
      before: "17 400 zł",
      after: "14 800 zł",
      difference: "-2 600 zł miesięcznie",
      percentChange: -15,
      icon: <TrendingDown className="w-6 h-6 text-[#003A30]" />,
      description: "Comiesięczna oszczędność, którą możesz reinwestować w rozwój firmy, nowe wyposażenie lub dodatkowy personel.",
      tooltip: "Zmniejszenie miesięcznych kosztów obsługi zadłużenia o 2 600 zł to 31 200 zł oszczędności rocznie."
    },
    {
      category: "Liczba kredytów",
      before: "4",
      after: "1",
      difference: "Uproszczona obsługa",
      percentChange: -75,
      icon: <CreditCard className="w-6 h-6 text-[#003A30]" />,
      description: "Konsolidacja kredytów upraszcza zarządzanie finansami, zmniejsza obciążenie administracyjne i obniża ryzyko opóźnień w płatnościach.",
      tooltip: "Jeden kredyt zamiast czterech to mniej dokumentacji, łatwiejsze zarządzanie płatnościami i niższe koszty obsługi."
    },
    {
      category: "Średnie oprocentowanie",
      before: "8,5%",
      after: "7,2%",
      difference: "-1,3 punktu proc.",
      percentChange: -15.3,
      icon: <Percent className="w-6 h-6 text-[#003A30]" />,
      description: "Przy dużych kwotach finansowania, każda dziesiąta część punktu procentowego ma znaczenie dla długoterminowej rentowności Twojego biznesu.",
      tooltip: "Na kredycie 1 200 000 zł, obniżenie oprocentowania o 1,3 p.p. to oszczędność 15 600 zł rocznie."
    },
    {
      category: "Roczne koszty obsługi",
      before: "208 800 zł",
      after: "177 600 zł",
      difference: "-31 200 zł rocznie",
      percentChange: -15,
      icon: <BarChart2 className="w-6 h-6 text-[#003A30]" />,
      description: "Ta oszczędność może sfinansować nowe stanowisko pracy, kampanię marketingową lub modernizację sprzętu w Twojej firmie.",
      tooltip: "W 5-letnim okresie to oszczędność 156 000 zł, które można przeznaczyć na rozwój firmy."
    }
  ];

  // Wybór odpowiednich danych w zależności od sekcji i opcji
  const comparisons: ComparisonItem[] = isRefinancing 
    ? (refinancingOption === 'lowerRate' ? refinancingOptions.lowerRate : refinancingOptions.shorterTerm)
    : optimizationData;

  // Formatowanie procentów z odpowiednim kolorem
  const formatPercentChange = (percent: number | undefined) => {
    if (percent === undefined) return null;
    
    const color = percent < 0 ? "text-emerald-600" : "text-red-600";
    return <span className={`${color} font-medium`}>{percent}%</span>;
  };

  // Obliczanie łącznych oszczędności na podstawie wybranej opcji
  const calculateTotalSavings = () => {
    if (isRefinancing) {
      return refinancingOption === 'lowerRate' 
        ? {
            monthly: "210 zł",
            yearly: "2 520 zł",
            total: "70 560 zł"
          }
        : {
            monthly: "0 zł", // ta sama rata
            yearly: "0 zł",
            total: "205 576 zł",
            shorterBy: "4 lata i 8 mies."
          };
    } else {
      return {
        monthly: "2 600 zł",
        yearly: "31 200 zł",
        fiveYears: "156 000 zł"
      };
    }
  };
  
  const totalSavings = calculateTotalSavings();

  // Funkcja renderująca tooltip
  const renderTooltip = (tooltip?: string) => {
    if (!tooltip) return null;
    
    return (
      <div className="group relative inline-block ml-1">
        <Info className="w-4 h-4 text-gray-400 cursor-help" />
        <div className="opacity-0 group-hover:opacity-100 absolute z-10 bg-gray-800 text-white text-xs rounded p-2 w-48 transition-opacity duration-300 -top-2 left-6">
          {tooltip}
        </div>
      </div>
    );
  };

  return (
    <section className="py-12 md:py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-12">
          <span className="inline-block px-4 py-1 rounded-full bg-emerald-100 text-emerald-800 text-sm font-medium mb-4">
            {isRefinancing ? 'Refinansowanie kredytu' : 'Optymalizacja finansowania'}
          </span>
          <h2 className="text-2xl md:text-4xl font-bold text-[#003A30] mb-4">
            {isRefinancing 
              ? (refinancingOption === 'shorterTerm' ? 'Kredyt spłacony prawie 5 lat wcześniej' : 'Niższa rata, ten sam okres kredytowania') 
              : 'Optymalizacja kosztów finansowania biznesu'
            }
          </h2>
          <p className="text-lg text-gray-600 mb-6">
            {isRefinancing 
              ? 'Symulacja refinansowania kredytu 500 000 zł z marżą obniżoną z 2,2% do 1,6%'
              : 'Analiza korzyści z konsolidacji i optymalizacji zadłużenia biznesowego'
            }
          </p>
          
          {/* Przełącznik opcji dla refinansowania */}
          {isRefinancing && (
            <div className="flex justify-center mb-8">
              <div className="bg-gray-100 rounded-full p-1 flex">
                <button 
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                    refinancingOption === 'lowerRate' 
                      ? 'bg-white shadow-sm text-emerald-700' 
                      : 'text-gray-600 hover:bg-white/30'
                  }`}
                  onClick={() => setRefinancingOption('lowerRate')}
                  aria-pressed={refinancingOption === 'lowerRate'}
                >
                  Niższa rata
                </button>
                <button 
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                    refinancingOption === 'shorterTerm' 
                      ? 'bg-white shadow-sm text-emerald-700' 
                      : 'text-gray-600 hover:bg-white/30'
                  }`}
                  onClick={() => setRefinancingOption('shorterTerm')}
                  aria-pressed={refinancingOption === 'shorterTerm'}
                >
                  Krótszy okres
                </button>
              </div>
            </div>
          )}

          {/* Karty z oszczędnościami */}
          <div className="flex flex-wrap justify-center gap-4 mb-8">
            {isRefinancing ? (
              refinancingOption === 'lowerRate' ? (
                <>
                  <div className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center min-w-[160px]">
                    <span className="text-lg font-medium text-gray-500">Niższa rata o</span>
                    <span className="text-2xl font-bold text-emerald-600">{totalSavings.monthly}</span>
                  </div>
                  <div className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center min-w-[160px]">
                    <span className="text-lg font-medium text-gray-500">Obniżka marży</span>
                    <span className="text-2xl font-bold text-emerald-600">0,6 p.p.</span>
                  </div>
                  <div className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center min-w-[160px]">
                    <span className="text-lg font-medium text-gray-500">Całkowita oszczędność</span>
                    <span className="text-2xl font-bold text-emerald-600">{totalSavings.total}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center min-w-[160px]">
                    <span className="text-lg font-medium text-gray-500">Krótszy okres o</span>
                    <span className="text-2xl font-bold text-emerald-600">{totalSavings.shorterBy}</span>
                  </div>
                  <div className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center min-w-[160px]">
                    <span className="text-lg font-medium text-gray-500">Obniżka marży</span>
                    <span className="text-2xl font-bold text-emerald-600">0,6 p.p.</span>
                  </div>
                  <div className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center min-w-[160px]">
                    <span className="text-lg font-medium text-gray-500">Całkowita oszczędność</span>
                    <span className="text-2xl font-bold text-emerald-600">{totalSavings.total}</span>
                  </div>
                </>
              )
            ) : (
              <>
                <div className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center min-w-[160px]">
                  <span className="text-lg font-medium text-gray-500">Miesięcznie</span>
                  <span className="text-2xl font-bold text-emerald-600">{totalSavings.monthly}</span>
                </div>
                <div className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center min-w-[160px]">
                  <span className="text-lg font-medium text-gray-500">Rocznie</span>
                  <span className="text-2xl font-bold text-emerald-600">{totalSavings.yearly}</span>
                </div>
                <div className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center min-w-[160px]">
                  <span className="text-lg font-medium text-gray-500">W ciągu 5 lat</span>
                  <span className="text-2xl font-bold text-emerald-600">{totalSavings.fiveYears}</span>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="max-w-4xl mx-auto">
          <div 
            className="bg-white rounded-xl shadow-lg overflow-hidden"
            role="table"
            aria-label={isRefinancing 
              ? "Porównanie warunków kredytu przed i po refinansowaniu"
              : "Porównanie kosztów przed i po optymalizacji"
            }
          >
            {/* Nagłówki */}
            <div 
              className="grid grid-cols-12 text-center font-bold text-[#003A30] p-4 border-b bg-gray-50"
              role="row"
            >
              <div role="columnheader" className="col-span-3 text-left flex items-center gap-2">Kategoria</div>
              <div role="columnheader" className="col-span-2">Przed</div>
              <div role="columnheader" className="col-span-2">Po</div>
              <div role="columnheader" className="col-span-3">Różnica</div>
              <div role="columnheader" className="col-span-2">Zmiana %</div>
            </div>

            {/* Wiersze z danymi */}
            {comparisons.map((item, index) => (
              <React.Fragment key={index}>
                <div 
                  className={`grid grid-cols-12 text-center p-4 hover:bg-gray-50 transition-colors duration-300 cursor-pointer ${showDetails === index ? 'bg-gray-50 border-b-0' : 'border-b'}`}
                  role="row"
                  onClick={() => setShowDetails(showDetails === index ? null : index)}
                >
                  <div 
                    className="col-span-3 font-medium text-[#003A30] text-left flex items-center gap-2"
                    role="rowheader"
                  >
                    {item.icon}
                    {item.category}
                    {renderTooltip(item.tooltip)}
                  </div>
                  <div 
                    className="col-span-2 text-red-600 flex items-center justify-center gap-1"
                    role="cell"
                  >
                    <XCircle className="w-4 h-4" aria-hidden="true" />
                    {item.before}
                  </div>
                  <div 
                    className="col-span-2 text-emerald-600 flex items-center justify-center gap-1"
                    role="cell"
                  >
                    <CheckCircle2 className="w-4 h-4" aria-hidden="true" />
                    {item.after}
                  </div>
                  <div 
                    className="col-span-3 text-[#CBA331] font-bold"
                    role="cell"
                  >
                    {item.difference}
                  </div>
                  <div 
                    className="col-span-2"
                    role="cell"
                  >
                    {formatPercentChange(item.percentChange)}
                  </div>
                </div>

                {/* Rozwijany opis */}
                {showDetails === index && (
                  <div className="col-span-12 p-6 bg-gray-50 border-b">
                    <p className="text-gray-700">{item.description}</p>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>

          {/* Sekcja CTA - bardziej profesjonalna */}
          <div className="mt-12 bg-gradient-to-r from-[#003A30] to-[#00584A] rounded-lg p-6 text-white shadow-md">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="text-left mb-6 md:mb-0 md:pr-6 md:w-2/3">
                <h3 className="text-lg md:text-xl font-semibold mb-2">
                  {isRefinancing 
                    ? 'Analiza możliwości refinansowania kredytu' 
                    : 'Optymalizacja struktury finansowania'
                  }
                </h3>
                <p className="text-sm text-gray-100 max-w-xl">
                  {isRefinancing
                    ? 'Przeprowadzimy bezpłatną analizę Twojej obecnej umowy kredytowej i przedstawimy konkretne możliwości optymalizacji.'
                    : 'Oferujemy profesjonalną analizę finansową i konkretne rekomendacje dopasowane do specyfiki Twojej firmy.'
                  }
                </p>
              </div>
              <div>
                <button className="px-5 py-2 bg-white text-[#003A30] font-medium rounded hover:bg-gray-100 transition-colors flex items-center gap-2">
                  <Calendar className="w-4 h-4" />
                  <span>Umów konsultację</span>
                </button>
              </div>
            </div>
          </div>

          {/* Dodatkowa informacja */}
          <div className="mt-6 text-center text-sm text-gray-500">
            {isRefinancing 
              ? '* Przykładowe wartości dla kredytu 500 000 zł. Ostateczne warunki zależą od indywidualnej sytuacji kredytowej.'
              : '* Przykładowe wartości dla firmy średniej wielkości. Dokładne oszczędności zależą od specyfiki Twojego biznesu.'
            }
          </div>
        </div>
      </div>
    </section>
  );
}
