import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { MenuItem } from './navData';

interface NavDesktopSubmenuProps {
  title: string;
  items: MenuItem[];
  sectionId: string;
  onItemClick: (path: string) => void;
}

export default function NavDesktopSubmenu({
  title,
  items,
  sectionId,
  onItemClick
}: NavDesktopSubmenuProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleItemClick = (path: string) => {
    onItemClick(path);
    setIsOpen(false);
  };

  const handleTitleClick = () => {
    onItemClick(`#${sectionId}`);
    setIsOpen(false);
  };

  return (
    <div 
      className="relative"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <button
        onClick={handleTitleClick}
        className="flex items-center gap-2 text-base font-medium text-gray-700 hover:text-[#003A30] transition-colors duration-300 py-2"
        aria-expanded={isOpen}
      >
        {title}
        <ChevronDown className={`w-4 h-4 transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      
      <div
        className={`
          absolute left-0 mt-1 w-64 bg-white rounded-lg shadow-xl py-2 
          transform transition-all duration-300 
          ${isOpen ? 'opacity-100 translate-y-0 visible' : 'opacity-0 -translate-y-2 invisible'}
        `}
      >
        {items.map((item) => (
          <button
            key={item.href}
            onClick={() => handleItemClick(item.href)}
            className="flex items-center gap-3 px-4 py-3 w-full text-left text-gray-700 hover:text-[#003A30] hover:bg-gray-50 transition-colors duration-300 group"
          >
            <item.Icon className="w-5 h-5 text-[#CBA331] group-hover:scale-110 transition-transform duration-300" />
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );
}