export * from './refinancing/mortgage-refinance';
export * from './refinancing/mortgage-plus';
export * from './refinancing/consolidation';

import { MORTGAGE_REFINANCE_CARD, MORTGAGE_REFINANCE_DETAILS } from './refinancing/mortgage-refinance';
import { MORTGAGE_PLUS_CARD, MORTGAGE_PLUS_DETAILS } from './refinancing/mortgage-plus';
import { CONSOLIDATION_CARD, CONSOLIDATION_DETAILS } from './refinancing/consolidation';

export const REFINANCING_CARDS = [
  MORTGAGE_REFINANCE_CARD,
  MORTGAGE_PLUS_CARD,
  CONSOLIDATION_CARD
];

export const REFINANCING_DETAILS = {
  "mortgage-refinance": MORTGAGE_REFINANCE_DETAILS,
  "mortgage-plus": MORTGAGE_PLUS_DETAILS,
  "consolidation": CONSOLIDATION_DETAILS
};