import { Shield } from 'lucide-react';
import type { Card, CardDetails } from '../types';

export const STRUCTURE_OPTIMIZATION_CARD: Card = {
  id: "structure-optimization",
  icon: Shield,
  title: "Optymalizacja zabezpieczeń kredytowych",
  description: "Uwolnij zamrożony potencjał majątku firmy poprzez efektywną restrukturyzację zabezpieczeń",
  benefits: [
    "Uwolnienie nadmiarowych zabezpieczeń do celów biznesowych",
    "Konsolidacja zabezpieczeń kilku kredytów pod jedną hipoteką",
    "Redukcja kosztów związanych z ustanawianiem i utrzymaniem zabezpieczeń"
  ],
  stats: {
    value: "35%",
    label: "Średnie uwolnienie wartości nadmiarowych zabezpieczeń"
  },
  cta: {
    label: "Sprawdź możliwości optymalizacji zabezpieczeń",
    action: () => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })
  }
};

export const STRUCTURE_OPTIMIZATION_DETAILS: CardDetails = {
  title: "Optymalizacja zabezpieczeń kredytowych",
  tagline: "Uwolnij zamrożony potencjał swojego majątku firmowego",
  description: "Szczegółowa analiza i restrukturyzacja posiadanych zabezpieczeń kredytowych pozwala na efektywniejsze wykorzystanie majątku firmy. Pomagamy uwolnić nadmiarowe zabezpieczenia, zredukować koszty i zwiększyć elastyczność finansową przedsiębiorstwa poprzez profesjonalne doradztwo i negocjacje z instytucjami finansowymi.",
  cta: {
    phone: "539 211 881",
    text: "Zadzwoń i dowiedz się, jak możemy pomóc w optymalizacji zabezpieczeń kredytowych Twojej firmy"
  },
  benefitHighlights: [
    "Uwolnienie zamrożonych aktywów firmy do innych celów biznesowych",
    "Redukcja kosztów związanych z ustanawianiem i utrzymaniem zabezpieczeń",
    "Zwiększenie potencjału pozyskania nowego finansowania",
    "Uproszczenie struktury zabezpieczeń i zarządzania nimi"
  ],
  whyUs: [
    "Posiadamy specjalistyczną wiedzę w zakresie optymalizacji zabezpieczeń kredytowych dla firm",
    "Nasz zespół ma doświadczenie bankowe pozwalające na efektywne negocjacje",
    "Prowadzimy pełną koordynację procesu zmiany zabezpieczeń, minimalizując obciążenia dla klienta",
    "Znajomość aktualnych wymogów i praktyk bankowych w zakresie zabezpieczeń (2025)"
  ],
  processComparison: {
    withUs: [
      {
        step: "Audyt zabezpieczeń",
        description: "Kompleksowa analiza wszystkich zabezpieczeń i ich wartości",
        icon: "Search",
        highlight: true
      },
      {
        step: "Strategia optymalizacji",
        description: "Opracowanie planu restrukturyzacji uwzględniającego specyfikę firmy",
        icon: "Layers"
      },
      {
        step: "Profesjonalne negocjacje",
        description: "Rozmowy z bankami w celu redukcji i konsolidacji zabezpieczeń",
        icon: "MessageSquare"
      },
      {
        step: "Efektywne wdrożenie",
        description: "Koordynacja procesu zmian z minimalizacją obciążeń dla klienta",
        icon: "CheckCircle",
        highlight: true
      }
    ],
    withoutUs: [
      {
        step: "Brak pełnego obrazu",
        description: "Niekompletna wiedza o strukturze i wartości zabezpieczeń",
        icon: "Eye",
        warning: true
      },
      {
        step: "Nieefektywne planowanie",
        description: "Brak strategicznego podejścia do restrukturyzacji zabezpieczeń",
        icon: "FileQuestion",
        warning: true
      },
      {
        step: "Słaba pozycja negocjacyjna",
        description: "Brak argumentów i know-how w rozmowach z bankami",
        icon: "AlertTriangle",
        warning: true
      },
      {
        step: "Obciążenie operacyjne",
        description: "Czasochłonny proces wymagający specjalistycznej wiedzy",
        icon: "Clock",
        warning: true
      }
    ]
  },
  calculations: {
    example: "Optymalizacja zabezpieczeń dla firmy produkcyjnej z sektora MŚP (2025)",
    before: "Sytuacja przed optymalizacją:\n• Trzy kredyty inwestycyjne o łącznej wartości 3,5 mln zł\n• Zabezpieczenia: hipoteka na nieruchomości produkcyjnej (7 mln zł), zastaw rejestrowy na maszynach (2,5 mln zł), hipoteka na nieruchomości prywatnej właściciela (2 mln zł)\n• Łączna wartość zabezpieczeń: 11,5 mln zł\n• Wskaźnik LTV (kredyt do wartości zabezpieczeń): 30,4%\n• Blokada potencjału rozwojowego przez nadmierne zabezpieczenia\n• Koszty utrzymania i monitoringu wielu form zabezpieczeń",
    after: "Po optymalizacji zabezpieczeń:\n• Konsolidacja trzech kredytów pod jednym finansowaniem\n• Zoptymalizowane zabezpieczenia: hipoteka na nieruchomości produkcyjnej (5 mln zł), zastaw rejestrowy na kluczowych maszynach (1 mln zł)\n• Łączna wartość zabezpieczeń: 6 mln zł\n• Nowy wskaźnik LTV: 58,3% (nadal bezpieczny dla banku)\n• Uwolnienie hipoteki na nieruchomości prywatnej właściciela\n• Uwolnienie części maszyn spod zastawu rejestrowego",
    savings: "Osiągnięte korzyści: uwolnienie zabezpieczeń o wartości 5,5 mln zł (48% pierwotnej wartości), możliwość wykorzystania uwolnionych aktywów do zabezpieczenia nowego finansowania lub leasingu, redukcja kosztów administracyjnych związanych z obsługą zabezpieczeń o około 8-10 tys. zł rocznie",
    extra: "Dodatkowe korzyści: uwolnienie nieruchomości prywatnej spod hipoteki bankowej, uproszczenie procesu zarządzania kredytami i zabezpieczeniami, poprawa wskaźników bilansowych firmy, zwiększenie potencjału do pozyskania nowego finansowania, możliwość sprzedaży lub modernizacji uwolnionych maszyn bez zgody banku"
  },
  benefits: [
    "Szczegółowa analiza aktualnej struktury zabezpieczeń kredytów firmowych",
    "Projektowanie efektywnej restrukturyzacji zabezpieczeń (np. konsolidacja kilku kredytów pod jedną hipoteką)",
    "Uwolnienie nadmiarowych zabezpieczeń do wykorzystania w innych celach biznesowych",
    "Wsparcie w procesie wyceny i przygotowania dokumentacji zabezpieczeń",
    "Negocjacje z bankami w zakresie zmniejszenia wymaganych poziomów zabezpieczeń",
    "Doradztwo w zakresie alternatywnych form zabezpieczeń potencjalnie korzystniejszych dla przedsiębiorcy",
    "Koordynacja procesu zmiany zabezpieczeń z minimalizacją obciążeń administracyjnych dla klienta"
  ],
  limitedTimeOffer: {
    text: "Bezpłatna wstępna analiza struktury zabezpieczeń i oszacowanie potencjału optymalizacji",
    expiry: "31.05.2025"
  },
  faq: [
    {
      question: "Jakie zabezpieczenia kredytowe najczęściej podlegają optymalizacji?",
      answer: "Najczęściej optymalizowane formy zabezpieczeń to: 1) Hipoteki ustanowione na nieruchomościach o wartości znacznie przewyższającej kwotę kredytu, 2) Zastawy rejestrowe na maszynach i urządzeniach, które blokują możliwość ich modernizacji lub sprzedaży, 3) Zabezpieczenia na majątku prywatnym właścicieli firm (nieruchomości, aktywa osobiste), 4) Przewłaszczenia na zabezpieczenie, które ograniczają swobodę dysponowania majątkiem, 5) Gwarancje i poręczenia osób trzecich generujące dodatkowe koszty. W praktyce, zgodnie z naszymi doświadczeniami z 2025 roku, około 70% firm z sektora MŚP posiada zabezpieczenia przekraczające wymagania banków o co najmniej 30% wartości kredytu, co stwarza duży potencjał optymalizacyjny."
    },
    {
      question: "Czy banki wyrażają zgodę na zmniejszenie zabezpieczeń kredytowych?",
      answer: "Tak, w określonych okolicznościach banki wyrażają zgodę na redukcję lub zmianę zabezpieczeń. Kluczowe czynniki zwiększające szanse na pozytywną decyzję to: 1) Dobra historia kredytowa i terminowa obsługa zobowiązań, 2) Znacząca nadwyżka wartości zabezpieczeń nad kwotą kredytu (wysoki wskaźnik pokrycia), 3) Zaawansowany etap spłaty kredytu (np. spłata ponad 50% kapitału), 4) Poprawa sytuacji finansowej firmy od momentu udzielenia kredytu, 5) Przedstawienie profesjonalnie przygotowanego wniosku z uzasadnieniem biznesowym. Według danych z marca 2025, banki zatwierdzają około 65-70% profesjonalnie przygotowanych wniosków o optymalizację zabezpieczeń, szczególnie gdy proponujemy rozwiązania uwzględniające również interes banku."
    },
    {
      question: "Ile trwa proces optymalizacji zabezpieczeń kredytowych?",
      answer: "Całkowity czas procesu optymalizacji zabezpieczeń wynosi zazwyczaj od 2 do 4 miesięcy i składa się z następujących etapów: 1) Analiza wstępna i audyt zabezpieczeń: 1-2 tygodnie, 2) Opracowanie strategii optymalizacji: 1-2 tygodnie, 3) Negocjacje z bankami: 3-6 tygodni, 4) Przygotowanie i podpisanie aneksów do umów kredytowych: 2-3 tygodnie, 5) Formalne zwolnienie zabezpieczeń (wykreślenie hipotek, zastawów itp.): 2-4 tygodnie. Harmonogram może ulec skróceniu w przypadku prostszych struktur zabezpieczeń lub wydłużeniu przy bardziej złożonych przypadkach, szczególnie gdy zaangażowanych jest kilka instytucji finansowych."
    },
    {
      question: "Jakie dokumenty są potrzebne do analizy możliwości optymalizacji zabezpieczeń?",
      answer: "Do przeprowadzenia kompleksowej analizy potrzebujemy: 1) Kopii umów kredytowych wraz z aneksami, 2) Dokumentacji ustanowionych zabezpieczeń (np. odpisy z ksiąg wieczystych, umowy zastawu), 3) Aktualnych operatów szacunkowych nieruchomości lub wycen innych aktywów stanowiących zabezpieczenie (nie starszych niż 2 lata), 4) Informacji o aktualnym saldzie kredytów, 5) Sprawozdań finansowych firmy za ostatnie 2-3 lata, 6) Zaświadczeń z banków o historii spłat kredytów. W przypadku braku niektórych dokumentów, możemy pomóc w ich pozyskaniu lub zaktualizowaniu. Dla nieruchomości możemy również zorganizować nowe operaty szacunkowe, które często wykazują wzrost wartości w porównaniu do wycen sprzed kilku lat, co dodatkowo wspiera proces optymalizacji zabezpieczeń."
    },
    {
      question: "Czy optymalizacja zabezpieczeń wiąże się z dodatkowymi kosztami po stronie klienta?",
      answer: "Proces optymalizacji może wiązać się z pewnymi kosztami, które należy uwzględnić w analizie opłacalności: 1) Opłaty bankowe za aneksy do umów kredytowych (zazwyczaj 0,5-1,0% wartości kredytu, często z możliwością negocjacji), 2) Koszty wykreślenia hipotek lub zastawów rejestrowych (opłaty sądowe: 100-300 zł za każde zabezpieczenie), 3) Koszty nowych wycen nieruchomości lub innych aktywów (1 500-3 000 zł za operat), 4) Ewentualne koszty notarialne przy zmianie zabezpieczeń hipotecznych. Jednak w większości przypadków, całkowite koszty procesu stanowią niewielki ułamek wartości uwalnianych zabezpieczeń i zwracają się w krótkim czasie dzięki nowym możliwościom biznesowym. Nasz zespół zawsze przedstawia szczegółową analizę kosztów i korzyści przed rozpoczęciem procesu."
    },
    {
      question: "Czy istnieją alternatywne formy zabezpieczeń kredytowych korzystniejsze dla przedsiębiorcy?",
      answer: "Tak, w 2025 roku banki akceptują szereg alternatywnych form zabezpieczeń, które mogą być korzystniejsze dla przedsiębiorcy: 1) Gwarancje BGK lub innych funduszy poręczeniowych (obejmujące do 80% wartości kredytu), 2) Ubezpieczenia kredytu (pozwalające na redukcję innych zabezpieczeń), 3) Zabezpieczenia na należnościach firmy (cesja należności od kluczowych kontrahentów), 4) Kaucje środków pieniężnych (szczególnie efektywne przy niskich stopach procentowych na lokatach), 5) Papiery wartościowe jako zabezpieczenie (obligacje skarbowe, akcje spółek giełdowych), 6) Cesje z polis ubezpieczeniowych majątku. Zgodnie z Rekomendacją T Komisji Nadzoru Finansowego (z uwzględnieniem aktualizacji z 2024 roku), banki powinny dywersyfikować formy zabezpieczeń, co stwarza przestrzeń do negocjacji bardziej elastycznych rozwiązań."
    },
    {
      question: "Jak rozliczane jest wynagrodzenie za usługę optymalizacji zabezpieczeń?",
      answer: "Nasze wynagrodzenie jest uzależnione od wielkości i złożoności struktury zabezpieczeń oraz zakresu niezbędnych działań. Przed rozpoczęciem współpracy przeprowadzamy bezpłatną wstępną analizę potencjału optymalizacji, na podstawie której możemy oszacować wartość możliwych do uwolnienia zabezpieczeń i przedstawić konkretną ofertę wynagrodzenia. Nasz model cenowy jest przejrzysty i uwzględnia specyfikę danego przypadku. Co istotne, klient płaci tylko za faktycznie osiągnięte rezultaty, co minimalizuje ryzyko finansowe związane z projektem. Wszystkie warunki współpracy, w tym szczegółowy zakres usług i model rozliczeń, są zawsze precyzyjnie określane w umowie."
    },
    {
      question: "Czy optymalizacja zabezpieczeń może negatywnie wpłynąć na relacje z bankiem?",
      answer: "Przy profesjonalnym podejściu, optymalizacja zabezpieczeń nie wpływa negatywnie na relacje z bankiem, a wręcz może je wzmocnić. Kluczowe jest właściwe przedstawienie wniosku jako działania biznesowo uzasadnionego, a nie próby osłabienia pozycji banku. W naszym podejściu zawsze uwzględniamy również korzyści dla banku, takie jak: 1) Uproszczenie administracji i monitoringu zabezpieczeń, 2) Potencjał do rozwoju współpracy w nowych obszarach dzięki uwolnionym aktywom, 3) Zwiększenie płynności i potencjału rozwojowego klienta, co przekłada się na bezpieczeństwo spłaty kredytu. Zgodnie z naszym doświadczeniem z marca 2025, banki coraz częściej postrzegają racjonalizację zabezpieczeń jako element normalnego cyklu życia kredytu, szczególnie w przypadku długoterminowej i bezproblemowej współpracy."
    }
  ],
  nextSteps: [
    "Skontaktuj się z nami telefonicznie lub przez formularz",
    "Umów się na bezpłatną wstępną konsultację",
    "Przygotuj podstawową dokumentację dotyczącą zabezpieczeń",
    "Otrzymaj wstępną analizę potencjału optymalizacji",
    "Zapoznaj się z rekomendowaną strategią działania",
    "Zdecyduj o rozpoczęciu procesu optymalizacji",
    "Korzystaj z uwolnionego potencjału biznesowego"
  ]
};