import { AlertCircle } from 'lucide-react';
import type { ProblemsSolutionsData } from './types';

export const optimizationProblemsData: ProblemsSolutionsData = {
  title: "Radykalne obniżenie rat i kosztów kredytowych",
  subtitle: "Odkryj, jak znacząco redukujemy finansowe obciążenia Twojej firmy, uwalniając środki na rozwój",
  problems: [
    {
      icon: AlertCircle,
      problem: "Nadmierne koszty obsługi zadłużenia",
      solution: "Strategiczna redukcja obciążeń finansowych",
      details: [
        "Wynegocjowanie preferencyjnych warunków niedostępnych dla standardowych klientów banków",
        "Eliminacja ukrytych opłat i prowizji obniżających rzeczywistą rentowność biznesu",
        "Zastąpienie kosztownych produktów kredytowych nowoczesnymi instrumentami finansowymi"
      ]
    },
    {
      icon: AlertCircle,
      problem: "Rozdrobniona struktura finansowania",
      solution: "Kompleksowa konsolidacja i uporządkowanie",
      details: [
        "Połączenie rozproszonych zobowiązań w jeden produkt o znacząco niższym całkowitym koszcie",
        "Uproszczenie administracji finansowej firmy i redukcja kosztów operacyjnych",
        "Uwolnienie potencjału kredytowego zamrożonego w nieefektywnej strukturze zadłużenia"
      ]
    },
    {
      icon: AlertCircle,
      problem: "Zagrożenia dla płynności finansowej",
      solution: "Zaawansowana inżynieria przepływów pieniężnych",
      details: [
        "Synchronizacja harmonogramów spłat z cyklami przychodowymi charakterystycznymi dla branży",
        "Wdrożenie elastycznych mechanizmów spłat dostosowanych do sezonowości biznesu",
        "Stworzenie bufora płynnościowego zabezpieczającego stabilność operacyjną przedsiębiorstwa"
      ]
    },
    {
      icon: AlertCircle,
      problem: "Nieefektywna struktura finansowania majątku",
      solution: "Strategiczna restrukturyzacja bilansu",
      details: [
        "Ekspercka analiza struktury aktywów i pasywów z identyfikacją obszarów optymalizacji",
        "Dopasowanie instrumentów finansowych do charakteru finansowanych aktywów biznesowych",
        "Implementacja długofalowej strategii finansowej wspierającej cele rozwojowe firmy"
      ]
    }
  ]
};