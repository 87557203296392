import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function NavLogo() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogoClick = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate('/');
      // Po przekierowaniu na stronę główną, przewiń na górę
      window.scrollTo({ top: 0 });
    }
  };

  return (
    <button 
      onClick={handleLogoClick}
      className="relative z-50 p-2 -ml-2 focus:outline-none focus:ring-2 focus:ring-[#003A30] focus:ring-offset-2 rounded-lg"
      aria-label="Strona główna"
    >
      <img 
        src="/logo.svg" 
        alt="Ekspert Kredytowy - Logo" 
        className="w-[160px] md:w-[200px] h-auto"
        loading="eager"
      />
    </button>
  );
}