import React from 'react';
import { Calendar, Clock, User, ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import type { BlogPost } from '@/blog/types';

interface ArticleHeaderProps {
  post: BlogPost;
}

export default function ArticleHeader({ post }: ArticleHeaderProps) {
  const navigate = useNavigate();

  // Format date
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('pl-PL', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <section className="relative py-12 md:py-16 lg:py-20 bg-[#003A30] text-white overflow-hidden">
      <div className="absolute inset-0">
        <img
          src={post.image}
          alt={post.title}
          className="w-full h-full object-cover opacity-20"
          loading="lazy"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-[#003A30] via-[#003A30]/90 to-[#003A30]" />
      </div>

      <div className="container mx-auto px-4 relative">
        <div className="max-w-3xl mx-auto">
          <button
            onClick={() => navigate('/blog')}
            className="inline-flex items-center text-white/80 hover:text-white mb-4 sm:mb-6 transition-colors duration-200"
          >
            <ArrowLeft className="w-4 h-4 sm:w-5 sm:h-5 mr-1 sm:mr-2" />
            Wróć do bloga
          </button>

          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4 sm:mb-6">
            {post.title}
          </h1>

          <div className="flex flex-wrap items-center gap-3 sm:gap-6 text-sm sm:text-base text-white/80">
            <div className="flex items-center gap-1 sm:gap-2">
              <Calendar className="w-4 h-4 sm:w-5 sm:h-5" />
              <span>{formatDate(post.date)}</span>
            </div>
            <div className="flex items-center gap-1 sm:gap-2">
              <Clock className="w-4 h-4 sm:w-5 sm:h-5" />
              <span>{post.readingTime} min czytania</span>
            </div>
            <div className="flex items-center gap-1 sm:gap-2">
              {post.author.avatar ? (
                <img
                  src={post.author.avatar}
                  alt={post.author.name}
                  className="w-6 h-6 sm:w-8 sm:h-8 rounded-full object-cover"
                  loading="lazy"
                />
              ) : (
                <User className="w-4 h-4 sm:w-5 sm:h-5" />
              )}
              <span>{post.author.name}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}