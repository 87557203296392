import { useState, useEffect } from 'react';

interface SavedArticle {
  id: string;
  title: string;
  url: string;
  timestamp: number;
}

export function useSaveArticle(articleId: string, articleTitle: string) {
  const [isSaved, setIsSaved] = useState(false);
  
  // Check if article is saved on mount
  useEffect(() => {
    const savedArticles = getSavedArticles();
    setIsSaved(savedArticles.some(article => article.id === articleId));
  }, [articleId]);
  
  // Get saved articles from localStorage
  const getSavedArticles = (): SavedArticle[] => {
    const saved = localStorage.getItem('savedArticles');
    return saved ? JSON.parse(saved) : [];
  };
  
  // Save article to localStorage
  const saveArticle = () => {
    const savedArticles = getSavedArticles();
    
    // Check if article is already saved
    if (savedArticles.some(article => article.id === articleId)) {
      return;
    }
    
    // Add article to saved articles
    const newSavedArticles = [
      ...savedArticles,
      {
        id: articleId,
        title: articleTitle,
        url: window.location.href,
        timestamp: Date.now()
      }
    ];
    
    // Save to localStorage
    localStorage.setItem('savedArticles', JSON.stringify(newSavedArticles));
    setIsSaved(true);
  };
  
  // Remove article from localStorage
  const removeArticle = () => {
    const savedArticles = getSavedArticles();
    
    // Filter out article
    const newSavedArticles = savedArticles.filter(article => article.id !== articleId);
    
    // Save to localStorage
    localStorage.setItem('savedArticles', JSON.stringify(newSavedArticles));
    setIsSaved(false);
  };
  
  // Toggle saved state
  const toggleSave = () => {
    if (isSaved) {
      removeArticle();
    } else {
      saveArticle();
    }
  };
  
  return { isSaved, toggleSave, saveArticle, removeArticle };
}