import { useState, useEffect } from 'react';

export function useReadingProgress() {
  const [progress, setProgress] = useState(0);
  
  useEffect(() => {
    // Throttling implementation
    let ticking = false;
    
    const updateProgress = () => {
      // Calculate how far the user has scrolled
      const currentScrollY = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      
      // Calculate the percentage of the page that has been scrolled
      // We subtract the client height because we don't want to count the viewport height
      const scrollableHeight = scrollHeight - clientHeight;
      
      if (scrollableHeight > 0) {
        const percentage = (currentScrollY / scrollableHeight) * 100;
        setProgress(Math.min(percentage, 100));
      }
      
      ticking = false;
    };
    
    // Add scroll event listener with throttling
    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          updateProgress();
          ticking = false;
        });
        ticking = true;
      }
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    
    // Initial calculation
    updateProgress();
    
    // Clean up
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  return progress;
}