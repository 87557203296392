import React from 'react';
import { Phone, ArrowRight } from 'lucide-react';
import NavMobileSubmenu from './NavMobileSubmenu';
import { MENUS } from './navData';

interface NavMobileMenuProps {
  isOpen: boolean;
  activeSubmenu: string;
  setActiveSubmenu: (submenu: string) => void;
  onNavigate: (path: string) => void;
}

export default function NavMobileMenu({ 
  isOpen, 
  activeSubmenu, 
  setActiveSubmenu, 
  onNavigate 
}: NavMobileMenuProps) {
  return (
    <div
      id="mobile-menu"
      className={`
        fixed inset-x-0 top-[80px] z-40
        max-h-[calc(100vh-80px)] overflow-y-auto
        bg-white/95 backdrop-blur-md
        transform transition-all duration-300 ease-in-out
        ${isOpen ? 'translate-y-0 opacity-100 visible' : '-translate-y-full opacity-0 invisible'}
        md:hidden
        shadow-lg
        pb-safe
      `}
      style={{ 
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        WebkitOverflowScrolling: 'touch'
      }}
      aria-hidden={!isOpen}
      role="dialog"
      aria-modal="true"
      aria-label="Menu mobilne"
    >
      <div className="container mx-auto px-4 py-6">
        <div className="space-y-4">
          <NavMobileSubmenu
            title="Usługi"
            items={MENUS.services}
            isOpen={activeSubmenu === 'services'}
            onToggle={() => setActiveSubmenu(activeSubmenu === 'services' ? '' : 'services')}
            onItemClick={onNavigate}
          />
          <NavMobileSubmenu
            title="Kalkulatory"
            items={MENUS.calculators}
            isOpen={activeSubmenu === 'calculators'}
            onToggle={() => setActiveSubmenu(activeSubmenu === 'calculators' ? '' : 'calculators')}
            onItemClick={onNavigate}
          />
          <NavMobileSubmenu
            title="Strefa wiedzy"
            items={MENUS.knowledge}
            isOpen={activeSubmenu === 'knowledge'}
            onToggle={() => setActiveSubmenu(activeSubmenu === 'knowledge' ? '' : 'knowledge')}
            onItemClick={onNavigate}
          />
          
          <button
            onClick={() => onNavigate('#contact')}
            className="w-full px-4 py-3 text-left text-base font-medium text-gray-700 hover:text-[#003A30] transition-colors duration-300 rounded-lg hover:bg-gray-50"
          >
            Kontakt
          </button>

          <div className="pt-4 space-y-4 border-t border-gray-100">
            <a 
              href="tel:+48539211881"
              className="flex items-center gap-2 px-4 text-[#003A30] hover:text-[#CBA331] text-lg font-medium"
            >
              <Phone className="w-5 h-5" />
              +48 539 211 881
            </a>
            
            <button
              onClick={() => onNavigate('#contact')}
              className="w-full px-6 py-3 bg-[#003A30] text-white rounded-lg hover:bg-[#CBA331] transition-colors duration-300 flex items-center justify-center gap-2"
            >
              Bezpłatna konsultacja
              <ArrowRight className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}