import React from 'react';
import { 
  Quote, 
  Star, 
  Calendar, 
  Building, 
  Check, 
  TrendingUp, 
  Shield, 
  Award, 
  ArrowRight, 
  AlertTriangle, 
  DollarSign, 
  Percent,
  Home,
  Clock,
  BadgePercent
} from 'lucide-react';
import type { CaseStudy } from '@/data/caseStudies/types';
import { useNavigate } from 'react-router-dom';

interface CaseStudyModalContentProps {
  caseStudy: CaseStudy;
}

export default function CaseStudyModalContent({ caseStudy }: CaseStudyModalContentProps) {
  const navigate = useNavigate();

  // Format currency
  const formatCurrency = (value: string) => {
    const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
    if (isNaN(numericValue)) return value;
    
    return new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(numericValue);
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
      {/* Left Column - Basic Info */}
      <div className="lg:col-span-1">
        <div className="bg-white rounded-2xl overflow-hidden">
          {/* Hero Image */}
          <div className="relative h-48 lg:h-64">
            <img 
              src={caseStudy.image} 
              alt={caseStudy.client}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
            <div className="absolute bottom-4 left-4 text-white">
              <h1 className="font-bold text-xl">{caseStudy.client}</h1>
              {caseStudy.clientOccupation && caseStudy.clientLocation && (
                <p className="text-sm text-gray-200">{caseStudy.clientOccupation} • {caseStudy.clientLocation}</p>
              )}
              <div className="flex mt-2">
                {Array(5).fill(0).map((_, i) => (
                  <Star 
                    key={i} 
                    className={`w-4 h-4 ${i < (caseStudy.rating || 5) ? 'text-[#CBA331] fill-[#CBA331]' : 'text-gray-300'}`} 
                  />
                ))}
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="p-6">
            {/* Direct Quote */}
            {caseStudy.directQuote && (
              <div className="mb-6 bg-gray-50 p-4 rounded-xl border-l-4 border-[#CBA331]">
                <Quote className="w-5 h-5 text-[#CBA331] mb-2" />
                <p className="text-gray-800 italic">"{caseStudy.directQuote}"</p>
              </div>
            )}

            {/* Basic Info */}
            <div className="space-y-4">
              <div>
                <h2 className="text-xl font-bold text-[#003A30] mb-2">{caseStudy.title}</h2>
                <p className="text-gray-600">{caseStudy.description}</p>
              </div>

              {/* Tags */}
              <div className="flex flex-wrap gap-2">
                {caseStudy.creditType && (
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800">
                    <Home className="w-4 h-4 mr-1" />
                    {caseStudy.creditType}
                  </span>
                )}
                {caseStudy.timelineInfo && (
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-purple-100 text-purple-800">
                    <Clock className="w-4 h-4 mr-1" />
                    {caseStudy.timelineInfo}
                  </span>
                )}
              </div>

              {/* Financial Benefits */}
              {(caseStudy.financialBenefit || caseStudy.savingsAmount || caseStudy.savingsPercentage) && (
                <div className="bg-emerald-50 p-4 rounded-xl space-y-2">
                  {caseStudy.financialBenefit && (
                    <div className="flex items-center gap-2 text-emerald-700">
                      <BadgePercent className="w-4 h-4" />
                      <span className="font-medium">{caseStudy.financialBenefit}</span>
                    </div>
                  )}
                  {caseStudy.savingsAmount && (
                    <div className="flex items-center gap-2 text-emerald-700">
                      <DollarSign className="w-4 h-4" />
                      <span className="font-medium">{caseStudy.savingsAmount}</span>
                    </div>
                  )}
                  {caseStudy.savingsPercentage && (
                    <div className="flex items-center gap-2 text-emerald-700">
                      <Percent className="w-4 h-4" />
                      <span className="font-medium">Oszczędność {caseStudy.savingsPercentage}%</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Right Column - Detailed Info */}
      <div className="lg:col-span-2 space-y-6">
        {/* Challenges and Solutions */}
        <div className="grid md:grid-cols-2 gap-6">
          {/* Challenges */}
          {caseStudy.challenges && caseStudy.challenges.length > 0 && (
            <div className="bg-white rounded-xl shadow-lg p-6">
              <h3 className="text-lg font-bold text-[#003A30] mb-4 flex items-center gap-2">
                <AlertTriangle className="w-5 h-5 text-red-500" />
                Wyzwania
              </h3>
              <ul className="space-y-3">
                {caseStudy.challenges.map((challenge, index) => (
                  <li key={index} className="flex items-start gap-2 text-gray-600">
                    <div className="w-1.5 h-1.5 rounded-full bg-red-500 mt-2 flex-shrink-0" />
                    <span>{challenge}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Solutions */}
          {caseStudy.solutions && caseStudy.solutions.length > 0 && (
            <div className="bg-white rounded-xl shadow-lg p-6">
              <h3 className="text-lg font-bold text-[#003A30] mb-4 flex items-center gap-2">
                <Check className="w-5 h-5 text-emerald-500" />
                Rozwiązania
              </h3>
              <ul className="space-y-3">
                {caseStudy.solutions.map((solution, index) => (
                  <li key={index} className="flex items-start gap-2 text-gray-600">
                    <Check className="w-5 h-5 text-emerald-500 flex-shrink-0" />
                    <span>{solution}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {/* What If Scenario */}
        {caseStudy.whatIfScenario && (
          <div className="bg-white rounded-xl shadow-lg p-6">
            <h3 className="text-lg font-bold text-[#003A30] mb-4 flex items-center gap-2">
              <AlertTriangle className="w-5 h-5 text-amber-500" />
              Co by było, gdyby...
            </h3>
            <p className="text-gray-600">{caseStudy.whatIfScenario}</p>
          </div>
        )}

        {/* Before/After Comparison */}
        {caseStudy.beforeAfter && (
          <div className="bg-white rounded-xl shadow-lg p-6">
            <h3 className="text-lg font-bold text-[#003A30] mb-4">Porównanie sytuacji</h3>
            <div className="grid md:grid-cols-2 gap-4">
              <div className="bg-red-50 rounded-lg p-4 border border-red-100">
                <h4 className="font-medium text-red-700 mb-2">Przed współpracą:</h4>
                <p className="text-gray-600">{caseStudy.beforeAfter.before}</p>
              </div>
              <div className="bg-emerald-50 rounded-lg p-4 border border-emerald-100">
                <h4 className="font-medium text-emerald-700 mb-2">Po współpracy:</h4>
                <p className="text-gray-600">{caseStudy.beforeAfter.after}</p>
              </div>
            </div>
          </div>
        )}

        {/* Reasoning */}
        {caseStudy.reasoning && (
          <div className="bg-white rounded-xl shadow-lg p-6">
            <h3 className="text-lg font-bold text-[#003A30] mb-4 flex items-center gap-2">
              <Award className="w-5 h-5 text-[#CBA331]" />
              Dlaczego wybrali nasze usługi
            </h3>
            <p className="text-gray-600">{caseStudy.reasoning}</p>
          </div>
        )}

        {/* Full Story */}
        <div className="bg-white rounded-xl shadow-lg p-6">
          <h3 className="text-lg font-bold text-[#003A30] mb-4">Pełna historia</h3>
          <div className="space-y-4">
            <div>
              <h4 className="font-medium text-[#003A30] mb-2">Sytuacja wyjściowa</h4>
              <p className="text-gray-600">{caseStudy.fullStory.situation}</p>
            </div>
            <div>
              <h4 className="font-medium text-[#003A30] mb-2">Wyzwanie</h4>
              <p className="text-gray-600">{caseStudy.fullStory.challenge}</p>
            </div>
            <div>
              <h4 className="font-medium text-[#003A30] mb-2">Rozwiązanie</h4>
              <p className="text-gray-600">{caseStudy.fullStory.solution}</p>
            </div>
          </div>
        </div>

        {/* CTA */}
        <div className="bg-[#003A30] rounded-xl shadow-lg p-6 text-center">
          <h3 className="text-xl font-bold text-white mb-4">
            Chcesz osiągnąć podobne rezultaty?
          </h3>
          <button
            onClick={() => navigate('#contact')}
            className="inline-flex items-center justify-center px-6 py-3 bg-[#CBA331] text-[#003A30] rounded-lg hover:bg-[#d4b04c] transition-colors duration-300 font-bold"
          >
            Umów bezpłatną konsultację
            <ArrowRight className="w-5 h-5 ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
}