import React, { useState } from 'react';
import { Menu, X, ChevronUp } from 'lucide-react';

interface MobileArticleViewProps {
  children: React.ReactNode;
  tableOfContents: React.ReactNode;
  articleActions: React.ReactNode;
}

export default function MobileArticleView({ 
  children, 
  tableOfContents, 
  articleActions 
}: MobileArticleViewProps) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);
  
  // Show back to top button when scrolled down
  React.useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 300);
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="relative">
      {/* Main Content */}
      <div className="prose prose-sm sm:prose md:prose-lg mx-auto px-4 md:px-0">
        {children}
      </div>
      
      {/* Mobile Navigation Button */}
      <button
        onClick={() => setSidebarOpen(true)}
        className="md:hidden fixed bottom-4 right-4 p-3 rounded-full bg-[#003A30] text-white shadow-lg flex items-center justify-center z-40 touch-manipulation"
        aria-label="Pokaż nawigację"
      >
        <Menu className="w-5 h-5" />
      </button>
      
      {/* Back to Top Button - only shown when scrolled down */}
      {showBackToTop && (
        <button
          onClick={scrollToTop}
          className="md:hidden fixed bottom-4 left-4 p-3 rounded-full bg-white text-[#003A30] shadow-lg flex items-center justify-center z-40 touch-manipulation"
          aria-label="Wróć do góry"
        >
          <ChevronUp className="w-5 h-5" />
        </button>
      )}
      
      {/* Mobile Navigation Sidebar */}
      <div
        className={`fixed inset-0 z-50 transform ${
          sidebarOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300 ease-in-out md:hidden`}
      >
        <div 
          className="absolute inset-0 bg-black/50 backdrop-blur-sm" 
          onClick={() => setSidebarOpen(false)}
        />
        
        <div className="absolute right-0 top-0 bottom-0 w-64 sm:w-80 bg-white shadow-xl flex flex-col">
          <div className="flex items-center justify-between p-4 border-b">
            <h2 className="font-semibold text-[#003A30]">Nawigacja</h2>
            <button
              onClick={() => setSidebarOpen(false)}
              className="p-1 rounded-full hover:bg-gray-100 touch-manipulation"
              aria-label="Zamknij nawigację"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
          
          <div className="flex-1 overflow-auto p-4">
            {tableOfContents}
          </div>
          
          <div className="p-4 border-t">
            {articleActions}
          </div>
        </div>
      </div>
    </div>
  );
}