import React, { useState, useMemo, useEffect } from 'react';
import { Search, Filter, Tag, Calendar, Clock, User, ChevronLeft, ChevronRight } from 'lucide-react';
import type { BlogCategory, BlogPostPreview } from './types';
import { BLOG_POSTS } from './data/posts';
import { BLOG_CATEGORIES } from './data/categories';
import ArticleCard from './components/cards/ArticleCard';
import Pagination from './components/ui/Pagination';
import { formatDate } from './utils/date-formatter';
import { BlogErrorBoundary } from './components/ErrorBoundary';
import FeaturedArticle from './components/FeaturedArticle';
import { POST_REFINANSOWANIE_2025 } from './data/posts/refinansowanie-2025';

// Ilość postów na stronę
const POSTS_PER_PAGE = 9;

export default function BlogIndex() {
  // Stany aplikacji
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<BlogCategory | ''>('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState<'newest' | 'oldest' | 'popular'>('newest');
  
  // Get all unique tags from blog posts
  const allTags = useMemo(() => {
    const tags = new Set<string>();
    BLOG_POSTS.forEach(post => post.tags?.forEach(tag => tags.add(tag)));
    return Array.from(tags).sort();
  }, []);

  // Filter posts based on search, category, and tags
  const filteredPosts = useMemo(() => {
    return BLOG_POSTS.filter(post => {
      const matchesSearch = searchTerm === '' || 
        post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.excerpt.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesCategory = selectedCategory === '' || post.category === selectedCategory;

      const matchesTags = selectedTags.length === 0 || 
        selectedTags.every(tag => post.tags.includes(tag));

      return matchesSearch && matchesCategory && matchesTags;
    });
  }, [searchTerm, selectedCategory, selectedTags]);

  // Posortowane i paginowane posty
  const paginatedPosts = useMemo(() => {
    // Najpierw sortujemy
    let sortedPosts = [...filteredPosts];
    if (sortOrder === 'newest') {
      sortedPosts.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    } else if (sortOrder === 'oldest') {
      sortedPosts.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    } else if (sortOrder === 'popular') {
      // Zakładamy, że popularność określamy na podstawie readingTime (to tylko przykład)
      sortedPosts.sort((a, b) => b.readingTime - a.readingTime);
    }
    
    // Następnie paginujemy
    const startIndex = (currentPage - 1) * POSTS_PER_PAGE;
    const endIndex = startIndex + POSTS_PER_PAGE;
    return sortedPosts.slice(startIndex, endIndex);
  }, [filteredPosts, currentPage, sortOrder]);

  // Calculate total pages
  const totalPages = Math.ceil(filteredPosts.length / POSTS_PER_PAGE);

  // Reset to page 1 when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, selectedCategory, selectedTags]);

  // Toggle tag selection
  const toggleTag = (tag: string) => {
    setSelectedTags(prev => 
      prev.includes(tag) 
        ? prev.filter(t => t !== tag)
        : [...prev, tag]
    );
  };

  // Clear all filters
  const clearFilters = () => {
    setSearchTerm('');
    setSelectedCategory('');
    setSelectedTags([]);
    setCurrentPage(1);
    setSortOrder('newest');
  };

  return (
    <BlogErrorBoundary>
      <div className="pt-20 bg-gray-50 text-gray-900">
        {/* Hero Section z eleganckimi budynkiem biurowym jako tło */}
        <section className="relative py-10 sm:py-12 md:py-16 lg:py-20 text-white overflow-hidden">
          {/* Tło z obrazem budynku biurowego */}
          <div className="absolute inset-0 z-0">
            <img 
              src="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
              alt="Elegancki budynek biurowy" 
              className="w-full h-full object-cover"
              loading="eager"
            />
            {/* Nakładka przyciemniająca dla lepszego kontrastu tekstu */}
            <div className="absolute inset-0 bg-gradient-to-b from-[#003A30]/70 via-[#004F42]/60 to-[#00604F]/10"></div>
          </div>

          {/* Efekty świetlne */}
          <div className="absolute inset-0 pointer-events-none z-10" aria-hidden="true">
            <div className="absolute top-0 left-1/4 w-64 h-64 bg-[#CBA331]/10 rounded-full blur-3xl opacity-50" />
            <div className="absolute bottom-0 right-1/4 w-64 h-64 bg-[#CBA331]/10 rounded-full blur-3xl opacity-50" />
          </div>

          <div className="container mx-auto px-4 relative z-20">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 sm:mb-4">
                Blog Eksperta Kredytowego
              </h1>
              <p className="text-base sm:text-lg md:text-xl text-gray-100 mb-6 sm:mb-8">
                Najnowsze artykuły, porady i aktualności ze świata kredytów i finansów
              </p>

              {/* Search Bar */}
              <div className="relative max-w-2xl mx-auto">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search className="h-4 w-4 sm:h-5 sm:w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  placeholder="Szukaj artykułów..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-8 sm:pl-10 pr-4 py-2 sm:py-3 bg-white/10 border border-white/20 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#CBA331] focus:border-transparent backdrop-blur-sm text-sm sm:text-base"
                  aria-label="Szukaj artykułów"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Filters Section - kolor tła dopasowany do kolorystyki stopki */}
        <section className="py-6 sm:py-8 bg-[#003A30] border-b border-[#002920] text-white">
          <div className="container mx-auto px-4">
            <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center justify-between">
              <div className="flex flex-col xs:flex-row w-full sm:w-auto flex-wrap gap-3 items-start xs:items-center">
                {/* Category Filter - pełna szerokość na bardzo małych ekranach */}
                <div className="w-full xs:w-auto">
                  <label htmlFor="category-filter" className="sr-only">Filtruj po kategorii</label>
                  <select
                    id="category-filter"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value as BlogCategory)}
                    className="w-full px-3 sm:px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#CBA331] focus:border-transparent bg-[#00483D] border border-[#004035] text-white text-sm sm:text-base"
                    aria-label="Filtruj po kategorii"
                  >
                    <option value="">Wszystkie kategorie</option>
                    {Object.values(BLOG_CATEGORIES).map(category => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                
                {/* Sort Order - pełna szerokość na bardzo małych ekranach */}
                <div className="w-full xs:w-auto">
                  <label htmlFor="sort-order" className="sr-only">Sortuj wpisy</label>
                  <select
                    id="sort-order"
                    value={sortOrder}
                    onChange={(e) => setSortOrder(e.target.value as 'newest' | 'oldest' | 'popular')}
                    className="w-full px-3 sm:px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#CBA331] focus:border-transparent bg-[#00483D] border border-[#004035] text-white text-sm sm:text-base"
                    aria-label="Sortuj wpisy"
                  >
                    <option value="newest">Najnowsze</option>
                    <option value="oldest">Najstarsze</option>
                    <option value="popular">Najpopularniejsze</option>
                  </select>
                </div>

                {/* Active Filters Count */}
                {(selectedCategory !== '' || selectedTags.length > 0 || searchTerm !== '') && (
                  <button
                    onClick={clearFilters}
                    className="inline-flex items-center px-3 py-2 rounded-lg text-xs sm:text-sm font-medium bg-[#CBA331]/20 text-[#CBA331] hover:bg-[#CBA331]/30 transition-colors duration-200"
                    aria-label="Wyczyść filtry"
                  >
                    <Filter className="w-3 h-3 sm:w-4 sm:h-4 mr-1" />
                    Wyczyść filtry
                    <span className="ml-1 px-1.5 sm:px-2 py-0.5 bg-[#CBA331] text-white rounded-full text-xs">
                      {(selectedCategory ? 1 : 0) + selectedTags.length + (searchTerm ? 1 : 0)}
                    </span>
                  </button>
                )}
              </div>

              {/* Results Count */}
              <div className="text-xs sm:text-sm text-gray-300">
                Znaleziono {filteredPosts.length} {filteredPosts.length === 1 ? 'artykuł' : 
                  filteredPosts.length < 5 ? 'artykuły' : 'artykułów'}
              </div>
            </div>

            {/* Tags - zaktualizowany wygląd tagów */}
            <div className="mt-3 sm:mt-4">
              <p className="sr-only" id="tag-filter-label">Filtruj po tagach</p>
              <div className="flex flex-wrap gap-1 sm:gap-2" aria-labelledby="tag-filter-label">
                {allTags.map(tag => (
                  <button
                    key={tag}
                    onClick={() => toggleTag(tag)}
                    className={`inline-flex items-center px-2 sm:px-3 py-0.5 sm:py-1 rounded-full text-xs sm:text-sm font-medium transition-colors duration-200 ${
                      selectedTags.includes(tag)
                        ? 'bg-[#CBA331] text-white'
                        : 'bg-[#F1F5F9] text-[#0F172A] hover:bg-[#E2E8F0]'
                    }`}
                    aria-pressed={selectedTags.includes(tag)}
                    aria-label={`Tag: ${tag}`}
                  >
                    <Tag className="w-3 h-3 sm:w-4 sm:h-4 mr-1" />
                    {tag}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Featured Article Section */}
        <section className="py-8 sm:py-12">
          <div className="container mx-auto px-4">
            <FeaturedArticle post={POST_REFINANSOWANIE_2025} />
          </div>
        </section>

        {/* Blog Posts Grid */}
        <section className="py-8 sm:py-12 md:py-16 lg:py-20">
          <div className="container mx-auto px-4">
            {filteredPosts.length > 0 ? (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
                  {paginatedPosts.map(post => (
                    <ArticleCard
                      key={post.id}
                      title={post.title}
                      excerpt={post.excerpt}
                      image={post.image}
                      date={formatDate(post.date)}
                      readingTime={post.readingTime}
                      url={`/blog/${post.slug}`}
                      tags={post.tags.slice(0, 3)}
                    />
                  ))}
                </div>

                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                  />
                )}
              </>
            ) : (
              <div className="text-center py-12">
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  Nie znaleziono artykułów
                </h3>
                <p className="text-gray-500 mb-4">
                  Spróbuj zmienić kryteria wyszukiwania lub wyczyść filtry
                </p>
                <button
                  onClick={clearFilters}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#003A30] hover:bg-[#00483D] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#003A30]"
                  aria-label="Wyczyść wszystkie filtry"
                >
                  Wyczyść filtry
                </button>
              </div>
            )}
          </div>
        </section>
      </div>
    </BlogErrorBoundary>
  );
}