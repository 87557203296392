import React from 'react';
import { BarChart, BadgePercent, Clock, Sparkles, ArrowRight, Zap, Info, Calculator } from 'lucide-react';
import { CalculationResults } from '../types';
import { WIBOR, MARGIN, BUFFER_RATE, FIXED_RATE } from '../utils/constants';

interface ResultsPanelProps {
  results: CalculationResults | null;
  isCalculated: boolean;
}

export default function ResultsPanel({ results, isCalculated }: ResultsPanelProps) {
  if (!isCalculated) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-8 h-full flex items-center justify-center">
        <div className="flex items-center">
          <p className="text-gray-500 mr-2">Obliczanie wyników</p>
          <div className="w-2 h-2 bg-blue-500 rounded-full animate-pulse"></div>
        </div>
      </div>
    );
  }

  if (!results) {
    return (
      <div className="flex flex-col items-center justify-center py-12 text-center">
        <div className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mb-4">
          <Info className="w-8 h-8 text-red-500" />
        </div>
        <h3 className="text-lg font-medium text-red-700 mb-2">Wystąpił błąd</h3>
        <p className="text-sm text-gray-500 max-w-md">
          Nie udało się obliczyć zdolności kredytowej. Sprawdź wprowadzone dane i spróbuj ponownie.
        </p>
      </div>
    );
  }

  // Format currency
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  // Format percentage
  const formatPercentage = (value: number) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  // Calculate total variable rate
  const totalVariableRate = (WIBOR + MARGIN + BUFFER_RATE) / 100;
  
  // Calculate total fixed rate
  const totalFixedRate = (FIXED_RATE + BUFFER_RATE) / 100;

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden relative">
      {/* Decorative background elements */}
      <div className="absolute inset-0 bg-gradient-to-br from-[#003A30]/[0.02] via-transparent to-[#CBA331]/[0.02] pointer-events-none" />
      <div className="absolute -top-24 -right-24 w-48 h-48 bg-[#003A30]/5 rounded-full blur-3xl opacity-20" />
      <div className="absolute -bottom-24 -left-24 w-48 h-48 bg-[#CBA331]/5 rounded-full blur-3xl opacity-20" />

      <div className="p-6 md:p-8 relative">
        <div className="flex items-center justify-center gap-3 mb-8">
          <div className="relative">
            <BarChart className="w-8 h-8 text-[#CBA331]" />
            <div className="absolute -top-1 -right-1 w-3 h-3 bg-[#003A30] rounded-full animate-pulse"></div>
          </div>
          <h2 className="text-2xl font-bold text-[#003A30] relative">
            Wyniki kalkulacji
            <div className="absolute -bottom-1 left-0 w-full h-0.5 bg-gradient-to-r from-[#CBA331]/0 via-[#CBA331] to-[#CBA331]/0"></div>
          </h2>
        </div>

        <div className="space-y-4">
          {/* Variable Rate Capacity */}
          <div className="group bg-gradient-to-br from-[#003A30]/5 to-transparent rounded-xl p-6 transition-all duration-300 hover:shadow-lg hover:from-[#003A30]/10 hover:transform hover:scale-[1.02] border border-transparent hover:border-[#003A30]/10">
            <div className="flex items-center gap-2 mb-3">
              <BadgePercent className="w-6 h-6 text-[#CBA331] transition-transform duration-300 group-hover:scale-110 group-hover:rotate-12" />
              <h3 className="text-lg font-semibold text-[#003A30]">Zdolność kredytowa (oprocentowanie zmienne)</h3>
            </div>
            <p className="text-3xl font-bold text-[#003A30] mb-2 transition-colors duration-300 group-hover:text-[#CBA331]">
              {formatCurrency(results.variableRateCapacity)}
            </p>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="bg-gray-50 p-3 rounded-lg">
                <p className="text-xs text-gray-500 mb-1">Oprocentowanie z buforem</p>
                <p className="text-sm font-semibold text-[#003A30]">{formatPercentage(totalVariableRate)}</p>
              </div>
              <div className="bg-gray-50 p-3 rounded-lg">
                <p className="text-xs text-gray-500 mb-1">Rata z buforem</p>
                <p className="text-sm font-semibold text-[#003A30]">{formatCurrency(results.monthlyPaymentVariable)}</p>
              </div>
            </div>
          </div>

          {/* Fixed Rate Capacity */}
          <div className="group bg-gradient-to-br from-[#CBA331]/5 to-transparent rounded-xl p-6 transition-all duration-300 hover:shadow-lg hover:from-[#CBA331]/10 hover:transform hover:scale-[1.02] border border-transparent hover:border-[#CBA331]/10">
            <div className="flex items-center gap-2 mb-3">
              <Sparkles className="w-6 h-6 text-[#CBA331] transition-transform duration-300 group-hover:scale-110 group-hover:rotate-12" />
              <h3 className="text-lg font-semibold text-[#003A30]">Zdolność kredytowa (oprocentowanie stałe)</h3>
            </div>
            <p className="text-3xl font-bold text-[#003A30] mb-2 transition-colors duration-300 group-hover:text-[#CBA331]">
              {formatCurrency(results.fixedRateCapacity)}
            </p>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="bg-gray-50 p-3 rounded-lg">
                <p className="text-xs text-gray-500 mb-1">Oprocentowanie z buforem</p>
                <p className="text-sm font-semibold text-[#003A30]">{formatPercentage(totalFixedRate)}</p>
              </div>
              <div className="bg-gray-50 p-3 rounded-lg">
                <p className="text-xs text-gray-500 mb-1">Rata z buforem</p>
                <p className="text-sm font-semibold text-[#003A30]">{formatCurrency(results.monthlyPaymentFixed)}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Additional Information */}
        <div className="grid grid-cols-2 gap-4 mt-6">
          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="text-sm font-medium text-[#003A30] mb-2">Koszty gospodarstwa</h4>
            <p className="text-lg font-bold text-[#003A30]">
              {formatCurrency(results.householdExpenses)}
            </p>
            <p className="text-xs text-gray-500 mt-1">
              {results.householdMembers} {results.householdMembers === 1 ? 'osoba' : results.householdMembers < 5 ? 'osoby' : 'osób'}
            </p>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="text-sm font-medium text-[#003A30] mb-2">Dostępny dochód na ratę</h4>
            <p className="text-lg font-bold text-[#003A30]">
              {formatCurrency(results.availableIncome)}
            </p>
            <p className="text-xs text-gray-500 mt-1">
              {formatPercentage(results.dsti)} dochodu
            </p>
          </div>
        </div>

        {/* CTA Button */}
        <div className="mt-8 relative">
          <div className="absolute inset-0 bg-gradient-to-r from-[#003A30] to-[#CBA331] rounded-lg opacity-70 blur-md animate-pulse" aria-hidden="true"></div>
          
          <button
            onClick={() => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })}
            className="relative w-full inline-flex items-center justify-center px-6 py-5 bg-gradient-to-r from-[#003A30] to-[#003A30]/90 text-white rounded-lg shadow-lg hover:shadow-xl hover:from-[#CBA331] hover:to-[#CBA331]/90 transition-all duration-500 group font-bold text-lg overflow-hidden z-10"
          >
            <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-transparent via-white/10 to-transparent -translate-x-full group-hover:translate-x-full transition-transform duration-1000 ease-in-out" aria-hidden="true"></span>
            
            <Calculator className="w-6 h-6 mr-3 text-[#CBA331] group-hover:text-white transition-colors duration-300 animate-pulse" />
            POTWIERDŹ ZDOLNOŚĆ KREDYTOWĄ
            <ArrowRight className="w-5 h-5 ml-3 group-hover:translate-x-2 transition-transform duration-300" />
          </button>
          
          <div className="absolute -bottom-2 inset-x-4 h-2 bg-black/10 blur-md rounded-full" aria-hidden="true"></div>
        </div>
      </div>
    </div>
  );
}