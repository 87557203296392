import React from 'react';
import { HelpCircle, Calculator, BadgePercent, PiggyBank, ArrowDown, FileText } from 'lucide-react';

export default function InstructionPanel() {
  const instructions = [
    {
      icon: Calculator,
      title: "Parametry podstawowe",
      steps: [
        "Wprowadź miesięczny dochód netto (1 000 - 50 000 zł)",
        "Określ liczbę osób w gospodarstwie (1-10 osób)",
        "Podaj sumę obecnych rat kredytowych",
        "Wprowadź limity na kartach i rachunkach"
      ]
    },
    {
      icon: BadgePercent,
      title: "Koszty gospodarstwa",
      steps: [
        "Automatyczne wyliczenie kosztów utrzymania",
        "Uwzględnienie liczby osób w gospodarstwie",
        "Kalkulacja dostępnego dochodu na ratę",
        "Analiza obciążenia budżetu"
      ]
    },
    {
      icon: PiggyBank,
      title: "Zdolność kredytowa",
      steps: [
        "Wyliczenie dla kredytu ze zmienną stopą",
        "Wyliczenie dla kredytu ze stałą stopą",
        "Uwzględnienie bufora na stopę procentową",
        "Prognoza maksymalnej raty kredytu"
      ]
    },
    {
      icon: FileText,
      title: "Analiza i optymalizacja",
      steps: [
        "Sprawdź możliwości zwiększenia dochodów (np. dodatkowa praca)",
        "Zidentyfikuj kredyty do spłaty lub refinansowania",
        "Rozważ redukcję limitów na kartach",
        "Porównaj kwotę z cenami nieruchomości w okolicy"
      ]
    }
  ];

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden">
      <div className="p-6 md:p-8">
        <div className="flex items-center justify-center gap-3 mb-8">
          <div className="relative">
            <HelpCircle className="w-8 h-8 text-[#CBA331]" />
            <div className="absolute -top-1 -right-1 w-3 h-3 bg-[#003A30] rounded-full animate-pulse"></div>
          </div>
          <h2 className="text-2xl font-bold text-[#003A30] relative">
            Jak korzystać z kalkulatora?
            <div className="absolute -bottom-1 left-0 w-full h-0.5 bg-gradient-to-r from-[#CBA331]/0 via-[#CBA331] to-[#CBA331]/0"></div>
          </h2>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
          {instructions.map((section, index) => (
            <div 
              key={index}
              className="relative group bg-gradient-to-br from-gray-50 to-white rounded-xl p-6 shadow-sm hover:shadow-md transition-all duration-300"
            >
              {/* Top Gradient Bar */}
              <div 
                className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-[#003A30] to-[#CBA331] transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100 rounded-t-xl" 
                aria-hidden="true"
              />
              
              <div className="flex flex-col h-full">
                <div className="flex items-center gap-3 mb-4">
                  <div className="w-10 h-10 bg-[#003A30]/5 rounded-lg flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
                    <section.icon className="w-5 h-5 text-[#003A30]" />
                  </div>
                  <h3 className="text-lg font-semibold text-[#003A30]">{section.title}</h3>
                </div>

                <ol className="space-y-3 list-none pl-0">
                  {section.steps.map((step, stepIndex) => (
                    <li 
                      key={stepIndex}
                      className="flex items-start gap-2 text-gray-600 text-sm"
                    >
                      <div className="flex items-center justify-center w-5 h-5 rounded-full bg-[#003A30]/5 flex-shrink-0 mt-0.5">
                        <span className="text-xs font-medium text-[#003A30]">{stepIndex + 1}</span>
                      </div>
                      {step}
                    </li>
                  ))}
                </ol>

                {index < instructions.length - 1 && (
                  <div className="hidden lg:block absolute -right-3 top-1/2 -translate-y-1/2">
                    <ArrowDown className="w-6 h-6 text-[#CBA331] rotate-[-90deg]" />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-8 p-4 bg-[#003A30]/5 rounded-xl">
          <p className="text-sm text-gray-600 text-center">
            Kalkulator ma charakter poglądowy. Dokładna zdolność kredytowa ustalana jest indywidualnie przez bank na podstawie szczegółowej analizy sytuacji finansowej.
            Skontaktuj się z nami, aby otrzymać szczegółową analizę dopasowaną do Twojej sytuacji.
          </p>
        </div>
      </div>
    </div>
  );
}