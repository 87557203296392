import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import emailjs from '@emailjs/browser';
import App from './App';
import './index.css';
import { initGA } from './utils/analytics';

// Initialize EmailJS with your public key
emailjs.init("k2tuWEEyE_72qP5Oy");

// Check environment - skip analytics in development
const isDevelopment = process.env.NODE_ENV === 'development' || 
  window.location.hostname.includes('webcontainer') || 
  window.location.hostname.includes('local');

// Initialize analytics directly without fetch check
if (!isDevelopment) {
  initGA();
} else {
  console.info('Analytics disabled in development environment');
}

// Root element validation
const root = document.getElementById('root');
if (!root) {
  throw new Error('Root element not found. Please check your HTML file.');
}

// Error Fallback Component
function ErrorFallback({ error }: { error: Error }) {
  return (
    <div style={{
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1rem',
      backgroundColor: 'rgb(249, 250, 251)',
      fontFamily: 'system-ui, -apple-system, sans-serif'
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '2rem',
        borderRadius: '0.75rem',
        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
        maxWidth: '28rem',
        width: '100%',
        textAlign: 'center'
      }}>
        <h1 style={{
          color: 'rgb(220, 38, 38)',
          fontSize: '1.5rem',
          fontWeight: 700,
          marginBottom: '1rem'
        }}>
          Wystąpił błąd aplikacji
        </h1>
        <p style={{
          color: 'rgb(75, 85, 99)',
          marginBottom: '1.5rem'
        }}>
          Przepraszamy, wystąpił nieoczekiwany błąd. Nasz zespół został powiadomiony o problemie.
        </p>
        <pre style={{
          backgroundColor: 'rgb(249, 250, 251)',
          padding: '1rem',
          borderRadius: '0.5rem',
          fontSize: '0.875rem',
          color: 'rgb(75, 85, 99)',
          marginBottom: '1.5rem',
          overflow: 'auto',
          textAlign: 'left'
        }}>
          {error.message}
        </pre>
        <button
          onClick={() => window.location.reload()}
          style={{
            backgroundColor: 'rgb(0, 58, 48)',
            color: 'white',
            padding: '0.75rem 1.5rem',
            borderRadius: '0.5rem',
            width: '100%',
            cursor: 'pointer',
            transition: 'background-color 0.3s'
          }}
        >
          Odśwież stronę
        </button>
      </div>
    </div>
  );
}

// Create root with error handling
try {
  const rootElement = createRoot(root);
  
  // Render app with error boundary
  rootElement.render(
    <StrictMode>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <App />
      </ErrorBoundary>
    </StrictMode>
  );
} catch (error) {
  console.error('Failed to initialize application:', error);
  
  // Show basic error message if React fails to initialize
  root.innerHTML = `
    <div style="
      min-height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      background-color: rgb(249, 250, 251);
      font-family: system-ui, -apple-system, sans-serif;
    ">
      <div style="
        background-color: white;
        padding: 2rem;
        border-radius: 0.75rem;
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
        max-width: 28rem;
        width: 100%;
        text-align: center;
      ">
        <h1 style="
          color: rgb(220, 38, 38);
          font-size: 1.5rem;
          font-weight: 700;
          margin-bottom: 1rem;
        ">
          Nie udało się załadować aplikacji
        </h1>
        <p style="
          color: rgb(75, 85, 99);
          margin-bottom: 1.5rem;
        ">
          Przepraszamy, wystąpił krytyczny błąd podczas ładowania aplikacji. Odśwież stronę lub spróbuj ponownie później.
        </p>
        <button onclick="window.location.reload()" style="
          background-color: rgb(0, 58, 48);
          color: white;
          padding: 0.75rem 1.5rem;
          border-radius: 0.5rem;
          width: 100%;
          cursor: pointer;
          transition: background-color 0.3s;
        ">
          Odśwież stronę
        </button>
      </div>
    </div>
  `;
}