import React from 'react';
import { HelpCircle } from 'lucide-react';

export default function FloatingHelpButton() {
  return (
    <button
      onClick={() => document.getElementById('instructions')?.scrollIntoView({ behavior: 'smooth' })}
      className="fixed right-6 top-1/2 -translate-y-1/2 z-40 bg-[#003A30] text-white p-3 rounded-full shadow-lg hover:bg-[#CBA331] transition-all duration-300 group"
      aria-label="Pokaż instrukcję"
    >
      <HelpCircle className="w-6 h-6" />
      <div className="absolute right-full mr-2 px-3 py-1 bg-white text-[#003A30] rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 whitespace-nowrap">
        Pokaż instrukcję
      </div>
    </button>
  );
}