import type { ConcernsData } from './types';

export const refinancingConcernsData: ConcernsData = {
  title: "Rozwiewamy wątpliwości",
  subtitle: "Poznaj rozwiązania typowych obaw związanych z refinansowaniem",
  concerns: [
    {
      concern: "Mam niską zdolność kredytową",
      solution: "Pomożemy znaleźć bank z najlepszymi warunkami i przeanalizujemy możliwości zwiększenia zdolności"
    },
    {
      concern: "Boję się kosztów refinansowania",
      solution: "Przygotujemy szczegółową analizę kosztów i oszczędności, aby upewnić się, że refinansowanie będzie opłacalne"
    },
    {
      concern: "Mam kilka kredytów do spłaty",
      solution: "Możemy skonsolidować wszystkie kredyty w jeden, z niższą ratą i lepszymi warunkami"
    },
    {
      concern: "Proces wydaje się skomplikowany",
      solution: "Przeprowadzimy Cię przez cały proces krok po kroku, zajmując się wszystkimi formalnościami"
    }
  ]
};