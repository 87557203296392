import type { ConcernsData } from './types';

export const businessConcernsData: ConcernsData = {
  title: "Rozwiewamy wątpliwości",
  subtitle: "Poznaj rozwiązania typowych obaw związanych z kredytem firmowym",
  concerns: [
    {
      concern: "Krótka historia działalności",
      solution: "Specjalne programy dla młodych firm i alternatywne formy zabezpieczeń"
    },
    {
      concern: "Brak wystarczających zabezpieczeń",
      solution: "Elastyczne podejście do zabezpieczeń i możliwość kredytów bez zabezpieczeń"
    },
    {
      concern: "Skomplikowana dokumentacja",
      solution: "Kompleksowa pomoc w przygotowaniu wszystkich dokumentów i formalnościach"
    },
    {
      concern: "Długi proces decyzyjny",
      solution: "Przyspieszona ścieżka decyzyjna i wsparcie na każdym etapie"
    }
  ]
};