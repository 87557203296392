import { Home, Building2, RefreshCw, PiggyBank, Calculator, BarChart, BookOpen, FileText, Files } from 'lucide-react';
import { DivideIcon as LucideIcon } from 'lucide-react';

export interface MenuItem {
  href: string;
  label: string;
  Icon: LucideIcon;
}

export const MENUS = {
  services: [
    { href: '/kredyty-hipoteczne', label: 'Kredyty hipoteczne', Icon: Home },
    { href: '/refinansowanie', label: 'Refinansowanie', Icon: RefreshCw },
    { href: '/kredyty-firmowe', label: 'Kredyty firmowe', Icon: Building2 },
    { href: '/optymalizacja', label: 'Optymalizacja', Icon: PiggyBank },
  ],
  calculators: [
    { href: '/kalkulator-kredytowy', label: 'Kalkulator kredytowy', Icon: Calculator },
    { href: '/zdolnosc-kredytowa', label: 'Zdolność kredytowa', Icon: BarChart },
  ],
  knowledge: [
    { href: '/blog', label: 'Blog', Icon: BookOpen },
    { href: '/poradniki', label: 'Poradniki', Icon: FileText },
    { href: '/dokumenty', label: 'Listy dokumentów', Icon: Files },
  ]
} as const;

export const SECTION_IDS = {
  services: 'uslugi',
  calculators: 'kalkulatory',
  knowledge: 'wiedza'
} as const;