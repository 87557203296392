import React from 'react';
import Concerns from '@/components/shared/Concerns';
import { refinancingConcernsData } from '@/data/ConcernsData';

export default function RefinancingConcerns() {
  return (
    <Concerns 
      concerns={refinancingConcernsData.concerns}
      title={refinancingConcernsData.title}
      subtitle={refinancingConcernsData.subtitle}
    />
  );
}