import type { CaseStudy } from '../types';

export const MORTGAGE_REFINANCE_CASE: CaseStudy = {
  id: "mortgage-refinance",
  client: "Marcin i Karolina",
  image: "https://media.istockphoto.com/id/2027279443/photo/unhappy-young-couple.webp?a=1&b=1&s=612x612&w=0&k=20&c=pBTzv8MXZkUD1nqaiJqMif_QPOR4zaK9M8CX0FZvHwM=",
  title: "Refinansowanie kredytu z okresem stałej stopy",
  description: "Pomogliśmy parze obniżyć ratę kredytu hipotecznego o 920 zł miesięcznie dzięki refinansowaniu po zakończeniu okresu stałego oprocentowania.",
  result: "Oszczędność 165 600 zł w całym okresie kredytowania",
  service: "refinance",
  challenges: [
    "Kredyt zaciągnięty w 2021 roku z wysoką marżą 2.9% i 5-letnim okresem stałego oprocentowania",
    "Nadmierne i kosztowne produkty dodatkowe wymagane przez bank",
    "Aktualna rata pochłaniająca ponad 45% dochodów gospodarstwa domowego",
    "Niekorzystne warunki wcześniejszej spłaty w obecnej umowie",
    "Koszty przeniesienia kredytu (wycena, opłaty notarialne, podatek PCC)"
  ],
  solutions: [
    "Precyzyjna analiza warunków wcześniejszej spłaty w obecnej umowie",
    "Negocjacja konkurencyjnych warunków w nowym banku z marżą 1.75%",
    "Wybór optymalnego okresu stałego oprocentowania (7 lat)",
    "Minimalizacja kosztów refinansowania dzięki promocji bankowej",
    "Redukcja nadmiarowych produktów dodatkowych"
  ],
  directQuote: "Mieliśmy poczucie, że płacimy zdecydowanie za dużo. Dzięki refinansowaniu nie tylko obniżyliśmy ratę o prawie 1000 zł miesięcznie, ale też zabezpieczyliśmy się przed dalszymi podwyżkami stóp procentowych na kolejne 7 lat. To ogromna ulga dla naszego budżetu.",
  financialBenefit: "Oszczędność 11 040 zł rocznie przy niższej racie",
  savingsPercentage: 22,
  savingsAmount: "920 zł miesięcznej oszczędności",
  whatIfScenario: "Bez refinansowania Marcin i Karolina musieliby kontynuować spłatę kredytu na niekorzystnych warunkach. Po zakończeniu okresu stałego oprocentowania w 2026 roku, przy prognozowanym wzroście stóp procentowych, ich rata mogłaby wzrosnąć nawet o dodatkowe 400-500 zł miesięcznie.",
  clientTypeTag: "Refinansowanie",
  ctaText: "Sprawdź, ile możesz zaoszczędzić",
  ctaLink: "/refinansowanie-kredytu",
  difficultyLevel: 3,
  timelineInfo: "Refinansowanie w 42 dni",
  rating: 5,
  date: "Luty 2025",
  creditType: "Refinansowanie kredytu hipotecznego",
  clientOccupation: "Informatyk i nauczycielka",
  clientLocation: "Kraków, Ruczaj",
  beforeAfter: {
    before: "Kredyt hipoteczny 620 000 zł, marża 2.9%, rata 4250 zł, obowiązkowe ubezpieczenie na życie 220 zł/mies., obowiązkowy ROR z wpływami 7000 zł, obowiązkowa karta kredytowa z wymogiem transakcji.",
    after: "Kredyt hipoteczny 605 000 zł (po częściowej spłacie), marża 1.75%, stałe oprocentowanie 6.2% na 7 lat, rata 3330 zł, dobrowolne ubezpieczenie 120 zł/mies., brak wymogów co do wpływów na ROR."
  },
  reasoning: "Wybraliśmy tę firmę doradczą, ponieważ specjalizują się w refinansowaniu kredytów hipotecznych. Zaimponowała nam ich dogłębna analiza aktualnej umowy i potencjalnych oszczędności. Przedstawili nam konkretne wyliczenia dla różnych banków i scenariuszy, dzięki czemu mogliśmy podjąć świadomą decyzję.",
  fullStory: {
    situation: "Para w wieku 35 lat spłacająca kredyt hipoteczny zaciągnięty w 2021 roku na zakup mieszkania 72m² w dzielnicy Ruczaj w Krakowie. Kredyt w wysokości 620 000 zł został udzielony na 30 lat z wysoką marżą 2.9% i okresem stałego oprocentowania na 5 lat. Bank wymagał zakupu drogiego pakietu ubezpieczeń i utrzymywania wysokich wpływów na rachunek osobisty.",
    challenge: "Głównym problemem była wysoka rata kredytu pochłaniająca znaczną część budżetu domowego oraz zbliżający się koniec okresu stałego oprocentowania, co stwarzało ryzyko dalszego wzrostu kosztów. Dodatkowo, wymagane przez bank produkty dodatkowe generowały niepotrzebne miesięczne obciążenia.",
    solution: "Kompleksowa analiza obecnej umowy kredytowej i warunków refinansowania w różnych bankach. Wybór oferty z niższą marżą (1.75%) i dłuższym okresem stałego oprocentowania (7 lat) oraz minimalnymi wymogami dotyczącymi produktów dodatkowych. Przeprowadzenie procesu refinansowania z minimalną ilością formalności i kosztów.",
    details: {
      property: {
        value: "850 000 zł (aktualna wycena)",
        type: "Mieszkanie 72m²",
        location: "Kraków, Ruczaj"
      },
      loan: {
        oldRate: "8.7% (WIBOR 5.8% + marża 2.9%)",
        oldPayment: "4250 zł + 220 zł ubezpieczenie",
        newRate: "6.2% (stała stopa na 7 lat)",
        newPayment: "3330 zł + 120 zł ubezpieczenie"
      },
      savings: {
        monthly: "920 zł (rata) + 100 zł (ubezpieczenie)",
        yearly: "12 240 zł",
        total: "165 600 zł (przy założeniu utrzymania obecnych warunków)"
      },
      timeline: {
        process: "42 dni od złożenia wniosku do uruchomienia",
        costs: "3200 zł (wycena, notariusz, PCC)"
      }
    }
  }
};