import React from 'react';
import Hero from '@/components/shared/Hero';
import ProblemsSolutions from '@/components/shared/ProblemsSolutions';
import Process from '@/components/shared/Process';
import CaseStudyList from '@/components/shared/CaseStudyList';
import Comparison from '@/components/shared/Comparison';
import ComparisonCards from '@/components/shared/ComparisonCards';
import ServicePageNavigation from '@/components/shared/ServicePageNavigation';
import { optimizationProblemsData } from '@/data/ProblemsSolutionsData';
import { optimizationCases } from '@/data/caseStudies/optimization';
import { OPTIMIZATION_CARDS, OPTIMIZATION_DETAILS } from '@/data/modal/optimization';
import Calculator from './sections/Calculator';
import Concerns from './sections/Concerns';
import TrustBar from './sections/TrustBar';
import Contact from '@/components/shared/Contact';
import { Shield, BadgePercent, Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function Optimization() {
  const navigate = useNavigate();

  return (
    <div className="pt-20 bg-gray-50">
      <ServicePageNavigation />
      
      <Hero 
        title="Optymalizacja kosztów kredytów firmowych"
        highlightedWord="optymalizacja"
        description="Profesjonalne doradztwo w zakresie optymalizacji kosztów kredytów i zobowiązań firmowych. Popraw płynność finansową swojej firmy."
        image="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?auto=format&fit=crop&q=80"
        badgeText="Ponad 300 zoptymalizowanych firm"
        stats={[
          {
            icon: BadgePercent,
            value: "42%",
            label: "Średnia redukcja kosztów"
          },
          {
            icon: Clock,
            value: "14-30",
            label: "Dni na wdrożenie"
          },
          {
            icon: Shield,
            value: "300+",
            label: "Zoptymalizowanych firm"
          }
        ]}
        primaryCta={{
          label: "Zacznij oszczędzać",
          action: () => navigate('#contact')
        }}
        secondaryCta={{
          label: "Oblicz potencjał",
          action: () => navigate('/kalkulator-oszczednosci')
        }}
      />
      <ProblemsSolutions 
        problems={optimizationProblemsData.problems}
        title={optimizationProblemsData.title}
        subtitle={optimizationProblemsData.subtitle}
      />
      <section id="calculator">
        <Calculator />
      </section>
      <section id="types">
        <ComparisonCards 
          cards={OPTIMIZATION_CARDS}
          details={OPTIMIZATION_DETAILS}
          title="Możliwości optymalizacji"
          subtitle="Poznaj możliwości optymalizacji kosztów finansowania Twojej firmy"
        />
      </section>
      <Comparison />
      <Process />
      <section id="testimonials">
        <CaseStudyList 
          cases={optimizationCases}
          title="Historie sukcesu optymalizacji"
          subtitle="Zobacz, jak pomogliśmy firmom zredukować koszty i poprawić płynność finansową"
        />
      </section>
      <Concerns />
      <TrustBar />
      <section id="contact">
        <Contact />
      </section>
    </div>
  );
}