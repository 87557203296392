import ReactGA from 'react-ga4';

// Check if we're in development mode
const isDevelopment = process.env.NODE_ENV === 'development' || 
  window.location.hostname.includes('webcontainer') || 
  window.location.hostname.includes('local');

// Initialize GA4 with improved error handling and development detection
export const initGA = () => {
  // Skip analytics in development environments
  if (isDevelopment) {
    console.info('Analytics disabled in development environment');
    return;
  }

  try {
    ReactGA.initialize('G-8B2GMXBK9G', {
      testMode: process.env.NODE_ENV !== 'production',
      gaOptions: {
        cookieFlags: 'SameSite=None;Secure',
        transport: 'beacon',
        allowAdFeatures: false
      }
    });
    
    // Test the connection
    ReactGA.send({
      hitType: "event",
      category: "System",
      action: "Init",
      nonInteraction: true
    });
    
    console.info('Google Analytics initialized successfully');
  } catch (error) {
    console.warn('Failed to initialize Google Analytics:', error);
  }
};

// Track page views with proper URL handling and error recovery
export const trackPageView = (path: string) => {
  // Skip in development
  if (isDevelopment) return;

  try {
    // Remove any leading/trailing whitespace and handle undefined/null
    const cleanPath = (path || '').trim();
    
    // Ensure path starts with a forward slash
    const normalizedPath = cleanPath.startsWith('/') ? cleanPath : `/${cleanPath}`;
    
    // Get the current hostname
    const hostname = window.location.hostname;
    
    // Construct the full URL
    const fullLocation = `${window.location.protocol}//${hostname}${normalizedPath}`;
    
    // Get and validate document referrer
    const referrer = document.referrer || '';
    const validReferrer = referrer && referrer !== 'about:blank' ? encodeURIComponent(referrer) : '';
    
    // Only track if we have a valid path
    if (normalizedPath) {
      ReactGA.send({
        hitType: "pageview",
        page: normalizedPath,
        location: fullLocation,
        title: document.title,
        // Only include referrer if it's valid
        ...(validReferrer ? { dr: validReferrer } : {}),
        // Add transport method
        transport: 'beacon'
      });
    }
  } catch (error) {
    console.warn('Analytics pageview error:', error);
    
    // Retry once after a short delay
    setTimeout(() => {
      try {
        ReactGA.send({
          hitType: "pageview",
          page: path
        });
      } catch (retryError) {
        console.warn('Analytics pageview retry failed:', retryError);
      }
    }, 2000);
  }
};

// Track events with error handling and retry logic
export const trackEvent = (category: string, action: string, label?: string) => {
  // Skip in development
  if (isDevelopment) return;

  try {
    if (!category || !action) {
      console.warn('Missing required event parameters');
      return;
    }
    ReactGA.event({
      category,
      action,
      label,
      nonInteraction: false,
      transport: 'beacon'
    });
  } catch (error) {
    console.warn('Analytics event error:', error);
    
    // Retry once after a short delay
    setTimeout(() => {
      try {
        ReactGA.event({
          category,
          action,
          label
        });
      } catch (retryError) {
        console.warn('Analytics event retry failed:', retryError);
      }
    }, 2000);
  }
};

// Safer analytics check that won't throw errors in development
export const checkAnalyticsBlocked = async (): Promise<boolean> => {
  // Always return true in development to skip analytics
  if (isDevelopment) {
    return true;
  }
  
  try {
    // More reliable way to check if analytics is blocked
    const timeoutPromise = new Promise<never>((_, reject) => {
      setTimeout(() => reject(new Error('Timeout checking analytics availability')), 3000);
    });
    
    const checkPromise = fetch('https://www.google-analytics.com/analytics.js', {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-cache',
      headers: {
        'Pragma': 'no-cache'
      }
    }).then(() => false); // not blocked
    
    // Race the fetch against the timeout
    return await Promise.race([checkPromise, timeoutPromise.then(() => true)]);
  } catch (error) {
    // Any error means analytics is likely blocked
    console.warn('Analytics appears to be blocked or unavailable:', 
      error instanceof Error ? error.message : 'Unknown error');
    return true;
  }
};