import React from 'react';

interface ReadingProgressBarProps {
  progress: number;
}

export default function ReadingProgressBar({ progress }: ReadingProgressBarProps) {
  return (
    <div className="fixed top-0 left-0 w-full h-1 z-50">
      <div 
        className="h-full bg-gradient-to-r from-[#003A30] via-[#80A39C] to-[#CBA331] transition-all duration-200 ease-out"
        style={{ 
          width: `${progress}%`,
          boxShadow: progress > 0 ? '0 1px 3px rgba(0,0,0,0.1)' : 'none'
        }}
        role="progressbar"
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-label="Postęp czytania"
      />
    </div>
  );
}