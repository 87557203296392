import { Building2, BadgePercent, Clock, Shield } from 'lucide-react';
import type { SocialProofStats } from './types';

export const businessStats: SocialProofStats = {
  clients: {
    icon: Building2,
    value: 500,
    showPlus: true,
    label: "Obsłużonych firm"
  },
  financing: {
    icon: BadgePercent,
    value: 2.1,
    suffix: " mld zł",
    label: "Wartość finansowania"
  },
  experience: {
    icon: Clock,
    value: 48,
    suffix: "h",
    label: "Szybka decyzja"
  },
  satisfaction: {
    icon: Shield,
    value: 95,
    suffix: "%",
    label: "Skuteczność"
  }
};