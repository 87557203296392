import type { CaseStudy } from '../types';

export const CREDIT_LINE_CASE: CaseStudy = {
  id: "credit-line-bgk",
  client: "Piotr",
  clientOccupation: "Właściciel",
  clientLocation: "Firma budowlano-remontowa",
  clientTypeTag: "Usługi budowlane i remontowe",
  image: "https://images.unsplash.com/photo-1564564321837-a57b7070ac4f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8bWFufGVufDB8fDB8fHww",
  title: "Limit w rachunku 500 000 zł z gwarancją BGK bez hipoteki",
  description: "Zapewniliśmy elastyczne finansowanie dla firmy budowlanej bez konieczności zabezpieczenia hipotecznego, umożliwiając realizację większej liczby projektów i znaczący wzrost przychodów.",
  result: "Limit w rachunku 500 000 zł na 3 lata + wzrost przychodów o 47%",
  creditType: "Limit w rachunku bieżącym",
  timelineInfo: "Decyzja w 10 dni",
  financialBenefit: "Wzrost marży o 3,8 punktu procentowego",
  savingsAmount: "235000",
  savingsPercentage: 47,
  rating: 5,
  challenges: [
    "Konieczność finansowania zakupu materiałów przed otrzymaniem płatności od klientów",
    "Długie cykle płatności (30-60 dni) przy projektach budowlanych",
    "Sezonowość branży i potrzeba elastycznego dostępu do finansowania",
    "Brak możliwości lub chęci zabezpieczenia kredytu hipoteką",
    "Wcześniejsze odmowy lub niekorzystne warunki w bankach"
  ],
  solutions: [
    "Limit w rachunku bieżącym 500 000 zł z długim, 3-letnim okresem dostępności",
    "Zabezpieczenie w formie gwarancji BGK (80% kwoty kredytu) bez hipoteki",
    "Konkurencyjne oprocentowanie: WIBOR + marża tylko 2,7%",
    "Płatność odsetek wyłącznie od wykorzystanej kwoty",
    "Automatyczne spłaty przy wpływach na rachunek"
  ],
  directQuote: "Jeśli prowadzisz firmę budowlaną lub remontową i zastanawiasz się, jak przejść na wyższy poziom, szczerze polecam spotkanie z doradcą. Limit w rachunku z gwarancją BGK to rozwiązanie, które naprawdę działa, a profesjonalne doradztwo pozwala uniknąć wielu pułapek i znacząco przyspiesza cały proces. Dla mnie to była inwestycja, która zwróciła się setki razy.",
  whatIfScenario: "Gdyby Piotr nie zdecydował się na spotkanie z doradcą i dalej próbował samodzielnie negocjować z bankami, prawdopodobnie nadal borykałby się z problemami płynnościowymi i musiałby odmawiać intratnych zleceń. Jak sam przyznaje, w ciągu dwóch lat przed uzyskaniem limitu stracił możliwość realizacji projektów wartych łącznie około 1,2 mln złotych. Kontynuacja działalności bez odpowiedniego finansowania oznaczałaby dalsze ograniczenie potencjału rozwojowego firmy i utratę pozycji konkurencyjnej na rynku.",
  beforeAfter: {
    before: "Realizacja 1-2 projektów jednocześnie. Konieczność odmawiania zleceń ze względu na brak płynności. Słaba pozycja negocjacyjna z dostawcami. Zatrudnienie 8 pracowników. Miesięczne przychody na poziomie 150 000 zł. Marża na poziomie 18%.",
    after: "Realizacja 3-4 projektów jednocześnie. Możliwość przyjmowania praktycznie wszystkich zleceń. Lepsza pozycja negocjacyjna i rabaty od dostawców. Zatrudnienie 12 pracowników. Miesięczne przychody na poziomie 220 000 zł (wzrost o 47%). Marża na poziomie 21,8% (wzrost o 3,8 punktu procentowego)."
  },
  reasoning: "Decyzja o pozyskaniu limitu w rachunku z gwarancją BGK wynikała z analizy specyfiki branży budowlano-remontowej, gdzie występuje znacząca rozbieżność czasowa między koniecznością poniesienia kosztów (zakup materiałów, wynagrodzenia) a otrzymaniem płatności od klientów. Tradycyjne rozwiązania kredytowe często wymagają zabezpieczenia hipotecznego, co stanowi barierę dla wielu przedsiębiorców. Gwarancja BGK eliminuje tę przeszkodę, jednocześnie umożliwiając uzyskanie korzystniejszych warunków cenowych. Trzyletni okres dostępności limitu zapewnia stabilność finansową w dłuższej perspektywie, co jest kluczowe dla planowania rozwoju firmy.",
  service: "business",
  fullStory: {
    situation: "Piotr od 8 lat prowadzi firmę budowlano-remontową zatrudniającą 8 pracowników. Firma specjalizuje się w remontach i wykończeniach powierzchni komercyjnych (biura, sklepy, hotele) oraz współpracy z deweloperami. Mimo solidnych kompetencji i dobrej reputacji, Piotr regularnie musiał odmawiać intratnych zleceń, ponieważ nie był w stanie sfinansować zakupu materiałów i wypłat dla pracowników przed otrzymaniem płatności od klientów. Typowy cykl w jego branży to płatność po zakończeniu etapu lub całego projektu (30-60 dni), podczas gdy koszty materiałów i robocizny musiał ponosić z góry.",
    challenge: "Przez trzy lata Piotr próbował samodzielnie załatwić finansowanie w różnych bankach. Za każdym razem słyszał, że musi zastawić dom lub że limit będzie zbyt mały i tylko na rok. Głównym wyzwaniem było znalezienie rozwiązania, które zapewniłoby wystarczająco duży i długoterminowy dostęp do finansowania bez konieczności zabezpieczenia hipotecznego. Piotr potrzebował elastycznego instrumentu, który pozwoliłby mu na realizację większej liczby projektów równocześnie i wykorzystanie pełnego potencjału firmy.",
    solution: "Przełom nastąpił, gdy kolega z branży polecił Piotrowi spotkanie z doradcą finansowym. Doradca zaproponował rozwiązanie, o którym Piotr wcześniej nie słyszał: limit w rachunku bieżącym w wysokości 500 000 zł na 3 lata, zabezpieczony gwarancją BGK zamiast hipoteką. Doradca znał szczegóły programu gwarancji BGK, o których nie wspominał żaden z banków, z którymi Piotr wcześniej rozmawiał. Co więcej, pomógł przygotować dokumentację w taki sposób, że cały proces przebiegł zaskakująco sprawnie. Dzięki profesjonalnemu doradztwu, Piotr uzyskał finansowanie na znacznie lepszych warunkach niż te, które były mu wcześniej oferowane.",
    details: {
      property: {
        type: "Firma budowlano-remontowa",
        experience: "8 lat na rynku",
        employees: "8 pracowników (przed uzyskaniem limitu)",
        clients: "Firmy komercyjne, deweloperzy",
        projects: "Remonty i wykończenia powierzchni komercyjnych"
      },
      loan: {
        amount: "500 000 zł",
        type: "Limit w rachunku bieżącym",
        period: "3 lata",
        rate: "WIBOR + marża 2,7%",
        security: "Gwarancja BGK (80% kwoty kredytu) + weksel in blanco",
        payment: "Odsetki tylko od wykorzystanej kwoty"
      },
      timeline: {
        application: "Przygotowanie dokumentacji: 3 dni",
        decision: "Decyzja kredytowa: 10 dni",
        completion: "Uruchomienie limitu: 14 dni od złożenia wniosku",
        firstResults: "Pierwsze efekty biznesowe: po 2 miesiącach"
      },
      savings: {
        revenueIncrease: "Wzrost miesięcznych przychodów z 150 000 zł do 220 000 zł (o 47%)",
        marginIncrease: "Wzrost marży z 18% do 21,8% (o 3,8 punktu procentowego)",
        projectsIncrease: "Wzrost liczby równoległych projektów z 1-2 do 3-4",
        employmentIncrease: "Zatrudnienie 4 dodatkowych pracowników",
        supplierConditions: "Uzyskanie rabatów od dostawców na poziomie 5-12%"
      }
    }
  }
};
