import React from 'react';
import { ArrowRight, Calendar, Clock, User } from 'lucide-react';
import { Link } from 'react-router-dom';
import { formatDate } from '../utils/date-formatter';
import type { BlogPost } from '../types';

interface FeaturedArticleProps {
  post: BlogPost;
}

export default function FeaturedArticle({ post }: FeaturedArticleProps) {
  return (
    <div className="bg-white rounded-2xl shadow-lg overflow-hidden mb-12">
      <div className="grid md:grid-cols-2 h-full">
        {/* Image Section */}
        <div className="relative h-64 md:h-full">
          <img 
            src={post.image} 
            alt={post.title}
            className="w-full h-full object-cover"
            loading="eager"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent md:bg-gradient-to-r md:from-black/70 md:via-black/30 md:to-transparent" />
          
          {/* Category Badge - Mobile Only */}
          <div className="absolute top-4 left-4 md:hidden">
            <span className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-[#CBA331] text-white">
              Wyróżnione
            </span>
          </div>
        </div>
        
        {/* Content Section */}
        <div className="p-6 md:p-8 flex flex-col h-full">
          {/* Category Badge - Desktop Only */}
          <div className="hidden md:block mb-4">
            <span className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-[#CBA331] text-white">
              Wyróżnione
            </span>
          </div>
          
          <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-[#003A30] mb-3 line-clamp-3">
            {post.title}
          </h2>
          
          <p className="text-gray-600 mb-4 line-clamp-3 md:line-clamp-4">
            {post.excerpt}
          </p>
          
          <div className="flex flex-wrap items-center gap-4 text-sm text-gray-500 mb-6">
            <div className="flex items-center gap-1">
              <Calendar className="w-4 h-4" />
              <span>{formatDate(post.date)}</span>
            </div>
            <div className="flex items-center gap-1">
              <Clock className="w-4 h-4" />
              <span>{post.readingTime} min</span>
            </div>
            <div className="flex items-center gap-1">
              {post.author.avatar ? (
                <img
                  src={post.author.avatar}
                  alt={post.author.name}
                  className="w-5 h-5 rounded-full object-cover"
                />
              ) : (
                <User className="w-4 h-4" />
              )}
              <span>{post.author.name}</span>
            </div>
          </div>
          
          <div className="mt-auto">
            <Link
              to={`/blog/${post.slug}`}
              className="inline-flex items-center justify-center px-6 py-3 bg-[#003A30] text-white rounded-lg hover:bg-[#CBA331] transition-colors duration-300 group"
            >
              Czytaj więcej
              <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}