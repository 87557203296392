import { AlertCircle } from 'lucide-react';
import type { ProblemsSolutionsData } from './types';

export const refinancingProblemsData: ProblemsSolutionsData = {
  title: "Uwolnij potencjał swojego kredytu hipotecznego",
  subtitle: "Przekształć obecne zobowiązanie w narzędzie finansowe na miarę Twoich potrzeb",
  problems: [
    {
      icon: AlertCircle,
      problem: "Zbyt wysokie raty kredytu hipotecznego",
      solution: "Realna redukcja miesięcznych obciążeń",
      details: [
        "Wynegocjowanie znacząco niższego oprocentowania dostępnego tylko dla wybranych klientów",
        "Eliminacja ukrytych kosztów obciążających Twój budżet domowy",
        "Strategiczne wydłużenie okresu spłaty z możliwością skrócenia bezkosztowymi nadpłatami"
      ]
    },
    {
      icon: AlertCircle,
      problem: "Niepokój związany z wysokimi stopami",
      solution: "Strategiczna adaptacja do trendów rynkowych",
      details: [
        "Wykorzystanie prognozowanych spadków stóp procentowych do maksymalizacji Twoich korzyści",
        "Ekspercka analiza momentu refinansowania w oparciu o cykl gospodarczy",
        "Elastyczne rozwiązania pozwalające czerpać korzyści z przyszłych spadków na rynku"
      ]
    },
    {
      icon: AlertCircle,
      problem: "Brak środków na nowe cele finansowe",
      solution: "Dodatkowy kapitał bez nowego kredytu",
      details: [
        "Pozyskanie nawet do 30% wartości nieruchomości bez dokumentowania celu",
        "Błyskawiczny dostęp do gotówki na realizację Twoich planów bez biurokracji",
        "Optymalne wykorzystanie wzrostu wartości nieruchomości bez obciążania budżetu"
      ]
    },
    {
      icon: AlertCircle,
      problem: "Obawa przed dodatkowymi kosztami",
      solution: "Refinansowanie bez ukrytych opłat",
      details: [
        "Precyzyjna analiza całkowitych kosztów refinansowania z gwarancją oszczędności",
        "Eliminacja zbędnych prowizji dzięki specjalnym warunkom niedostępnym dla indywidualnych klientów",
        "Kalkulacja rzeczywistego zwrotu z refinansowania pokazująca konkretne korzyści finansowe"
      ]
    }
  ]
};