import React, { useEffect, useRef } from 'react';
import { Tag } from 'lucide-react';
import type { BlogPost } from '@/blog/types';
import QuoteBox from '../ui/QuoteBox';
import KeyPoints from '../ui/KeyPoints';
import YouTubeThumbnail from '../ui/YouTubeThumbnail';
import CTABox from '../ui/CTABox';
import OptimizedImage from '../ui/OptimizedImage';
import { processHeadings, processQuoteBlocks, processYouTubeLinks, processCTASection } from '../../utils/content-processors';

interface ArticleContentProps {
  post: BlogPost;
}

export default function ArticleContent({ post }: ArticleContentProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  
  // Process content when it changes
  useEffect(() => {
    if (contentRef.current) {
      // Process headings for table of contents
      processHeadings(contentRef);
      
      // Process quote blocks
      processQuoteBlocks(contentRef);
      
      // Process YouTube links
      processYouTubeLinks(contentRef, post.youtubeVideos);
      
      // Process CTA section
      processCTASection(contentRef, post.customCTA);
    }
  }, [post.content, post.youtubeVideos, post.customCTA]);

  // Add responsive table wrapper to tables
  useEffect(() => {
    if (contentRef.current) {
      const tables = contentRef.current.querySelectorAll('table');
      tables.forEach(table => {
        // Check if table is already wrapped
        if (table.parentElement?.classList.contains('table-responsive-wrapper')) {
          return;
        }
        
        // Create wrapper
        const wrapper = document.createElement('div');
        wrapper.className = 'table-responsive-wrapper -webkit-overflow-scrolling-touch';
        
        // Insert wrapper before table
        table.parentNode?.insertBefore(wrapper, table);
        
        // Move table inside wrapper
        wrapper.appendChild(table);
      });
    }
  }, [post.content]);

  // Process images to use OptimizedImage component
  useEffect(() => {
    if (contentRef.current) {
      const images = contentRef.current.querySelectorAll('img:not(.optimized)');
      images.forEach(img => {
        // Skip if already processed
        if (img.classList.contains('optimized')) return;
        
        // Get attributes
        const src = img.getAttribute('src') || '';
        const alt = img.getAttribute('alt') || '';
        const width = img.getAttribute('width');
        const height = img.getAttribute('height');
        
        // Create figure element
        const figure = document.createElement('figure');
        figure.className = 'my-6';
        
        // Create new optimized image
        const newImg = document.createElement('img');
        newImg.src = src;
        newImg.alt = alt;
        newImg.className = 'rounded-lg w-full h-auto object-cover shadow-sm optimized';
        newImg.setAttribute('loading', 'lazy');
        newImg.setAttribute('decoding', 'async');
        
        if (width) newImg.setAttribute('width', width);
        if (height) newImg.setAttribute('height', height);
        
        // Create srcset
        const lastDotIndex = src.lastIndexOf('.');
        if (lastDotIndex !== -1) {
          const fileBase = src.substring(0, lastDotIndex);
          const fileExt = src.substring(lastDotIndex);
          
          const srcset = [
            `${src} 1280w`,
            `${fileBase}?w=1024${fileExt} 1024w`,
            `${fileBase}?w=768${fileExt} 768w`,
            `${fileBase}?w=640${fileExt} 640w`
          ].join(', ');
          
          newImg.setAttribute('srcset', srcset);
          newImg.setAttribute('sizes', '(max-width: 640px) 100vw, (max-width: 768px) 85vw, (max-width: 1024px) 75vw, 60vw');
        }
        
        // Add image to figure
        figure.appendChild(newImg);
        
        // Add caption if alt text exists
        if (alt) {
          const figcaption = document.createElement('figcaption');
          figcaption.className = 'mt-2 text-center text-sm text-gray-500';
          figcaption.textContent = alt;
          figure.appendChild(figcaption);
        }
        
        // Replace original image with figure
        img.parentNode?.replaceChild(figure, img);
      });
    }
  }, [post.content]);

  return (
    <article className="bg-white rounded-xl shadow-lg overflow-hidden">
      <div className="p-4 sm:p-6 md:p-8 lg:p-10">
        {/* Tags */}
        <div className="flex flex-wrap gap-1 sm:gap-2 mb-6 sm:mb-8">
          {post.tags.map(tag => (
            <span
              key={tag}
              className="inline-flex items-center px-2 sm:px-3 py-0.5 sm:py-1 rounded-full text-xs sm:text-sm font-medium bg-gray-100 text-gray-800"
            >
              <Tag className="w-3 h-3 sm:w-4 sm:h-4 mr-1" />
              {tag}
            </span>
          ))}
        </div>

        {/* Content */}
        <div 
          ref={contentRef}
          className="prose prose-sm sm:prose-base md:prose-lg max-w-none mx-auto px-0 sm:px-0"
          dangerouslySetInnerHTML={{ __html: post.content }}
        />

        {/* Custom CTA if available */}
        {post.customCTA && (
          <div className="my-6 sm:my-8">
            <CTABox 
              title={post.customCTA.title}
              description={post.customCTA.description}
              primaryButtonText={post.customCTA.primaryButtonText}
              primaryButtonUrl={post.customCTA.primaryButtonUrl}
              secondaryButtonText={post.customCTA.secondaryButtonText}
              secondaryButtonUrl={post.customCTA.secondaryButtonUrl}
              type={post.customCTA.type}
            />
          </div>
        )}
      </div>
    </article>
  );
}