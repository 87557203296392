import { FileSearch } from 'lucide-react';
import type { Card, CardDetails } from '../types';

export const DEBT_MANAGEMENT_CARD: Card = {
  id: "debt-management",
  icon: FileSearch,
  title: "Audyt i analiza zadłużenia firmy",
  description: "Kompleksowa ocena obecnych zobowiązań kredytowych i rekomendacje optymalizacyjne",
  benefits: [
    "Identyfikacja kredytów o niekorzystnych parametrach",
    "Ocena możliwości restrukturyzacji bez dodatkowych kosztów",
    "Konkretne rekomendacje optymalizacyjne"
  ],
  stats: {
    value: "15%",
    label: "Średnia możliwa redukcja kosztów finansowania"
  },
  cta: {
    label: "Sprawdź możliwe oszczędności dla Twojej firmy",
    action: () => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })
  }
};

export const DEBT_MANAGEMENT_DETAILS: CardDetails = {
  title: "Audyt i analiza zadłużenia firmy",
  tagline: "Pełna przejrzystość Twoich zobowiązań finansowych",
  description: "Kompleksowa analiza aktualnych zobowiązań kredytowych Państwa firmy pozwala zidentyfikować obszary potencjalnych oszczędności, ukryte koszty oraz możliwości restrukturyzacji. Dostarczamy konkretne rekomendacje dotyczące optymalizacji struktury zadłużenia, które realnie poprawiają płynność finansową i redukują koszty obsługi zobowiązań.",
  cta: {
    phone: "539 211 881",
    text: "Zadzwoń i dowiedz się, jak możemy pomóc w optymalizacji zobowiązań finansowych Twojej firmy"
  },
  benefitHighlights: [
    "Pełna analiza kosztów finansowania i harmonogramów spłat",
    "Identyfikacja ukrytych opłat i niekorzystnych zapisów w umowach",
    "Określenie potencjalnych oszczędności w perspektywie krótko- i długoterminowej",
    "Strategia optymalizacji struktury zadłużenia"
  ],
  whyUs: [
    "Wykorzystujemy zaawansowane narzędzia analityczne do oceny efektywności struktury zadłużenia",
    "Posiadamy doświadczenie w optymalizacji finansowania dla firm z różnych branż",
    "Zapewniamy bezstronną analizę, niezależną od konkretnych instytucji finansowych",
    "Nasz zespół ma doświadczenie bankowo-finansowe po obu stronach rynku"
  ],
  processComparison: {
    withUs: [
      {
        step: "Kompleksowy audyt zobowiązań",
        description: "Szczegółowa analiza wszystkich kredytów, leasingów i innych zobowiązań",
        icon: "FileSearch",
        highlight: true
      },
      {
        step: "Profesjonalna ocena kosztów",
        description: "Identyfikacja jawnych i ukrytych kosztów finansowania",
        icon: "Calculator"
      },
      {
        step: "Rekomendacje optymalizacyjne",
        description: "Konkretne propozycje działań z wyliczonymi korzyściami",
        icon: "FileCheck"
      },
      {
        step: "Wsparcie we wdrożeniu",
        description: "Pomoc w realizacji rekomendowanych zmian",
        icon: "CheckCircle",
        highlight: true
      }
    ],
    withoutUs: [
      {
        step: "Fragmentaryczna ocena",
        description: "Brak całościowego spojrzenia na strukturę zadłużenia",
        icon: "FileX",
        warning: true
      },
      {
        step: "Niewidoczne koszty",
        description: "Trudność w identyfikacji wszystkich ukrytych opłat",
        icon: "AlertTriangle",
        warning: true
      },
      {
        step: "Brak strategii optymalizacji",
        description: "Chaotyczne podejście do zarządzania zobowiązaniami",
        icon: "Shuffle",
        warning: true
      },
      {
        step: "Utracone oszczędności",
        description: "Niewykorzystany potencjał redukcji kosztów finansowania",
        icon: "DollarSign",
        warning: true
      }
    ]
  },
  calculations: {
    example: "Audyt zadłużenia firmy z sektora MŚP o łącznej wartości zobowiązań 5 mln zł (2025)",
    before: "Sytuacja przed audytem:\n• Kredyt inwestycyjny: 2,5 mln zł, WIBOR 5,81% + marża 3,0% = 8,81%\n• Leasing maszyn: 1,2 mln zł, efektywne oprocentowanie 9,2%\n• Limit w rachunku: 800 tys. zł, WIBOR 5,81% + marża 4,0% = 9,81%\n• Faktoring: 500 tys. zł, efektywny koszt 11,5%\n• Łączny roczny koszt obsługi: około 452 tys. zł\n• Obciążenie przepływów finansowych nierównomierne, kumulacja spłat w określonych miesiącach\n• Brak skoordynowanej strategii zarządzania zadłużeniem",
    after: "Po wdrożeniu rekomendacji audytowych:\n• Refinansowanie kredytu inwestycyjnego: 2,5 mln zł, WIBOR 5,81% + marża 1,9% = 7,71%\n• Renegocjacja warunków leasingu do efektywnego kosztu 8,4%\n• Limit w rachunku: 800 tys. zł, WIBOR 5,81% + marża 2,2% = 8,01%\n• Zmiana dostawcy usług faktoringowych: efektywny koszt 9,5%\n• Optymalizacja harmonogramów spłat z uwzględnieniem sezonowości biznesu\n• Łączny roczny koszt obsługi: około 383 tys. zł",
    savings: "Osiągnięte korzyści: roczna oszczędność około 69 tys. zł (15,3% całkowitych kosztów finansowania), lepsze dopasowanie harmonogramów spłat do przepływów finansowych, zmniejszenie ryzyka utraty płynności, uwolnienie części zabezpieczeń majątkowych",
    extra: "Dodatkowe korzyści: uporządkowanie dokumentacji kredytowej, eliminacja zbędnych produktów finansowych, przygotowanie strategii finansowania na kolejne 3 lata z uwzględnieniem planów rozwojowych, poprawa wskaźników finansowych wpływających na scoring kredytowy, co ułatwi pozyskanie nowego finansowania w przyszłości"
  },
  benefits: [
    "Kompleksowa analiza wszystkich zobowiązań kredytowych firmy",
    "Identyfikacja kredytów i leasingów o niekorzystnych parametrach",
    "Ocena możliwości refinansowania lub restrukturyzacji bez dodatkowych kosztów",
    "Analiza harmonogramów spłat w kontekście przepływów finansowych firmy",
    "Ocena wskaźników finansowych wpływających na scoring kredytowy",
    "Identyfikacja ukrytych opłat i prowizji w umowach kredytowych",
    "Sporządzenie szczegółowego raportu z konkretnymi rekomendacjami",
    "Określenie potencjalnych oszczędności w skali roku oraz długoterminowo"
  ],
  limitedTimeOffer: {
    text: "Wstępna analiza potencjalnych oszczędności bez opłat dla firm z obrotem powyżej 2 mln zł",
    expiry: "30.04.2025"
  },
  faq: [
    {
      question: "Jak wygląda proces audytu zadłużenia mojej firmy?",
      answer: "Proces składa się z czterech głównych etapów: 1) Zebranie i analiza dokumentacji - klient dostarcza umowy kredytowe, leasingowe, harmonogramy spłat, historię operacji oraz sprawozdania finansowe; 2) Audyt właściwy - nasz zespół przeprowadza szczegółową analizę wszystkich dokumentów, identyfikując obszary potencjalnej optymalizacji; 3) Opracowanie rekomendacji - przygotowujemy raport zawierający konkretne propozycje zmian wraz z wyliczeniem potencjalnych korzyści; 4) Prezentacja wyników - podczas spotkania omawiamy wyniki audytu i proponowane działania optymalizacyjne. Cały proces trwa zwykle 2-3 tygodnie, w zależności od wielkości i złożoności struktury zadłużenia firmy."
    },
    {
      question: "Jakie dokumenty są potrzebne do przeprowadzenia audytu?",
      answer: "Do kompleksowego audytu zadłużenia potrzebujemy: 1) Kopii wszystkich umów kredytowych, leasingowych i faktoringowych; 2) Aktualnych harmonogramów spłat; 3) Wyciągów z rachunków firmowych za ostatnie 6-12 miesięcy; 4) Sprawozdań finansowych za ostatnie 2-3 lata; 5) Listy aktualnych zabezpieczeń kredytowych; 6) Informacji o planowanych inwestycjach i potrzebach finansowych na najbliższe 1-2 lata. Zapewniamy pełną poufność przekazanych dokumentów zgodnie z obowiązującymi przepisami RODO oraz podpisaną umową o zachowaniu poufności (NDA)."
    },
    {
      question: "Czy istnieją firmy, dla których audyt zadłużenia nie przyniesie korzyści?",
      answer: "Audyt zadłużenia może przynieść mniejsze korzyści w przypadku: 1) Firm, które niedawno (w ciągu ostatnich 6 miesięcy) przeprowadziły restrukturyzację lub refinansowanie zobowiązań na konkurencyjnych warunkach; 2) Przedsiębiorstw z bardzo niskim poziomem zadłużenia (poniżej 500 tys. zł); 3) Firm korzystających wyłącznie z preferencyjnych kredytów dotowanych lub z programów pomocowych. Jednak nawet w tych przypadkach audyt może dostarczyć wartościowych informacji dotyczących strategii zarządzania finansami w przyszłości. Przed rozpoczęciem pełnego audytu przeprowadzamy wstępną ocenę, która pozwala określić potencjał oszczędności - dzięki temu klient wie z wyprzedzeniem, czy pełny audyt będzie dla niego opłacalny."
    },
    {
      question: "Jakie typowe problemy z finansowaniem firm identyfikujecie podczas audytów?",
      answer: "Najczęściej spotykane problemy to: 1) Nieoptymalna struktura finansowania - np. finansowanie długoterminowych inwestycji krótkimi kredytami obrotowymi; 2) Niekorzystne zapisy w umowach - np. klauzule umożliwiające jednostronną zmianę warunków przez bank; 3) Nadmierne zabezpieczenia - blokowanie większej wartości majątku niż wymagane; 4) Ukryte opłaty i prowizje - szczególnie w produktach leasingowych i faktoringowych; 5) Brak wykorzystania potencjału negocjacyjnego - firmy często nie negocjują warunków z bankami; 6) Niedopasowanie harmonogramów spłat do sezonowości biznesu; 7) Niewłaściwe zarządzanie płynnością - utrzymywanie wysokich środków na nieoprocentowanych rachunkach przy jednoczesnym korzystaniu z drogich kredytów. Nasze analizy wskazują, że około 78% firm z sektora MŚP ma co najmniej dwa z wymienionych problemów w swojej strukturze finansowania."
    },
    {
      question: "W jaki sposób rozliczane jest wynagrodzenie za audyt zadłużenia?",
      answer: "Oferujemy dwa modele rozliczeń: 1) Model standardowy - stała opłata uzależniona od wielkości firmy i złożoności struktury zadłużenia (zwykle od 5 000 do 15 000 zł netto); 2) Model success fee - niższa opłata podstawowa plus prowizja od faktycznie zrealizowanych oszczędności w pierwszym roku po wdrożeniu rekomendacji (zwykle 15-20% wartości oszczędności). W obydwu przypadkach najpierw przeprowadzamy bezpłatną wstępną ocenę potencjału oszczędności, która pozwala klientowi zdecydować, czy chce przystąpić do pełnego audytu. Dodatkowo, dla firm z obrotem powyżej 8 mln zł rocznie oferujemy opcję rozłożenia płatności na raty."
    },
    {
      question: "Czy faktycznie możliwe jest refinansowanie kredytów bez dodatkowych kosztów?",
      answer: "Tak, zgodnie z aktualnym stanem prawnym (marzec 2025), art. 49 Ustawy o kredycie hipotecznym oraz art. 36a i 36c Ustawy Prawo Bankowe dają przedsiębiorcom prawo do wcześniejszej spłaty kredytu środkami z nowego finansowania bez ponoszenia dodatkowych opłat, jeśli odpowiednia informacja została przekazana bankowi z wyprzedzeniem (na ogół 3 miesiące). W praktyce, nawet jeśli umowa kredytowa zawiera opłaty za wcześniejszą spłatę, często można je negocjować lub całkowicie wyeliminować - szczególnie w przypadku wieloletniej współpracy z bankiem. Nasi doradcy posiadają doświadczenie w negocjacjach z bankami i w większości przypadków potrafią doprowadzić do zniesienia lub istotnego obniżenia potencjalnych opłat związanych z refinansowaniem."
    },
    {
      question: "Jak szybko można zrealizować oszczędności zidentyfikowane podczas audytu?",
      answer: "Tempo realizacji oszczędności zależy od rodzaju rekomendacji: 1) Szybkie oszczędności (1-2 miesiące): renegocjacja warunków istniejących umów, optymalizacja wykorzystania limitów w rachunku, zmiana struktury zabezpieczeń; 2) Średnioterminowe oszczędności (2-4 miesiące): refinansowanie kredytów, zmiana dostawców usług leasingowych lub faktoringowych; 3) Długoterminowe oszczędności (4-6 miesięcy): kompleksowa restrukturyzacja zadłużenia, przebudowa całej struktury finansowania. Średnio nasi klienci realizują około 35-40% zidentyfikowanych oszczędności w ciągu pierwszych trzech miesięcy od zakończenia audytu, a pełną implementację rekomendacji zwykle osiągają w ciągu 6 miesięcy."
    },
    {
      question: "Czy pomagacie również we wdrożeniu rekomendacji z audytu?",
      answer: "Tak, oferujemy kompleksowe wsparcie we wdrażaniu rekomendacji audytowych. Nasze wsparcie obejmuje: 1) Przygotowanie konkretnej strategii wdrożenia zmian z harmonogramem działań; 2) Pomoc w negocjacjach z bankami i innymi instytucjami finansowymi; 3) Przygotowanie wymaganej dokumentacji do refinansowania lub restrukturyzacji; 4) Koordynację procesu zmiany zabezpieczeń; 5) Monitoring wdrożenia dla zapewnienia realizacji zakładanych korzyści. Wsparcie we wdrożeniu może być dodatkowo płatne lub wliczone w cenę audytu - w zależności od wybranego pakietu usług. Nasz model działania opiera się na partnerstwie, dlatego zależy nam na faktycznej realizacji zidentyfikowanych oszczędności, a nie tylko na dostarczeniu teoretycznych rekomendacji."
    }
  ],
  nextSteps: [
    "Skontaktuj się z nami telefonicznie lub przez formularz",
    "Umów się na bezpłatną wstępną konsultację",
    "Przygotuj podstawową dokumentację dotyczącą zobowiązań",
    "Otrzymaj wstępną ocenę potencjału oszczędności",
    "Zdecyduj o przystąpieniu do pełnego audytu",
    "Uzyskaj szczegółowy raport z rekomendacjami",
    "Wdrażaj optymalne rozwiązania z naszym wsparciem"
  ]
};