export * from './business/investment-loan';
export * from './business/working-capital';
export * from './business/credit-line';

import { INVESTMENT_LOAN_CARD, INVESTMENT_LOAN_DETAILS } from './business/investment-loan';
import { WORKING_CAPITAL_CARD, WORKING_CAPITAL_DETAILS } from './business/working-capital';
import { CREDIT_LINE_CARD, CREDIT_LINE_DETAILS } from './business/credit-line';

export const BUSINESS_CARDS = [
  INVESTMENT_LOAN_CARD,
  WORKING_CAPITAL_CARD,
  CREDIT_LINE_CARD
];

export const BUSINESS_DETAILS = {
  "investment-loan": INVESTMENT_LOAN_DETAILS,
  "working-capital": WORKING_CAPITAL_DETAILS,
  "credit-line": CREDIT_LINE_DETAILS
};