import React, { useEffect, useState } from 'react';
import { List } from 'lucide-react';

interface Heading {
  id: string;
  text: string;
  level: number;
}

interface TableOfContentsProps {
  headings: Heading[];
  activeHeading: string | null;
}

export default function TableOfContents({ headings, activeHeading }: TableOfContentsProps) {
  const [isSticky, setIsSticky] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  
  // Handle scroll to make TOC sticky
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsSticky(scrollPosition > 300);
    };
    
    // Throttle scroll event
    let ticking = false;
    const throttledHandleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    };
    
    window.addEventListener('scroll', throttledHandleScroll, { passive: true });
    return () => window.removeEventListener('scroll', throttledHandleScroll);
  }, []);

  // Scroll to heading when clicked
  const scrollToHeading = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 100; // Adjust for fixed header
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
      
      // On mobile, collapse the TOC after clicking
      if (window.innerWidth < 768) {
        setIsExpanded(false);
      }
    }
  };

  if (headings.length === 0) {
    return null;
  }

  return (
    <div className={`bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 ${
      isSticky ? 'lg:sticky lg:top-24' : ''
    }`}>
      <div className="p-4 sm:p-6">
        {/* Header - clickable on mobile to expand/collapse */}
        <div 
          className="flex items-center gap-2 mb-2 sm:mb-4 cursor-pointer md:cursor-default touch-manipulation"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <List className="w-5 h-5 text-[#CBA331]" />
          <h2 className="text-base sm:text-lg font-bold text-[#003A30]">Spis treści</h2>
          <svg 
            className={`w-4 h-4 ml-auto text-gray-500 transition-transform duration-300 md:hidden ${isExpanded ? 'rotate-180' : ''}`} 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </div>
        
        {/* Navigation - collapsible on mobile */}
        <nav className={`overflow-hidden transition-all duration-300 ${
          isExpanded || window.innerWidth >= 768 ? 'max-h-96' : 'max-h-0 md:max-h-96'
        }`}>
          <ul className="space-y-1 sm:space-y-2">
            {headings.map((heading) => (
              <li 
                key={heading.id}
                className={`transition-all duration-200 ${
                  heading.level === 2 ? 'ml-0' : 'ml-2 sm:ml-4'
                }`}
              >
                <button
                  onClick={() => scrollToHeading(heading.id)}
                  className={`text-left w-full px-1 sm:px-2 py-1 rounded-md text-xs sm:text-sm hover:bg-gray-100 transition-colors duration-200 touch-manipulation ${
                    activeHeading === heading.id
                      ? 'font-medium text-[#003A30] bg-gray-100'
                      : 'text-gray-600'
                  }`}
                >
                  <span className="line-clamp-2">{heading.text}</span>
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
}