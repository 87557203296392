import React, { useState } from 'react';
import { Share2, Facebook, Twitter, Linkedin, Link2, Check } from 'lucide-react';

interface SocialShareProps {
  title: string;
  url: string;
  className?: string;
}

export default function SocialShare({ title, url, className = '' }: SocialShareProps) {
  const [copied, setCopied] = useState(false);
  
  const encodedTitle = encodeURIComponent(title);
  const encodedUrl = encodeURIComponent(url);
  
  const shareLinks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
    twitter: `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`
  };
  
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <div className={`bg-white rounded-xl shadow-sm overflow-hidden border border-gray-100 ${className}`}>
      <div className="p-5">
        <div className="flex items-center gap-2 mb-4">
          <Share2 className="w-5 h-5 text-[#CBA331]" />
          <h2 className="text-base font-semibold text-[#003A30]">Udostępnij artykuł</h2>
        </div>
        
        <div className="flex gap-3">
          <a 
            href={shareLinks.facebook}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center w-10 h-10 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-200 shadow-sm"
            aria-label="Udostępnij na Facebooku"
          >
            <Facebook className="w-5 h-5" />
          </a>
          
          <a 
            href={shareLinks.twitter}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center w-10 h-10 rounded-lg bg-sky-500 text-white hover:bg-sky-600 transition-colors duration-200 shadow-sm"
            aria-label="Udostępnij na Twitterze"
          >
            <Twitter className="w-5 h-5" />
          </a>
          
          <a 
            href={shareLinks.linkedin}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center w-10 h-10 rounded-lg bg-blue-700 text-white hover:bg-blue-800 transition-colors duration-200 shadow-sm"
            aria-label="Udostępnij na LinkedIn"
          >
            <Linkedin className="w-5 h-5" />
          </a>
          
          <button
            onClick={copyToClipboard}
            className={`flex items-center justify-center w-10 h-10 rounded-lg transition-colors duration-200 shadow-sm ${
              copied 
                ? 'bg-green-500 text-white' 
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
            aria-label="Kopiuj link"
          >
            {copied ? <Check className="w-5 h-5" /> : <Link2 className="w-5 h-5" />}
          </button>
        </div>
      </div>
    </div>
  );
}