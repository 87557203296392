import React, { Suspense } from 'react';
import { BadgePercent, FileText, HelpCircle, CheckCircle2, Clock, ArrowRight, PhoneCall, Medal, Shield, Star, ThumbsUp, TrendingUp, Users, AlertTriangle, BarChart, Calendar, ClipboardCheck, Hourglass, Search, Lightbulb, CreditCard, PiggyBank, Compass } from 'lucide-react';
import LazySection from './LazySection';

interface ModalDetails {
  title: string;
  tagline: string; // Krótki, chwytliwy slogan
  description: string;
  cta: { // Call to Action
    phone: string;
    text: string;
    urgencyNote?: string; // np. "Tylko do końca miesiąca!"
  };
  benefitHighlights: string[]; // 3-4 najważniejsze korzyści do wyświetlenia na górze
  whyUs: string[];
  processComparison: { // Porównanie procesu realizacji kredytu
    withUs: Array<{
      step: string;
      description: string;
      icon?: string; // Nazwa ikony Lucide
      highlight?: boolean; // Czy krok powinien być wyróżniony
    }>;
    withoutUs: Array<{
      step: string;
      description: string;
      icon?: string;
      warning?: boolean; // Czy krok powinien mieć ostrzeżenie
    }>;
  };
  calculations: {
    example: string;
    before: string;
    after: string;
    savings?: string;
    extra?: string;
  };
  benefits: string[];
  limitedTimeOffer?: { // Oferta ograniczona czasowo
    text: string;
    expiry?: string;
  };
  faq: Array<{
    question: string;
    answer: string;
  }>;
  nextSteps: string[];
}

interface ModalContentProps {
  details?: ModalDetails;
}

const LoadingSpinner = () => (
  <div className="flex justify-center items-center py-4">
    <div className="w-8 h-8 border-4 border-[#003A30]/20 border-t-[#003A30] rounded-full animate-spin"></div>
  </div>
);

// Function to handle phone call
const handlePhoneCall = (phoneNumber) => {
  window.location.href = `tel:${phoneNumber.replace(/\s+/g, '')}`;
};

export default function ModalContent({ details }: ModalContentProps) {
  if (!details) {
    return (
      <div className="p-6 text-center text-gray-500">
        <div className="w-12 h-12 mx-auto mb-4 bg-gray-100 rounded-full flex items-center justify-center">
          <HelpCircle className="w-6 h-6 text-gray-400" />
        </div>
        <p>Nie znaleziono szczegółowych informacji.</p>
      </div>
    );
  }

  // Update phone number
  const phoneNumber = "539 211 881";

  return (
    <div className="space-y-6">
      {/* ATTENTION: Header with CTA */}
      <LazySection>
        <div className="bg-gradient-to-r from-[#003A30] to-[#0B5D4E] text-white rounded-xl p-6">
          <h3 className="text-xl font-bold mb-1">{details.title}</h3>
          <p className="text-[#CBA331] font-medium mb-3">{details.tagline}</p>
          
          <p className="text-gray-100 mb-4">{details.description}</p>
          
          <div className="bg-white/10 backdrop-blur rounded-lg p-4 mb-4 border border-white/20">
            <div className="flex items-center gap-2 mb-2">
              <PhoneCall className="w-5 h-5 text-[#CBA331]" />
              <span className="font-bold text-lg">{phoneNumber}</span>
            </div>
            <p className="text-sm">{details.cta.text}</p>
            {details.cta.urgencyNote && (
              <div className="flex items-center gap-2 mt-2 bg-[#CBA331]/20 p-2 rounded text-sm">
                <Clock className="w-4 h-4 text-[#CBA331]" />
                <span className="font-medium">{details.cta.urgencyNote}</span>
              </div>
            )}
          </div>
          
          {/* Benefit Highlights - krótka lista głównych korzyści */}
          <div className="grid grid-cols-2 gap-3">
            {details.benefitHighlights.map((highlight, index) => (
              <div key={index} className="flex items-start gap-2">
                <CheckCircle2 className="w-5 h-5 text-[#CBA331] flex-shrink-0 mt-0.5" />
                <span className="text-sm font-medium">{highlight}</span>
              </div>
            ))}
          </div>
        </div>
      </LazySection>

      {/* Sekcja social proof została usunięta zgodnie z prośbą */}

      {/* Limited Time Offer */}
      {details.limitedTimeOffer && (
        <LazySection delay={150}>
          <div className="bg-[#CBA331]/10 border border-[#CBA331]/20 rounded-xl p-4">
            <div className="flex items-center gap-3">
              <Clock className="w-6 h-6 text-[#CBA331]" />
              <div>
                <h4 className="font-bold text-[#003A30]">Oferta ograniczona czasowo</h4>
                <p className="text-[#0B5D4E]">{details.limitedTimeOffer.text}</p>
                {details.limitedTimeOffer.expiry && (
                  <p className="text-sm font-medium text-[#CBA331] mt-1">Ważne do: {details.limitedTimeOffer.expiry}</p>
                )}
              </div>
            </div>
          </div>
        </LazySection>
      )}
      
      {/* Process Comparison - porównanie realizacji kredytu */}
      <LazySection delay={180}>
        <div className="space-y-4">
          <div className="flex items-center gap-3 mb-2">
            <Compass className="w-6 h-6 text-[#CBA331]" />
            <h4 className="text-lg font-bold text-[#003A30]">Droga do kredytu</h4>
          </div>
          
          <div className="grid md:grid-cols-2 gap-6">
            {/* Z nami */}
            <div className="bg-white rounded-xl p-5 border-2 border-[#003A30]/10 relative">
              <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#003A30] text-white px-4 py-1 rounded-full font-bold text-sm">
                Z nami
              </div>
              
              <ul className="space-y-4 mt-4">
                {details.processComparison.withUs.map((step, index) => {
                  const IconComponent = step.icon ? 
                    (step.icon === 'ClipboardCheck' ? ClipboardCheck : 
                     step.icon === 'Search' ? Search :
                     step.icon === 'PiggyBank' ? PiggyBank :
                     step.icon === 'CreditCard' ? CreditCard :
                     step.icon === 'Lightbulb' ? Lightbulb : CheckCircle2) : CheckCircle2;
                    
                  return (
                    <li key={index} className={`flex items-start gap-3 ${step.highlight ? 'bg-[#003A30]/5 p-3 rounded-lg border border-[#003A30]/10' : 'p-1'}`}>
                      <div className={`w-7 h-7 rounded-full flex items-center justify-center flex-shrink-0 ${step.highlight ? 'bg-[#003A30] text-white' : 'bg-[#003A30]/10'}`}>
                        <IconComponent className={`w-4 h-4 ${step.highlight ? 'text-white' : 'text-[#003A30]'}`} />
                      </div>
                      <div>
                        <p className="font-medium text-[#003A30]">{step.step}</p>
                        <p className="text-sm text-gray-600">{step.description}</p>
                      </div>
                    </li>
                  );
                })}
              </ul>
              
              <div className="mt-5 border-t border-gray-100 pt-4">
                <div className="flex items-center gap-2 text-[#003A30] font-medium">
                  <Hourglass className="w-5 h-5 text-[#CBA331]" />
                  <span>Średni czas: 2-4 tygodnie</span>
                </div>
              </div>
            </div>
            
            {/* Bez nas */}
            <div className="bg-white rounded-xl p-5 border-2 border-red-100 relative">
              <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red-500 text-white px-4 py-1 rounded-full font-bold text-sm">
                Bez nas
              </div>
              
              <ul className="space-y-4 mt-4">
                {details.processComparison.withoutUs.map((step, index) => {
                  const IconComponent = step.icon ? 
                    (step.icon === 'AlertTriangle' ? AlertTriangle : 
                     step.icon === 'Hourglass' ? Hourglass :
                     step.icon === 'Search' ? Search :
                     step.icon === 'BarChart' ? BarChart :
                     step.icon === 'Calendar' ? Calendar : CheckCircle2) : CheckCircle2;
                    
                  return (
                    <li key={index} className={`flex items-start gap-3 ${step.warning ? 'bg-red-50 p-3 rounded-lg border border-red-100' : 'p-1'}`}>
                      <div className={`w-7 h-7 rounded-full flex items-center justify-center flex-shrink-0 ${step.warning ? 'bg-red-500 text-white' : 'bg-gray-100'}`}>
                        <IconComponent className={`w-4 h-4 ${step.warning ? 'text-white' : 'text-gray-500'}`} />
                      </div>
                      <div>
                        <p className="font-medium text-gray-700">{step.step}</p>
                        <p className="text-sm text-gray-600">{step.description}</p>
                      </div>
                    </li>
                  );
                })}
              </ul>
              
              <div className="mt-5 border-t border-gray-100 pt-4">
                <div className="flex items-center gap-2 text-red-600 font-medium">
                  <Hourglass className="w-5 h-5" />
                  <span>Średni czas: 8-12 tygodni</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazySection>

      {/* DESIRE: Calculations - pokazujemy konkretne korzyści finansowe */}
      <LazySection delay={200}>
        <div className="bg-gray-50 rounded-xl p-6 space-y-4">
          <div className="flex items-center gap-3 mb-4">
            <BadgePercent className="w-6 h-6 text-[#CBA331]" />
            <h4 className="text-lg font-bold text-[#003A30]">Ile zaoszczędzisz?</h4>
          </div>
          <p className="text-sm text-gray-600">{details.calculations.example}</p>
          <div className="grid md:grid-cols-2 gap-4">
            <div className="bg-white rounded-lg p-4 border border-red-100">
              <div className="text-red-600 font-medium mb-2">Bez nas:</div>
              <div className="text-gray-600 whitespace-pre-line">{details.calculations.before}</div>
            </div>
            <div className="bg-white rounded-lg p-4 border border-emerald-100">
              <div className="text-emerald-600 font-medium mb-2">Z nami:</div>
              <div className="text-gray-600 whitespace-pre-line">{details.calculations.after}</div>
            </div>
          </div>
          {details.calculations.savings && (
            <div className="bg-emerald-50 text-emerald-700 font-medium p-4 rounded-lg border border-emerald-100">
              <div className="flex items-center gap-2">
                <TrendingUp className="w-5 h-5" />
                <span>{details.calculations.savings}</span>
              </div>
            </div>
          )}
          {details.calculations.extra && (
            <div className="bg-blue-50 text-blue-700 font-medium p-4 rounded-lg border border-blue-100">
              <div className="flex items-center gap-2">
                <ThumbsUp className="w-5 h-5" />
                <span>{details.calculations.extra}</span>
              </div>
            </div>
          )}
        </div>
      </LazySection>

      {/* Why Us */}
      <LazySection delay={300}>
        <div className="bg-[#003A30]/5 rounded-xl p-6 space-y-4">
          <div className="flex items-center gap-3 mb-4">
            <Medal className="w-6 h-6 text-[#CBA331]" />
            <h4 className="text-lg font-bold text-[#003A30]">Dlaczego warto z nami współpracować?</h4>
          </div>
          <ul className="space-y-3">
            {details.whyUs.map((point, index) => (
              <li key={index} className="flex items-start gap-3">
                <div className="w-6 h-6 rounded-full bg-[#CBA331]/20 flex items-center justify-center flex-shrink-0">
                  <CheckCircle2 className="w-4 h-4 text-[#CBA331]" />
                </div>
                <span className="text-gray-700">{point}</span>
              </li>
            ))}
          </ul>
        </div>
      </LazySection>

      {/* Benefits */}
      <LazySection delay={400}>
        <div>
          <div className="flex items-center gap-3 mb-4">
            <Shield className="w-6 h-6 text-[#CBA331]" />
            <h4 className="text-lg font-bold text-[#003A30]">Korzyści dla Ciebie</h4>
          </div>
          <div className="grid md:grid-cols-2 gap-4">
            {details.benefits.map((benefit, index) => (
              <div key={index} className="flex items-start gap-3 bg-white p-3 rounded-lg border border-gray-100">
                <div className="w-6 h-6 rounded-full bg-[#003A30]/10 flex items-center justify-center flex-shrink-0">
                  <CheckCircle2 className="w-4 h-4 text-[#003A30]" />
                </div>
                <span className="text-gray-700">{benefit}</span>
              </div>
            ))}
          </div>
        </div>
      </LazySection>

      {/* Sekcje z historiami sukcesu i wymaganymi dokumentami zostały usunięte */}

      {/* FAQ */}
      <LazySection delay={600}>
        <div>
          <div className="flex items-center gap-3 mb-4">
            <HelpCircle className="w-6 h-6 text-[#CBA331]" />
            <h4 className="text-lg font-bold text-[#003A30]">Najczęściej zadawane pytania</h4>
          </div>
          <div className="space-y-3">
            {details.faq.map((item, index) => (
              <div key={index} className="bg-white rounded-lg p-4 border border-gray-100">
                <h5 className="font-medium text-[#003A30] mb-2 flex items-start gap-2">
                  <span className="text-[#CBA331]">Q:</span>
                  <span>{item.question}</span>
                </h5>
                <p className="text-gray-600 text-sm pl-5">{item.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </LazySection>

      {/* ACTION: Next Steps + CTA */}
      <LazySection delay={700}>
        <div className="bg-[#003A30]/5 rounded-xl p-6">
          <div className="flex items-center gap-3 mb-4">
            <ArrowRight className="w-6 h-6 text-[#CBA331]" />
            <h4 className="text-lg font-bold text-[#003A30]">Jak zacząć?</h4>
          </div>
          <ol className="space-y-3 mb-6">
            {details.nextSteps.map((step, index) => (
              <li key={index} className="flex items-start gap-3">
                <span className="flex items-center justify-center w-7 h-7 rounded-full bg-[#003A30] text-white text-sm font-medium flex-shrink-0">
                  {index + 1}
                </span>
                <span className="text-gray-700 pt-1">{step}</span>
              </li>
            ))}
          </ol>
          
          {/* Final CTA */}
          <div className="bg-[#003A30] text-white rounded-lg p-5 text-center">
            <h4 className="font-bold text-lg mb-2">Zadzwoń i uzyskaj bezpłatną konsultację</h4>
            <div className="text-2xl font-bold text-[#CBA331] mb-3">{phoneNumber}</div>
            <p className="text-gray-200 mb-3">{details.cta.text}</p>
            <button 
              type="button"
              className="bg-[#CBA331] hover:bg-[#B89321] text-[#003A30] font-bold py-3 px-6 rounded-lg transition duration-200 w-full md:w-auto"
              onClick={() => handlePhoneCall(phoneNumber)}
            >
              Zadzwoń teraz
            </button>
          </div>
        </div>
      </LazySection>
    </div>
  );
}