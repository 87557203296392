import React from 'react';
import Hero from './Hero';
import HomePageNavigation from './HomePageNavigation';
import SocialProof from '@/components/shared/SocialProof';
import Services from './Services';
import Calculators from './Calculators';
import Process from '@/components/shared/Process';
import Contact from '@/components/shared/Contact';
import ProblemsSolutions from '@/components/shared/ProblemsSolutions';
import Testimonials from './Testimonials';
import { homeProblemsData } from '@/data/ProblemsSolutionsData';
import { homeStats } from '@/data/SocialProofData';
import { Shield, Clock, Star } from 'lucide-react';

export default function HomePage() {
  return (
    <main className="bg-gray-50">
      <HomePageNavigation />
      
      {/* Hero Section */}
      <Hero 
        stats={[
          {
            icon: Shield,
            value: "Gwarancja",
            label: "Najlepszych warunków"
          },
          {
            icon: Clock,
            value: "24h",
            label: "Decyzja kredytowa"
          },
          {
            icon: Star,
            value: "99%",
            label: "Zadowolonych klientów"
          }
        ]}
      />

      {/* Social Proof */}
      <SocialProof stats={homeStats} />

      {/* Problems & Solutions */}
      <ProblemsSolutions 
        problems={homeProblemsData.problems}
        title={homeProblemsData.title}
        subtitle={homeProblemsData.subtitle}
      />

      {/* Services */}
      <section id="services">
        <Services />
      </section>

      {/* Calculators */}
      <section id="calculator">
        <Calculators />
      </section>

      {/* Process */}
      <section id="process">
        <Process />
      </section>

      {/* Testimonials */}
      <section id="testimonials">
        <Testimonials />
      </section>

      {/* Contact */}
      <section id="contact">
        <Contact />
      </section>
    </main>
  );
}