import React from 'react';
import { X } from 'lucide-react';

interface NavMobileButtonProps {
  isOpen: boolean;
  onClick: () => void;
}

export default function NavMobileButton({ isOpen, onClick }: NavMobileButtonProps) {
  return (
    <button
      className="relative z-50 w-10 h-10 flex items-center justify-center text-[#003A30] hover:text-[#CBA331] transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-[#003A30] focus:ring-offset-2 rounded-lg md:hidden touch-manipulation"
      onClick={onClick}
      aria-expanded={isOpen}
      aria-controls="mobile-menu"
      aria-label={isOpen ? "Zamknij menu" : "Otwórz menu"}
    >
      {isOpen ? (
        <X className="w-6 h-6 text-current" />
      ) : (
        <img 
          src="/menu-icon.svg" 
          alt="" 
          className="w-6 h-6"
          aria-hidden="true"
        />
      )}
    </button>
  );
}