import { Search, ListChecks, FileText, Building2, CheckCircle2, Clock, BadgePercent, Percent } from 'lucide-react';
import type { ProcessStepsData } from './types';

export const mortgageStepsData: ProcessStepsData = {
  title: "Prosty i przejrzysty proces",
  subtitle: "Przeprowadzimy Cię przez cały proces kredytowy, zapewniając profesjonalne wsparcie na każdym etapie",
  steps: [
    {
      number: 1,
      icon: Search,
      title: "Analiza potrzeb",
      description: "Poznajemy Twoje oczekiwania i sytuację finansową",
      features: [
        "Określenie budżetu i preferencji",
        "Ocena możliwości finansowych",
        "Wstępna symulacja kredytu"
      ],
      stats: {
        icon: Clock,
        value: "45 min",
        label: "Spokojna rozmowa"
      }
    },
    {
      number: 2,
      icon: ListChecks,
      title: "Wybór najlepszej oferty",
      description: "Prezentacja bezpiecznych i sprawdzonych rozwiązań",
      features: [
        "Porównanie stabilnych banków",
        "Analiza wszystkich kosztów",
        "Wybór bezpiecznej opcji"
      ],
      stats: {
        icon: Percent,
        value: "3-4",
        label: "Sprawdzone banki"
      }
    },
    {
      number: 3,
      icon: FileText,
      title: "Kompletna dokumentacja",
      description: "Pomagamy skompletować i sprawdzić wszystkie dokumenty",
      features: [
        "Przejrzysta lista dokumentów",
        "Wsparcie na każdym kroku",
        "Dokładna weryfikacja"
      ],
      stats: {
        icon: Clock,
        value: "3-5 dni",
        label: "Spokojne tempo"
      }
    },
    {
      number: 4,
      icon: Building2,
      title: "Pewna decyzja",
      description: "Składamy wniosek z najwyższą szansą akceptacji",
      features: [
        "Staranne przygotowanie",
        "Monitoring procesu",
        "Skuteczne negocjacje"
      ],
      stats: {
        icon: BadgePercent,
        value: "97%",
        label: "Pozytywnych decyzji"
      }
    },
    {
      number: 5,
      icon: CheckCircle2,
      title: "Bezpieczna finalizacja",
      description: "Doprowadzamy proces do szczęśliwego końca",
      features: [
        "Sprawdzenie umowy",
        "Asysta przy podpisaniu",
        "Kontrola wypłaty"
      ],
      stats: {
        icon: Clock,
        value: "10-14 dni",
        label: "Spokojny proces"
      }
    }
  ]
};