import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export default function Pagination({ currentPage, totalPages, onPageChange }: PaginationProps) {
  if (totalPages <= 1) return null;

  return (
    <nav className="flex justify-center mt-8" aria-label="Paginacja">
      <ul className="inline-flex items-center -space-x-px">
        <li>
          <button
            onClick={() => onPageChange(Math.max(1, currentPage - 1))}
            disabled={currentPage === 1}
            className={`relative inline-flex items-center px-3 py-2 rounded-l-md border ${
              currentPage === 1 
              ? 'border-gray-300 bg-white text-gray-300 cursor-not-allowed' 
              : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50'
            }`}
            aria-label="Poprzednia strona"
          >
            <span className="sr-only">Poprzednia</span>
            <ChevronLeft className="h-5 w-5" aria-hidden="true" />
          </button>
        </li>
        
        {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
          let pageNumber;
          if (totalPages <= 5) {
            pageNumber = i + 1;
          } else if (currentPage <= 3) {
            pageNumber = i + 1;
          } else if (currentPage >= totalPages - 2) {
            pageNumber = totalPages - 4 + i;
          } else {
            pageNumber = currentPage - 2 + i;
          }
          
          return (
            <li key={i}>
              <button
                onClick={() => onPageChange(pageNumber)}
                className={`relative inline-flex items-center px-4 py-2 border ${
                  currentPage === pageNumber
                    ? 'z-10 bg-[#003A30] border-[#003A30] text-white'
                    : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                }`}
                aria-current={currentPage === pageNumber ? 'page' : undefined}
                aria-label={`Strona ${pageNumber}`}
              >
                {pageNumber}
              </button>
            </li>
          );
        })}
        
        {totalPages > 5 && currentPage < totalPages - 2 && (
          <>
            <li>
              <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-gray-700">
                ...
              </span>
            </li>
            <li>
              <button
                onClick={() => onPageChange(totalPages)}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-gray-500 hover:bg-gray-50"
                aria-label={`Strona ${totalPages}`}
              >
                {totalPages}
              </button>
            </li>
          </>
        )}
        
        <li>
          <button
            onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
            disabled={currentPage === totalPages}
            className={`relative inline-flex items-center px-3 py-2 rounded-r-md border ${
              currentPage === totalPages 
              ? 'border-gray-300 bg-white text-gray-300 cursor-not-allowed' 
              : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50'
            }`}
            aria-label="Następna strona"
          >
            <span className="sr-only">Następna</span>
            <ChevronRight className="h-5 w-5" aria-hidden="true" />
          </button>
        </li>
      </ul>
    </nav>
  );
}