import React from 'react';
import Hero from '@/components/shared/Hero';
import { Files, FileCheck, Download, Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function Documents() {
  const navigate = useNavigate();

  const documentLists = [
    {
      category: "Kredyt hipoteczny",
      items: [
        "Dokumenty tożsamości",
        "Dokumenty potwierdzające dochód",
        "Dokumenty dotyczące nieruchomości",
        "Dokumenty dodatkowe dla firm",
        "Zaświadczenia i oświadczenia"
      ]
    },
    {
      category: "Kredyt firmowy",
      items: [
        "Dokumenty rejestrowe firmy",
        "Dokumenty finansowe",
        "Zaświadczenia ZUS i US",
        "Dokumenty zabezpieczeń",
        "Biznesplan (jeśli wymagany)"
      ]
    },
    {
      category: "Refinansowanie",
      items: [
        "Dokumenty obecnego kredytu",
        "Historia spłat",
        "Dokumenty dochodowe",
        "Zgody i oświadczenia",
        "Dokumenty zabezpieczeń"
      ]
    }
  ];

  return (
    <div className="pt-20 bg-gray-50">
      <Hero 
        title="Listy dokumentów do kredytu"
        highlightedWord="dokumentów"
        description="Kompletne listy dokumentów potrzebnych do różnych rodzajów kredytów. Przygotuj się do procesu kredytowego z naszymi listami kontrolnymi."
        image="https://images.unsplash.com/photo-1568992687947-868a62a9f521?auto=format&fit=crop&q=80"
        stats={[
          {
            icon: Files,
            value: "15+",
            label: "List dokumentów"
          },
          {
            icon: FileCheck,
            value: "100%",
            label: "Kompletność"
          },
          {
            icon: Clock,
            value: "-50%",
            label: "Oszczędność czasu"
          }
        ]}
        primaryCta={{
          label: "Pobierz listy",
          action: () => navigate('#lists')
        }}
        secondaryCta={{
          label: "Skonsultuj dokumenty",
          action: () => navigate('#contact')
        }}
      />

      {/* Documents Lists */}
      <section id="lists" className="py-12 md:py-20">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {documentLists.map((list, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300">
                <h2 className="text-xl font-bold text-[#003A30] mb-4 flex items-center gap-2">
                  <FileCheck className="w-6 h-6 text-[#CBA331]" />
                  {list.category}
                </h2>
                <ul className="space-y-3 mb-6">
                  {list.items.map((item, itemIndex) => (
                    <li key={itemIndex} className="flex items-start gap-2 text-gray-600">
                      <div className="w-1.5 h-1.5 rounded-full bg-[#CBA331] mt-2 flex-shrink-0" />
                      {item}
                    </li>
                  ))}
                </ul>
                <button className="flex items-center gap-2 text-[#003A30] font-semibold hover:text-[#CBA331] transition-colors duration-300">
                  <Download className="w-5 h-5" />
                  Pobierz pełną listę
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}