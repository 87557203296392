import React, { useState } from 'react';
import { Share2, Bookmark, BookmarkCheck } from 'lucide-react';
import { useSaveArticle } from '../../hooks/useSaveArticle';
import { useShare } from '../../hooks/useShare';

interface ShareButtonsProps {
  articleId: string;
  articleTitle: string;
  articleUrl: string;
}

export default function ShareButtons({ 
  articleId, 
  articleTitle, 
  articleUrl 
}: ShareButtonsProps) {
  const { isSaved, toggleSave } = useSaveArticle(articleId, articleTitle);
  const { share, isSharing } = useShare();
  
  const handleShare = async () => {
    await share({ title: articleTitle, url: articleUrl });
  };
  
  return (
    <div className="flex space-x-2 my-6">
      <button
        onClick={handleShare}
        disabled={isSharing}
        className="flex items-center px-4 py-2 bg-[#003A30] text-white rounded-lg hover:bg-[#004d40] transition-colors duration-200 touch-manipulation"
        aria-label="Udostępnij artykuł"
      >
        <Share2 className="w-4 h-4 mr-2" />
        <span>Udostępnij</span>
      </button>
      
      <button
        onClick={toggleSave}
        className={`flex items-center px-4 py-2 rounded-lg transition-colors duration-200 touch-manipulation ${
          isSaved 
            ? 'bg-[#CBA331]/10 text-[#CBA331]' 
            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
        }`}
        aria-label={isSaved ? "Usuń z zapisanych" : "Zapisz artykuł"}
      >
        {isSaved ? (
          <>
            <BookmarkCheck className="w-4 h-4 mr-2" />
            <span>Zapisano</span>
          </>
        ) : (
          <>
            <Bookmark className="w-4 h-4 mr-2" />
            <span>Zapisz</span>
          </>
        )}
      </button>
    </div>
  );
}