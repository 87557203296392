import type { CaseStudy } from '../types';

export const WORKING_CAPITAL_CASE: CaseStudy = {
  client: "Marek i Anna",
  image: "https://plus.unsplash.com/premium_photo-1661521365042-65d486c46f14?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Y291cGxlJTIwd29ya2luZ3xlbnwwfHwwfHx8MA%3D%3D",
  clientOccupation: "Właściciele",
  clientLocation: "Rzeszów",
  rating: 5,
  clientTypeTag: "Rodzinna firma handlowa",
  title: "Kredyt obrotowy ratalny umożliwiający ekspansję rodzinnej firmy handlowej",
  description: "Pomogliśmy przedsiębiorcom pozyskać długoterminowy kredyt obrotowy z równymi ratami, który pozwolił na podwojenie stanów magazynowych i zwiększenie zasięgu działania firmy bez obciążania bieżącej płynności.",
  result: "Kredyt obrotowy ratalny 500 000 zł na 10 lat + wzrost obrotów o 73%",
  creditType: "Kredyt obrotowy ratalny",
  timelineInfo: "Decyzja w 9 dni",
  financialBenefit: "Wzrost marży o 4,2 punktu procentowego",
  savingsAmount: "437000",
  savingsPercentage: "73",
  directQuote: "Przez lata działaliśmy na granicy płynności, realizując zamówienia 'na styk' i tracąc okazje na większe kontrakty. Kredyt obrotowy ratalny całkowicie odmienił naszą sytuację. Dzięki rozłożeniu spłaty na 10 lat, miesięczna rata jest na tyle niska, że nie obciąża naszej bieżącej działalności. Tymczasem korzyści skali przy większych zamówieniach pozwoliły nam nie tylko pokryć koszty kredytu, ale znacząco zwiększyć rentowność.",
  challenges: [
    "Ograniczone stany magazynowe uniemożliwiające obsługę większych zamówień",
    "Utrata klientów B2B z powodu braku odpowiednich zapasów 'na już'",
    "Brak możliwości negocjacji lepszych warunków u dostawców przy małych zamówieniach",
    "Nieprzewidywalne skoki popytu powodujące okresowe problemy z płynnością",
    "Konkurencja z większymi podmiotami mającymi dostęp do kapitału"
  ],
  solutions: [
    "Kredyt obrotowy ratalny z długim, 10-letnim okresem spłaty zapewniający niską miesięczną ratę",
    "Struktura finansowania pozwalająca na jednorazowe dokapitalizowanie firmy kwotą 500 000 zł",
    "Oprocentowanie oparte o WIBOR z marżą 2,5%, co zapewnia konkurencyjny koszt finansowania",
    "Brak wymogu zabezpieczenia na nieruchomości – tylko zastaw rejestrowy na towarach i weksle",
    "Elastyczny harmonogram spłat uwzględniający sezonowość w branży"
  ],
  whatIfScenario: "Kontynuacja działalności bez dokapitalizowania oznaczałaby dalsze trudności z obsługą większych zamówień i utratę potencjalnych klientów. Firma nadal kupowałaby towar w małych partiach po wyższych cenach, a jej konkurencyjność systematycznie by spadała. W dłuższej perspektywie, przy rosnącej konkurencji w branży, mogłoby to zagrozić stabilności biznesu, a nawet doprowadzić do konieczności ograniczenia działalności. Próba dokapitalizowania firmy ze środków własnych wymagałaby wielu lat oszczędzania, co oznaczałoby utratę szans rynkowych już teraz.",
  beforeAfter: {
    before: "Średnie stany magazynowe: 320 000 zł. Czas realizacji większych zamówień: 3-4 tygodnie. Średni rabat od dostawców: 8%. Marża handlowa: 18,5%. Miesięczne obroty: ok. 180 000 zł. Częste odmowy realizacji większych zamówień z powodu braku towaru i możliwości szybkiego finansowania zakupów.",
    after: "Średnie stany magazynowe: 750 000 zł. Czas realizacji większych zamówień: 2-3 dni. Średni rabat od dostawców: 17%. Marża handlowa: 22,7%. Miesięczne obroty: ok. 310 000 zł. Możliwość obsługi zamówień B2B o wartości do 100 000 zł 'od ręki'. Rata kredytu: 5 470 zł miesięcznie - łatwa do obsługi przy zwiększonej skali działalności."
  },
  reasoning: "Decyzja o pozyskaniu długoterminowego kredytu obrotowego wynikała z analizy punktu równowagi między zyskiem ze zwiększonej skali działalności a kosztem finansowania. Dla firmy handlowej z marżą 18-23% kluczowe jest utrzymanie odpowiednich stanów magazynowych i możliwość natychmiastowej realizacji zamówień. Jednorazowe dokapitalizowanie firmy pozwoliło na osiągnięcie efektu skali, który przełożył się na lepsze rabaty u dostawców, większą konkurencyjność i wyższe marże. Rozłożenie spłaty na 10 lat umożliwiło zachowanie dobrej płynności finansowej, gdyż miesięczna rata 5 470 zł stanowi zaledwie 1,7% miesięcznych obrotów po rozszerzeniu działalności. Dodatkowo, wzrost rentowności o 4,2 punktu procentowego (z 18,5% do 22,7%) pozwolił na samofinansowanie kosztu kredytu z dodatkowego zysku.",
  fullStory: {
    situation: "Marek i Anna prowadzą rodzinną firmę handlową w Rzeszowie, specjalizującą się w dystrybucji specjalistycznych materiałów budowlanych. Przez 8 lat zbudowali solidną reputację i bazę klientów, jednak stale borykali się z problemem niedostatecznych stanów magazynowych. Firma była rentowna, ale działała na granicy płynności – każde większe zamówienie wymagało balansowania między zaliczkami od klientów a płatnościami dla dostawców. Z tego powodu wielu potencjalnych, większych klientów B2B decydowało się na współpracę z konkurencją, która mogła zapewnić szybszą realizację zamówień.",
    challenge: "Głównym wyzwaniem było przerwanie błędnego koła: zbyt małe stany magazynowe → niemożność obsługi dużych zamówień → brak środków na zwiększenie stanów magazynowych. Firma potrzebowała jednorazowego, znaczącego zastrzyku gotówki, który pozwoliłby na zmianę skali działalności. Jednocześnie, właściciele obawiali się tradycyjnych kredytów obrotowych z krótkim okresem spłaty, gdyż mogłyby one jeszcze bardziej obciążyć ich płynność. Potrzebne było rozwiązanie, które pozwoliłoby na znaczące zwiększenie kapitału obrotowego bez generowania wysokich, krótkoterminowych zobowiązań.",
    solution: "Zaproponowaliśmy nietypowe rozwiązanie: kredyt obrotowy ratalny na 10 lat. Taki kredyt, choć ma wyższe łączne koszty odsetkowe niż tradycyjny kredyt obrotowy na 1-3 lata, zapewnia znacznie niższą miesięczną ratę, co było kluczowe dla zachowania płynności. Kwota 500 000 zł pozwoliła firmie na ponad dwukrotne zwiększenie stanów magazynowych, wprowadzenie nowych linii produktowych i rozpoczęcie aktywnego pozyskiwania klientów B2B. Zastosowanie długiego okresu kredytowania (10 lat) przełożyło się na miesięczną ratę zaledwie 5 470 zł, co stanowiło akceptowalne obciążenie nawet przy dotychczasowej skali działalności.",
    benefits: "Efekty wdrożonego rozwiązania przerosły oczekiwania właścicieli. Zwiększone stany magazynowe umożliwiły natychmiastową realizację większości zamówień. Większa skala zakupów przełożyła się na lepsze rabaty u dostawców (wzrost z 8% do 17%). W rezultacie, marża handlowa wzrosła z 18,5% do 22,7%. Miesięczne obroty zwiększyły się o 73% (z 180 000 zł do 310 000 zł), co znacznie przekroczyło koszt obsługi kredytu. Szacowany dodatkowy zysk miesięczny wyniósł około 16 400 zł, przy racie kredytu 5 470 zł. Po 10 latach, przy założeniu utrzymania obecnych wyników, łączna dodatkowa korzyść finansowa wyniesie około 1,3 mln zł, po uwzględnieniu całkowitego kosztu kredytu."
  },
  details: {
    business: {
      type: "Firma handlowa – dystrybucja specjalistycznych materiałów budowlanych",
      experience: "8 lat na rynku",
      clients: "Klienci indywidualni, wykonawcy, małe i średnie firmy budowlane",
      initialTurnover: "180 000 zł miesięcznie",
      currentTurnover: "310 000 zł miesięcznie"
    },
    loan: {
      amount: "500 000 zł",
      type: "Kredyt obrotowy ratalny",
      period: "10 lat (120 miesięcy)",
      rate: "WIBOR + marża 2,5%",
      initialPayment: "5 470 zł miesięcznie przy WIBOR 5,81%",
      securityRequired: "Zastaw rejestrowy na towarach, weksle in blanco"
    },
    timeline: {
      application: "Złożenie wniosku: 1 dzień",
      decision: "Decyzja kredytowa: 9 dni",
      funding: "Uruchomienie środków: 14 dni od złożenia wniosku",
      firstResults: "Pierwsze wyniki zwiększonej skali: po 3 miesiącach"
    },
    financialImpact: {
      inventoryIncrease: "z 320 000 zł do 750 000 zł (wzrost o 134%)",
      marginIncrease: "z 18,5% do 22,7% (wzrost o 4,2 punktu procentowego)",
      monthlyProfitBefore: "33 300 zł",
      monthlyProfitAfter: "70 400 zł (wzrost o 111%)",
      creditCost: "5 470 zł miesięcznie",
      netBenefit: "31 600 zł miesięcznie po spłacie raty",
      returnOnInvestment: "ROI z kredytu: 76% w skali roku",
      breakevenPoint: "Próg rentowności: sprzedaż na poziomie 142 000 zł miesięcznie"
    },
    businessAdvantages: {
      supplierRelations: "Poprawa pozycji negocjacyjnej, status preferowanego dystrybutora u 3 kluczowych dostawców",
      customerRetention: "Wzrost lojalności klientów B2B o 42% dzięki szybszej realizacji zamówień",
      marketPosition: "Wejście do grona 3 największych dystrybutorów w regionie",
      competitiveEdge: "Możliwość szybkiej realizacji dużych zamówień i oferowania lepszych cen",
      futureGrowth: "Podstawa do dalszej ekspansji i wejścia na rynki sąsiednich województw"
    },
    calculationMethodology: {
      title: "Analiza opłacalności długoterminowego kredytu obrotowego",
      approach: "Porównujemy przyrost zysków wynikający ze zwiększonej skali działalności z kosztem kredytu, biorąc pod uwagę zarówno bezpośrednie korzyści finansowe (wyższe marże, większe obroty), jak i korzyści pośrednie (lepsza pozycja konkurencyjna, większa stabilność).",
      assumptions: [
        "Stopa procentowa: WIBOR 5,81% + marża 2,5% = 8,31% (stan na dzień uruchomienia kredytu)",
        "Równe raty kapitałowo-odsetkowe przez cały okres kredytowania",
        "Wzrost obrotów o 73% w wyniku zwiększenia stanów magazynowych",
        "Wzrost marży o 4,2 punktu procentowego dzięki lepszym warunkom zakupowym i optymalizacji oferty"
      ],
      detailedCalculation: {
        creditCost: "Łączny koszt odsetek przez 10 lat: ok. 156 400 zł",
        additionalProfit: "Dodatkowy miesięczny zysk: 37 100 zł",
        monthlyBenefit: "Miesięczna korzyść netto (po spłacie raty): 31 600 zł",
        totalBenefit: "Całkowita korzyść finansowa przez 10 lat: ok. 3 792 000 zł",
        netBenefit: "Korzyść netto po uwzględnieniu kosztu kredytu: ok. 3 635 600 zł"
      },
      sensitivityAnalysis: "Nawet przy spadku przychodów o 30% względem założeń i wzroście WIBOR do 8%, inwestycja pozostaje wysoce opłacalna, generując korzyść netto na poziomie ok. 2,5 mln zł w okresie 10 lat."
    }
  }
};