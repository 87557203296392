import React, { useState, useEffect } from 'react';
import { BarChart, LineChart, Filter } from 'lucide-react';
import { CalculationResults, LoanData } from '../MortgageCalculator';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ChartPanelProps {
  results: CalculationResults | null;
  loanData: LoanData;
}

export default function ChartPanel({ results, loanData }: ChartPanelProps) {
  const [chartType, setChartType] = useState<'line' | 'bar'>('line');
  const [viewMode, setViewMode] = useState<'monthly' | 'yearly'>('yearly');

  // Format currency
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  // Prepare chart data
  const prepareChartData = () => {
    if (!results) return null;

    const schedule = results.schedule;
    const totalMonths = loanData.period * 12;
    
    // For yearly view, aggregate data by year
    if (viewMode === 'yearly') {
      const years = Math.ceil(schedule.length / 12);
      const labels = Array.from({ length: years }, (_, i) => `Rok ${i + 1}`);
      
      const remainingBalanceData = [];
      const paidPrincipalData = [];
      const interestData = [];
      const realInterestData = [];
      
      for (let year = 0; year < years; year++) {
        const startMonth = year * 12;
        const endMonth = Math.min(startMonth + 11, schedule.length - 1);
        
        if (startMonth < schedule.length) {
          remainingBalanceData.push(schedule[endMonth].remainingBalance);
          paidPrincipalData.push(loanData.amount - schedule[endMonth].remainingBalance);
          
          const yearlyInterest = schedule
            .slice(startMonth, endMonth + 1)
            .reduce((sum, item) => sum + item.interest, 0);
          interestData.push(yearlyInterest);
          
          const yearlyRealInterest = schedule
            .slice(startMonth, endMonth + 1)
            .reduce((sum, item) => sum + item.realInterest, 0);
          realInterestData.push(yearlyRealInterest);
        }
      }
      
      return {
        labels,
        datasets: [
          {
            label: 'Pozostały kapitał',
            data: remainingBalanceData,
            backgroundColor: 'rgba(0, 58, 48, 0.7)',
            borderColor: 'rgba(0, 58, 48, 1)',
            borderWidth: 1,
            yAxisID: 'y'
          },
          {
            label: 'Spłacony kapitał',
            data: paidPrincipalData,
            backgroundColor: 'rgba(0, 58, 48, 0.3)',
            borderColor: 'rgba(0, 58, 48, 0.7)',
            borderWidth: 1,
            yAxisID: 'y'
          },
          {
            label: 'Odsetki',
            data: interestData,
            backgroundColor: 'rgba(203, 163, 49, 0.7)',
            borderColor: 'rgba(203, 163, 49, 1)',
            borderWidth: 1,
            yAxisID: 'y1'
          },
          {
            label: 'Odsetki realne',
            data: realInterestData,
            backgroundColor: 'rgba(203, 163, 49, 0.3)',
            borderColor: 'rgba(203, 163, 49, 0.7)',
            borderWidth: 1,
            yAxisID: 'y1'
          }
        ]
      };
    } else {
      // For monthly view, sample data to avoid overcrowding
      const sampleInterval = Math.max(1, Math.floor(totalMonths / 50));
      const sampledSchedule = schedule.filter((_, i) => i % sampleInterval === 0);
      
      const labels = sampledSchedule.map(item => `M${item.month}`);
      const remainingBalanceData = sampledSchedule.map(item => item.remainingBalance);
      const paidPrincipalData = sampledSchedule.map(item => loanData.amount - item.remainingBalance);
      const interestData = sampledSchedule.map(item => item.cumulativeInterest);
      const realInterestData = sampledSchedule.map(item => item.cumulativeRealInterest);
      
      return {
        labels,
        datasets: [
          {
            label: 'Pozostały kapitał',
            data: remainingBalanceData,
            backgroundColor: 'rgba(0, 58, 48, 0.7)',
            borderColor: 'rgba(0, 58, 48, 1)',
            borderWidth: 1,
            yAxisID: 'y'
          },
          {
            label: 'Spłacony kapitał',
            data: paidPrincipalData,
            backgroundColor: 'rgba(0, 58, 48, 0.3)',
            borderColor: 'rgba(0, 58, 48, 0.7)',
            borderWidth: 1,
            yAxisID: 'y'
          },
          {
            label: 'Odsetki (skumulowane)',
            data: interestData,
            backgroundColor: 'rgba(203, 163, 49, 0.7)',
            borderColor: 'rgba(203, 163, 49, 1)',
            borderWidth: 1,
            yAxisID: 'y1'
          },
          {
            label: 'Odsetki realne (skumulowane)',
            data: realInterestData,
            backgroundColor: 'rgba(203, 163, 49, 0.3)',
            borderColor: 'rgba(203, 163, 49, 0.7)',
            borderWidth: 1,
            yAxisID: 'y1'
          }
        ]
      };
    }
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      x: {
        title: {
          display: true,
          text: viewMode === 'yearly' ? 'Lata' : 'Miesiące'
        }
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        title: {
          display: true,
          text: 'Kapitał (PLN)'
        },
        ticks: {
          callback: (value: number) => formatCurrency(value)
        }
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        title: {
          display: true,
          text: 'Odsetki (PLN)'
        },
        ticks: {
          callback: (value: number) => formatCurrency(value)
        },
        grid: {
          drawOnChartArea: false
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += formatCurrency(context.parsed.y);
            }
            return label;
          }
        }
      },
      legend: {
        position: 'top' as const,
      }
    }
  };

  if (!results) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-8 h-full flex items-center justify-center">
        <p className="text-gray-500">Obliczanie wyników...</p>
      </div>
    );
  }

  const chartData = prepareChartData();
  if (!chartData) return null;

  const ChartComponent = chartType === 'line' ? Line : Bar;

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden">
      <div className="p-6 md:p-8">
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center gap-3">
            <div className="relative">
              <LineChart className="w-8 h-8 text-[#CBA331]" />
              <div className="absolute -top-1 -right-1 w-3 h-3 bg-[#003A30] rounded-full animate-pulse"></div>
            </div>
            <h2 className="text-2xl font-bold text-[#003A30] relative">
              Wizualizacja danych
              <div className="absolute -bottom-1 left-0 w-full h-0.5 bg-gradient-to-r from-[#CBA331]/0 via-[#CBA331] to-[#CBA331]/0"></div>
            </h2>
          </div>

          {/* Chart Controls */}
          <div className="flex gap-2">
            <div className="inline-flex rounded-md shadow-sm" role="group">
              <button
                type="button"
                onClick={() => setChartType('line')}
                className={`px-4 py-2 text-sm font-medium rounded-l-lg ${
                  chartType === 'line'
                    ? 'bg-[#003A30] text-white'
                    : 'bg-white text-gray-700 hover:bg-gray-100'
                }`}
              >
                <LineChart className="w-4 h-4" />
              </button>
              <button
                type="button"
                onClick={() => setChartType('bar')}
                className={`px-4 py-2 text-sm font-medium rounded-r-lg ${
                  chartType === 'bar'
                    ? 'bg-[#003A30] text-white'
                    : 'bg-white text-gray-700 hover:bg-gray-100'
                }`}
              >
                <BarChart className="w-4 h-4" />
              </button>
            </div>
            
            <button
              type="button"
              onClick={() => setViewMode(viewMode === 'yearly' ? 'monthly' : 'yearly')}
              className="inline-flex items-center px-4 py-2 text-sm font-medium bg-gray-100 rounded-lg hover:bg-gray-200"
            >
              <Filter className="w-4 h-4 mr-1" />
              {viewMode === 'yearly' ? 'Miesięcznie' : 'Rocznie'}
            </button>
          </div>
        </div>

        {/* Chart Container */}
        <div className="h-[400px] relative">
          <ChartComponent data={chartData} options={chartOptions} />
        </div>

        {/* Legend */}
        <div className="mt-6 grid grid-cols-2 md:grid-cols-4 gap-4">
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 bg-[#003A30] opacity-70 rounded-sm"></div>
            <span className="text-sm text-gray-700">Pozostały kapitał</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 bg-[#003A30] opacity-30 rounded-sm"></div>
            <span className="text-sm text-gray-700">Spłacony kapitał</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 bg-[#CBA331] opacity-70 rounded-sm"></div>
            <span className="text-sm text-gray-700">Odsetki</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 bg-[#CBA331] opacity-30 rounded-sm"></div>
            <span className="text-sm text-gray-700">Odsetki realne</span>
          </div>
        </div>

        {/* Chart Description */}
        <div className="mt-6 text-sm text-gray-500">
          <p>
            Wykres przedstawia strukturę spłaty kredytu w czasie, pokazując pozostały kapitał, spłacony kapitał oraz odsetki.
            Odsetki realne uwzględniają wpływ inflacji (3% rocznie) na wartość pieniądza w czasie.
          </p>
        </div>
      </div>
    </div>
  );
}