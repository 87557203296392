import { Home } from 'lucide-react';
import type { Card, ModalDetails } from '../types';

export const FIRST_HOME_CARD: Card = {
  id: "first-home",
  icon: Home,
  title: "Spełnij marzenie o pierwszym mieszkaniu",
  description: "Zyskaj klucze do własnego M szybciej niż myślisz",
  benefits: [
    "Nawet o połowę niższy wkład własny",
    "Dostęp do wszystkich programów wsparcia na rynku",
    "Kredyt dopasowany do Twoich możliwości finansowych"
  ],
  stats: {
    value: "10%",
    label: "Wystarczy tylko tyle wkładu własnego"
  },
  cta: {
    label: "Sprawdź swoją zdolność kredytową",
    action: () => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })
  }
};

export const FIRST_HOME_DETAILS: ModalDetails = {
  title: "Spełnij marzenie o własnym mieszkaniu",
  tagline: "Własne M w zasięgu ręki",
  description: "Posiadanie własnego mieszkania jest teraz w Twoim zasięgu. Pomożemy Ci przejść przez cały proces kredytowy, zapewniając wsparcie w znalezieniu odpowiedniego finansowania i dostęp do dostępnych programów wsparcia.",
  cta: {
    phone: "539 211 881",
    text: "Zadzwoń i dowiedz się więcej o możliwościach finansowania zakupu pierwszego mieszkania"
  },
  benefitHighlights: [
    "Możliwość uzyskania niższego wkładu własnego",
    "Pomoc w dostępie do programów wsparcia",
    "Dopasowanie kredytu do Twoich możliwości",
    "Wsparcie w procesie kredytowym"
  ],
  whyUs: [
    "Współpracujemy z wieloma bankami, aby znaleźć najlepszą ofertę dla Ciebie",
    "Doświadczeni doradcy pomogą w przejściu przez proces kredytowy",
    "Pomagamy skompletować i przygotować wszystkie wymagane dokumenty",
    "Oferujemy profesjonalne doradztwo na każdym etapie"
  ],
  processComparison: {
    withUs: [
      {
        step: "Analiza zdolności kredytowej",
        description: "Sprawdzamy Twoją sytuację i doradzamy optymalne rozwiązania",
        icon: "Search",
        highlight: true
      },
      {
        step: "Przedstawienie ofert",
        description: "Pokazujemy porównanie dostępnych opcji finansowania",
        icon: "Lightbulb"
      },
      {
        step: "Wsparcie w dokumentacji",
        description: "Pomagamy w przygotowaniu i złożeniu dokumentów",
        icon: "ClipboardCheck"
      },
      {
        step: "Szybki monitoring procesu",
        description: "Regularne informowanie o statusie wniosku, zwykle 3-4 tygodnie",
        icon: "CreditCard",
        highlight: true
      }
    ],
    withoutUs: [
      {
        step: "Samodzielne poszukiwania",
        description: "Konieczność indywidualnego sprawdzenia ofert wielu banków",
        icon: "Search",
        warning: true
      },
      {
        step: "Analiza warunków",
        description: "Samodzielna interpretacja skomplikowanych warunków kredytowych",
        icon: "AlertTriangle"
      },
      {
        step: "Przygotowanie dokumentacji",
        description: "Samodzielne kompletowanie i dostarczanie dokumentów",
        icon: "Calendar",
        warning: true
      },
      {
        step: "Dłuższe oczekiwanie na decyzję",
        description: "Brak wsparcia w komunikacji z bankiem, typowo 8-12 tygodni",
        icon: "Hourglass",
        warning: true
      }
    ]
  },
  calculations: {
    example: "Mieszkanie 45m² w Krakowie (630 000 zł)",
    before: "Standardowa oferta kredytowa:\n• Wymagany wkład własny: 126 000 zł (20%)\n• Przykładowa rata: około 3 560 zł miesięcznie\n• Oprocentowanie: około 7,1-8,0% (z marżą banku)\n• Typowy czas oczekiwania: 8-12 tygodni",
    after: "Możliwości z naszym wsparciem:\n• Możliwy wkład własny od 63 000 zł (10%)*\n• Przykładowa rata: około 3 390 zł miesięcznie**\n• Oprocentowanie: około 6,5-7,0% (wynegocjowana marża)\n• Wsparcie w maksymalnym wykorzystaniu ulg podatkowych\n• Czas procesu: zwykle 3-4 tygodnie\n\n* w zależności od indywidualnej sytuacji klienta i oferty banków\n** przy kredycie na 30 lat i wynegocjowanym niższym oprocentowaniu",
    savings: "Korzyści: do 63 000 zł mniej wkładu własnego, rata niższa nawet o 170 zł miesięcznie (ponad 2 000 zł rocznie) oraz przyśpieszenie procesu o 4-8 tygodni",
    extra: "Dodatkowo: pomoc w znalezieniu najkorzystniejszej oferty ubezpieczenia, co może obniżyć całkowity koszt kredytu nawet o kilkanaście tysięcy złotych w okresie kredytowania"
  },
  benefits: [
    "Pomoc w znalezieniu kredytów z niższym wkładem własnym",
    "Wsparcie w uzyskaniu korzystnych warunków kredytowych",
    "Pomoc w aplikowaniu o dostępne programy wsparcia",
    "Profesjonalna obsługa procesu kredytowego",
    "Analiza zdolności kredytowej i symulacja różnych scenariuszy",
    "Doradztwo w kwestii umowy przedwstępnej zakupu mieszkania"
  ],
  limitedTimeOffer: {
    text: "Bezpłatna analiza zdolności kredytowej dla nowych klientów",
    expiry: "30.04.2025"
  },
  faq: [
    {
      question: "Ile naprawdę muszę zarabiać, żeby dostać kredyt na mieszkanie w 2025 roku?",
      answer: "Według aktualnych wymogów KNF, przy przeciętnej cenie mieszkania 45m² w dużym mieście (ok. 630 000 zł), singiel powinien zarabiać minimum ok. 8 000-9 000 zł netto, a para łącznie ok. 11 000-12 000 zł netto. Jednak banki stosują różne kryteria oceny zdolności kredytowej, a my możemy pomóc znaleźć bank, który najkorzystniej oceni Twoją sytuację. Warto też wiedzieć, że część banków nie uwzględnia w pełni kosztów utrzymania mieszkania przy wyliczaniu zdolności, co może znacząco wpłynąć na ostateczną decyzję."
    },
    {
      question: "Co zrobić, jeśli nie mam wystarczającego wkładu własnego?",
      answer: "Istnieje kilka możliwości: 1) Programy bankowe z ubezpieczeniem niskiego wkładu własnego (możliwe 10% zamiast 20%), 2) Gwarancja bankowa uzupełniająca wkład własny (oferowana przez niektóre banki), 3) Kredyt rodzinny lub gwarancja od rodziców, 4) Kredyt hipoteczny z dodatkowym zabezpieczeniem na innej nieruchomości. Każde z tych rozwiązań ma swoje warunki i ograniczenia - podczas indywidualnej konsultacji pomożemy dobrać najlepszą opcję do Twojej sytuacji."
    },
    {
      question: "Czy istnieją jakieś programy wsparcia dla osób kupujących pierwsze mieszkanie w 2025 roku?",
      answer: "W 2025 roku sytuacja programów wsparcia dla nabywców mieszkań zmieniła się. Program 'Bezpieczny Kredyt 2%' zakończył się, a obecnie dostępne są: program 'Mieszkanie na Start' (dopłaty do najmu), ulgi podatkowe dla pierwszych nabywców (odliczenie części kosztów od podatku), oraz lokalne programy samorządowe w niektórych miastach. Pomagamy naszym klientom na bieżąco śledzić zmiany w przepisach i wykorzystać wszystkie dostępne możliwości wsparcia, które mogą znacząco wpłynąć na dostępność kredytu i jego całkowity koszt."
    },
    {
      question: "Umowa o pracę na okres próbny - czy dostanę kredyt?",
      answer: "Banki z reguły nie akceptują umów na okres próbny przy udzielaniu kredytów hipotecznych. Standardowo wymagają umowy na czas nieokreślony lub określony, z minimum 3-6 miesięcznym stażem u obecnego pracodawcy. Jeśli jesteś na okresie próbnym, warto rozważyć złożenie wniosku po jego zakończeniu i przedłużeniu umowy. Możemy doradzić, jak zaplanować proces kredytowy w takiej sytuacji oraz sprawdzić, czy Twoja historia zatrudnienia i dodatkowe zabezpieczenia mogą zwiększyć szanse na pozytywną decyzję."
    },
    {
      question: "Na jakie ukryte koszty powinienem się przygotować przy zakupie mieszkania?",
      answer: "Oprócz ceny mieszkania i wkładu własnego, należy uwzględnić: 1) Prowizję bankową (0-3% kwoty kredytu), 2) Opłaty notarialne i sądowe (ok. 1-2% wartości nieruchomości), 3) Podatek PCC przy rynku wtórnym (2% wartości), 4) Koszty wyceny nieruchomości (400-1000 zł), 5) Koszty ubezpieczeń wymaganych przez bank, 6) Ewentualne koszty pośrednika (2-3%), 7) Koszty wykończenia mieszkania. Pomożemy Ci zaplanować budżet uwzględniający wszystkie te elementy."
    },
    {
      question: "Co się stanie, jeśli stracę pracę w trakcie spłacania kredytu?",
      answer: "W takiej sytuacji masz kilka opcji: 1) Skorzystanie z ubezpieczenia spłaty kredytu, jeśli zostało zawarte, 2) Wakacje kredytowe (zawieszenie spłaty rat na określony czas), 3) Restrukturyzacja kredytu (zmiana harmonogramu spłat). Banki często oferują programy pomocowe dla klientów w trudnej sytuacji finansowej. W przypadku trudności ze spłatą, zawsze warto jak najszybciej skontaktować się z bankiem i szukać rozwiązań. Możemy doradzić w zakresie odpowiedniego ubezpieczenia kredytu już na etapie jego zaciągania."
    }
  ],
  nextSteps: [
    "Skontaktuj się z nami telefonicznie lub przez formularz",
    "Umów się na konsultację",
    "Wspólna analiza Twojej sytuacji finansowej",
    "Przedstawienie dostępnych opcji finansowania",
    "Wsparcie w całym procesie kredytowym"
  ]
};
