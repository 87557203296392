import { PiggyBank, BadgePercent, Clock, Shield } from 'lucide-react';
import type { SocialProofStats } from './types';

export const optimizationStats: SocialProofStats = {
  clients: {
    icon: PiggyBank,
    value: 300,
    showPlus: true,
    label: "Zoptymalizowanych firm"
  },
  financing: {
    icon: BadgePercent,
    value: 42,
    suffix: "%",
    label: "Średnia redukcja kosztów"
  },
  experience: {
    icon: Clock,
    value: 14,
    suffix: " dni",
    label: "Minimalny czas wdrożenia"
  },
  satisfaction: {
    icon: Shield,
    value: 99,
    suffix: "%",
    label: "Zadowolonych klientów"
  }
};