import { useState } from 'react';

interface ShareOptions {
  title: string;
  url: string;
  text?: string;
}

export function useShare() {
  const [isSharing, setIsSharing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  const share = async ({ title, url, text }: ShareOptions) => {
    setIsSharing(true);
    setError(null);
    
    try {
      // Check if Web Share API is available
      if (navigator.share) {
        await navigator.share({
          title,
          text,
          url
        });
        return true;
      } else {
        // Fallback to copying to clipboard
        await navigator.clipboard.writeText(url);
        return 'copied';
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unknown error occurred');
      }
      return false;
    } finally {
      setIsSharing(false);
    }
  };
  
  return { share, isSharing, error };
}