/**
 * Process headings in content to add IDs for table of contents
 * @param contentRef - Reference to content element
 */
export function processHeadings(contentRef: React.RefObject<HTMLElement>) {
  if (!contentRef.current) return;
  
  const headings = contentRef.current.querySelectorAll('h2, h3, h4, h5, h6');
  
  headings.forEach((heading) => {
    // Create ID from heading text
    const id = heading.textContent
      ?.toLowerCase()
      .replace(/[^\w\s]/gi, '')
      .replace(/\s+/g, '-') || '';
    
    // Set ID attribute
    heading.setAttribute('id', id);
  });
}

/**
 * Process quote blocks in content to replace with custom component
 * @param contentRef - Reference to content element
 */
export function processQuoteBlocks(contentRef: React.RefObject<HTMLElement>) {
  if (!contentRef.current) return;
  
  const quoteBlocks = contentRef.current.querySelectorAll('blockquote');
  
  quoteBlocks.forEach((quote, index) => {
    const quoteId = `quote-${index}`;
    quote.setAttribute('id', quoteId);
    
    // Create a wrapper for the QuoteBox component
    const wrapper = document.createElement('div');
    wrapper.setAttribute('data-quote-id', quoteId);
    wrapper.className = 'quote-box-wrapper my-8';
    
    // Insert wrapper before the quote
    quote.parentNode?.insertBefore(wrapper, quote);
    
    // Move quote inside wrapper
    wrapper.appendChild(quote);
  });
}

/**
 * Process YouTube links in content to replace with YouTube component
 * @param contentRef - Reference to content element
 * @param youtubeVideos - Array of YouTube video data
 */
export function processYouTubeLinks(
  contentRef: React.RefObject<HTMLElement>,
  youtubeVideos?: Array<{
    id: string;
    url: string;
    title: string;
    description?: string;
    position?: 'top' | 'middle' | 'bottom';
  }>
) {
  if (!contentRef.current || !youtubeVideos || youtubeVideos.length === 0) return;
  
  // Find all links that might be YouTube links
  const links = contentRef.current.querySelectorAll('a');
  
  links.forEach(link => {
    const href = link.getAttribute('href') || '';
    
    // Check if this is a YouTube link
    const youtubeVideo = youtubeVideos.find(video => video.url === href);
    
    if (youtubeVideo) {
      // Create a placeholder for the YouTube component
      const placeholder = document.createElement('div');
      placeholder.setAttribute('data-youtube-id', youtubeVideo.id);
      placeholder.setAttribute('data-youtube-title', youtubeVideo.title);
      if (youtubeVideo.description) {
        placeholder.setAttribute('data-youtube-description', youtubeVideo.description);
      }
      placeholder.className = 'youtube-placeholder my-8';
      
      // Replace the link with the placeholder
      link.parentNode?.replaceChild(placeholder, link);
    }
  });
}

/**
 * Process CTA sections in content
 * @param contentRef - Reference to content element
 * @param customCTA - Custom CTA data
 */
export function processCTASection(
  contentRef: React.RefObject<HTMLElement>,
  customCTA?: {
    type: 'calculator' | 'consultation' | 'download';
    title: string;
    description: string;
    primaryButtonText: string;
    primaryButtonUrl: string;
    secondaryButtonText?: string;
    secondaryButtonUrl?: string;
  }
) {
  if (!contentRef.current || !customCTA) return;
  
  // Create a placeholder for the CTA component
  const placeholder = document.createElement('div');
  placeholder.setAttribute('data-cta-type', customCTA.type);
  placeholder.setAttribute('data-cta-title', customCTA.title);
  placeholder.setAttribute('data-cta-description', customCTA.description);
  placeholder.setAttribute('data-cta-primary-text', customCTA.primaryButtonText);
  placeholder.setAttribute('data-cta-primary-url', customCTA.primaryButtonUrl);
  
  if (customCTA.secondaryButtonText && customCTA.secondaryButtonUrl) {
    placeholder.setAttribute('data-cta-secondary-text', customCTA.secondaryButtonText);
    placeholder.setAttribute('data-cta-secondary-url', customCTA.secondaryButtonUrl);
  }
  
  placeholder.className = 'cta-placeholder my-12';
  
  // Find a good place to insert the CTA (e.g., after the first h2)
  const firstH2 = contentRef.current.querySelector('h2');
  if (firstH2 && firstH2.nextElementSibling) {
    firstH2.nextElementSibling.after(placeholder);
  } else {
    // If no h2 found, append to the end
    contentRef.current.appendChild(placeholder);
  }
}