import type { CaseStudy } from '../types';

export const CONSOLIDATION_CASE: CaseStudy = {
  id: "consolidation",
  client: "Adam",
  image: "https://images.unsplash.com/photo-1563013544-824ae1b704d3?w=300&h=300&fit=crop&auto=format",
  title: "Konsolidacja hipoteki i kredytów konsumenckich",
  description: "Pomogliśmy uwolnić się od wysokich rat wielu zobowiązań poprzez konsolidację pod jedną hipotekę.",
  result: "Redukcja miesięcznej raty o 2 800 zł i uwolniona zdolność kredytowa",
  service: "consolidation",
  challenges: [
    "Istniejący kredyt hipoteczny z marżą 2.1%",
    "Dwa kredyty gotówkowe z oprocentowaniem 12.5% i 13.2%",
    "Zadłużenie na dwóch kartach kredytowych (około 35 000 zł z oprocentowaniem 24%)",
    "Zbyt duże obciążenie miesięcznego budżetu ratami",
    "Trudności z terminowym regulowaniem wszystkich zobowiązań"
  ],
  solutions: [
    "Konsolidacja wszystkich zobowiązań w jeden kredyt hipoteczny",
    "Uzyskanie akceptowalnej marży 2.7% dla kredytu konsolidacyjnego",
    "Wydłużenie okresu kredytowania o 5 lat dla dalszego obniżenia raty",
    "Zabezpieczenie kredytu na nieruchomości z wyższą wyceną",
    "Wybór banku z elastyczną ofertą kredytu konsolidacyjnego"
  ],
  directQuote: "Nie sądziłem, że możliwe jest tak znaczące odciążenie mojego miesięcznego budżetu. Spłacając jedną, wygodną ratę zamiast pięciu różnych zobowiązań, odzyskałem spokój umysłu i mogę wreszcie zacząć odkładać pieniądze zamiast martwić się o spłaty na koniec każdego miesiąca.",
  financialBenefit: "Oszczędność ponad 33 600 zł rocznie",
  savingsPercentage: 42,
  savingsAmount: "2 800 zł miesięcznie mniej na obsługę zadłużenia",
  whatIfScenario: "Bez konsolidacji Adam prawdopodobnie musiałby podjąć dodatkową pracę lub sprzedać samochód, aby sprostać wszystkim zobowiązaniom. Wysokie raty różnych kredytów, szczególnie krótkoterminowych kredytów gotówkowych i kart kredytowych, pochłaniały ponad 65% jego miesięcznych dochodów, co czyniło jego sytuację finansową skrajnie niestabilną.",
  clientTypeTag: "Konsolidacja",
  ctaText: "Sprawdź, ile możesz zaoszczędzić dzięki konsolidacji",
  ctaLink: "/konsolidacja-kredytow",
  difficultyLevel: 4,
  timelineInfo: "Konsolidacja w 35 dni",
  rating: 5,
  date: "Marzec 2025",
  creditType: "Konsolidacyjny kredyt hipoteczny",
  clientOccupation: "Specjalista IT",
  clientLocation: "Kraków, Podgórze",
  beforeAfter: {
    before: "Kredyt hipoteczny (320 000 zł, rata 2 600 zł, oprocentowanie 7.9%), dwa kredyty gotówkowe (łącznie 140 000 zł, raty 3 600 zł, oprocentowanie 12.5-13.2%), dwie karty kredytowe (35 000 zł, minimalne spłaty 1 050 zł, oprocentowanie 24%) - łączne zobowiązania 7 250 zł miesięcznie, stres i problemy z płynnością.",
    after: "Jeden kredyt hipoteczny konsolidacyjny (495 000 zł, rata 4 450 zł, oprocentowanie 8.5% = WIBOR 5.8% + marża 2.7%), brak innych zobowiązań kredytowych, oszczędność 2 800 zł miesięcznie, odzyskana stabilność finansowa i spokój psychiczny, możliwość budowania oszczędności."
  },
  reasoning: "Wybrałem tę firmę doradczą, ponieważ jako jedyni przedstawili mi szczegółową analizę finansową mojej sytuacji i konkretny plan działania. Doceniłem ich profesjonalizm w znalezieniu banku, który zaakceptował konsolidację wszystkich moich zobowiązań przy akceptowalnej racie i bez ukrytych opłat.",
  fullStory: {
    situation: "Adam, 35-letni specjalista IT, na przestrzeni ostatnich 5 lat zaciągnął szereg zobowiązań finansowych - kredyt hipoteczny na mieszkanie 60m², dwa kredyty gotówkowe na remont i zakup samochodu oraz systematycznie korzystał z kart kredytowych. Mimo dobrej pensji, comiesięczna obsługa wszystkich rat i minimalnych spłat pochłaniała zdecydowaną większość jego dochodów, uniemożliwiając oszczędzanie i powodując ciągły stres finansowy.",
    challenge: "Adam obsługiwał kredyt hipoteczny o wartości 320 000 zł z ratą 2 600 zł, dwa kredyty gotówkowe o łącznej wartości 140 000 zł z ratami wynoszącymi w sumie 3 600 zł oraz posiadał zadłużenie na dwóch kartach kredytowych w wysokości 35 000 zł z minimalnymi miesięcznymi spłatami 1 050 zł. Łączne miesięczne zobowiązania wynosiły 7 250 zł, co stanowiło ponad 65% jego miesięcznych dochodów.",
    solution: "Kompleksowa analiza wszystkich zobowiązań i sytuacji majątkowej klienta, a następnie konsolidacja wszystkich kredytów w jeden kredyt hipoteczny z wydłużonym okresem spłaty. Mimo nieco wyższej marży niż w pierwotnym kredycie hipotecznym, główne źródło oszczędności stanowiła zamiana wysokooprocentowanych kredytów gotówkowych i zadłużenia na kartach kredytowych na kredyt hipoteczny z niższym oprocentowaniem.",
    details: {
      property: {
        value: "680 000 zł (aktualna wycena)",
        type: "Mieszkanie 60m²",
        location: "Kraków, Podgórze"
      },
      loan: {
        oldObligations: "Hipoteka: 320 000 zł (7.9%), Gotówkowe: 140 000 zł (12.5-13.2%), Karty: 35 000 zł (24%)",
        oldPayments: "Łącznie 7 250 zł miesięcznie",
        newLoan: "495 000 zł (WIBOR 5.8% + marża 2.7%)",
        newPayment: "4 450 zł miesięcznie"
      },
      savings: {
        monthly: "2 800 zł",
        yearly: "33 600 zł",
        total: "Ponad 180 000 zł w całym okresie kredytowania"
      },
      timeline: {
        application: "7 dni przygotowania dokumentacji",
        approval: "18 dni na decyzję kredytową",
        completion: "10 dni na finalizację i uruchomienie kredytu"
      }
    }
  }
};