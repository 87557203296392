import type { BlogPost } from '../types';

/**
 * Generate Article schema for a blog post
 * @param post - Blog post data
 * @returns Article schema object
 */
export function generateArticleSchema(post: BlogPost): Record<string, any> {
  return {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: post.title,
    description: post.excerpt,
    image: post.image,
    datePublished: post.schema.datePublished,
    dateModified: post.schema.dateModified,
    author: {
      '@type': 'Person',
      name: post.author.name,
      image: post.author.avatar
    },
    publisher: {
      '@type': 'Organization',
      name: post.schema.publisher.name,
      logo: {
        '@type': 'ImageObject',
        url: post.schema.publisher.logo
      }
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': window.location.href
    }
  };
}

/**
 * Generate BreadcrumbList schema for a blog post
 * @param post - Blog post data
 * @returns BreadcrumbList schema object
 */
export function generateBreadcrumbSchema(post: BlogPost): Record<string, any> {
  const baseUrl = window.location.origin;
  
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Strona główna',
        item: baseUrl
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Blog',
        item: `${baseUrl}/blog`
      },
      {
        '@type': 'ListItem',
        position: 3,
        name: post.title,
        item: window.location.href
      }
    ]
  };
}

/**
 * Generate FAQPage schema for a blog post with FAQ section
 * @param faqs - Array of FAQ items with question and answer
 * @returns FAQPage schema object
 */
export function generateFAQSchema(faqs: Array<{ question: string; answer: string }>): Record<string, any> {
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqs.map(faq => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.answer
      }
    }))
  };
}

/**
 * Generate all schemas for a blog post
 * @param post - Blog post data
 * @param faqs - Optional array of FAQ items
 * @returns Array of schema objects
 */
export function generateAllSchemas(
  post: BlogPost, 
  faqs?: Array<{ question: string; answer: string }>
): Record<string, any>[] {
  const schemas = [
    generateArticleSchema(post),
    generateBreadcrumbSchema(post)
  ];
  
  if (faqs && faqs.length > 0) {
    schemas.push(generateFAQSchema(faqs));
  }
  
  return schemas;
}