import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Loader2 } from 'lucide-react';
import type { BlogPost } from './types';
import { BLOG_POSTS } from './data/posts';
import ArticleTemplate from './components/layout/ArticleTemplate';
import { BlogErrorBoundary } from './components/ErrorBoundary';

// Map of all blog posts
const blogPosts: Record<string, BlogPost> = {};

// Populate the map with all blog posts
BLOG_POSTS.forEach(post => {
  blogPosts[post.slug] = post;
});

export default function BlogPost() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch the post data
  useEffect(() => {
    const fetchPost = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        // Simulate network request with a small delay for better UX
        await new Promise(resolve => setTimeout(resolve, 300));
        
        if (slug) {
          const foundPost = blogPosts[slug];
          
          if (foundPost) {
            setPost(foundPost);
            
            // Update document title
            document.title = foundPost.title + ' | Ekspert Kredytowy';
            
            // Track page view (this would be analytics in a real app)
            console.log(`Viewed post: ${foundPost.title}`);
          } else {
            setError('Post not found');
          }
        } else {
          setError('Invalid slug');
        }
      } catch (err) {
        setError('Failed to load post');
        console.error('Error loading post:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  // Handle loading state
  if (isLoading) {
    return (
      <div className="pt-20 min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="flex flex-col items-center p-8">
          <Loader2 className="w-12 h-12 text-[#003A30] animate-spin mb-4" />
          <p className="text-lg text-gray-600">Wczytywanie artykułu...</p>
        </div>
      </div>
    );
  }

  // Handle post not found or error
  if (!post || error) {
    return (
      <div className="pt-20 min-h-screen bg-gray-50">
        <div className="container mx-auto px-4 py-12">
          <div className="max-w-3xl mx-auto bg-white rounded-xl shadow-lg p-8 text-center"> 
            <h1 className="text-2xl font-bold text-[#003A30] mb-4">
              {error === 'Post not found' ? 'Nie znaleziono artykułu' : 'Wystąpił błąd'}
            </h1>
            <p className="text-gray-600 mb-6">
              {error === 'Post not found' 
                ? 'Przepraszamy, ale szukany artykuł nie istnieje lub został przeniesiony.'
                : 'Przepraszamy, wystąpił błąd podczas wczytywania artykułu. Spróbuj ponownie później.'}
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button
                onClick={() => navigate('/blog')}
                className="inline-flex items-center px-6 py-3 bg-[#003A30] text-white rounded-lg hover:bg-[#CBA331] transition-colors duration-300"
              >
                <ArrowLeft className="w-5 h-5 mr-2" />
                Wróć do bloga
              </button>
              
              {error !== 'Post not found' && (
                <button
                  onClick={() => window.location.reload()}
                  className="inline-flex items-center px-6 py-3 border border-[#003A30] text-[#003A30] rounded-lg hover:bg-gray-50 transition-colors duration-300"
                >
                  Odśwież stronę
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Render the article when everything is loaded successfully
  return (
    <BlogErrorBoundary>
      <ArticleTemplate post={post} />
    </BlogErrorBoundary>
  );
}