import React from 'react';
import { Users, Home, CreditCard, Info, Calculator, Sparkles } from 'lucide-react';
import { formatCurrency, formatPercentage } from '../utils/formatters';
import { CREDIT_LIMIT_BURDEN_PERCENT, FIRST_PERSON_EXPENSE, ADDITIONAL_PERSON_EXPENSE } from '../utils/constants';
import type { FormData } from '../types';

interface InputPanelProps {
  formData: FormData;
  onFormDataChange: (data: FormData) => void;
}

export default function InputPanel({ formData, onFormDataChange }: InputPanelProps) {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onFormDataChange({
      ...formData,
      [name]: parseFloat(value) || 0
    });
  };

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden relative">
      {/* Decorative background elements */}
      <div className="absolute inset-0 bg-gradient-to-br from-[#003A30]/[0.02] via-transparent to-[#CBA331]/[0.02] pointer-events-none" />
      <div className="absolute -top-24 -right-24 w-48 h-48 bg-[#003A30]/5 rounded-full blur-3xl opacity-20" />
      <div className="absolute -bottom-24 -left-24 w-48 h-48 bg-[#CBA331]/5 rounded-full blur-3xl opacity-20" />

      <div className="p-6 md:p-8 relative">
        {/* Header */}
        <div className="flex items-center justify-center gap-3 mb-8">
          <div className="relative">
            <Calculator className="w-8 h-8 text-[#CBA331] transform transition-transform duration-300 hover:scale-110" />
            <Sparkles className="w-3 h-3 text-[#CBA331]/50 absolute -top-1 -right-1 animate-pulse" />
          </div>
          <h2 className="text-2xl font-bold text-[#003A30] relative">
            Parametry kalkulacji
            <div className="absolute -bottom-1 left-0 w-full h-0.5 bg-gradient-to-r from-[#CBA331]/0 via-[#CBA331] to-[#CBA331]/0" />
          </h2>
        </div>

        <div className="space-y-8">
          {/* Income */}
          <div>
            <label htmlFor="income" className="block text-sm font-medium text-gray-700 mb-2 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Users className="w-4 h-4 text-[#CBA331]" />
                <span>Miesięczny dochód netto</span>
              </div>
              <span className="text-[#CBA331] font-semibold">{formatCurrency(formData.income)}</span>
            </label>
            <input
              type="range"
              id="income"
              name="income"
              min="1000"
              max="50000"
              step="100"
              value={formData.income}
              onChange={handleInputChange}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-[#003A30]"
            />
            <div className="mt-1 flex justify-between text-xs text-gray-500">
              <span>1 000 zł</span>
              <span>50 000 zł</span>
            </div>
          </div>

          {/* Household Members */}
          <div>
            <label htmlFor="householdMembers" className="block text-sm font-medium text-gray-700 mb-2 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Users className="w-4 h-4 text-[#CBA331]" />
                <span>Liczba osób w gospodarstwie domowym</span>
              </div>
              <span className="text-[#CBA331] font-semibold">{formData.householdMembers}</span>
            </label>
            <input
              type="range"
              id="householdMembers"
              name="householdMembers"
              min="1"
              max="10"
              step="1"
              value={formData.householdMembers}
              onChange={handleInputChange}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-[#003A30]"
            />
            <div className="mt-1 flex justify-between text-xs text-gray-500">
              <span>1 osoba</span>
              <span>10 osób</span>
            </div>
          </div>

          {/* Existing Loans */}
          <div>
            <label htmlFor="existingLoans" className="block text-sm font-medium text-gray-700 mb-2 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Home className="w-4 h-4 text-[#CBA331]" />
                <span>Obecne raty kredytowe (miesięcznie)</span>
              </div>
              <span className="text-[#CBA331] font-semibold">{formatCurrency(formData.existingLoans)}</span>
            </label>
            <input
              type="range"
              id="existingLoans"
              name="existingLoans"
              min="0"
              max="10000"
              step="100"
              value={formData.existingLoans}
              onChange={handleInputChange}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-[#003A30]"
            />
            <div className="mt-1 flex justify-between text-xs text-gray-500">
              <span>0 zł</span>
              <span>10 000 zł</span>
            </div>
          </div>

          {/* Credit Limits */}
          <div>
            <label htmlFor="creditLimits" className="block text-sm font-medium text-gray-700 mb-2 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <CreditCard className="w-4 h-4 text-[#CBA331]" />
                <span>Limity na kartach i rachunkach</span>
              </div>
              <span className="text-[#CBA331] font-semibold">{formatCurrency(formData.creditLimits)}</span>
            </label>
            <input
              type="range"
              id="creditLimits"
              name="creditLimits"
              min="0"
              max="100000"
              step="1000"
              value={formData.creditLimits}
              onChange={handleInputChange}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-[#003A30]"
            />
            <div className="mt-1 flex justify-between text-xs text-gray-500">
              <span>0 zł</span>
              <span>100 000 zł</span>
            </div>
            <p className="mt-1 text-xs text-gray-500">
              Obciążenie: {formatPercentage(CREDIT_LIMIT_BURDEN_PERCENT)} wartości limitów ({formatCurrency(formData.creditLimits * CREDIT_LIMIT_BURDEN_PERCENT)})
            </p>
          </div>

          {/* Information Box */}
          <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
            <div className="flex items-start gap-3">
              <Info className="w-5 h-5 text-[#CBA331] flex-shrink-0 mt-0.5" />
              <div className="text-sm text-gray-600">
                <p className="font-medium text-[#003A30] mb-1">Założenia kalkulacji:</p>
                <ul className="list-disc list-inside space-y-1 text-xs">
                  <li>Wkład własny: 20%</li>
                  <li>Okres kredytowania: 25 lat</li>
                  <li>Oprocentowanie zmienne: WIBOR 5.81% + marża 1.60%</li>
                  <li>Oprocentowanie stałe: 6.70%</li>
                  <li>Bufor na stopę procentową: 2.50%</li>
                  <li>Maksymalne obciążenie ratą: {formData.income >= 10000 ? '60%' : '50%'} dochodu{formData.income >= 10000 ? " (dochód >= 10 000 zł)" : ""}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}