import { RefreshCw } from 'lucide-react';
import type { Card, ModalDetails } from '../types';

export const MORTGAGE_REFINANCE_CARD: Card = {
  id: "mortgage-refinance",
  icon: RefreshCw,
  title: "Refinansowanie kredytu hipotecznego",
  description: "Obniż ratę lub skróć okres kredytowania swojego obecnego kredytu mieszkaniowego",
  benefits: [
    "Niższa rata miesięczna nawet o 488 zł",
    "Korzystniejsze oprocentowanie - marża niższa nawet o 0,7 p.p.",
    "Możliwość skrócenia kredytu nawet o 6 lat i 5 miesięcy"
  ],
  stats: {
    value: "299 400 zł",
    label: "Maksymalna oszczędność na odsetkach"
  },
  cta: {
    label: "Sprawdź swoje oszczędności",
    action: () => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })
  }
};

export const MORTGAGE_REFINANCE_DETAILS: ModalDetails = {
  title: "Refinansowanie kredytu hipotecznego",
  tagline: "Uwolnij potencjał swojego kredytu mieszkaniowego",
  description: "Refinansowanie kredytu hipotecznego to proces zamiany obecnego kredytu na nowy, z korzystniejszymi warunkami. Dzięki naszej wiedzy i doświadczeniu pomożemy Ci uzyskać niższą ratę, lepsze oprocentowanie lub skrócić okres kredytowania - w zależności od Twoich priorytetów i aktualnej sytuacji finansowej.",
  cta: {
    phone: "539 211 881",
    text: "Zadzwoń i dowiedz się, ile możesz zaoszczędzić dzięki refinansowaniu"
  },
  benefitHighlights: [
    "Obniżenie miesięcznej raty kredytu nawet o 488 zł (12,5%)",
    "Skrócenie okresu kredytowania o ponad 6 lat przy zachowaniu obecnej raty",
    "Możliwość wyboru między niższą ratą a krótszym okresem",
    "Oszczędność na odsetkach nawet 299 400 zł przy kredycie 500 000 zł"
  ],
  whyUs: [
    "Analizujemy oferty wszystkich banków na rynku, a nie tylko wybranych partnerów",
    "Doświadczenie w negocjacjach z bankami pozwala nam uzyskać lepsze warunki",
    "Kompleksowa obsługa całego procesu refinansowania - od analizy po podpisanie umowy",
    "Bezpłatne doradztwo i transparentne informacje o wszystkich kosztach"
  ],
  processComparison: {
    withUs: [
      {
        step: "Bezpłatna analiza opłacalności",
        description: "Szczegółowa ocena potencjalnych oszczędności i kosztów",
        icon: "Calculator",
        highlight: true
      },
      {
        step: "Przygotowanie dokumentacji",
        description: "Kompleksowa pomoc w przygotowaniu wszystkich wymaganych dokumentów",
        icon: "FileText"
      },
      {
        step: "Negocjacje z bankami",
        description: "Uzyskanie najkorzystniejszych warunków dzięki naszemu doświadczeniu",
        icon: "HandShake"
      },
      {
        step: "Szybka realizacja procesu",
        description: "Sprawne przeprowadzenie przez cały proces, zwykle 14-21 dni",
        icon: "Clock",
        highlight: true
      }
    ],
    withoutUs: [
      {
        step: "Samodzielne poszukiwania",
        description: "Czasochłonne porównywanie ofert różnych banków na własną rękę",
        icon: "Search",
        warning: true
      },
      {
        step: "Brak wiedzy o ukrytych kosztach",
        description: "Ryzyko przeoczenia ważnych opłat i warunków umowy",
        icon: "AlertTriangle",
        warning: true
      },
      {
        step: "Standardowe warunki",
        description: "Brak możliwości negocjacji lepszych warunków",
        icon: "ThumbsDown",
        warning: true
      },
      {
        step: "Wydłużony proces",
        description: "Typowo 60-90 dni na realizację całego procesu",
        icon: "Hourglass",
        warning: true
      }
    ]
  },
  calculations: {
    example: "Przykład refinansowania kredytu hipotecznego w 2025 roku (kwota 500 000 zł, 25 lat)",
    before: "Obecny kredyt hipoteczny:\n• Pozostała kwota do spłaty: 500 000 zł\n• Okres pozostały do spłaty: 25 lat\n• Oprocentowanie zmienne: 8,11% (WIBOR 5,81% + marża 2,30%)\n• Miesięczna rata: 3 896 zł\n• Całkowity koszt odsetek: 668 676 zł",
    after: "OPCJA 1: Niższa rata, ten sam okres kredytowania\n\nZmienne oprocentowanie:\n• Oprocentowanie: 7,41% (WIBOR 5,81% + marża 1,60%)\n• Nowa miesięczna rata: 3 666 zł\n• Miesięczna oszczędność: 230 zł\n• Oszczędność na odsetkach: 68 955 zł\n\nStałe oprocentowanie (przez 5 lat):\n• Oprocentowanie: 6,60%\n• Nowa miesięczna rata: 3 407 zł\n• Miesięczna oszczędność: 488 zł\n• Potencjalna oszczędność na odsetkach: jeszcze większa przy utrzymaniu niskiej stopy",
    savings: "OPCJA 2: Ta sama rata, krótszy okres kredytowania\n\nZmienne oprocentowanie:\n• Utrzymana obecna rata: 3 896 zł\n• Skrócony okres kredytowania: 21 lat i 4 miesiące\n• Skrócenie kredytu o: 3 lata i 8 miesięcy (44 miesiące)\n• Oszczędność na odsetkach: 173 310 zł\n\nStałe oprocentowanie (przez 5 lat):\n• Utrzymana obecna rata: 3 896 zł\n• Skrócony okres kredytowania: 18 lat i 7 miesięcy\n• Skrócenie kredytu o: 6 lat i 5 miesięcy (77 miesięcy)\n• Oszczędność na odsetkach: 299 400 zł",
    extra: "Ważne: Przyśpieszenie spłaty kredytu o 6 lat i 5 miesięcy oznacza uniknięcie 77 rat po 3 896 zł, co daje dodatkową oszczędność 299 400 zł. Refinansowanie ze stałym oprocentowaniem dodatkowo zabezpiecza przed ewentualnym wzrostem stóp procentowych w przyszłości."
  },
  benefits: [
    "Niższa miesięczna rata o nawet 488 zł dzięki korzystniejszej marży (o 0,7 p.p. niższej)",
    "Możliwość wyboru między oprocentowaniem zmiennym a stałym",
    "Możliwość skrócenia okresu kredytowania nawet o 6 lat i 5 miesięcy",
    "Uwolnienie się od niepotrzebnych produktów dodatkowych",
    "Możliwość konsolidacji innych zobowiązań",
    "Zmiana zabezpieczenia kredytu (np. przy przeprowadzce)"
  ],
  limitedTimeOffer: {
    text: "Bezpłatna analiza opłacalności refinansowania Twojego kredytu hipotecznego",
    expiry: "30.04.2025"
  },
  faq: [
    {
      question: "Czy opłaca się refinansować kredyt hipoteczny w 2025 roku?",
      answer: "Tak, w 2025 roku refinansowanie jest szczególnie opłacalne z kilku powodów: 1) Banki konkurują o klientów oferując niższe marże niż kilka lat temu (obniżka nawet o 0,7 p.p.), 2) Według analiz rynkowych, obecnie około 70% kredytów hipotecznych udzielonych przed 2020 rokiem ma zawyżone marże w stosunku do obecnych standardów, 3) Stopy procentowe mają tendencję spadkową, co dodatkowo zwiększa opłacalność. Refinansowanie jest zazwyczaj korzystne, gdy: różnica między obecną a nową marżą wynosi minimum 0,5-0,7 p.p., pozostał znaczący okres spłaty (powyżej 15 lat), oraz koszty refinansowania zwrócą się w perspektywie 2-3 lat."
    },
    {
      question: "Co jest bardziej opłacalne: niższa rata czy skrócenie okresu kredytowania?",
      answer: "Wybór zależy od Twoich priorytetów finansowych. Niższa rata jest korzystna, gdy: potrzebujesz zwiększyć miesięczną płynność finansową, chcesz przeznaczyć zaoszczędzone środki na inne cele (np. inwestycje), lub Twoja sytuacja finansowa jest niestabilna. Skrócenie okresu kredytowania jest lepsze, gdy: zależy Ci na maksymalnej oszczędności na odsetkach (nawet 299 400 zł przy kredycie 500 000 zł), chcesz szybciej uwolnić się od zobowiązań kredytowych, lub Twoja sytuacja finansowa jest stabilna i możesz nadal płacić dotychczasową ratę."
    },
    {
      question: "Jakie są koszty refinansowania kredytu hipotecznego?",
      answer: "Koszty refinansowania mogą obejmować: 1) Prowizję za udzielenie nowego kredytu (obecnie 0-2% kwoty kredytu, często z możliwością negocjacji), 2) Koszt wyceny nieruchomości (400-1000 zł), 3) Opłaty notarialne i sądowe związane z ustanowieniem nowej hipoteki (1000-2000 zł), 4) Ewentualną opłatę za wcześniejszą spłatę obecnego kredytu. Łączne koszty refinansowania zazwyczaj wynoszą 2000-10000 zł, w zależności od kwoty kredytu i warunków. W większości przypadków koszty te zwracają się już w pierwszym roku dzięki niższym ratom."
    },
    {
      question: "Ile trwa proces refinansowania kredytu hipotecznego?",
      answer: "Przy współpracy z doradcą kredytowym, cały proces zajmuje typowo 14-21 dni i składa się z następujących etapów: 1) Analiza opłacalności i wybór banku (1-2 dni), 2) Przygotowanie i złożenie wniosku kredytowego wraz z wymaganą dokumentacją (3-4 dni), 3) Rozpatrzenie wniosku przez bank (7-10 dni), 4) Podpisanie umowy kredytowej i ustanowienie zabezpieczeń (3-5 dni). Samodzielne przeprowadzenie tego procesu zwykle trwa 60-90 dni."
    },
    {
      question: "Czy mogę zmienić bank podczas refinansowania?",
      answer: "Tak, zmiana banku jest istotą refinansowania. Nowy bank spłaca całość kredytu w obecnym banku, a Ty zaciągasz nowy kredyt na lepszych warunkach. Zaletą zmiany banku jest możliwość wynegocjowania lepszych warunków jako nowy klient, a także uwolnienie się od niedogodnych warunków u obecnego kredytodawcy."
    },
    {
      question: "Czy przy refinansowaniu mogę zmienić oprocentowanie ze zmiennego na stałe?",
      answer: "Tak, refinansowanie jest doskonałą okazją do zmiany rodzaju oprocentowania. Obecnie (marzec 2025) większość banków oferuje stałe oprocentowanie na okres 5-7 lat, po którym kredyt przechodzi na oprocentowanie zmienne. Przy obecnym poziomie stóp procentowych, oprocentowanie stałe wynosi średnio 6,60%, podczas gdy zmienne kształtuje się na poziomie około 7,41%."
    }
  ],
  nextSteps: [
    "Skontaktuj się z nami telefonicznie lub przez formularz",
    "Przygotuj podstawowe informacje o swoim obecnym kredycie",
    "Otrzymasz bezpłatną analizę opłacalności refinansowania",
    "Wybór najkorzystniejszej opcji i przygotowanie dokumentacji",
    "Złożenie wniosku i monitoring procesu",
    "Podpisanie umowy i uruchomienie nowego kredytu"
  ]
};