import type { CaseStudy } from '../types';

export const COST_REDUCTION_CASE: CaseStudy = {
  id: "cost-reduction-retail",
  client: "Marek",
  clientOccupation: "Właściciel",
  clientLocation: "Sieć sklepów spożywczych",
  clientTypeTag: "Handel detaliczny spożywczy",
  image: "https://images.unsplash.com/photo-1542838132-92c53300491e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Z3JvY2VyeXxlbnwwfHwwfHx8MA%3D%3D",
  title: "Optymalizacja kosztów finansowania sieci sklepów - oszczędność 135 000 zł rocznie",
  description: "Przeprowadziliśmy kompleksową restrukturyzację zadłużenia sieci sklepów spożywczych, redukując koszty obsługi o 42% i uwalniając zabezpieczenia, co umożliwiło dalszy rozwój biznesu.",
  result: "Oszczędność 135 000 zł rocznie + uwolnienie zabezpieczeń",
  timelineInfo: "Pełny efekt w 2 miesiące",
  financialBenefit: "Redukcja kosztów finansowania o 42%",
  savingsAmount: "135000",
  savingsPercentage: 42,
  rating: 5,
  service: "optimization",
  challenges: [
    "Rozproszenie finansowania w 7 różnych instytucjach z różnymi terminami płatności",
    "Wysokie i nieregularne obciążenia finansowe wpływające na płynność",
    "Nadmierne zabezpieczenia blokujące możliwości rozwojowe",
    "Nieoptymalne warunki kredytowe negocjowane w różnych okresach",
    "Czasochłonna administracja związana z obsługą wielu umów kredytowych"
  ],
  solutions: [
    "Kompleksowa analiza całej struktury finansowania i identyfikacja obszarów optymalizacji",
    "Konsolidacja 7 różnych kredytów i leasingów w 2 produkty finansowe",
    "Renegocjacja warunków cenowych z wykorzystaniem efektu skali",
    "Optymalizacja zabezpieczeń i uwolnienie nadmiarowych zabezpieczeń",
    "Dostosowanie harmonogramów spłat do sezonowości biznesu"
  ],
  directQuote: "Przez lata myślałem, że wysokie koszty finansowania to po prostu cena za rozwój biznesu. Nie zdawałem sobie sprawy, jak nieefektywna była nasza struktura kredytowa. Profesjonalna optymalizacja nie tylko przyniosła nam znaczące oszczędności, ale też uwolniła zabezpieczenia, dzięki czemu mogliśmy otworzyć kolejny sklep. Żałuję tylko, że nie zrobiłem tego wcześniej - przez ostatnie 3 lata przepłaciłem ponad 400 000 złotych.",
  whatIfScenario: "Gdyby Marek nie zdecydował się na optymalizację kosztów finansowania, jego firma nadal płaciłaby o 135 000 zł więcej rocznie za obsługę zadłużenia. W perspektywie 5 lat oznaczałoby to stratę ponad 675 000 złotych. Co więcej, nadmierne zabezpieczenia nadal blokowałyby możliwości rozwojowe, uniemożliwiając otwarcie nowych lokalizacji. Rosnąca konkurencja w branży detalicznej mogłaby doprowadzić do utraty udziału w rynku i stopniowego spadku rentowności biznesu.",
  beforeAfter: {
    before: "7 różnych kredytów i leasingów w 5 instytucjach finansowych. Miesięczne obciążenia na poziomie 26 700 zł z nieregularnymi skokami do 35 000 zł. Średnie oprocentowanie: WIBOR + 3,9%. Zabezpieczenia na wszystkich nieruchomościach firmowych i prywatnym domu właściciela. Kilkanaście godzin miesięcznie poświęcanych na administrację kredytową.",
    after: "2 produkty finansowe w jednym banku. Stałe miesięczne obciążenie: 15 500 zł. Średnie oprocentowanie: WIBOR + 1,8%. Zwolnienie zabezpieczeń na domu właściciela i jednej nieruchomości firmowej. Uproszczona administracja zajmująca 2 godziny miesięcznie. Możliwość otwarcia nowego sklepu dzięki uwolnionym zabezpieczeniom."
  },
  reasoning: "Optymalizacja kosztów finansowania w przypadku sieci sklepów spożywczych była szczególnie uzasadniona ze względu na specyfikę branży: niskie marże, dużą konkurencję i znaczące potrzeby kapitałowe. Rozproszenie finansowania w wielu instytucjach, które narastało wraz z rozwojem sieci, prowadziło do nieefektywności cenowej (brak efektu skali) oraz operacyjnej (skomplikowana administracja). Konsolidacja zadłużenia pozwoliła wykorzystać efekt skali w negocjacjach, a uwolnienie nadmiarowych zabezpieczeń umożliwiło dalszy rozwój. Kluczowym czynnikiem sukcesu była kompleksowa analiza całej struktury finansowania, a nie tylko poszczególnych produktów, co pozwoliło zidentyfikować wszystkie obszary optymalizacji.",
  fullStory: {
    situation: "Marek, 47-letni przedsiębiorca, zbudował w ciągu 12 lat sieć 6 sklepów spożywczych typu convenience w średniej wielkości mieście i okolicznych miejscowościach. Firma osiągała roczne przychody na poziomie 8,5 mln zł i zatrudniała 28 osób. Każdy etap rozwoju sieci finansowany był oddzielnie - począwszy od pierwszego kredytu inwestycyjnego na otwarcie pierwszego sklepu, przez kolejne kredyty na następne lokalizacje, kredyty obrotowe na zakup towarów, aż po leasingi na wyposażenie i samochody dostawcze. Z czasem struktura finansowania stała się bardzo skomplikowana: 7 różnych kredytów i leasingów w 5 instytucjach finansowych o łącznej wartości 2,1 mln zł. Miesięczne obciążenia z tytułu rat i odsetek wynosiły średnio 26 700 zł, ale występowały miesiące, gdy kwota ta wzrastała nawet do 35 000 zł ze względu na różne harmonogramy spłat.",
    challenge: "Głównym wyzwaniem była nieefektywna struktura finansowania, która narastała organicznie wraz z rozwojem biznesu. Marek nigdy nie miał czasu, by spojrzeć na nią całościowo. Każdy kredyt negocjowany był oddzielnie, często pod presją czasu i bez porównywania ofert różnych banków. Skutkowało to wysokimi marżami (średnio WIBOR + 3,9%) i nadmiernymi zabezpieczeniami. Wszystkie nieruchomości firmowe były obciążone hipotekami, a dodatkowo bank zażądał zabezpieczenia na prywatnym domu Marka. Nieregularne i wysokie obciążenia finansowe powodowały okresowe problemy z płynnością, szczególnie w miesiącach o niższej sprzedaży. Marek rozważał otwarcie kolejnego sklepu, ale brak wolnych zabezpieczeń uniemożliwiał pozyskanie finansowania. Dodatkowo, obsługa administracyjna tylu umów kredytowych pochłaniała kilkanaście godzin miesięcznie, które Marek mógłby poświęcić na rozwój biznesu.",
    solution: "Przełom nastąpił, gdy znajomy przedsiębiorca polecił Markowi spotkanie z doradcą finansowym specjalizującym się w optymalizacji kosztów finansowania. Doradca przeprowadził kompleksową analizę całej struktury zadłużenia, identyfikując obszary nieefektywności i potencjalne oszczędności. Następnie przygotował strategię optymalizacji, która obejmowała: 1) Konsolidację wszystkich zobowiązań w jednym banku, 2) Wykorzystanie efektu skali w negocjacjach warunków cenowych, 3) Racjonalizację zabezpieczeń, 4) Dostosowanie harmonogramu spłat do sezonowości biznesu. Doradca przeprowadził negocjacje z kilkoma bankami, uzyskując najlepsze warunki od instytucji, która zaoferowała konsolidację całego zadłużenia w formie dwóch produktów: kredytu inwestycyjnego na finansowanie nieruchomości i długoterminowego kredytu obrotowego na pozostałe potrzeby. Dzięki profesjonalnie przygotowanej dokumentacji i strategii negocjacyjnej, udało się uzyskać znacznie lepsze warunki niż te, które Marek miał wcześniej: średnia marża spadła z 3,9% do 1,8%, a część zabezpieczeń została zwolniona, w tym hipoteka na domu Marka i jednej z nieruchomości firmowych.",
    details: {
      property: {
        type: "Sieć sklepów spożywczych typu convenience",
        revenue: "8,5 mln zł rocznie",
        locations: "6 sklepów w średniej wielkości mieście i okolicach",
        employees: "28 osób",
        experience: "12 lat na rynku"
      },
      loan: {
        beforeStructure: "7 kredytów i leasingów w 5 instytucjach",
        beforeAmount: "2,1 mln zł łącznego zadłużenia",
        beforeRate: "WIBOR + średnio 3,9% marży",
        beforeCosts: "320 000 zł rocznie (26 700 zł miesięcznie)",
        afterStructure: "2 kredyty w jednym banku",
        afterRate: "WIBOR + 1,8% marży",
        afterCosts: "185 000 zł rocznie (15 500 zł miesięcznie)",
        reduction: "42% redukcji kosztów obsługi zadłużenia"
      },
      timeline: {
        initialMeeting: "1 dzień",
        analysis: "2 tygodnie",
        negotiations: "3 tygodnie",
        implementation: "45 dni od pierwszego spotkania",
        fullEffect: "2 miesiące od pierwszego spotkania"
      },
      savings: {
        monthlySavings: "11 200 zł miesięcznie",
        annualSavings: "135 000 zł rocznie",
        fiveYearSavings: "Ponad 675 000 zł w ciągu 5 lat",
        releasedCollateral: "Zwolnienie hipoteki na domu właściciela i jednej nieruchomości firmowej",
        timeEfficiency: "Oszczędność około 10-15 godzin miesięcznie na administracji kredytowej",
        businessGrowth: "Możliwość otwarcia nowego sklepu dzięki uwolnionym zabezpieczeniom"
      }
    }
  }
};
