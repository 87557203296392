import { AlertCircle } from 'lucide-react';
import type { ProblemsSolutionsData } from './types';

export const businessProblemsData: ProblemsSolutionsData = {
  title: "Kapitał dla rozwoju Twojego biznesu",
  subtitle: "Poznaj, jak skutecznie przyspieszamy finansowanie i eliminujemy bariery w rozwoju Twojej firmy",
  problems: [
    {
      icon: AlertCircle,
      problem: "Restrykcyjne wymagania bankowe",
      solution: "Strategiczne przygotowanie do finansowania",
      details: [
        "Profesjonalna strukturyzacja dokumentacji finansowej zwiększająca szanse akceptacji",
        "Bezpośredni dostęp do decydentów bankowych dzięki naszym relacjom biznesowym",
        "Kompleksowe wsparcie w spełnieniu wymogów formalnych nawet dla młodych firm"
      ]
    },
    {
      icon: AlertCircle,
      problem: "Ograniczona zdolność kredytowa firmy",
      solution: "Optymalizacja finansowa dla zwiększenia limitów",
      details: [
        "Ekspercka analiza sprawozdań finansowych i identyfikacja ukrytego potencjału kredytowego",
        "Implementacja sprawdzonych metod poprawy wskaźników finansowych kluczowych dla banków",
        "Dostęp do alternatywnych źródeł finansowania niedostępnych dla zwykłych klientów"
      ]
    },
    {
      icon: AlertCircle,
      problem: "Niekonkurencyjne warunki kredytowania",
      solution: "Eksperckie negocjacje na poziomie instytucjonalnym",
      details: [
        "Wykorzystanie efektu skali i naszej pozycji rynkowej do uzyskania preferencyjnych stawek",
        "Eliminacja ukrytych opłat i prowizji często pomijanych w standardowych ofertach bankowych",
        "Gwarancja najniższego całkowitego kosztu finansowania dopasowanego do specyfiki branży"
      ]
    },
    {
      icon: AlertCircle,
      problem: "Pilna potrzeba płynności finansowej",
      solution: "Błyskawiczny dostęp do finansowania",
      details: [
        "Przyspieszony proces oceny wniosku dzięki priorytetowym ścieżkom w instytucjach finansowych",
        "Natychmiastowe rozwiązania pomostowe zabezpieczające płynność w oczekiwaniu na kredyt",
        "Strategiczna koordynacja procesu zapewniająca środki dokładnie wtedy, gdy są potrzebne"
      ]
    }
  ]
};