import React, { useState, useEffect } from 'react';
import { Calculator as CalculatorIcon, ArrowRight, BadgePercent, Shield, Sparkles, Building2, Home } from 'lucide-react';

interface CalculationResult {
  loanAmount: number;
  ltv: number;
}

export default function Calculator() {
  const [propertyValue, setPropertyValue] = useState<number>(1000000);
  const [propertyType, setPropertyType] = useState<'residential' | 'commercial'>('residential');
  const [isVisible, setIsVisible] = useState(false);

  // Calculate loan amount based on property value, type and period
  const calculateLoanAmount = (value: number, type: 'residential' | 'commercial', months: number): CalculationResult => {
    // Współczynniki LTV (Loan to Value) zależne od typu nieruchomości i okresu
    const ltv = type === 'residential' 
      ? (months === 96 ? 1.77 : 1.13)  // dla nieruchomości mieszkalnej
      : (months === 96 ? 1.44 : 0.91); // dla nieruchomości komercyjnej
    
    // Obliczenie kwoty kredytu
    const calculatedAmount = value * ltv;
    
    return {
      loanAmount: calculatedAmount,
      ltv: ltv * 100
    };
  };

  // Calculate results for both periods
  const results96 = calculateLoanAmount(propertyValue, propertyType, 96);
  const results120 = calculateLoanAmount(propertyValue, propertyType, 120);

  // Format currency
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN',
      maximumFractionDigits: 0
    }).format(value);
  };

  // Format percentage
  const formatPercentage = (value: number) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value / 100);
  };

  // Intersection Observer for animation
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.2 }
    );

    const element = document.getElementById('calculator-section');
    if (element) {
      observer.observe(element);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <section id="calculator-section" className="py-8 sm:py-12 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <div className={`
          max-w-6xl mx-auto bg-white rounded-2xl shadow-lg overflow-hidden
          transform transition-all duration-700 ease-out backdrop-blur-sm
          hover:shadow-xl hover:shadow-[#003A30]/5
          ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'}
        `}>
          <div className="p-4 sm:p-6 md:p-8 relative">
            {/* Decorative background elements */}
            <div className="absolute inset-0 bg-gradient-to-br from-[#003A30]/[0.02] via-transparent to-[#CBA331]/[0.02] pointer-events-none" />
            <div className="absolute -top-24 -right-24 w-48 h-48 bg-[#003A30]/5 rounded-full blur-3xl opacity-20" />
            <div className="absolute -bottom-24 -left-24 w-48 h-48 bg-[#CBA331]/5 rounded-full blur-3xl opacity-20" />

            {/* Header */}
            <div className="flex items-center justify-center gap-2 sm:gap-3 mb-6 sm:mb-8 relative">
              <div className="relative">
                <CalculatorIcon className="w-6 h-6 sm:w-8 sm:h-8 text-[#CBA331] transform transition-transform duration-300 hover:scale-110" />
                <Sparkles className="w-3 h-3 sm:w-4 sm:h-4 text-[#CBA331]/50 absolute -top-1 -right-1 animate-pulse" />
              </div>
              <h2 className="text-xl sm:text-2xl font-bold text-[#003A30] relative">
                Kalkulator kredytu firmowego
                <div className="absolute -bottom-1 left-0 w-full h-0.5 bg-gradient-to-r from-[#CBA331]/0 via-[#CBA331] to-[#CBA331]/0" />
              </h2>
            </div>

            <div className="grid md:grid-cols-2 gap-6 md:gap-8">
              {/* Left Column - Inputs */}
              <div className="space-y-6">
                {/* Property Value Slider */}
                <div className="relative group">
                  <div className="flex justify-between items-center mb-2">
                    <label className="text-sm font-medium text-gray-700 transition-colors group-hover:text-[#003A30]">
                      Wartość nieruchomości
                    </label>
                    <span className="text-lg sm:text-xl font-bold text-[#003A30] transition-colors duration-300 group-hover:text-[#CBA331]">
                      {formatCurrency(propertyValue)}
                    </span>
                  </div>
                  <input
                    type="range"
                    min="300000"
                    max="2000000"
                    step="50000"
                    value={propertyValue}
                    onChange={(e) => setPropertyValue(Number(e.target.value))}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                  />
                  <div className="flex justify-between text-xs text-gray-500 mt-1">
                    <span>300 tys.</span>
                    <span>2 mln</span>
                  </div>
                </div>

                {/* Property Type Selection */}
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">
                    Typ nieruchomości
                  </label>
                  <div className="grid grid-cols-2 gap-3">
                    <button
                      onClick={() => setPropertyType('residential')}
                      className={`flex items-center gap-2 px-4 py-3 rounded-lg border-2 transition-all duration-300 ${
                        propertyType === 'residential'
                          ? 'border-[#003A30] bg-[#003A30] text-white'
                          : 'border-gray-200 hover:border-[#003A30] text-gray-700'
                      }`}
                    >
                      <Home className="w-5 h-5" />
                      <span>Mieszkalna</span>
                    </button>
                    <button
                      onClick={() => setPropertyType('commercial')}
                      className={`flex items-center gap-2 px-4 py-3 rounded-lg border-2 transition-all duration-300 ${
                        propertyType === 'commercial'
                          ? 'border-[#003A30] bg-[#003A30] text-white'
                          : 'border-gray-200 hover:border-[#003A30] text-gray-700'
                      }`}
                    >
                      <Building2 className="w-5 h-5" />
                      <span>Komercyjna</span>
                    </button>
                  </div>
                </div>

                {/* BGK Information */}
                <div className="bg-blue-50 rounded-xl p-4 flex items-start gap-3">
                  <Shield className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
                  <div>
                    <h3 className="text-sm font-semibold text-blue-700 mb-1">
                      Gwarancja BGK
                    </h3>
                    <p className="text-sm text-blue-600">
                      Kredyt wymaga dodatkowego zabezpieczenia w formie poręczenia z Banku Gospodarstwa Krajowego (BGK)
                    </p>
                  </div>
                </div>
              </div>

              {/* Right Column - Results */}
              <div className="space-y-6">
                {/* 96 months result */}
                <div className="group bg-gradient-to-br from-[#003A30]/5 to-transparent rounded-xl p-6 transition-all duration-300 hover:shadow-lg hover:from-[#003A30]/10">
                  <div className="flex items-center gap-2 mb-2">
                    <BadgePercent className="w-5 h-5 text-[#CBA331] transition-transform duration-300 group-hover:scale-110" />
                    <h3 className="text-sm font-semibold text-[#003A30]">Okres 96 miesięcy (8 lat)</h3>
                  </div>
                  <p className="text-2xl font-bold text-[#003A30] mb-1 transition-colors duration-300 group-hover:text-[#CBA331]">
                    {formatCurrency(results96.loanAmount)}
                  </p>
                  <p className="text-sm text-gray-600">
                    LTV: {formatPercentage(results96.ltv)}
                  </p>
                </div>

                {/* 120 months result */}
                <div className="group bg-gradient-to-br from-[#CBA331]/5 to-transparent rounded-xl p-6 transition-all duration-300 hover:shadow-lg hover:from-[#CBA331]/10">
                  <div className="flex items-center gap-2 mb-2">
                    <BadgePercent className="w-5 h-5 text-[#CBA331] transition-transform duration-300 group-hover:scale-110" />
                    <h3 className="text-sm font-semibold text-[#003A30]">Okres 120 miesięcy (10 lat)</h3>
                  </div>
                  <p className="text-2xl font-bold text-[#003A30] mb-1 transition-colors duration-300 group-hover:text-[#CBA331]">
                    {formatCurrency(results120.loanAmount)}
                  </p>
                  <p className="text-sm text-gray-600">
                    LTV: {formatPercentage(results120.ltv)}
                  </p>
                </div>
              </div>
            </div>

            {/* CTA Button */}
            <div className="mt-8 text-center">
              <button
                onClick={() => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })}
                className="inline-flex items-center justify-center px-6 sm:px-8 py-3 sm:py-4 bg-[#003A30] text-white rounded-lg hover:bg-[#CBA331] transition-all duration-300 group text-sm sm:text-base font-medium"
              >
                Otrzymaj szczegółową ofertę
                <ArrowRight className="w-4 h-4 sm:w-5 sm:h-5 ml-2 group-hover:translate-x-1 transition-transform duration-300" />
              </button>
              
              {/* Disclaimer */}
              <p className="mt-6 text-xs text-gray-500 text-center max-w-xl mx-auto">
                * Przedstawione wyliczenia mają charakter orientacyjny. 
                Szczegółowa oferta zostanie przygotowana po analizie dokumentów finansowych.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}