import React, { useState, useEffect, useRef } from 'react';
import { AlertCircle, CheckCircle2 } from 'lucide-react';

export interface Concern {
  concern: string;
  solution: string;
}

interface ConcernsProps {
  title?: string;
  subtitle?: string;
  concerns: Concern[];
  className?: string;
}

export default function Concerns({
  title = "Rozwiewamy wątpliwości",
  subtitle = "Poznaj rozwiązania typowych obaw",
  concerns,
  className = ''
}: ConcernsProps) {
  const [visibleCards, setVisibleCards] = useState<number[]>([]);
  const cardsRef = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const cardIndex = cardsRef.current.findIndex(ref => ref === entry.target);
            if (cardIndex !== -1 && !visibleCards.includes(cardIndex)) {
              setVisibleCards(prev => [...prev, cardIndex].sort((a, b) => a - b));
            }
          }
        });
      },
      {
        threshold: 0.2,
        rootMargin: '-10% 0px -10% 0px'
      }
    );

    cardsRef.current.forEach(card => {
      if (card) observer.observe(card);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section className={`py-12 md:py-20 bg-gradient-to-b from-white to-gray-50 ${className}`}>
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-12">
          <h2 className="text-2xl md:text-4xl font-bold text-[#003A30] mb-4">
            {title}
          </h2>
          <p className="text-gray-600 text-lg">
            {subtitle}
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
          {concerns.map((item, index) => (
            <div 
              key={index}
              ref={el => cardsRef.current[index] = el}
              className={`
                relative bg-white rounded-xl shadow-lg overflow-hidden
                transform transition-all duration-700 ease-out
                ${visibleCards.includes(index) 
                  ? 'translate-y-0 scale-100 opacity-100' 
                  : 'translate-y-8 scale-95 opacity-0'
                }
                hover:shadow-xl hover:-translate-y-1 group
              `}
              style={{
                transitionDelay: `${index * 100}ms`
              }}
            >
              {/* Top Gradient Bar */}
              <div 
                className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-[#003A30] to-[#CBA331] transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100" 
                aria-hidden="true"
              />
              
              <div className="p-6">
                <div className={`
                  flex items-start gap-4 mb-4
                  transform transition-all duration-500
                  ${visibleCards.includes(index) ? 'translate-x-0 opacity-100' : 'translate-x-4 opacity-0'}
                `}>
                  <div className={`
                    flex-shrink-0 w-10 h-10 rounded-full bg-red-50 flex items-center justify-center
                    transform transition-transform duration-500
                    ${visibleCards.includes(index) ? 'scale-100' : 'scale-90'}
                  `}>
                    <AlertCircle className="w-6 h-6 text-red-500" />
                  </div>
                  <p className="text-lg font-bold text-red-600">{item.concern}</p>
                </div>
                <div className={`
                  flex items-start gap-4
                  transform transition-all duration-500 delay-200
                  ${visibleCards.includes(index) ? 'translate-x-0 opacity-100' : 'translate-x-4 opacity-0'}
                `}>
                  <div className={`
                    flex-shrink-0 w-10 h-10 rounded-full bg-emerald-50 flex items-center justify-center
                    transform transition-transform duration-500
                    ${visibleCards.includes(index) ? 'scale-100' : 'scale-90'}
                  `}>
                    <CheckCircle2 className="w-6 h-6 text-emerald-500" />
                  </div>
                  <p className="text-gray-600">{item.solution}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}