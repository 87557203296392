import React, { useState } from 'react';
import { LineChart } from 'lucide-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import type { FormData } from '../types';
import { calculateCreditworthiness } from '../utils/calculations';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface AnalysisChartProps {
  formData: FormData;
}

export default function AnalysisChart({ formData }: AnalysisChartProps) {
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 5;

  // Format currency
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  // Generate analysis data with 5% increments
  const generateAnalysisData = () => {
    const incomeData = [];
    const baseIncome = formData.income;
    
    // Income analysis (0% to +100% from current income) in 5% increments
    for (let i = 0; i <= 100; i += 5) {
      const modifiedIncome = baseIncome * (1 + i / 100);
      const modifiedFormData = {
        ...formData,
        income: modifiedIncome
      };
      
      const results = calculateCreditworthiness(modifiedFormData);
      
      incomeData.push({
        change: i,
        value: modifiedIncome,
        creditworthinessVariable: results.variableRateCapacity,
        creditworthinessFixed: results.fixedRateCapacity
      });
    }

    return incomeData;
  };

  const analysisData = generateAnalysisData();

  // Prepare chart data
  const chartData = {
    labels: analysisData.map(d => `+${d.change}%`),
    datasets: [
      {
        label: 'Zdolność kredytowa (stopa zmienna)',
        data: analysisData.map(d => d.creditworthinessVariable),
        borderColor: '#003A30',
        backgroundColor: '#003A30',
        borderWidth: 3,
        fill: false,
        tension: 0.4,
        pointRadius: 4,
        pointHoverRadius: 6,
        pointBackgroundColor: '#003A30',
        yAxisID: 'y'
      },
      {
        label: 'Zdolność kredytowa (stopa stała)',
        data: analysisData.map(d => d.creditworthinessFixed),
        borderColor: '#CBA331',
        backgroundColor: '#CBA331',
        borderWidth: 3,
        fill: false,
        tension: 0.4,
        pointRadius: 4,
        pointHoverRadius: 6,
        pointBackgroundColor: '#CBA331',
        yAxisID: 'y'
      },
      {
        label: 'Dochód',
        data: analysisData.map(d => d.value),
        borderColor: '#6B7280',
        backgroundColor: '#6B7280',
        borderWidth: 2,
        borderDash: [5, 5],
        fill: false,
        tension: 0.4,
        pointRadius: 3,
        pointHoverRadius: 5,
        pointBackgroundColor: '#6B7280',
        yAxisID: 'y1'
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          usePointStyle: true,
          padding: 20,
          font: {
            size: 12,
            weight: '600' as const
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        titleColor: '#1a1a1a',
        titleFont: {
          size: 13,
          weight: '600' as const
        },
        bodyColor: '#1a1a1a',
        bodyFont: {
          size: 12
        },
        padding: 12,
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1,
        callbacks: {
          label: (context: any) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += formatCurrency(context.parsed.y);
            }
            return label;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(0, 0, 0, 0.05)',
          drawBorder: false
        },
        ticks: {
          font: {
            size: 11
          }
        }
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        title: {
          display: true,
          text: 'Zdolność kredytowa (PLN)',
          font: {
            size: 12,
            weight: '600' as const
          }
        },
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.05)',
          drawBorder: false
        },
        ticks: {
          callback: (value: number) => formatCurrency(value),
          font: {
            size: 11
          }
        }
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        title: {
          display: true,
          text: 'Dochód (PLN)',
          font: {
            size: 12,
            weight: '600' as const
          }
        },
        beginAtZero: true,
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          callback: (value: number) => formatCurrency(value),
          font: {
            size: 11
          }
        }
      }
    }
  };

  // Filter data for table to show only 10% increments
  const tableData = analysisData.filter(item => item.change % 10 === 0);
  
  // Calculate pagination for table data
  const totalPages = Math.ceil(tableData.length / itemsPerPage);
  const currentItems = tableData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden mt-8">
      <div className="p-6 md:p-8">
        <div className="flex items-center gap-3 mb-8">
          <LineChart className="w-6 h-6 text-[#CBA331]" />
          <h2 className="text-xl font-bold text-[#003A30]">
            Analiza wzrostu zdolności kredytowej
          </h2>
        </div>

        {/* Chart */}
        <div className="h-[400px] mb-8">
          <Line data={chartData} options={chartOptions} />
        </div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-50 border-b border-gray-200">
                <th className="px-4 py-3 text-left text-sm font-semibold text-[#003A30]">
                  Wzrost dochodu
                </th>
                <th className="px-4 py-3 text-right text-sm font-semibold text-[#003A30]">
                  Dochód
                </th>
                <th className="px-4 py-3 text-right text-sm font-semibold text-[#003A30]">
                  Zdolność (stopa zmienna)
                </th>
                <th className="px-4 py-3 text-right text-sm font-semibold text-[#003A30]">
                  Zdolność (stopa stała)
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr 
                  key={index}
                  className="border-b border-gray-100 hover:bg-gray-50 transition-colors duration-150"
                >
                  <td className="px-4 py-3 text-sm">
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      +{item.change}%
                    </span>
                  </td>
                  <td className="px-4 py-3 text-sm text-right font-medium text-[#003A30]">
                    {formatCurrency(item.value)}
                  </td>
                  <td className="px-4 py-3 text-sm text-right font-medium text-[#003A30]">
                    {formatCurrency(item.creditworthinessVariable)}
                  </td>
                  <td className="px-4 py-3 text-sm text-right font-medium text-[#003A30]">
                    {formatCurrency(item.creditworthinessFixed)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        {totalPages > 1 && (
          <div className="flex justify-center mt-4">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                onClick={() => setCurrentPage(i + 1)}
                className={`w-8 h-8 mx-1 flex items-center justify-center rounded-md ${
                  currentPage === i + 1
                    ? 'bg-[#003A30] text-white'
                    : 'text-gray-700 hover:bg-gray-100'
                }`}
              >
                {i + 1}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}