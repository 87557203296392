import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import type { BlogPost, BlogPostPreview } from '../types';
import { BLOG_POSTS } from '../data/posts';

interface BlogContextType {
  currentPost: BlogPost | null;
  setCurrentPost: (post: BlogPost | null) => void;
  recentPosts: BlogPostPreview[];
  popularTags: string[];
  isLoading: boolean;
}

const BlogContext = createContext<BlogContextType | undefined>(undefined);

export const BlogProvider = ({ children }: { children: ReactNode }) => {
  const [currentPost, setCurrentPost] = useState<BlogPost | null>(null);
  const [recentPosts, setRecentPosts] = useState<BlogPostPreview[]>([]);
  const [popularTags, setPopularTags] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // Initialize data
  useEffect(() => {
    // Get recent posts
    const sortedPosts = [...BLOG_POSTS].sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
    
    // Convert to preview format
    const recentPostPreviews = sortedPosts.slice(0, 5).map(post => ({
      id: post.id,
      title: post.title,
      slug: post.slug,
      excerpt: post.excerpt,
      image: post.image,
      date: post.date,
      readingTime: post.readingTime,
      category: post.category,
      tags: post.tags,
      author: {
        name: post.author.name,
        avatar: post.author.avatar
      }
    }));
    
    // Get popular tags
    const tagCounts = new Map<string, number>();
    BLOG_POSTS.forEach(post => {
      post.tags.forEach(tag => {
        tagCounts.set(tag, (tagCounts.get(tag) || 0) + 1);
      });
    });
    
    // Sort tags by count and get top 10
    const sortedTags = Array.from(tagCounts.entries())
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10)
      .map(([tag]) => tag);
    
    setRecentPosts(recentPostPreviews);
    setPopularTags(sortedTags);
    setIsLoading(false);
  }, []);

  return (
    <BlogContext.Provider value={{ 
      currentPost, 
      setCurrentPost, 
      recentPosts, 
      popularTags, 
      isLoading 
    }}>
      {children}
    </BlogContext.Provider>
  );
};

export const useBlog = () => {
  const context = useContext(BlogContext);
  if (context === undefined) {
    throw new Error('useBlog must be used within a BlogProvider');
  }
  return context;
};