import { Home, Building2, Hammer } from 'lucide-react';
import type { Card, CardDetails } from '../types';

// Karta produktu dla budowy domu lub zakupu domu do wykończenia
export const HOUSE_BUILD_CARD: Card = {
  id: "house-build",
  icon: Hammer,
  title: "Zbuduj lub wykończ dom swoich marzeń",
  description: "Elastyczne finansowanie dostosowane do etapów budowy i wykończenia",
  benefits: [
    "Wypłata kredytu w transzach - płacisz tylko za ukończone etapy",
    "Nawet 24 miesiące karencji w spłacie kapitału",
    "Możliwość refinansowania już poniesionych kosztów"
  ],
  stats: {
    value: "do 30%",
    label: "Niższe raty w trakcie budowy"
  },
  cta: {
    label: "Sprawdź możliwości finansowania",
    action: () => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })
  }
};

// Szczegóły karty dla budowy domu lub zakupu domu do wykończenia
export const HOUSE_BUILD_DETAILS: CardDetails = {
  title: "Zbuduj lub wykończ dom swoich marzeń",
  tagline: "Finansowanie dopasowane do Twoich potrzeb",
  description: "Budowa własnego domu lub zakup nieruchomości do wykończenia to poważna inwestycja. Zapewniamy kompleksowe wsparcie kredytowe dostosowane do etapów realizacji, profesjonalne doradztwo techniczne i rozwiązania pozwalające zrealizować marzenie o własnym domu bez nadmiernego obciążenia domowego budżetu.",
  cta: {
    phone: "539 211 881",
    text: "Zadzwoń i dowiedz się więcej o możliwościach finansowania budowy lub wykończenia domu"
  },
  benefitHighlights: [
    "Finansowanie dostosowane do faktycznych postępów prac",
    "Niższe raty w okresie budowy dzięki karencji w spłacie kapitału",
    "Elastyczny harmonogram transz dostosowany do etapów inwestycji",
    "Możliwość refinansowania kosztów już poniesionych"
  ],
  whyUs: [
    "Specjalizujemy się w kredytach na budowę i wykończenie domu od 10 lat",
    "Współpracujemy z doświadczonymi inspektorami nadzoru budowlanego",
    "Znamy specyfikę procesu budowlanego i potrafimy dopasować finansowanie",
    "Oferujemy kompleksowe wsparcie formalne i techniczne na każdym etapie"
  ],
  processComparison: {
    withUs: [
      {
        step: "Kompleksowa analiza projektu",
        description: "Szczegółowa analiza dokumentacji i kosztorysu",
        icon: "Search",
        highlight: true
      },
      {
        step: "Finansowanie etapowe",
        description: "Wypłata kredytu w transzach zgodnie z postępem prac",
        icon: "Layers"
      },
      {
        step: "Karencja w spłacie kapitału",
        description: "Niższe raty w okresie budowy",
        icon: "TrendingDown",
        highlight: true
      },
      {
        step: "Wsparcie techniczne",
        description: "Pomoc przy odbiorach częściowych i ocenie postępu prac",
        icon: "CheckCircle"
      }
    ],
    withoutUs: [
      {
        step: "Uproszczona ocena projektu",
        description: "Ryzyko niedoszacowania kosztów i czasu realizacji",
        icon: "FileText",
        warning: true
      },
      {
        step: "Jednorazowa lub kwartalna wypłata",
        description: "Konieczność finansowania różnic z własnych środków",
        icon: "DollarSign",
        warning: true
      },
      {
        step: "Pełna rata od początku",
        description: "Wysokie obciążenie budżetu już w fazie budowy",
        icon: "TrendingUp",
        warning: true
      },
      {
        step: "Brak wsparcia technicznego",
        description: "Samodzielna weryfikacja jakości i zakresu wykonanych prac",
        icon: "AlertTriangle",
        warning: true
      }
    ]
  },
  calculations: {
    example: "Dom o powierzchni 140m² (marzec 2025):\n• Stan surowy zamknięty: ok. 3 800-4 300 zł/m² (530 000-600 000 zł)\n• Stan deweloperski: ok. 5 000-5 700 zł/m² (700 000-800 000 zł)\n• Wykończenie: dodatkowo 1 800-3 000 zł/m² (250 000-420 000 zł)\n• Ceny różnią się w zależności od regionu i standardu",
    before: "Standardowy kredyt budowlany:\n• Jednorazowa wypłata lub maksymalnie 2-3 transze\n• Pełna rata już od początku: około 5 050 zł miesięcznie (przy kredycie 750 000 zł, oprocentowaniu 6,8%)\n• Konieczność finansowania różnic z własnych środków przy wzroście kosztów (10-15% rezerwy)\n• Typowy okres budowy: 24-36 miesięcy\n• Brak elastyczności w przypadku opóźnień lub zmian w projekcie",
    after: "Z naszym wsparciem:\n• Wypłata w 4-6 elastycznych transzach dopasowanych do postępu prac\n• Obniżona rata w okresie budowy: około 3 550 zł miesięcznie (dla kredytu 750 000 zł, dzięki karencji w spłacie kapitału)\n• Karencja w spłacie kapitału: do 24 miesięcy\n• Wbudowana rezerwa na nieprzewidziane wydatki (5-10%)\n• Wsparcie w zarządzaniu budżetem i harmonogramem\n• Elastyczność w przypadku zmian w projekcie\n• Pomoc techniczna przy odbiorach częściowych",
    savings: "Korzyści: około 1 500 zł niższa rata miesięczna przez 2 lata budowy (łączna oszczędność do 36 000 zł), lepsze zarządzanie płynnością finansową, elastyczność dostosowana do rzeczywistego przebiegu budowy oraz minimalizacja ryzyka przekroczenia budżetu",
    extra: "Dodatkowe korzyści: możliwość refinansowania już poniesionych kosztów (np. zakupu działki, przygotowania projektu), opcja kredytu łączonego z innymi źródłami finansowania, dostęp do kredytów z oprocentowaniem stałym dla większego bezpieczeństwa finansowego. Aktualne oprocentowanie kredytów budowlanych (marzec 2025) wynosi od 6,7% do 7,0% w zależności od wkładu własnego i historii kredytowej."
  },
  benefits: [
    "Finansowanie dokładnie dopasowane do rzeczywistych etapów budowy lub wykończenia",
    "Możliwość karencji w spłacie kapitału nawet do 24 miesięcy (zgodnie z art. 37 Ustawy o kredycie hipotecznym)",
    "Elastyczny harmonogram wypłat - płacisz tylko za faktycznie ukończone prace",
    "Możliwość refinansowania kosztów już poniesionych (do 12 miesięcy wstecz)",
    "Wsparcie techniczne przy odbiorach częściowych i ocenie postępu prac",
    "Pomoc w zarządzaniu budżetem budowy i unikaniu przekroczeń kosztów",
    "Specjalne warunki dla domów energooszczędnych (zgodnie z Dyrektywą 2010/31/UE)"
  ],
  limitedTimeOffer: {
    text: "Bezpłatna analiza projektu i kosztorysu budowlanego dla nowych klientów",
    expiry: "30.06.2025"
  },
  faq: [
    {
      question: "Na czym polega karencja w spłacie kapitału i jakie daje korzyści?",
      answer: "Karencja w spłacie kapitału to okres (nawet do 24 miesięcy, zgodnie z najnowszymi ofertami banków w marcu 2025), w którym spłacasz tylko odsetki od wykorzystanej części kredytu, bez spłaty części kapitałowej. Przy kredycie na budowę domu o wartości 750 000 zł i oprocentowaniu 6,8%, karencja pozwala obniżyć ratę z około 5 050 zł do około 3 550 zł miesięcznie w okresie budowy. To znacząca ulga dla domowego budżetu w czasie, gdy ponosisz wiele dodatkowych wydatków związanych z budową. Po zakończeniu okresu karencji rata wzrasta do standardowej wysokości, ale wtedy zazwyczaj budowa jest już zakończona i nie ponosisz dodatkowych kosztów związanych z inwestycją."
    },
    {
      question: "Ile faktycznie kosztuje budowa domu w 2025 roku?",
      answer: "Koszt budowy domu w 2025 roku zależy od wielu czynników. Dla domu o powierzchni 140m²: stan surowy zamknięty to koszt około 3 800-4 300 zł/m² (łącznie 530 000-600 000 zł), stan deweloperski to około 5 000-5 700 zł/m² (łącznie 700 000-800 000 zł), a wykończenie to dodatkowe 1 800-3 000 zł/m² (250 000-420 000 zł). Ceny różnią się w zależności od: 1) Regionu Polski (różnice do 20-30% między największymi miastami a mniejszymi miejscowościami), 2) Standardu wykończenia (ekonomiczny, średni, premium), 3) Technologii budowy (tradycyjna, energooszczędna, prefabrykowana), 4) Ukształtowania terenu i warunków gruntowych. Dodatkowe koszty to przyłącza (10 000-40 000 zł) i zagospodarowanie terenu (15 000-60 000 zł). Przy planowaniu budżetu zawsze rekomendujemy uwzględnienie rezerwy na nieprzewidziane wydatki w wysokości 10-15% wartości inwestycji."
    },
    {
      question: "Jak dokładnie działa finansowanie etapowe przy budowie domu?",
      answer: "Finansowanie etapowe polega na wypłacie kredytu w transzach (zazwyczaj 4-6), zgodnie z faktycznym postępem prac budowlanych. Przed każdą kolejną transzą inspektor nadzoru (wyznaczony przez bank lub rekomendowany przez nas) potwierdza zakończenie poprzedniego etapu, co daje pewność, że środki są wypłacane tylko za faktycznie wykonane prace. To rozwiązanie pozwala lepiej kontrolować budżet i minimalizować ryzyko finansowe, gdyż nie płacisz z góry za prace, które jeszcze nie zostały wykonane. Dodatkowo, dzięki temu systemowi, możesz dostosować tempo realizacji do swoich możliwości finansowych."
    },
    {
      question: "Czy mogę uzyskać kredyt na dokończenie już rozpoczętej budowy domu?",
      answer: "Tak, oferujemy specjalne rozwiązania kredytowe dla inwestycji w toku. W takim przypadku kluczowa jest inwentaryzacja stanu istniejącego oraz wycena już wykonanych prac. Na tej podstawie określany jest aktualny stan zaawansowania budowy (zazwyczaj wyrażony jako procent ukończenia inwestycji) oraz szacowany koszt dokończenia. Możliwe jest również refinansowanie już poniesionych nakładów (do 12 miesięcy wstecz, zgodnie z aktualnymi regulacjami banków). Proces uzyskania takiego kredytu wymaga dokładniejszej dokumentacji technicznej, ale mamy doświadczenie w przeprowadzaniu klientów przez ten proces i współpracujemy z rzeczoznawcami, którzy pomogą w przygotowaniu niezbędnych dokumentów."
    },
    {
      question: "Jaki wkład własny jest wymagany przy kredycie na budowę domu?",
      answer: "Zgodnie z wymogami Komisji Nadzoru Finansowego (marzec 2025), standardowy minimalny wkład własny przy kredycie na budowę domu wynosi 20% wartości inwestycji. Dzięki współpracy z wybranymi bankami, możemy w niektórych przypadkach uzyskać finansowanie z wkładem od 10-15% (z dodatkowym ubezpieczeniem niskiego wkładu). Co ważne, za wkład własny może być uznana już posiadana działka budowlana (jej wartość rynkowa), poniesione koszty prac budowlanych oraz koszty dokumentacji. W przypadku domów energooszczędnych (spełniających wymogi Dyrektywy UE 2018/844), niektóre banki oferują preferencyjne warunki dotyczące zarówno wkładu własnego jak i oprocentowania. Dzięki uwzględnieniu wartości działki, typowy gotówkowy wkład własny może wynosić znacznie mniej niż wymagane 20% całej inwestycji."
    },
    {
      question: "Jak długo trwa proces uzyskania kredytu na budowę domu?",
      answer: "Proces uzyskania kredytu na budowę domu jest dłuższy niż w przypadku standardowego kredytu hipotecznego ze względu na konieczność weryfikacji dokumentacji technicznej. Obecnie (marzec 2025) średni czas od złożenia kompletnego wniosku do uzyskania decyzji kredytowej wynosi 30-45 dni. Z naszym wsparciem proces przebiega sprawniej, dzięki: 1) Profesjonalnemu przygotowaniu dokumentacji (oszczędność 10-14 dni), 2) Równoległemu procesowaniu wniosków w kilku bankach oferujących najlepsze warunki, 3) Doświadczeniu w komunikacji z działami technicznymi banków. Kluczowe dla sprawnego procesu jest kompletna dokumentacja: pozwolenie na budowę, projekt techniczny, szczegółowy kosztorys oraz harmonogram prac. Nasi klienci uzyskują decyzję kredytową średnio o 2 tygodnie szybciej niż klienci indywidualni, dzięki czemu mogą szybciej rozpocząć budowę."
    }
  ],
  nextSteps: [
    "Skontaktuj się z nami telefonicznie lub przez formularz",
    "Umów się na bezpłatną konsultację",
    "Przedstaw nam swój projekt lub koncepcję domu",
    "Otrzymasz wstępną analizę możliwości finansowania",
    "Przygotowanie profesjonalnego kosztorysu i harmonogramu",
    "Złożenie wniosku kredytowego i wybór najlepszej oferty",
    "Wsparcie w całym procesie budowy i kontroli postępu prac"
  ]
};