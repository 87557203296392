import { Search, ListChecks, FileText, Building2, CheckCircle2, Clock, BadgePercent, Percent, TrendingUp } from 'lucide-react';
import type { ProcessStepsData } from './types';

export const businessStepsData: ProcessStepsData = {
  title: "Przeprowadzę Twoją firmę przez proces finansowania",
  subtitle: "Oferuję wsparcie dla przedsiębiorców, którzy cenią czas i szukają skutecznych rozwiązań",
  steps: [
    {
      number: 1,
      icon: Search,
      title: "Profesjonalna analiza",
      description: "Szybko ocenię potencjał Twojej firmy",
      features: [
        "Kompleksowa analiza finansowa",
        "Realne możliwości kapitałowe",
        "Wstępna kalkulacja zdolności"
      ],
      stats: {
        icon: Clock,
        value: "24h",
        label: "Na ocenę"
      }
    },
    {
      number: 2,
      icon: ListChecks,
      title: "Strategia finansowania",
      description: "Rozwiązanie dopasowane do celów firmy",
      features: [
        "Optymalne formy finansowania",
        "Analiza kosztów dla branży",
        "Strategia zgodna z rozwojem"
      ],
      stats: {
        icon: Percent,
        value: "4-6",
        label: "Rozwiązań"
      }
    },
    {
      number: 3,
      icon: FileText,
      title: "Sprawna dokumentacja",
      description: "Minimum obciążeń administracyjnych",
      features: [
        "Optymalny pakiet dokumentów",
        "Wsparcie w kompletowaniu",
        "Weryfikacja przed złożeniem"
      ],
      stats: {
        icon: Clock,
        value: "48h",
        label: "Gotowości"
      }
    },
    {
      number: 4,
      icon: Building2,
      title: "Szybka decyzja",
      description: "Priorytetowe rozpatrzenie wniosku",
      features: [
        "Sprawdzone ścieżki współpracy",
        "Aktywne monitorowanie procesu",
        "Skuteczne negocjacje warunków"
      ],
      stats: {
        icon: BadgePercent,
        value: "95%",
        label: "Skuteczności"
      }
    },
    {
      number: 5,
      icon: CheckCircle2,
      title: "Efektywna realizacja",
      description: "Szybkie uruchomienie finansowania",
      features: [
        "Minimum formalności",
        "Sprawne podpisanie umowy",
        "Terminowa wypłata środków"
      ],
      stats: {
        icon: TrendingUp,
        value: "7 dni",
        label: "Do realizacji"
      }
    }
  ]
};