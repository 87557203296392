import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ArrowDown } from 'lucide-react';
import { 
  homeStepsData,
  mortgageStepsData, 
  businessStepsData, 
  refinancingStepsData, 
  optimizationStepsData 
} from '@/data/processStepsData';
import type { ProcessStepsData } from '@/data/processStepsData';

export default function Process() {
  const [activeStep, setActiveStep] = useState(0);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [mobileSteps, setMobileSteps] = useState<number[]>([]);
  const [isProcessVisible, setIsProcessVisible] = useState(false);
  const sectionRef = useRef<HTMLElement | null>(null);
  const progressBarRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const [stepsData, setStepsData] = useState<ProcessStepsData>(homeStepsData);

  useEffect(() => {
    if (location.pathname.includes('kredyty-hipoteczne')) {
      setStepsData(mortgageStepsData);
    } else if (location.pathname.includes('kredyty-firmowe')) {
      setStepsData(businessStepsData);
    } else if (location.pathname.includes('refinansowanie')) {
      setStepsData(refinancingStepsData);
    } else if (location.pathname.includes('optymalizacja')) {
      setStepsData(optimizationStepsData);
    } else {
      setStepsData(homeStepsData);
    }
  }, [location]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (!hasAnimated) {
            setHasAnimated(true);
            startStepAnimation();
          }
          setIsProcessVisible(true);
        } else {
          setIsProcessVisible(false);
        }
      },
      { 
        threshold: 0.1,
        rootMargin: '-80px 0px -100% 0px' // Modified rootMargin to hide progress bar earlier
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, [hasAnimated]);

  const startStepAnimation = () => {
    let step = 0;
    const interval = setInterval(() => {
      if (step < stepsData.steps.length) {
        setActiveStep(step);
        step++;
      } else {
        clearInterval(interval);
      }
    }, 1000);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!progressBarRef.current || !sectionRef.current) return;

      const sectionRect = sectionRef.current.getBoundingClientRect();
      const isVisible = sectionRect.top < window.innerHeight && sectionRect.bottom >= 80;
      
      if (isVisible) {
        progressBarRef.current.style.transform = 'translateY(0)';
        progressBarRef.current.style.opacity = '1';
      } else {
        progressBarRef.current.style.transform = 'translateY(-100%)';
        progressBarRef.current.style.opacity = '0';
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const observeMobileSteps = () => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const stepIndex = parseInt(entry.target.getAttribute('data-step') || '0');
              setMobileSteps((prev) => {
                if (!prev.includes(stepIndex)) {
                  return [...prev, stepIndex].sort((a, b) => a - b);
                }
                return prev;
              });
            }
          });
        },
        {
          threshold: 0.5,
          rootMargin: '-10% 0px -10% 0px'
        }
      );

      document.querySelectorAll('[data-step]').forEach((step) => {
        observer.observe(step);
      });

      return observer;
    };

    const observer = observeMobileSteps();
    return () => observer.disconnect();
  }, [hasAnimated]);

  return (
    <section 
      ref={sectionRef} 
      id="process" 
      className="relative py-16 md:py-24 bg-gradient-to-br from-[#003A30]/5 via-white to-[#CBA331]/5 overflow-hidden" 
      aria-labelledby="process-heading"
    >
      <div className="absolute inset-0 pointer-events-none" aria-hidden="true">
        <div className="absolute top-0 left-1/4 w-64 h-64 bg-[#003A30]/10 rounded-full blur-3xl opacity-50" />
        <div className="absolute bottom-0 right-1/4 w-64 h-64 bg-[#CBA331]/10 rounded-full blur-3xl opacity-50" />
      </div>

      <div className="container mx-auto px-4">
        <div className="relative max-w-3xl mx-auto text-center mb-12 md:mb-16">
          <span className="inline-block text-[#CBA331] font-semibold mb-2">Jak działamy</span>
          <h2 id="process-heading" className="text-2xl md:text-3xl font-bold text-[#003A30] mb-4">
            {stepsData.title}
          </h2>
          <p className="text-gray-600 text-lg">
            {stepsData.subtitle}
          </p>
        </div>

        <div className="hidden lg:block relative mb-12">
          <div 
            className="absolute left-0 right-0 top-[45px] h-1 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200"
            role="progressbar"
            aria-valuemin={0}
            aria-valuemax={100}
            aria-valuenow={(activeStep + 1) * 25}
            aria-label="Postęp procesu"
          >
            <div 
              className="h-full bg-gradient-to-r from-[#003A30] to-[#CBA331] transition-all duration-1000 rounded-full"
              style={{ width: `${(activeStep + 1) * 25}%` }}
            />
          </div>

          <div className="flex justify-between relative">
            {stepsData.steps.map((step, index) => (
              <div 
                key={index}
                className={`w-1/5 px-4 transition-all duration-500 ${
                  index <= activeStep ? 'opacity-100 translate-y-0' : 'opacity-50 translate-y-4'
                }`}
                role="region"
                aria-label={`Krok ${index + 1}: ${step.title}`}
              >
                <div className="flex justify-center mb-8">
                  <div className={`
                    w-[90px] h-[90px] rounded-2xl flex items-center justify-center
                    transition-all duration-500 shadow-lg
                    ${index <= activeStep 
                      ? 'bg-gradient-to-br from-[#003A30] to-[#003A30]/90 scale-100' 
                      : 'bg-white scale-90'
                    }
                  `}>
                    <step.icon className={`
                      w-10 h-10 transition-all duration-500
                      ${index <= activeStep ? 'text-white' : 'text-gray-400'}
                    `} aria-hidden="true" />
                  </div>
                </div>

                <div className="text-center">
                  <div className="h-16 flex items-center justify-center">
                    <h3 className="text-lg font-bold text-[#003A30]">{step.title}</h3>
                  </div>
                  
                  <div className="h-20 mb-4">
                    <p className="text-sm text-gray-600">{step.description}</p>
                  </div>
                  
                  <div className="h-32 mb-4">
                    <ul className="text-left space-y-2" role="list">
                      {step.features.map((feature, idx) => (
                        <li key={idx} className="flex items-center gap-2 text-gray-600 text-sm">
                          <div className="w-1.5 h-1.5 rounded-full bg-[#CBA331] flex-shrink-0" aria-hidden="true" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="inline-flex items-center gap-2 px-4 py-2 bg-white rounded-full shadow-md">
                    <step.stats.icon className="w-4 h-4 text-[#CBA331]" aria-hidden="true" />
                    <span className="text-[#003A30] font-bold">{step.stats.value}</span>
                    <span className="text-gray-500 text-sm">{step.stats.label}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="lg:hidden space-y-6">
          <div 
            ref={progressBarRef}
            className="fixed top-20 left-0 right-0 z-50 transition-all duration-300"
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              backdropFilter: 'blur(8px)',
              WebkitBackdropFilter: 'blur(8px)',
              borderBottom: '1px solid rgba(229, 231, 235, 1)',
              transform: isProcessVisible ? 'translateY(0)' : 'translateY(-100%)',
              opacity: isProcessVisible ? 1 : 0
            }}
            role="progressbar"
            aria-valuemin={0}
            aria-valuemax={100}
            aria-valuenow={(mobileSteps.length / (stepsData.steps.length - 1)) * 100}
            aria-label="Postęp procesu na urządzeniu mobilnym"
          >
            <div className="container mx-auto px-4">
              <div className="flex justify-between items-center py-2">
                {stepsData.steps.map((_, index) => (
                  <div 
                    key={index}
                    className={`w-8 h-8 rounded-full flex items-center justify-center text-sm font-medium transition-all duration-300 ${
                      mobileSteps.includes(index)
                        ? 'bg-[#003A30] text-white'
                        : 'bg-gray-100 text-gray-400'
                    }`}
                    aria-label={`Krok ${index + 1}`}
                  >
                    {index + 1}
                  </div>
                ))}
              </div>
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-gray-100">
                <div 
                  className="h-full bg-gradient-to-r from-[#003A30] to-[#CBA331] transition-all duration-500"
                  style={{ 
                    width: `${(mobileSteps.length / (stepsData.steps.length - 1)) * 100}%`,
                  }}
                />
              </div>
            </div>
          </div>

          {stepsData.steps.map((step, index) => (
            <div 
              key={index}
              data-step={index}
              className={`group bg-white rounded-2xl shadow-[0_4px_20px_rgba(0,0,0,0.08)] hover:shadow-[0_8px_30px_rgba(0,0,0,0.12)] transition-all duration-500 overflow-hidden transform ${
                mobileSteps.includes(index) 
                  ? 'translate-y-0 opacity-100' 
                  : 'translate-y-4 opacity-50'
              }`}
              role="region"
              aria-label={`Krok ${index + 1}: ${step.title}`}
            >
              <div 
                className="h-1 bg-gradient-to-r from-[#003A30] to-[#CBA331] transform origin-left transition-transform duration-500"
                style={{ 
                  transform: mobileSteps.includes(index) ? 'scaleX(1)' : 'scaleX(0)'
                }}
                aria-hidden="true"
              />
              
              <div className="p-6">
                <div className="flex items-start gap-4">
                  <div className={`
                    w-12 h-12 rounded-xl flex items-center justify-center flex-shrink-0 transition-all duration-500
                    ${mobileSteps.includes(index)
                      ? 'bg-gradient-to-br from-[#003A30] to-[#003A30]/90 scale-100'
                      : 'bg-gray-100 scale-90'
                    }
                  `}>
                    <step.icon className={`
                      w-6 h-6 transition-colors duration-500
                      ${mobileSteps.includes(index) ? 'text-white' : 'text-gray-400'}
                    `} aria-hidden="true" />
                  </div>

                  <div className="flex-1">
                    <h3 className="text-lg font-bold text-[#003A30] mb-2">{step.title}</h3>
                    <p className="text-gray-600 text-sm mb-4">{step.description}</p>

                    <ul className="space-y-2 mb-4" role="list">
                      {step.features.map((feature, idx) => (
                        <li key={idx} className="flex items-center gap-2 text-gray-600 text-sm">
                          <div className="w-1.5 h-1.5 rounded-full bg-[#CBA331] flex-shrink-0" aria-hidden="true" />
                          {feature}
                        </li>
                      ))}
                    </ul>

                    <div className="inline-flex items-center gap-2 px-4 py-2 bg-gray-50 rounded-full shadow-md">
                      <step.stats.icon className="w-4 h-4 text-[#CBA331]" aria-hidden="true" />
                      <span className="text-[#003A30] font-bold">{step.stats.value}</span>
                      <span className="text-gray-500 text-sm">{step.stats.label}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-12 text-center">
          <a 
            href="#contact"
            className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-[#003A30] to-[#003A30]/90 text-white rounded-lg hover:from-[#CBA331] hover:to-[#CBA331]/90 transition-all duration-300 group shadow-lg hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-[#003A30] focus:ring-offset-2"
            aria-label="Rozpocznij proces kredytowy"
          >
            Rozpocznij proces
            <ArrowDown className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform duration-300" aria-hidden="true" />
          </a>
        </div>
      </div>
    </section>
  );
}