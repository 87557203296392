import React from 'react';
import { Calendar, Clock, Tag } from 'lucide-react';
import { Link } from 'react-router-dom';

interface ArticleCardProps {
  title: string;
  excerpt: string;
  image: string;
  date: string;
  readingTime: number;
  url: string;
  tags?: string[];
}

export default function ArticleCard({ 
  title, 
  excerpt, 
  image, 
  date, 
  readingTime, 
  url,
  tags = []
}: ArticleCardProps) {
  return (
    <Link 
      to={url}
      className="group flex flex-col bg-white rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 hover:border-gray-200"
    >
      {/* Image container */}
      <div className="relative h-48 overflow-hidden">
        <img
          src={image}
          alt=""
          className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
          loading="lazy"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
      </div>
      
      {/* Content */}
      <div className="flex-1 p-5 flex flex-col">
        <div className="flex flex-wrap gap-2 mb-2">
          {tags.slice(0, 2).map((tag, index) => (
            <span 
              key={index}
              className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-[#003A30]/5 text-[#003A30]"
            >
              <Tag className="w-3 h-3 mr-1" />
              {tag}
            </span>
          ))}
        </div>
        
        <h3 className="text-lg font-bold text-[#003A30] mb-2 group-hover:text-[#CBA331] transition-colors duration-200 line-clamp-2">
          {title}
        </h3>
        
        <p className="text-gray-600 mb-4 line-clamp-2 text-sm">
          {excerpt}
        </p>
        
        <div className="mt-auto flex items-center justify-between pt-3 border-t border-gray-100 text-xs text-gray-500">
          <div className="flex items-center">
            <Calendar className="w-3 h-3 mr-1" />
            <span>{date}</span>
          </div>
          <div className="flex items-center">
            <Clock className="w-3 h-3 mr-1" />
            <span>{readingTime} min</span>
          </div>
        </div>
      </div>
    </Link>
  );
}