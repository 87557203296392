import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

// Layouts
import Navbar from '@/components/layout/navbar/Navbar';
import Footer from '@/components/layout/Footer';
import ScrollToTop from '@/components/utils/ScrollToTop';
import CookieConsent from '@/components/shared/CookieConsent';

// Pages
import HomePage from '@/components/home/HomePage';

// Services
import MortgageLoan from '@/pages/services/mortgage/MortgageLoan';
import BusinessLoan from '@/pages/services/business/BusinessLoan';
import Refinancing from '@/pages/services/refinancing/Refinancing';
import Optimization from '@/pages/services/optimization/Optimization';

// Calculators
import MortgageCalculator from '@/pages/calculators/MortgageCalculator';
import BusinessCalculator from '@/pages/calculators/BusinessCalculator';
import CreditworthinessCalculator from '@/pages/calculators/creditworthiness/CreditworthinessCalculator';
import SavingsCalculator from '@/pages/calculators/SavingsCalculator';

// Knowledge
import BlogIndex from '@/blog/BlogIndex';
import BlogPost from '@/blog/ArticlePage';
import Guides from '@/pages/knowledge/Guides';
import Documents from '@/pages/knowledge/Documents';

// Legal
import PrivacyPolicy from '@/pages/legal/PrivacyPolicy';

// Analytics
import { initGA, trackPageView } from '@/utils/analytics';

// Blog Context Provider
import { BlogProvider } from '@/blog/context/BlogContext';

// Error Fallback Component
function ErrorFallback({ error }: { error: Error }) {
  return (
    <div className="min-h-screen flex items-center justify-center p-4 bg-gray-50">
      <div className="bg-white rounded-2xl shadow-xl p-6 md:p-8 max-w-lg w-full text-center">
        <div className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-6">
          <svg className="w-8 h-8 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
        </div>
        <h1 className="text-2xl font-bold text-red-600 mb-4">
          Wystąpił błąd aplikacji
        </h1>
        <p className="text-gray-600 mb-6">
          Przepraszamy, wystąpił nieoczekiwany błąd. Nasz zespół został powiadomiony o problemie.
        </p>
        <pre className="bg-gray-50 p-4 rounded-lg text-sm text-gray-600 mb-6 overflow-auto text-left">
          {error.message}
        </pre>
        <button
          onClick={() => window.location.reload()}
          className="w-full bg-[#003A30] text-white px-6 py-3 rounded-lg hover:bg-[#CBA331] transition-all duration-300"
        >
          Odśwież stronę
        </button>
      </div>
    </div>
  );
}

// Page Tracker Component with Error Boundary
function PageTracker() {
  const location = useLocation();

  React.useEffect(() => {
    try {
      const path = location.pathname + location.search;
      trackPageView(path);
    } catch (error) {
      console.warn('Page tracking error:', error);
    }
  }, [location]);

  return null;
}

function App() {
  React.useEffect(() => {
    initGA();
  }, []);

  return (
    <Router>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BlogProvider>
          <ScrollToTop />
          <PageTracker />
          <div className="min-h-screen flex flex-col">
            <Navbar />
            <main className="flex-grow">
              <Routes>
                {/* Home */}
                <Route path="/" element={<HomePage />} />
                
                {/* Services */}
                <Route path="/kredyty-hipoteczne" element={<MortgageLoan />} />
                <Route path="/kredyty-firmowe" element={<BusinessLoan />} />
                <Route path="/refinansowanie" element={<Refinancing />} />
                <Route path="/optymalizacja" element={<Optimization />} />
                
                {/* Calculators */}
                <Route path="/kalkulator-kredytowy" element={<MortgageCalculator />} />
                <Route path="/kalkulator-firmowy" element={<BusinessCalculator />} />
                <Route path="/zdolnosc-kredytowa" element={<CreditworthinessCalculator />} />
                <Route path="/kalkulator-oszczednosci" element={<SavingsCalculator />} />
                
                {/* Knowledge */}
                <Route path="/blog" element={<BlogIndex />} />
                <Route path="/blog/:slug" element={<BlogPost />} />
                <Route path="/poradniki" element={<Guides />} />
                <Route path="/dokumenty" element={<Documents />} />

                {/* Legal */}
                <Route path="/polityka-prywatnosci" element={<PrivacyPolicy />} />

                {/* 404 Page */}
                <Route path="*" element={
                  <div className="min-h-[calc(100vh-80px)] flex items-center justify-center p-4 bg-gray-50">
                    <div className="bg-white rounded-2xl shadow-xl p-8 max-w-lg w-full text-center">
                      <div className="w-16 h-16 bg-[#003A30]/10 rounded-full flex items-center justify-center mx-auto mb-6">
                        <svg className="w-8 h-8 text-[#003A30]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                      <h1 className="text-2xl font-bold text-[#003A30] mb-4">
                        Strona nie znaleziona
                      </h1>
                      <p className="text-gray-600 mb-6">
                        Przepraszamy, ale strona której szukasz nie istnieje lub została przeniesiona.
                      </p>
                      <a
                        href="/"
                        className="inline-flex items-center justify-center w-full bg-[#003A30] text-white px-6 py-3 rounded-lg hover:bg-[#CBA331] transition-all duration-300"
                      >
                        Wróć do strony głównej
                      </a>
                    </div>
                  </div>
                } />
              </Routes>
            </main>
            <Footer />
            <CookieConsent />
          </div>
        </BlogProvider>
      </ErrorBoundary>
    </Router>
  );
}

export default App;