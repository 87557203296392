import type { ConcernsData } from './types';

export const mortgageConcernsData: ConcernsData = {
  title: "Rozwiewamy wątpliwości",
  subtitle: "Poznaj rozwiązania typowych obaw związanych z kredytem hipotecznym",
  concerns: [
    {
      concern: "Nie mam wystarczającego wkładu własnego",
      solution: "Pomożemy znaleźć bank akceptujący niższy wkład własny lub zaproponujemy alternatywne rozwiązania"
    },
    {
      concern: "Mam umowę na czas określony",
      solution: "Współpracujemy z bankami, które akceptują różne formy zatrudnienia"
    },
    {
      concern: "Prowadzę działalność gospodarczą krócej niż 2 lata",
      solution: "Znamy banki, które udzielą kredytu nawet przy krótkiej historii działalności"
    },
    {
      concern: "Mam inne zobowiązania kredytowe",
      solution: "Pomożemy w konsolidacji zobowiązań lub znajdziemy bank z odpowiednią ofertą"
    }
  ]
};