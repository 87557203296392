import { Building2 } from 'lucide-react';
import type { Card, CardDetails } from '../types';

export const INVESTMENT_LOAN_CARD: Card = {
  id: "investment-loan",
  icon: Building2,
  title: "Kredyt na nieruchomość firmową",
  description: "Finansowanie zakupu lub budowy nieruchomości dla Twojej firmy",
  benefits: [
    "Wyjątkowo niska marża od 1,40%",
    "Okres kredytowania do 20 lat",
    "Możliwość finansowania nawet do 90% LTV"
  ],
  stats: {
    value: "0%",
    label: "Prowizja już od"
  },
  cta: {
    label: "Zainwestuj w rozwój firmy",
    action: () => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })
  }
};

export const INVESTMENT_LOAN_DETAILS: CardDetails = {
  title: "Kredyt na nieruchomość firmową",
  tagline: "Stabilna inwestycja w przyszłość Twojej firmy",
  description: "Finansowanie zakupu, budowy lub modernizacji nieruchomości komercyjnej z wyjątkowo niską marżą od 1,40% i prowizją od 0%. Koniec z kosztami najmu - inwestuj we własną przestrzeń biznesową i buduj majątek firmy przy jednoczesnych korzyściach podatkowych.",
  cta: {
    phone: "539 211 881",
    text: "Zadzwoń i dowiedz się, jak możemy pomóc Ci sfinansować nieruchomość dla Twojej firmy"
  },
  benefitHighlights: [
    "Niższe koszty finansowania dzięki zabezpieczeniu hipotecznemu",
    "Dłuższy okres kredytowania niż w standardowych kredytach inwestycyjnych",
    "Dostęp do specjalnych programów kredytowych dla firm",
    "Elastyczny harmonogram spłat dopasowany do przepływów finansowych firmy"
  ],
  whyUs: [
    "Współpracujemy z bankami specjalizującymi się w finansowaniu nieruchomości komercyjnych",
    "Doświadczenie w obsłudze kredytów firmowych zabezpieczonych hipotecznie",
    "Kompleksowe wsparcie w przygotowaniu wymaganej dokumentacji",
    "Skuteczne negocjacje warunków kredytowych z instytucjami finansowymi"
  ],
  processComparison: {
    withUs: [
      {
        step: "Profesjonalna analiza potrzeb i możliwości",
        description: "Dokładna ocena zdolności kredytowej i dobór optymalnej struktury finansowania",
        icon: "Calculator",
        highlight: true
      },
      {
        step: "Przygotowanie dokumentacji",
        description: "Wsparcie w kompletowaniu i weryfikacji dokumentów finansowych i biznesowych",
        icon: "FileText"
      },
      {
        step: "Negocjacje z bankami",
        description: "Uzyskanie najkorzystniejszych warunków dzięki naszym relacjom z bankami",
        icon: "Users"
      },
      {
        step: "Sprawna realizacja procesu",
        description: "Średni czas decyzji kredytowej: 5-14 dni",
        icon: "CheckCircle",
        highlight: true
      }
    ],
    withoutUs: [
      {
        step: "Samodzielne poszukiwania finansowania",
        description: "Ograniczony dostęp do ofert, brak możliwości porównania warunków",
        icon: "Search",
        warning: true
      },
      {
        step: "Trudności z dokumentacją",
        description: "Ryzyko odrzucenia wniosku z powodu formalnych błędów",
        icon: "AlertTriangle",
        warning: true
      },
      {
        step: "Brak możliwości negocjacji",
        description: "Akceptacja standardowych warunków bez indywidualnego podejścia",
        icon: "X",
        warning: true
      },
      {
        step: "Wydłużony proces decyzyjny",
        description: "Czas oczekiwania na decyzję: 21-60 dni",
        icon: "Clock",
        warning: true
      }
    ]
  },
  calculations: {
    example: "Finansowanie zakupu lokalu komercyjnego za 2 000 000 zł (powierzchnia 250 m²)",
    before: "Wynajem lokalu komercyjnego:\nMiesięczny koszt: 12 000 - 15 000 zł (średnio 13 500 zł)\nRoczny koszt: 162 000 zł\nPo 15 latach: 2 430 000 zł wydane bez budowania majątku\nBrak ochrony przed wzrostem stawek najmu",
    after: "Kredyt hipoteczny firmowy:\nWkład własny: 20% (400 000 zł)\nKwota kredytu: 1 600 000 zł\nOprocentowanie: WIBOR 5,81% + marża 1,40% = 7,21%\nMiesięczna rata: 11 650 zł\nOkres kredytowania: 15 lat\nMiesięczne koszty utrzymania nieruchomości: 3 300 zł\n(podatek: 480 zł, ubezpieczenie: 330 zł, remonty/konserwacja: 2 500 zł)",
    extra: "Korzyści podatkowe (przy CIT 19%):\n• Amortyzacja: 50 000 zł rocznie (2,5% wartości budynku)\n• Odsetki: 115 000 zł w pierwszym roku (malejące w kolejnych latach)\n• Łączna oszczędność podatkowa: ok. 31 350 zł w pierwszym roku (2 610 zł miesięcznie)",
    savings: "Realne miesięczne koszty (po uwzględnieniu korzyści podatkowych):\n• Najem: 13 500 zł\n• Zakup: 12 340 zł (11 650 zł rata + 3 300 zł utrzymanie - 2 610 zł korzyści podatkowe)\n• Miesięczna oszczędność: 1 160 zł w pierwszym roku\n• Po 15 latach: własność nieruchomości wartej 2,4-2,8 mln zł"
  },
  benefits: [
    "Wyjątkowo niska marża już od 1,40%",
    "Prowizja od 0% wartości kredytu",
    "Finansowanie do 20 lat",
    "Możliwość finansowania do 90% wartości nieruchomości",
    "Budowanie majątku firmy zamiast opłacania czynszu",
    "Korzyści podatkowe - amortyzacja i odsetki jako koszt uzyskania przychodu",
    "Zabezpieczenie przed wzrostem czynszów najmu"
  ],
  limitedTimeOffer: {
    text: "Bezpłatna analiza zdolności kredytowej i ocena wartości nieruchomości dla nowych klientów",
    expiry: "30.06.2025"
  },
  requirements: [
    "Minimum 12 miesięcy prowadzenia działalności",
    "Dokumentacja finansowa firmy za ostatni rok",
    "Biznesplan lub plan inwestycyjny",
    "Wycena nieruchomości przez rzeczoznawcę",
    "Dokumenty rejestrowe firmy",
    "Zaświadczenia o niezaleganiu z ZUS i US"
  ],
  nextSteps: [
    "Skontaktuj się z nami telefonicznie lub przez formularz",
    "Przedstaw swój plan inwestycyjny i potrzeby finansowe",
    "Otrzymaj wstępną analizę możliwości kredytowych",
    "Przygotowanie i złożenie wniosku kredytowego",
    "Podpisanie umowy kredytowej",
    "Realizacja inwestycji w nieruchomość"
  ],
  faq: [
    {
      question: "Jakie są rzeczywiste korzyści podatkowe zakupu nieruchomości firmowej?",
      answer: "Zakup nieruchomości firmowej oferuje dwie główne korzyści podatkowe: 1) Amortyzacja - standardowo 2,5% rocznie dla budynków (w przypadku lokalu wartego 2 mln zł to około 50 000 zł rocznie jako koszt), 2) Odsetki od kredytu hipotecznego - pełna kwota odsetek stanowi koszt uzyskania przychodu (przy kredycie 1,6 mln zł to około 118 500 zł w pierwszym roku). Dodatkowo, wydatki na utrzymanie, remonty i modernizację nieruchomości mogą być również zaliczane do kosztów. Przy stawce podatku CIT 19% lub PIT (skala podatkowa/podatek liniowy), oszczędności podatkowe są znaczące. Warto dodać, że zgodnie z art. 16c pkt 5 ustawy o podatku dochodowym od osób prawnych, z amortyzacji wyłączone są grunty, a amortyzacji podlegają tylko budynki i budowle."
    },
    {
      question: "Co jeśli mój biznes będzie potrzebował się przeprowadzić w przyszłości?",
      answer: "Jest to uzasadniona obawa, którą adresujemy na dwa sposoby: 1) Możliwość wynajmu posiadanej nieruchomości - jeśli Twoja firma musi się przenieść, możesz wynająć posiadaną nieruchomość innym podmiotom, generując dodatkowy przychód i potencjalnie pokrywając raty kredytu, 2) Sprzedaż z zyskiem - po kilku latach spłacania kredytu i przy naturalnym wzroście wartości nieruchomości komercyjnych, sprzedaż może przynieść znaczący zysk, 3) W przypadku zakupu przez spółkę celową (SPV) - możliwe jest nawet wydzielenie nieruchomości poza główną działalność operacyjną. Ważne jest, aby przy wyborze nieruchomości uwzględniać jej potencjał rynkowy i uniwersalność zastosowania, co zwiększa możliwości w przyszłości. Według danych rynkowych, dobrze zlokalizowane nieruchomości komercyjne w głównych miastach mają niski wskaźnik pustostanów (poniżej 10%), co wskazuje na stabilność tego rynku."
    },
    {
      question: "Jak obecna sytuacja gospodarcza i stopy procentowe wpływają na opłacalność zakupu?",
      answer: "Obecna sytuacja gospodarcza (marzec 2025) charakteryzuje się stopniową stabilizacją stóp procentowych, z WIBOR 3M na poziomie 5,81% i prognozami niewielkich obniżek w najbliższych kwartałach. Jednocześnie, rynek nieruchomości komercyjnych pozostaje stabilny, z umiarkowanym trendem wzrostowym cen. To stwarza korzystny moment na inwestycję z trzech powodów: 1) Oczekiwane obniżki stóp procentowych mogą zmniejszyć koszty kredytu w kolejnych latach, 2) Ceny nieruchomości wykazują tendencję wzrostową (średnio 3-5% rocznie dla dobrych lokalizacji), 3) Wysoka inflacja w ostatnich latach sprawia, że aktywa rzeczowe, takie jak nieruchomości, stanowią dobrą ochronę kapitału. Warto zauważyć, że według danych NBP, średnie oprocentowanie kredytów dla przedsiębiorstw na nieruchomości jest niższe niż dla innych rodzajów kredytów firmowych ze względu na zabezpieczenie hipoteczne, co dodatkowo zwiększa atrakcyjność tej formy finansowania."
    },
    {
      question: "Ile faktycznie kapitału obrotowego muszę zamrozić w inwestycji?",
      answer: "Zrozumiałe obawy o zamrożenie kapitału obrotowego są kluczowe dla firm w fazie rozwoju. Standardowo wymagany wkład własny to 20-30% wartości nieruchomości, jednak oferujemy rozwiązania minimalizujące to obciążenie: 1) Kredyty z niższym wkładem własnym - w niektórych przypadkach możliwe jest uzyskanie finansowania do 90% wartości, co przy nieruchomości za 2 mln zł oznacza wkład własny na poziomie 200 tys. zł, 2) Możliwość wykorzystania innych aktywów jako zabezpieczenia (np. posiadanych nieruchomości, zorganizowanej części przedsiębiorstwa), 3) Dla firm działających dłużej niż 3 lata z dobrą historią kredytową, niektóre banki oferują specjalne programy z obniżonym wymogiem wkładu własnego. Warto również rozważyć etapowanie inwestycji lub zakup mniejszej nieruchomości na początku, z planem rozbudowy w przyszłości, co zmniejsza początkowe obciążenie finansowe."
    },
    {
      question: "Jakie są rzeczywiste koszty utrzymania własnej nieruchomości vs. najem?",
      answer: "Koszty utrzymania własnej nieruchomości obejmują: 1) Podatek od nieruchomości (około 23 zł/m² rocznie dla powierzchni komercyjnych w 2025 r.), 2) Ubezpieczenie (0,1-0,3% wartości rocznie), 3) Koszty remontów i konserwacji (średnio 1-2% wartości budynku rocznie), 4) Ewentualne opłaty wspólnotowe. W przypadku lokalu o powierzchni 300m² i wartości 2 mln zł, roczne koszty utrzymania wynoszą około 28-35 tys. zł, czyli 2300-2900 zł miesięcznie. Przy najmie, oprócz czynszu (12-15 tys. zł miesięcznie), najemca często ponosi również koszty eksploatacyjne, ubezpieczenia oraz adaptacji przestrzeni. Kluczowa różnica: koszty utrzymania własnej nieruchomości są w większości przewidywalne i nie podlegają jednostronnym podwyżkom, jak w przypadku najmu. Według danych rynkowych, całkowity koszt posiadania nieruchomości (TCO) po uwzględnieniu wszystkich wydatków i korzyści, w perspektywie 10+ lat jest zwykle o 25-40% niższy niż koszt najmu podobnej powierzchni."
    },
    {
      question: "Co się stanie, jeśli w przyszłości wartość nieruchomości spadnie?",
      answer: "Obawy o spadek wartości nieruchomości są uzasadnione, szczególnie w niepewnych czasach gospodarczych. Warto jednak zauważyć: 1) Historycznie nieruchomości komercyjne w dobrych lokalizacjach wykazują stabilność wartości w długim terminie, z okresowymi korektami rzadko przekraczającymi 15-20%, 2) W przypadku kredytu hipotecznego, nawet tymczasowy spadek wartości nieruchomości nie wpływa na warunki już udzielonego finansowania, 3) Nieruchomość komercyjna generuje wartość dla firmy nie tylko poprzez potencjalny wzrost ceny, ale przede wszystkim przez oszczędności operacyjne i korzyści podatkowe. Warto również podkreślić, że według analiz rynkowych, lokalizacja jest kluczowym czynnikiem stabilności wartości - nieruchomości w centralnych punktach miast lub w głównych strefach biznesowych wykazują najwyższą odporność na wahania rynkowe. Dlatego przy wyborze nieruchomości priorytetem powinna być dobra lokalizacja oraz uniwersalność zastosowania, co minimalizuje ryzyko utraty wartości."
    }
  ]
};