import type { CaseStudy } from '../types';

export const MORTGAGE_PLUS_CASE: CaseStudy = {
  id: "mortgage-plus",
  client: "Robert i Alicja",
  image: "https://media.istockphoto.com/id/1796495560/photo/senior-couple-walking-on-a-tree-log-in-forest.webp?a=1&b=1&s=612x612&w=0&k=20&c=vwRTlV495uGWtIe-yWlYBHKa1Yojve8Q7MsEXfauHxM=",
  title: "Refinansowanie z dodatkową gotówką na remont i samochód",
  description: "Pomogliśmy rodzinie obniżyć ratę kredytu i jednocześnie pozyskać środki na gruntowny remont oraz zakup nowego samochodu.",
  result: "Utrzymanie podobnej raty + 230 000 zł dodatkowej gotówki",
  service: "refinance-plus",
  challenges: [
    "Istniejący kredyt hipoteczny z wysoką marżą 2.3% z 2020 roku",
    "Potrzeba finansowania remontu domu (wymiana instalacji, okien i elewacji)",
    "Konieczność zakupu nowego, rodzinnego samochodu",
    "Restrykcyjna polityka bankowa dotycząca kredytów konsumpcyjnych",
    "Maksymalizacja dodatkowej kwoty przy utrzymaniu bezpiecznej raty"
  ],
  solutions: [
    "Refinansowanie z niższą marżą 1.6% w nowym banku",
    "Wykorzystanie wzrostu wartości nieruchomości dla zwiększenia zdolności kredytowej",
    "Dodatkowe 30% wartości nieruchomości na cele konsumpcyjne",
    "Optymalizacja okresu kredytowania dla zachowania komfortowej raty",
    "Strukturyzacja kredytu z częścią na cele mieszkaniowe (niższe oprocentowanie) i konsumpcyjne"
  ],
  directQuote: "Zaskoczył nas fakt, że możemy uzyskać potrzebne 230 tysięcy na remont i samochód bez znaczącego zwiększenia miesięcznej raty. Dzięki kompleksowemu refinansowaniu uniknęliśmy zaciągania drogich kredytów gotówkowych i mamy wszystkie zobowiązania w jednej, wygodnej racie.",
  financialBenefit: "Uniknięcie kosztownego kredytu gotówkowego (oszczędność ok. 120 000 zł)",
  savingsPercentage: 35,
  savingsAmount: "Dostęp do 230 000 zł bez znaczącego wzrostu raty",
  whatIfScenario: "Bez refinansowania z dodatkową kwotą, Robert i Alicja musieliby zaciągnąć drogie kredyty konsumpcyjne na remont i samochód. Rata takiego kredytu gotówkowego na 230 000 zł wynosiłaby około 4800 zł miesięcznie na 5 lat, co znacząco obciążyłoby domowy budżet.",
  clientTypeTag: "Refinansowanie+",
  ctaText: "Sprawdź, ile dodatkowej gotówki możesz uzyskać",
  ctaLink: "/refinansowanie-plus",
  difficultyLevel: 4,
  timelineInfo: "Realizacja w 45 dni",
  rating: 5,
  date: "Marzec 2025",
  creditType: "Refinansowanie z gotówką",
  clientOccupation: "Lekarz i prawniczka",
  clientLocation: "Kraków, Wola Justowska",
  beforeAfter: {
    before: "Kredyt hipoteczny 520 000 zł, marża 2.3%, rata 4580 zł, stary samochód wymagający częstych napraw, dom z przestarzałą instalacją elektryczną i nieefektywnym ogrzewaniem.",
    after: "Kredyt hipoteczny 750 000 zł (w tym 230 000 zł na remont i samochód), marża 1.6%, rata 4780 zł, nowy ekonomiczny samochód rodzinny, wyremontowany dom z nowoczesną instalacją i energooszczędnym ogrzewaniem."
  },
  reasoning: "Zdecydowaliśmy się na tę firmę ze względu na ich kompleksowe podejście i doświadczenie w refinansowaniu kredytów z dodatkową gotówką. Doradzili nam jak zoptymalizować dodatkową kwotę, by osiągnąć wszystkie nasze cele finansowe, jednocześnie dbając o bezpieczeństwo finansowe naszej rodziny.",
  fullStory: {
    situation: "Para z dwójką dzieci mieszkająca w domu jednorodzinnym na Woli Justowskiej w Krakowie, zakupionym w 2020 roku. Robert (lekarz) i Alicja (prawniczka) spłacali kredyt hipoteczny w wysokości 520 000 zł z wysoką marżą 2.3%. Dom wymagał gruntownego remontu, w tym wymiany instalacji elektrycznej, okien i ocieplenia. Dodatkowo rodzina potrzebowała nowego, bardziej ekonomicznego samochodu w miejsce starego, awaryjnego auta.",
    challenge: "Główne wyzwanie stanowiło pozyskanie znacznych środków na remont i zakup samochodu bez nadmiernego obciążania domowego budżetu. Standardowe kredyty gotówkowe wiązałyby się z wysokim oprocentowaniem i krótkim okresem spłaty, co drastycznie zwiększyłoby miesięczne zobowiązania.",
    solution: "Kompleksowe refinansowanie istniejącego kredytu hipotecznego z dodatkową kwotą 230 000 zł (ok. 30% wartości nieruchomości) z przeznaczeniem na remont domu i zakup samochodu. Dzięki negocjacjom udało się obniżyć marżę z 2.3% do 1.6% i zoptymalizować strukturę nowego kredytu tak, by większa część dodatkowej kwoty kwalifikowała się jako cel mieszkaniowy (niższe oprocentowanie).",
    details: {
      property: {
        value: "780 000 zł (aktualna wycena)",
        type: "Dom jednorodzinny 160m²",
        location: "Kraków, Wola Justowska"
      },
      loan: {
        oldRate: "8.1% (WIBOR 5.8% + marża 2.3%)",
        oldPayment: "4580 zł",
        newRate: "7.4% (WIBOR 5.8% + marża 1.6%)",
        newPayment: "4780 zł"
      },
      additionalFunds: {
        renovation: "170 000 zł (wymiana instalacji, okien, ocieplenie)",
        car: "60 000 zł (nowy, ekonomiczny samochód rodzinny)"
      },
      savings: {
        monthly: "Utrzymanie niemal identycznego obciążenia budżetu (+200 zł) mimo dodania 230 000 zł",
        interestSavings: "Około 120 000 zł w porównaniu do kredytu gotówkowego",
        benefits: "Nowoczesny, energooszczędny dom (niższe rachunki) i niezawodny samochód (niższe koszty eksploatacji)"
      },
      timeline: {
        process: "45 dni od wniosku do uruchomienia",
        renovation: "4 miesiące po otrzymaniu środków"
      }
    }
  }
};