import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { X, Cookie, Shield } from 'lucide-react';

const COOKIE_CONSENT_KEY = 'cookie-consent';

export default function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Sprawdź czy użytkownik już zaakceptował cookies
    const hasConsent = Cookies.get(COOKIE_CONSENT_KEY);
    if (!hasConsent) {
      setIsVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    // Ustaw cookie na rok
    Cookies.set(COOKIE_CONSENT_KEY, 'true', { expires: 365 });
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 p-4 md:p-6 bg-white/95 backdrop-blur-sm border-t border-gray-200 shadow-lg">
      <div className="container mx-auto max-w-6xl">
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-6">
          {/* Icon and Text */}
          <div className="flex items-start gap-4 flex-grow">
            <div className="flex-shrink-0 w-10 h-10 bg-[#003A30]/5 rounded-lg flex items-center justify-center">
              <Cookie className="w-5 h-5 text-[#003A30]" />
            </div>
            <div className="flex-grow">
              <h3 className="text-lg font-semibold text-[#003A30] mb-1">
                Szanujemy Twoją prywatność
              </h3>
              <p className="text-sm text-gray-600">
                Używamy plików cookie, aby zapewnić najlepsze doświadczenia na naszej stronie. 
                Kontynuując korzystanie z serwisu, zgadzasz się na ich użycie zgodnie z naszą{' '}
                <a href="/polityka-prywatnosci" className="text-[#003A30] hover:text-[#CBA331] underline">
                  Polityką Prywatności
                </a>.
              </p>
            </div>
          </div>

          {/* Buttons */}
          <div className="flex items-center gap-3 ml-14 sm:ml-0">
            <button
              onClick={acceptCookies}
              className="inline-flex items-center px-4 py-2 bg-[#003A30] text-white rounded-lg hover:bg-[#CBA331] transition-colors duration-300"
            >
              <Shield className="w-4 h-4 mr-2" />
              Akceptuję
            </button>
            <button
              onClick={() => setIsVisible(false)}
              className="p-2 text-gray-400 hover:text-[#003A30] transition-colors duration-300 rounded-lg hover:bg-gray-100"
              aria-label="Zamknij"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}