import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div className="pt-20 bg-gray-50">
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-lg p-8 md:p-12">
          <h1 className="text-3xl md:text-4xl font-bold text-[#003A30] mb-8">
            Polityka Prywatności i Ochrony Danych Osobowych
          </h1>
          <p className="text-gray-600 mb-4">Data obowiązywania: 18 marca 2025 r.</p>

          <div className="prose prose-lg max-w-none">
            <p className="text-gray-600 mb-8">
              Szanowni Państwo,
              <br /><br />
              W trosce o Państwa prywatność oraz wypełniając obowiązki wynikające z przepisów prawa, w szczególności Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. („RODO"), przedstawiamy szczegółowe informacje dotyczące zasad przetwarzania Państwa danych osobowych przez FUNDAMENTY MAJĄTKU SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ.
            </p>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-[#003A30] mb-4">I. Administrator danych osobowych</h2>
              <p className="text-gray-600">
                Administratorem danych osobowych jest:
                <br /><br />
                FUNDAMENTY MAJĄTKU SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ<br />
                ul. ALEJA POKOJU 18D<br />
                31-564 KRAKÓW<br />
                NIP: 6751795919<br />
                REGON: 527991221<br />
                KRS: 0001092308
                <br /><br />
                Kontakt z Administratorem możliwy jest pod adresem e-mailowym: FUNDAMENTYMAJATKU@GMAIL.COM lub telefonicznie pod numerem: +48 539 211 881.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-[#003A30] mb-4">II. Inspektor Ochrony Danych (IOD)</h2>
              <p className="text-gray-600">
                Administrator wyznaczył Inspektora Ochrony Danych, z którym można się kontaktować we wszystkich sprawach związanych z ochroną danych osobowych:
                <br /><br />
                Adres e-mail: iod@arturzych.pl
                <br />
                Korespondencyjnie na adres siedziby Administratora (z dopiskiem „Inspektor Ochrony Danych").
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-[#003A30] mb-4">III. Rodzaje przetwarzanych danych osobowych</h2>
              <p className="text-gray-600">
                Administrator przetwarza następujące dane osobowe przekazane przez użytkowników za pośrednictwem formularza kontaktowego dostępnego na stronie internetowej:
              </p>
              <ul className="list-disc list-inside text-gray-600 mt-4">
                <li>Imię i nazwisko</li>
                <li>Numer telefonu</li>
                <li>Adres e-mail</li>
              </ul>
              <p className="text-gray-600 mt-4">
                Podanie powyższych danych jest dobrowolne, lecz niezbędne do świadczenia usług pośrednictwa kredytowego.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-[#003A30] mb-4">IV. Cele przetwarzania danych osobowych oraz podstawy prawne</h2>
              <p className="text-gray-600 mb-4">Państwa dane osobowe są przetwarzane w następujących celach:</p>
              <ol className="list-decimal list-inside text-gray-600 space-y-4">
                <li>Świadczenie usług pośrednictwa kredytowego oraz innych usług finansowych, w tym kontakt telefoniczny lub mailowy w celu przedstawienia ofert kredytowych oraz podjęcia działań przed zawarciem umowy – na podstawie art. 6 ust. 1 lit. b RODO (wykonanie umowy lub podjęcie działań przed zawarciem umowy).</li>
                <li>Realizacja obowiązków prawnych, wynikających m.in. z ustawy o przeciwdziałaniu praniu pieniędzy oraz finansowaniu terroryzmu – na podstawie art. 6 ust. 1 lit. c RODO (obowiązek prawny).</li>
                <li>Dochodzenie roszczeń lub obrona przed roszczeniami, obsługa reklamacji oraz zapewnienie jakości świadczonych usług – na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora).</li>
              </ol>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-[#003A30] mb-4">V. Odbiorcy danych osobowych</h2>
              <p className="text-gray-600 mb-4">Państwa dane mogą być przekazywane następującym kategoriom odbiorców:</p>
              <ul className="list-disc list-inside text-gray-600">
                <li>Współpracującym instytucjom finansowym (bankom, instytucjom kredytowym), które są odrębnymi administratorami danych.</li>
                <li>Dostawcom usług IT, hostingowych, poczty elektronicznej.</li>
                <li>Podmiotom świadczącym usługi księgowe, audytorskie i prawne.</li>
              </ul>
              <p className="text-gray-600 mt-4">
                Pełna lista współpracujących instytucji jest dostępna na życzenie u Administratora.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-[#003A30] mb-4">VI. Przekazywanie danych poza Europejski Obszar Gospodarczy (EOG)</h2>
              <p className="text-gray-600">
                Administrator nie przekazuje Państwa danych osobowych do państw trzecich ani organizacji międzynarodowych spoza EOG.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-[#003A30] mb-4">VII. Okres przechowywania danych osobowych</h2>
              <p className="text-gray-600">
                Państwa dane będą przechowywane przez okres niezbędny do realizacji celów wskazanych powyżej:
              </p>
              <ul className="list-disc list-inside text-gray-600 mt-4">
                <li>Przez czas świadczenia usługi pośrednictwa kredytowego.</li>
                <li>Przez okres wymagany obowiązującymi przepisami prawa (np. ustawa o przeciwdziałaniu praniu pieniędzy – okres minimum 5 lat od zakończenia relacji biznesowej).</li>
                <li>Do czasu przedawnienia ewentualnych roszczeń wynikających ze świadczonych usług.</li>
              </ul>
              <p className="text-gray-600 mt-4">
                Po upływie tych okresów dane zostaną usunięte lub poddane anonimizacji.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-[#003A30] mb-4">VIII. Prawa osób, których dane dotyczą</h2>
              <p className="text-gray-600 mb-4">Zgodnie z RODO mają Państwo prawo do:</p>
              <ul className="list-disc list-inside text-gray-600">
                <li>dostępu do swoich danych osobowych oraz uzyskania ich kopii,</li>
                <li>sprostowania (poprawienia) swoich danych,</li>
                <li>usunięcia danych („prawo do bycia zapomnianym"),</li>
                <li>ograniczenia przetwarzania swoich danych,</li>
                <li>przenoszenia swoich danych,</li>
                <li>wniesienia sprzeciwu wobec przetwarzania Państwa danych na podstawie uzasadnionego interesu administratora.</li>
              </ul>
              <p className="text-gray-600 mt-4">
                W celu realizacji powyższych praw prosimy o kontakt z Administratorem lub Inspektorem Ochrony Danych za pośrednictwem wskazanych powyżej kanałów komunikacji.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-[#003A30] mb-4">IX. Prawo wniesienia skargi do organu nadzorczego</h2>
              <p className="text-gray-600">
                Jeżeli uznają Państwo, że przetwarzanie Państwa danych narusza przepisy RODO, mają Państwo prawo wniesienia skargi do organu nadzorczego – Prezesa Urzędu Ochrony Danych Osobowych:
                <br /><br />
                Prezes Urzędu Ochrony Danych Osobowych<br />
                ul. Stawki 2<br />
                00-193 Warszawa<br />
                www.uodo.gov.pl
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-[#003A30] mb-4">X. Profilowanie i automatyczne podejmowanie decyzji</h2>
              <p className="text-gray-600">
                Administrator nie prowadzi profilowania ani automatycznego podejmowania decyzji w rozumieniu art. 22 RODO wobec użytkowników strony internetowej.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-[#003A30] mb-4">XI. Bezpieczeństwo danych osobowych</h2>
              <p className="text-gray-600">
                Administrator stosuje odpowiednie środki techniczne i organizacyjne zapewniające bezpieczeństwo przetwarzania Państwa danych osobowych, chroniące je przed przypadkową utratą, uszkodzeniem lub dostępem osób nieuprawnionych.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-[#003A30] mb-4">XII. Informacje dotyczące plików cookies</h2>
              
              <h3 className="text-xl font-bold text-[#003A30] mb-3 mt-6">1. Czym są pliki cookies?</h3>
              <p className="text-gray-600 mb-4">
                Pliki cookies (tzw. „ciasteczka") to niewielkie pliki tekstowe, które są zapisywane i przechowywane na urządzeniu końcowym Użytkownika (komputerze, smartfonie, tablecie) podczas przeglądania strony internetowej. Pliki te zawierają szereg informacji, takich jak np. ustawienia językowe, preferencje użytkownika czy dane niezbędne do prawidłowego funkcjonowania strony.
              </p>

              <h3 className="text-xl font-bold text-[#003A30] mb-3 mt-6">2. Rodzaje wykorzystywanych plików cookies</h3>
              <p className="text-gray-600 mb-4">Na stronie internetowej Administratora wykorzystywane są następujące rodzaje plików cookies:</p>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li><strong>Cookies niezbędne</strong> – pliki cookies konieczne do prawidłowego funkcjonowania strony internetowej.</li>
                <li><strong>Cookies analityczne</strong> – pliki cookies wykorzystywane do celów statystycznych i analitycznych.</li>
                <li><strong>Cookies funkcjonalne</strong> – pliki cookies umożliwiające zapamiętanie ustawień i preferencji Użytkownika.</li>
                <li><strong>Cookies marketingowe</strong> – pliki cookies wykorzystywane do śledzenia aktywności Użytkowników.</li>
              </ul>

              <h3 className="text-xl font-bold text-[#003A30] mb-3 mt-6">3. Okres przechowywania plików cookies</h3>
              <p className="text-gray-600 mb-4">W zależności od ich funkcji, pliki cookies mogą być:</p>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li><strong>Sesyjne</strong> – pliki tymczasowe, usuwane po zamknięciu przeglądarki.</li>
                <li><strong>Stałe</strong> – pliki pozostające na urządzeniu przez określony czas lub do momentu ich ręcznego usunięcia.</li>
              </ul>

              <h3 className="text-xl font-bold text-[#003A30] mb-3 mt-6">4. Podmioty trzecie</h3>
              <p className="text-gray-600 mb-4">Na naszej stronie mogą być również wykorzystywane pliki cookies pochodzące od podmiotów trzecich, takich jak:</p>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li>Google Analytics (w celu analizy ruchu na stronie)</li>
                <li>Narzędzia społecznościowe (np. wtyczki Facebooka, LinkedIn)</li>
                <li>Narzędzia do marketingu online</li>
              </ul>

              <h3 className="text-xl font-bold text-[#003A30] mb-3 mt-6">5. Zarządzanie plikami cookies</h3>
              <p className="text-gray-600 mb-4">
                Każdy Użytkownik może samodzielnie zarządzać ustawieniami plików cookies za pomocą ustawień swojej przeglądarki internetowej. Możliwe jest:
              </p>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li>Akceptowanie wszystkich plików cookies</li>
                <li>Powiadamianie o używaniu plików cookies</li>
                <li>Blokowanie wszystkich lub wybranych plików cookies</li>
                <li>Usuwanie zapisanych plików cookies</li>
              </ul>

              <h3 className="text-xl font-bold text-[#003A30] mb-3 mt-6">6. Konsekwencje wyłączenia plików cookies</h3>
              <p className="text-gray-600">
                Należy pamiętać, że wyłączenie plików cookies może wpłynąć na funkcjonalność strony internetowej, a niektóre jej funkcje mogą nie działać prawidłowo lub być niedostępne.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-[#003A30] mb-4">XIII. Postanowienia końcowe</h2>
              <p className="text-gray-600">
                Administrator zastrzega sobie prawo do zmiany niniejszej Polityki Prywatności poprzez publikację nowej wersji dokumentu na stronie internetowej Administratora.
                <br /><br />
                W przypadku jakichkolwiek pytań lub wątpliwości dotyczących niniejszej Polityki Prywatności zachęcamy do kontaktu z Administratorem lub Inspektorem Ochrony Danych.
                <br /><br />
                Dziękujemy za zapoznanie się z naszą Polityką Prywatności i zapewniamy o naszym najwyższym zaangażowaniu w ochronę Państwa prywatności i bezpieczeństwa Państwa danych osobowych.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}