import type { CaseStudy } from '../types';

export const STRUCTURE_OPTIMIZATION_CASE: CaseStudy = {
  id: "debt-analysis-construction",
  client: "Andrzej",
  clientOccupation: "Właściciel",
  clientLocation: "Firma budowlano-remontowa",
  clientTypeTag: "Usługi budowlano-remontowe",
  image: "https://plus.unsplash.com/premium_photo-1661284937039-4d00e054d9cc?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YnVzaW5lc3MlMjBtYW58ZW58MHx8MHx8fDA%3D",
  title: "Audyt i analiza zadłużenia firmy budowlanej - oszczędność 190 000 zł rocznie",
  description: "Przeprowadziliśmy kompleksową analizę zadłużenia firmy budowlano-remontowej, identyfikując kredyty o niekorzystnych parametrach i wdrażając rekomendacje optymalizacyjne, które znacząco obniżyły koszty finansowania bez dodatkowych nakładów.",
  result: "Oszczędność 190 000 zł rocznie + zwiększenie limitów na rozwój",
  timelineInfo: "Wdrożenie w 6 tygodni",
  financialBenefit: "Redukcja kosztów finansowania o 28%",
  savingsAmount: "190000",
  savingsPercentage: 28,
  rating: 5,
  service: "optimization",
  challenges: [
    "7 różnych zobowiązań finansowych w 5 instytucjach z nieoptymalną strukturą",
    "Dwa kredyty obrotowe z wysokimi marżami (4,8% i 5,2%) i dodatkowymi opłatami",
    "Kredyt inwestycyjny z harmonogramem niedostosowanym do sezonowości branży",
    "Wysokie koszty obsługi leasingów sprzętu budowlanego",
    "Karta kredytowa z wysokim efektywnym kosztem (WIBOR + 6%) i ukrytymi opłatami"
  ],
  solutions: [
    "Kompleksowy audyt wszystkich zobowiązań finansowych i analiza ich rzeczywistego kosztu",
    "Refinansowanie kredytu inwestycyjnego w innym banku (obniżka marży z 2,8% do 1,7%)",
    "Konsolidacja dwóch kredytów obrotowych z obniżeniem marży z 5% do 3%",
    "Spłata części leasingów z dodatkowych środków uzyskanych z refinansowania",
    "Zamiana karty kredytowej na limit w rachunku bieżącym ze zwiększonym limitem na rozwój"
  ],
  directQuote: "Byłem przekonany, że moje warunki finansowania są rynkowe. Regularnie słyszałem od doradców bankowych, że mam 'świetne warunki' i 'najlepszą ofertę na rynku'. Gdy niezależny doradca przedstawił mi rzeczywisty koszt moich zobowiązań i porównał je do obecnie dostępnych na rynku, byłem w szoku. Nie miałem pojęcia, że przepłacam ponad 190 000 zł rocznie. Co więcej, żaden z banków, z którymi współpracowałem przez lata, nigdy nie zaproponował mi optymalizacji. Gdybym wiedział o tym wcześniej, już dawno zainwestowałbym w nowy sprzęt i zatrudnił dodatkową ekipę. Patrząc wstecz, żałuję tylko jednego - że nie zdecydowałem się na niezależny audyt zadłużenia kilka lat wcześniej.",
  whatIfScenario: "Gdyby Andrzej nie zdecydował się na przeprowadzenie audytu i analizy zadłużenia, jego firma nadal przepłacałaby około 190 000 zł rocznie na kosztach finansowania. W ciągu kolejnych 5 lat oznaczałoby to niemal milion złotych zbędnych wydatków. Co więcej, firma nadal zmagałaby się z problemami płynnościowymi w sezonie zimowym z powodu niedostosowanego harmonogramu spłat kredytu inwestycyjnego. Ograniczony dostęp do finansowania obrotowego (ze względu na wysokie obciążenia) uniemożliwiłby przyjmowanie większych zleceń i dalszy rozwój firmy. Z czasem, przy rosnącej konkurencji w branży budowlanej, mogłoby to prowadzić do stopniowej utraty pozycji rynkowej i spadku rentowności, a w konsekwencji nawet do konieczności redukcji zatrudnienia lub ograniczenia zakresu działalności.",
  beforeAfter: {
    before: "7 różnych zobowiązań w 5 instytucjach. Kredyt inwestycyjny z marżą 2,8% i stałymi ratami przez cały rok. Dwa kredyty obrotowe z marżami 4,8% i 5,2%. Trzy umowy leasingowe ze średnim efektywnym oprocentowaniem 10%. Karta kredytowa z limitem 100 tys. zł i efektywnym kosztem WIBOR + 6%. Miesięczne obciążenia: 58 000 zł (ze skokami do 65 000 zł). Problemy z płynnością w sezonie zimowym.",
    after: "4 zoptymalizowane zobowiązania. Kredyt inwestycyjny z marżą 1,7% i harmonogramem dostosowanym do sezonowości (niższe raty zimą). Jeden skonsolidowany kredyt obrotowy z marżą 3%. Jedna pozostała umowa leasingowa. Limit w rachunku bieżącym 250 tys. zł z oprocentowaniem WIBOR + 2,9%. Miesięczne obciążenia: 41 800 zł (ze stabilnymi ratami dostosowanymi do sezonu). Znacząca poprawa płynności i możliwość rozwoju."
  },
  reasoning: "Audyt i analiza zadłużenia firmy budowlano-remontowej były szczególnie uzasadnione ze względu na specyfikę branży: wyraźną sezonowość, długie cykle płatności i konieczność finansowania materiałów z góry. Z czasem, wraz z rozwojem firmy, struktura finansowania stała się nieefektywna, chaotycznie rozbudowywana w zależności od bieżących potrzeb, bez całościowego spojrzenia na optymalizację kosztów. Większość przedsiębiorców nie ma czasu ani specjalistycznej wiedzy, by na bieżąco monitorować warunki rynkowe i analizować rzeczywiste koszty swoich zobowiązań. Dodatkowo, instytucje finansowe nie mają interesu w proponowaniu klientom optymalizacji, która obniżyłaby ich marże. Kluczowym czynnikiem sukcesu w tym przypadku była kompleksowa analiza całej struktury zadłużenia, identyfikacja nieefektywności oraz wiedza o aktualnych warunkach rynkowych i wewnętrznych procedurach bankowych.",
  fullStory: {
    situation: "Andrzej, 46-letni właściciel firmy budowlano-remontowej z 12-letnim doświadczeniem na rynku, zbudował stabilny biznes zatrudniający 22 pracowników stałych oraz dodatkowo podwykonawców w sezonie. Firma specjalizuje się w kompleksowych remontach i wykończeniach dla klientów biznesowych i indywidualnych, generując roczne przychody na poziomie 9,2 mln zł. Przez lata rozwoju biznesu Andrzej zaciągał różne zobowiązania finansowe w zależności od bieżących potrzeb - kredyt inwestycyjny na zakup siedziby, kredyty obrotowe na finansowanie projektów, leasingi na sprzęt i pojazdy oraz kartę kredytową na nieprzewidziane wydatki. Z czasem struktura finansowania stała się bardzo złożona: 7 różnych zobowiązań w 5 instytucjach o łącznej wartości 2,3 mln zł, generujących miesięczne obciążenia na poziomie 58 000 zł. Mimo stabilnych przychodów, firma doświadczała okresowych problemów z płynnością, szczególnie w sezonie zimowym, gdy liczba zleceń była mniejsza, a raty kredytów pozostawały na tym samym poziomie.",
    challenge: "Głównym wyzwaniem była skomplikowana i nieefektywna struktura finansowania, która narastała organicznie wraz z rozwojem firmy. Andrzej, skupiony na prowadzeniu projektów i pozyskiwaniu klientów, nie miał czasu na szczegółową analizę warunków finansowych. Każde zobowiązanie było zaciągane indywidualnie, często pod presją czasu, co skutkowało nieoptymalnymi warunkami. Dwa kredyty obrotowe w różnych bankach miały wysokie marże (4,8% i 5,2%) oraz ukryte opłaty zwiększające rzeczywisty koszt. Kredyt inwestycyjny, mimo solidnej historii spłat, nadal miał marżę 2,8% i harmonogram niedostosowany do sezonowości branży, co powodowało problemy z płynnością w miesiącach zimowych. Trzy umowy leasingowe na sprzęt budowlany i samochody charakteryzowały się wysokim efektywnym oprocentowaniem (średnio 10%), a firmowa karta kredytowa, choć wygodna, generowała koszty na poziomie WIBOR + 6%. Sytuację komplikował fakt, że doradcy w bankach, z którymi Andrzej współpracował, zapewniali go, że ma 'najlepsze możliwe warunki', zniechęcając do poszukiwania alternatyw.",
    solution: "Przełom nastąpił, gdy podczas branżowej konferencji Andrzej rozmawiał z innym przedsiębiorcą, który podzielił się swoimi doświadczeniami z optymalizacji kosztów finansowania. Zainspirowany tą rozmową, Andrzej zdecydował się na spotkanie z niezależnym doradcą finansowym specjalizującym się w audycie zadłużenia i optymalizacji kosztów finansowania.\n\nDoradca przeprowadził kompleksową analizę wszystkich zobowiązań, obliczając ich rzeczywisty koszt z uwzględnieniem ukrytych opłat i prowizji. Wyniki analizy były zaskakujące dla Andrzeja - rzeczywiste koszty jego finansowania były o 20-40% wyższe niż standardowe warunki dostępne wówczas na rynku dla firmy o podobnym profilu i historii kredytowej.\n\nNa podstawie analizy doradca przygotował konkretne rekomendacje optymalizacyjne:\n\n1. Refinansowanie kredytu inwestycyjnego w innym banku, co pozwoliło obniżyć marżę z 2,8% do 1,7% oraz dostosować harmonogram spłat do sezonowości branży (niższe raty w miesiącach zimowych, wyższe w sezonie).\n\n2. Konsolidacja dwóch kredytów obrotowych w jeden produkt z marżą 3% zamiast dotychczasowych 4,8% i 5,2%, oraz eliminacja większości opłat dodatkowych.\n\n3. Spłata części leasingów (tych najmniej korzystnych) z dodatkowych środków uzyskanych dzięki refinansowaniu kredytu inwestycyjnego na korzystniejszych warunkach.\n\n4. Zamiana karty kredytowej na limit w rachunku bieżącym z oprocentowaniem WIBOR + 2,9% oraz zwiększenie dostępnego limitu z 100 tys. zł do 250 tys. zł, co dało firmie większą elastyczność i możliwości rozwojowe.\n\nPodczas negocjacji z bankami doradca wykorzystał swoją wiedzę o wewnętrznych procedurach bankowych oraz aktualnych ofertach, przedstawiając konkretne propozycje, które banki mogły zaakceptować bez długotrwałych procesów decyzyjnych. Cały proces, od rozpoczęcia audytu do wdrożenia wszystkich rekomendacji, trwał 6 tygodni.",
    details: {
      property: {
        type: "Firma budowlano-remontowa",
        revenue: "9,2 mln zł rocznie",
        clients: "Klienci biznesowi (70%) i indywidualni (30%)",
        employees: "22 pracowników stałych + podwykonawcy",
        experience: "12 lat na rynku"
      },
      loan: {
        beforeStructure: "7 zobowiązań w 5 instytucjach",
        beforeAmount: "2,3 mln zł łącznego zadłużenia",
        beforeCosts: "696 000 zł rocznie (58 000 zł miesięcznie)",
        afterStructure: "4 zoptymalizowane zobowiązania",
        afterAmount: "2,3 mln zł (brak dodatkowego zadłużenia)",
        afterCosts: "501 600 zł rocznie (41 800 zł miesięcznie)",
        savingsPerYear: "194 400 zł rocznie (28% redukcji kosztów)"
      },
      timeline: {
        initialAudit: "1 tydzień",
        analysis: "1 tydzień",
        recommendations: "1 tydzień",
        negotiations: "2 tygodnie",
        implementation: "1 tydzień",
        fullEffect: "6 tygodni od rozpoczęcia audytu"
      },
      savings: {
        refinancingBenefit: "Obniżka raty kredytu inwestycyjnego o 6 200 zł miesięcznie",
        consolidationBenefit: "Oszczędność na kredytach obrotowych: 4 800 zł miesięcznie",
        leasingOptimization: "Oszczędność na leasingach: 3 100 zł miesięcznie",
        creditCardElimination: "Oszczędność na karcie kredytowej: 2 100 zł miesięcznie",
        totalMonthlySavings: "16 200 zł miesięcznie",
        improvedLiquidity: "Poprawa płynności w sezonie zimowym dzięki niższym ratom",
        increasedCreditLimit: "Zwiększenie dostępnego limitu finansowania o 150 000 zł"
      }
    }
  }
};
