import { 
  WIBOR,
  MARGIN,
  BUFFER_RATE,
  FIXED_RATE,
  LOAN_PERIOD_YEARS,
  MAX_DSTI_STANDARD,
  MAX_DSTI_HIGH_INCOME,
  HIGH_INCOME_THRESHOLD,
  FIRST_PERSON_EXPENSE,
  ADDITIONAL_PERSON_EXPENSE,
  CREDIT_LIMIT_BURDEN_PERCENT
} from './constants';

import type { FormData, CalculationResults } from '../types';

export function calculateCreditworthiness(formData: FormData): CalculationResults {
  // Calculate household expenses
  const householdExpenses = FIRST_PERSON_EXPENSE + 
    (formData.householdMembers > 1 ? (formData.householdMembers - 1) * ADDITIONAL_PERSON_EXPENSE : 0);

  // Calculate credit limit burden
  const creditLimitBurden = formData.creditLimits * CREDIT_LIMIT_BURDEN_PERCENT;

  // Calculate total monthly obligations
  const totalObligations = formData.existingLoans + creditLimitBurden;

  // Calculate DSTI (Debt Service to Income ratio)
  const dsti = formData.income >= HIGH_INCOME_THRESHOLD ? MAX_DSTI_HIGH_INCOME : MAX_DSTI_STANDARD;

  // Calculate maximum monthly payment based on DSTI
  const maxPaymentDSTI = formData.income * dsti - totalObligations;

  // Calculate maximum monthly payment based on disposable income
  const maxPaymentIncome = formData.income - householdExpenses - totalObligations;

  // Take the lower of the two values
  const maxMonthlyPayment = Math.min(maxPaymentDSTI, maxPaymentIncome);

  // Calculate available income for loan payment (this is what we'll display)
  const availableIncome = maxMonthlyPayment;

  // Calculate loan capacity for variable rate
  // WIBOR + margin + buffer = 5.81% + 1.60% + 2.50% = 9.91%
  const variableRate = (WIBOR + MARGIN + BUFFER_RATE) / 100;
  const monthlyVariableRate = variableRate / 12;
  const months = LOAN_PERIOD_YEARS * 12;

  const variableRateCapacity = calculateLoanAmount(maxMonthlyPayment, monthlyVariableRate, months);
  const monthlyPaymentVariable = calculateMonthlyPayment(variableRateCapacity, monthlyVariableRate, months);

  // Calculate loan capacity for fixed rate
  // Fixed rate + buffer = 6.70% + 2.50% = 9.20%
  const fixedRate = (FIXED_RATE + BUFFER_RATE) / 100;
  const monthlyFixedRate = fixedRate / 12;

  const fixedRateCapacity = calculateLoanAmount(maxMonthlyPayment, monthlyFixedRate, months);
  const monthlyPaymentFixed = calculateMonthlyPayment(fixedRateCapacity, monthlyFixedRate, months);

  return {
    variableRateCapacity,
    fixedRateCapacity,
    monthlyPaymentVariable,
    monthlyPaymentFixed,
    householdExpenses,
    availableIncome,
    dsti,
    householdMembers: formData.householdMembers
  };
}

// Calculate loan amount based on monthly payment
function calculateLoanAmount(monthlyPayment: number, monthlyRate: number, months: number): number {
  if (monthlyPayment <= 0 || monthlyRate <= 0 || months <= 0) return 0;
  return monthlyPayment * ((1 - Math.pow(1 + monthlyRate, -months)) / monthlyRate);
}

// Calculate monthly payment for a loan
function calculateMonthlyPayment(loanAmount: number, monthlyRate: number, months: number): number {
  if (loanAmount <= 0 || monthlyRate <= 0 || months <= 0) return 0;
  return loanAmount * (monthlyRate * Math.pow(1 + monthlyRate, months)) / 
         (Math.pow(1 + monthlyRate, months) - 1);
}