import { Building2 } from 'lucide-react';
import type { Card, CardDetails } from '../types';

export const CONSOLIDATION_CARD: Card = {
  id: "consolidation",
  icon: Building2,
  title: "Konsolidacja kredytów zabezpieczona hipoteką",
  description: "Jeden kredyt zamiast wielu - bezpieczne rozwiązanie finansowe",
  benefits: [
    "Nawet do 50% niższa łączna rata kredytowa",
    "Zabezpieczenie hipoteczne dające stabilność",
    "Możliwość wydłużenia okresu spłaty"
  ],
  stats: {
    value: "8,81%",
    label: "Oprocentowanie zmienne"
  },
  cta: {
    label: "Sprawdź swoją zdolność konsolidacyjną",
    action: () => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })
  }
};

export const CONSOLIDATION_DETAILS: CardDetails = {
  title: "Konsolidacja kredytów zabezpieczona hipoteką",
  tagline: "Kompleksowe rozwiązanie dla Twoich zobowiązań finansowych",
  description: "Profesjonalna konsolidacja kredytów z wykorzystaniem zabezpieczenia hipotecznego pozwala na optymalizację kosztów i uproszczenie obsługi zadłużenia.",
  cta: {
    phone: "539 211 881",
    text: "Zadzwoń i dowiedz się, jak możemy pomóc Ci w konsolidacji kredytów"
  },
  benefitHighlights: [
    "Redukcja łącznego obciążenia kredytowego",
    "Jeden kredyt zamiast wielu różnych zobowiązań",
    "Możliwość negocjacji lepszych warunków",
    "Zabezpieczenie hipoteczne dające stabilność finansową"
  ],
  whyUs: [
    "Specjalizujemy się w kompleksowej obsłudze konsolidacji kredytów",
    "Mamy dostęp do ofert kredytowych niedostępnych dla klientów indywidualnych",
    "Doradzamy w zakresie optymalnego rozwiązania finansowego",
    "Minimalizujemy koszty i ryzyko finansowe"
  ],
  processComparison: {
    withUs: [
      {
        step: "Analiza zobowiązań",
        description: "Kompleksowy przegląd wszystkich aktualnych kredytów i zobowiązań",
        icon: "Search",
        highlight: true
      },
      {
        step: "Dobór optymalnej strategii",
        description: "Przedstawienie najkorzystniejszego wariantu konsolidacji",
        icon: "Lightbulb"
      },
      {
        step: "Przygotowanie dokumentacji",
        description: "Profesjonalna pomoc w kompletowaniu niezbędnych dokumentów",
        icon: "FileText"
      },
      {
        step: "Bezpieczna konsolidacja",
        description: "Przeprowadzenie procesu z minimalizacją ryzyka finansowego",
        icon: "CheckCircle",
        highlight: true
      }
    ],
    withoutUs: [
      {
        step: "Samodzielne poszukiwania",
        description: "Ryzyko przypadkowego wyboru niekorzystnej oferty",
        icon: "Search",
        warning: true
      },
      {
        step: "Skomplikowana dokumentacja",
        description: "Trudności w przygotowaniu dokumentów kredytowych",
        icon: "AlertTriangle"
      },
      {
        step: "Ryzyko błędów",
        description: "Możliwość popełnienia kosztownych pomyłek proceduralnych",
        icon: "XCircle",
        warning: true
      },
      {
        step: "Wyższe koszty",
        description: "Brak możliwości negocjacji korzystniejszych warunków",
        icon: "DollarSign",
        warning: true
      }
    ]
  },
  calculations: {
    example: "Szczegółowa analiza konsolidacji kredytów o łącznej wartości 300 000 zł",
    before: `Aktualna struktura zobowiązań:
• Kredyt gotówkowy (150 000 zł): WIBOR 6,5% + marża 4%, rata 1 800 zł
• Kredyt samochodowy (100 000 zł): WIBOR 6,7% + marża 3,5%, rata 1 500 zł
• Karta kredytowa (50 000 zł): Oprocentowanie 15%, minimalna rata 1 200 zł

Parametry zobowiązań:
• Łączna miesięczna rata: 4 500 zł
• Całkowity koszt obsługi zadłużenia rocznie: około 54 000 zł
• Całkowity koszt odsetek: szacunkowo 80 000 - 90 000 zł`,
    after: `Scenariusze konsolidacji:
1. Oprocentowanie zmienne:
• WIBOR 5,81% + marża 3% = 8,81%
• Kredyt konsolidacyjny: 300 000 zł, okres 20 lat
• Łączna rata: około 3 100 zł
• Całkowity koszt obsługi zadłużenia rocznie: około 37 200 zł

2. Oprocentowanie stałe:
• Stała stopa: około 8%
• Kredyt konsolidacyjny: 300 000 zł, okres 20 lat
• Łączna rata: około 3 050 zł
• Całkowity koszt obsługi zadłużenia rocznie: około 36 600 zł
• Możliwość wydłużenia okresu spłaty do 25 lat`,
    savings: `Potencjalne oszczędności:
• Miesięczne zmniejszenie raty: około 1 400 - 1 600 zł
• Roczne oszczędności: około 16 800 - 19 200 zł
• Redukcja kosztów obsługi zadłużenia: ok. 25-30%
• Porównanie kosztów odsetek:
   - Dotychczasowe zobowiązania: 80 000 - 90 000 zł
   - Po konsolidacji: 55 000 - 65 000 zł`,
    extra: `Kluczowe zastrzeżenia:
• Dokładna wysokość rat zależy od indywidualnej sytuacji finansowej
• Prezentowane wyliczenia mają charakter przykładowy
• Wymagana szczegółowa analiza dokumentów finansowych
• Możliwe dodatkowe koszty związane z ustanowieniem zabezpieczenia hipotecznego
• Rzeczywiste oprocentowanie może różnić się od prezentowanego
• Ostateczne warunki ustalane są indywidualnie`
  },
  benefits: [
    "Redukcja łącznego zadłużenia",
    "Jedna rata zamiast wielu zobowiązań",
    "Możliwość negocjacji niższego oprocentowania",
    "Zabezpieczenie hipoteczne dające stabilność",
    "Potencjał wydłużenia okresu spłaty",
    "Kompleksowe doradztwo finansowe"
  ],
  limitedTimeOffer: {
    text: "Bezpłatna analiza możliwości konsolidacji kredytów",
    expiry: "31.05.2025"
  },
  faq: [
    {
      question: "Jakie dokładnie rodzaje kredytów można skonsolidować?",
      answer: "Konsolidacją można objąć: 1) Kredyty gotówkowe – z banków i SKOK-ów, 2) Kredyty ratalne – np. na zakup sprzętu AGD/RTV, 3) Karty kredytowe z różnych instytucji, 4) Kredyty samochodowe, 5) Kredyty odnawialne, 6) Pożyczki pozabankowe. Wyłączone są zazwyczaj: kredyty hipoteczne, studenckie oraz zobowiązania wobec US i ZUS. Każdy przypadek wymaga indywidualnej weryfikacji prawnej i finansowej."
    },
    {
      question: "Czy konsolidacja wpłynie na moją zdolność kredytową?",
      answer: "Wpływ konsolidacji na zdolność kredytową jest wielowymiarowy: 1) Pozytywne aspekty - obniżenie łącznego miesięcznego zobowiązania, uproszczenie struktury długu, potencjalne zmniejszenie ryzyka opóźnień w spłatach, 2) Możliwe negatywne konsekwencje - czasowe obniżenie zdolności kredytowej z powodu nowego zobowiązania, konieczność ustanowienia hipoteki. Dokładny bilans zależy od indywidualnej historii kredytowej, wartości konsolidowanych zobowiązań i sytuacji dochodowej klienta."
    },
    {
      question: "Jak długo trwa proces konsolidacji?",
      answer: "Proces konsolidacji kredytów trwa standardowo 30-45 dni i składa się z następujących etapów: 1) Wstępna analiza dokumentów (3-5 dni), 2) Weryfikacja zdolności kredytowej (5-7 dni), 3) Przygotowanie i złożenie wniosku kredytowego (7-10 dni), 4) Decyzja banku (7-14 dni), 5) Procedura spłaty dotychczasowych zobowiązań (5-7 dni). Każdy etap może ulec wydłużeniu w zależności od kompletności dokumentów i indywidualnej sytuacji kredytowej."
    },
    {
      question: "Czy mogę otrzymać dodatkową gotówkę podczas konsolidacji?",
      answer: "Dodatkowa gotówka w ramach konsolidacji jest możliwa, ale obwarowana wieloma warunkami: 1) Maksymalna kwota dodatkowych środków to zazwyczaj 10-20% wartości konsolidowanych zobowiązań, 2) Decydujące czynniki to: aktualna zdolność kredytowa, wartość nieruchomości stanowiącej zabezpieczenie, historia kredytowa, 3) Każda dodatkowa kwota zwiększa ryzyko bankowe i może skutkować wyższym oprocentowaniem, 4) Konieczna jest szczegółowa analiza indywidualnej sytuacji finansowej. Nie ma gwarancji przyznania dodatkowych środków."
    }
  ],
  nextSteps: [
    "Skontaktuj się z nami telefonicznie lub przez formularz",
    "Umów się na bezpłatną konsultację",
    "Przygotuj dokumenty potwierdzające zobowiązania",
    "Przeprowadzimy szczegółową analizę Twoich kredytów",
    "Przedstawimy optymalne rozwiązanie konsolidacyjne",
    "Wesprzemy Cię w całym procesie konsolidacji"
  ]
};