import { RefreshCw } from 'lucide-react';
import type { Card, CardDetails } from '../types';

export const COST_REDUCTION_CARD: Card = {
  id: "cost-reduction",
  icon: RefreshCw,
  title: "Refinansowanie i konsolidacja kredytów firmowych",
  description: "Optymalizacja struktury finansowania dopasowana do specyfiki Twojej działalności",
  benefits: [
    "Redukcja miesięcznych rat nawet o 30-40%",
    "Obniżenie marży kredytowej średnio o 0,7-1,2 p.p.",
    "Uwolnienie potencjału finansowego Twojej firmy"
  ],
  stats: {
    value: "0,7 p.p.",
    label: "Średnia redukcja marży kredytowej"
  },
  cta: {
    label: "Sprawdź możliwości refinansowania",
    action: () => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })
  }
};

export const COST_REDUCTION_DETAILS: CardDetails = {
  title: "Refinansowanie i konsolidacja kredytów firmowych",
  tagline: "Uwolnij potencjał finansowy swojej firmy",
  description: "Projektujemy optymalną strukturę finansowania dostosowaną do specyfiki Twojej działalności. Poprzez refinansowanie istniejących zobowiązań i ich konsolidację, pomagamy obniżyć miesięczne obciążenia, uwolnić przepływy pieniężne i stworzyć przestrzeń dla dalszego rozwoju.",
  cta: {
    phone: "539 211 881",
    text: "Zadzwoń i dowiedz się, jak możemy pomóc w optymalizacji finansowania Twojej firmy"
  },
  benefitHighlights: [
    "Redukcja miesięcznego obciążenia finansowego firmy",
    "Uwolnienie zabezpieczeń i zwiększenie zdolności kredytowej",
    "Uproszczenie obsługi dzięki konsolidacji wielu zobowiązań",
    "Profesjonalne negocjacje warunków z instytucjami finansowymi"
  ],
  whyUs: [
    "Dostęp do ofert wszystkich znaczących instytucji finansowych na rynku",
    "Doświadczenie w projektowaniu optymalnych struktur finansowania dla firm różnej wielkości",
    "Kompleksowa obsługa procesu 'pod klucz' - od analizy po finalizację",
    "Znajomość aktualnych programów wsparcia i gwarancji BGK oraz innych instytucji"
  ],
  processComparison: {
    withUs: [
      {
        step: "Profesjonalna analiza finansowa",
        description: "Kompleksowa ocena sytuacji finansowej i potrzeb Twojej firmy",
        icon: "FileSearch",
        highlight: true
      },
      {
        step: "Projektowanie optymalnej struktury",
        description: "Dopasowanie rozwiązań do specyfiki działalności i planów rozwojowych",
        icon: "Lightbulb"
      },
      {
        step: "Negocjacje z instytucjami finansowymi",
        description: "Profesjonalne negocjacje marż, prowizji i warunków finansowania",
        icon: "Users"
      },
      {
        step: "Obsługa procesu 'pod klucz'",
        description: "Kompleksowe prowadzenie procesu aż do finalizacji",
        icon: "CheckCircle",
        highlight: true
      }
    ],
    withoutUs: [
      {
        step: "Ograniczone możliwości",
        description: "Dostęp tylko do ofert banków, z którymi firma już współpracuje",
        icon: "Lock",
        warning: true
      },
      {
        step: "Brak strategicznego podejścia",
        description: "Trudności w zaprojektowaniu optymalnej struktury finansowania",
        icon: "AlertTriangle",
        warning: true
      },
      {
        step: "Słabsza pozycja negocjacyjna",
        description: "Ograniczone możliwości negocjacji warunków",
        icon: "ThumbsDown",
        warning: true
      },
      {
        step: "Czasochłonny proces",
        description: "Samodzielne prowadzenie czasochłonnego i skomplikowanego procesu",
        icon: "Clock",
        warning: true
      }
    ]
  },
  calculations: {
    example: "Refinansowanie zadłużenia firmy produkcyjnej z sektora MŚP (2025)",
    before: "Sytuacja przed refinansowaniem:\n• Kredyt inwestycyjny: 1,5 mln zł, WIBOR 5,81% + marża 2,30% = 8,11%, rata 30 500 zł (pozostały okres 4 lata)\n• Kredyt obrotowy: 800 tys. zł, WIBOR 5,81% + marża 2,80% = 8,61%, rata 21 800 zł (pozostały okres 3 lata)\n• Leasing maszyn: 600 tys. zł, efektywne oprocentowanie 9,2%, rata 19 700 zł (pozostały okres 2,5 roku)\n• Łączna miesięczna rata: 72 000 zł\n• Różne terminy płatności, skomplikowana obsługa wielu zobowiązań\n• Zablokowane zabezpieczenia (hipoteka, zastaw na maszynach, środki obrotowe)",
    after: "Po refinansowaniu z naszym wsparciem:\n• Skonsolidowany kredyt inwestycyjny: 2,1 mln zł, WIBOR 5,81% + marża 1,60% = 7,41%, rata 32 600 zł (nowy okres 6 lat)\n• Nowy limit obrotowy: 800 tys. zł, WIBOR 5,81% + marża 1,90% = 7,71%, rata 11 400 zł (odnawialny)\n• Łączna miesięczna rata: 44 000 zł\n• Jeden termin płatności, uproszczona obsługa zadłużenia\n• Częściowe uwolnienie zabezpieczeń majątkowych\n• Wykorzystanie gwarancji BGK de minimis redukującej wymogi zabezpieczeń",
    savings: "Korzyści finansowe: miesięczna oszczędność około 28 000 zł (38,9% redukcji), roczna oszczędność około 336 000 zł, uwolnienie dodatkowej zdolności kredytowej na rozwój dzięki niższym ratom, obniżenie bieżących obciążeń finansowych przy jednoczesnej optymalizacji struktury zadłużenia",
    extra: "Dodatkowe korzyści: uporządkowanie struktury finansowej, uproszczenie obsługi zadłużenia, lepsza pozycja w negocjacjach z dostawcami dzięki poprawie przepływów, możliwość wykorzystania uwolnionych zabezpieczeń do nowych projektów inwestycyjnych. Ważna informacja: w naszym przykładzie widoczna jest istotna redukcja rat dzięki wydłużeniu okresu kredytowania, co jest często kluczową strategią w przypadku refinansowania zobowiązań krótkoterminowych."
  },
      benefits: [
    "Projektowanie optymalnej struktury finansowania dostosowanej do specyfiki działalności",
    "Selekcja najkorzystniejszych ofert uwzględniających indywidualny profil firmy",
    "Kompleksowa pomoc w przygotowaniu wymaganej dokumentacji kredytowej",
    "Profesjonalne negocjacje warunków (marże, prowizje, okresy kredytowania)",
    "Prowadzenie całego procesu refinansowania 'pod klucz' - od analizy po finalizację",
    "Wsparcie w procesie uruchomienia środków i rozliczenia dotychczasowych zobowiązań",
    "Wykorzystanie gwarancji BGK i innych instrumentów wsparcia do redukcji kosztów",
    "Optymalizacja struktury zabezpieczeń z wykorzystaniem posiadanego majątku i zapasów",
    "Redukcja miesięcznych obciążeń nawet o 30-60% poprzez wydłużenie okresu kredytowania krótkoterminowych zobowiązań"
  ],
  limitedTimeOffer: {
    text: "Bezpłatna analiza potencjalnych oszczędności z tytułu refinansowania dla firm z rocznym obrotem powyżej 3 mln zł",
    expiry: "30.04.2025"
  },
  faq: [
    {
      question: "Jak długo trwa proces refinansowania kredytów firmowych?",
      answer: "Standardowy proces refinansowania kredytów firmowych trwa od 4 do 8 tygodni, w zależności od złożoności struktury finansowania i wielkości firmy. Proces składa się z kilku kluczowych etapów: 1) Analiza finansowa i przygotowanie koncepcji (1-2 tygodnie), 2) Zebranie ofert od instytucji finansowych (2-3 tygodnie), 3) Negocjacje warunków i wybór najkorzystniejszego rozwiązania (1 tydzień), 4) Formalizacja i finalizacja procesu (1-2 tygodnie). Dzięki naszemu doświadczeniu i relacjom z instytucjami finansowymi, jesteśmy w stanie przeprowadzić cały proces sprawnie i z zachowaniem odpowiedniej staranności."
    },
    {
      question: "Jakie oszczędności można realnie uzyskać dzięki refinansowaniu?",
      answer: "Bazując na danych z marca 2025 roku i naszym doświadczeniu, realne oszczędności z tytułu refinansowania kredytów firmowych wynoszą zwykle: 1) Obniżenie marży kredytowej o 0,7-1,2 punktu procentowego (z typowej marży 2,30-2,80% do poziomu 1,60-1,90%), 2) Redukcję miesięcznej raty o 30-40% dzięki optymalizacji struktury i harmonogramu spłat (zwłaszcza poprzez wydłużenie okresu kredytowania krótkoterminowych zobowiązań), 3) Zmniejszenie kosztów obsługi o 5-10% poprzez konsolidację zobowiązań. W przypadkach firm z wysokim udziałem krótkoterminowych kredytów obrotowych, redukcja rat może sięgać nawet 50-60%. Zgodnie z art. 36a Ustawy Prawo Bankowe, przy refinansowaniu kredytów firmowych, bank nie może pobierać prowizji za wcześniejszą spłatę, jeśli refinansowanie następuje w ramach tej samej instytucji, co dodatkowo zwiększa opłacalność procesu."
    },
    {
      question: "Czy moja firma ma szansę na refinansowanie, jeśli ma okresowe problemy z płynnością?",
      answer: "Tak, refinansowanie jest możliwe nawet dla firm z przejściowymi problemami z płynnością, pod warunkiem że: 1) Ogólna sytuacja finansowa firmy jest stabilna (dodatni wynik finansowy w ostatnim roku), 2) Problemy z płynnością mają charakter sezonowy lub przejściowy, 3) Firma nie ma przeterminowanych zobowiązań wobec ZUS, US i innych instytucji publicznych. W takich przypadkach refinansowanie może być nawet bardziej korzystne, gdyż pozwala na dostosowanie harmonogramu spłat do sezonowości biznesu. Wykorzystujemy również instrumenty wsparcia, takie jak gwarancje BGK de minimis (aktualnie do 80% kwoty kredytu, zgodnie z rozporządzeniem Ministra Finansów z dnia 10.06.2024), które znacząco zwiększają szanse na pozytywną decyzję kredytową."
    },
    {
      question: "Czy można refinansować kredyty bez dodatkowych zabezpieczeń?",
      answer: "Tak, w wielu przypadkach możliwe jest refinansowanie bez konieczności ustanawiania dodatkowych zabezpieczeń. Główne strategie, które stosujemy to: 1) Wykorzystanie gwarancji BGK de minimis (aktualnie do 80% kwoty kredytu i maksymalnie 3,5 mln zł), 2) Optymalizacja istniejących zabezpieczeń - często obecne zabezpieczenia są przewartościowane względem aktualnego zadłużenia, 3) Wykorzystanie alternatywnych form zabezpieczeń, takich jak zastaw na zapasach czy cesja należności. Dodatkowo, zgodnie z art. 95 Ustawy Prawo Bankowe, przy refinansowaniu możliwe jest przeniesienie istniejącego zabezpieczenia hipotecznego na nowy kredyt bez konieczności ponownego ustanawiania hipoteki, co znacząco redukuje koszty całego procesu."
    },
    {
      question: "Jak refinansowanie wpłynie na zdolność kredytową mojej firmy w przyszłości?",
      answer: "Prawidłowo przeprowadzone refinansowanie zazwyczaj pozytywnie wpływa na zdolność kredytową firmy poprzez: 1) Obniżenie miesięcznego obciążenia finansowego, co poprawia wskaźniki obsługi zadłużenia (DSCR), 2) Uporządkowanie struktury finansowania, co jest pozytywnie postrzegane przez banki, 3) Uwolnienie części zabezpieczeń, które mogą zostać wykorzystane do nowych kredytów. Zgodnie z aktualnymi wymogami wynikającymi z Rekomendacji T Komisji Nadzoru Finansowego (2025), banki przy ocenie zdolności kredytowej firm uwzględniają wskaźnik DSCR na poziomie minimum 1,2-1,3, a refinansowanie często pozwala na poprawę tego wskaźnika o 0,2-0,4 punktu, co bezpośrednio przekłada się na wyższą zdolność kredytową."
    },
    {
      question: "Jakie dokumenty są niezbędne do rozpoczęcia procesu refinansowania?",
      answer: "Do rozpoczęcia procesu refinansowania potrzebujemy następujących dokumentów: 1) Sprawozdania finansowe za ostatnie 2 lata (bilans, rachunek zysków i strat), 2) Aktualne (nie starsze niż 3 miesiące) zestawienie zobowiązań kredytowych wraz z harmonogramami spłat, 3) Zaświadczenia o niezaleganiu z ZUS i US (lub oświadczenie), 4) Umowy kredytowe dotychczasowych zobowiązań, 5) Dokumenty dotyczące zabezpieczeń (np. odpisy z ksiąg wieczystych), 6) Uproszczone sprawozdanie za bieżący okres roku. Dla firm rozliczających się na podstawie KPiR wymagamy: wyciąg z KPiR za ostatnie 2 lata, deklaracje PIT/CIT za ostatnie 2 lata oraz aktualne zestawienie przychodów i kosztów. Dokumentacja może różnić się w zależności od specyfiki firmy i jej formy prawnej."
    },
    {
      question: "Jak wygląda proces wykorzystania gwarancji BGK przy refinansowaniu?",
      answer: "Proces wykorzystania gwarancji BGK przy refinansowaniu obejmuje następujące kroki: 1) Weryfikacja kwalifikowalności firmy do programu gwarancyjnego (obecnie podstawowym warunkiem jest status MŚP i prowadzenie działalności minimum 3 miesiące), 2) Przygotowanie wniosku o gwarancję wraz z dokumentacją kredytową, 3) Złożenie wniosku przez bank refinansujący do BGK, 4) Uzyskanie decyzji (zazwyczaj w ciągu 2-3 dni roboczych). Zgodnie z aktualnymi warunkami programu de minimis (stan na marzec 2025), gwarancja może obejmować do 80% kwoty kredytu, maksymalnie 3,5 mln zł, a opłata prowizyjne wynosi 0,5% kwoty gwarancji rocznie. Nasza firma posiada doświadczenie w efektywnym wykorzystaniu tego instrumentu i pomagamy w przygotowaniu całej niezbędnej dokumentacji, co znacząco zwiększa szanse na pozytywną decyzję."
    },
    {
      question: "Czy można refinansować kredyty w różnych walutach?",
      answer: "Tak, możliwe jest refinansowanie kredytów denominowanych w różnych walutach. Dostępne są dwie główne strategie: 1) Konsolidacja wszystkich zobowiązań do jednej waluty (najczęściej PLN) - eliminuje ryzyko kursowe, ale może wiązać się z kosztami przewalutowania, 2) Zachowanie struktury walutowej z optymalizacją warunków w ramach każdej waluty - odpowiednie dla firm generujących przychody w różnych walutach. Przy refinansowaniu kredytów walutowych należy uwzględnić aktualny spread walutowy (obecnie w marcu 2025 średni spread w bankach wynosi 2-3% dla głównych walut) oraz wymogi wynikające z Rekomendacji S Komisji Nadzoru Finansowego, która nakłada na banki obowiązek dokładnego informowania o ryzyku walutowym. Dla firm rozliczających się w euro, szczególnie korzystne są obecnie kredyty oparte o stopę EURIBOR, która wynosi około 3,3-3,5% (stan na marzec 2025), co w połączeniu z marżą 1,8-2,2% daje efektywne oprocentowanie znacząco niższe niż w przypadku kredytów złotowych."
    }
  ],
  nextSteps: [
    "Skontaktuj się z nami telefonicznie lub przez formularz",
    "Umów się na bezpłatną konsultację refinansową",
    "Przygotuj podstawową dokumentację finansową",
    "Otrzymaj analizę potencjalnych oszczędności i struktury refinansowania",
    "Wybierz najkorzystniejszą opcję spośród przedstawionych",
    "Skorzystaj z naszego wsparcia w całym procesie refinansowania"
  ]
};