import React from 'react';
import Concerns from '@/components/shared/Concerns';
import { optimizationConcernsData } from '@/data/ConcernsData';

export default function OptimizationConcerns() {
  return (
    <Concerns 
      concerns={optimizationConcernsData.concerns}
      title={optimizationConcernsData.title}
      subtitle={optimizationConcernsData.subtitle}
    />
  );
}