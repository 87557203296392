import React, { useState } from 'react';
import { Quote, Star } from 'lucide-react';
import CaseStudyModal from './CaseStudyModal';
import type { CaseStudy } from '@/data/caseStudies/types';

interface CaseStudyListProps {
  cases: CaseStudy[];
  title?: string;
  subtitle?: string;
  emptyMessage?: string;
  itemsPerPage?: number;
}

export default function CaseStudyList({ 
  cases = [],
  title = "Historie sukcesu",
  subtitle = "Zobacz, jak pomogliśmy innym w spełnieniu marzeń o własnym mieszkaniu",
  emptyMessage = "Aktualnie brak historii do wyświetlenia. Wróć wkrótce, aby poznać doświadczenia naszych klientów.",
  itemsPerPage = 3
}: CaseStudyListProps) {
  const [selectedCaseIndex, setSelectedCaseIndex] = useState<number | null>(null);
  const [loadedCount, setLoadedCount] = useState(itemsPerPage);
  const [isLoading, setIsLoading] = useState(false);

  // Load more cases
  const loadMoreCases = () => {
    if (isLoading || loadedCount >= cases.length) return;
    
    setIsLoading(true);
    
    // Simulate loading delay
    setTimeout(() => {
      setLoadedCount(prev => Math.min(prev + itemsPerPage, cases.length));
      setIsLoading(false);
    }, 300);
  };

  const handleClose = () => {
    setSelectedCaseIndex(null);
  };

  const handlePrevious = () => {
    if (selectedCaseIndex !== null && selectedCaseIndex > 0) {
      setSelectedCaseIndex(selectedCaseIndex - 1);
    }
  };

  const handleNext = () => {
    if (selectedCaseIndex !== null && selectedCaseIndex < cases.length - 1) {
      setSelectedCaseIndex(selectedCaseIndex + 1);
    }
  };

  // Get currently visible cases
  const visibleCases = cases.slice(0, loadedCount);

  return (
    <section 
      className="py-12 md:py-20 bg-gradient-to-b from-white to-gray-50" 
      aria-labelledby="case-studies-heading"
    >
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-12">
          <h2 
            id="case-studies-heading" 
            className="text-2xl md:text-4xl font-bold text-[#003A30] mb-4"
          >
            {title}
          </h2>
          <p className="text-lg text-gray-600">
            {subtitle}
          </p>
        </div>

        {cases.length === 0 ? (
          <div className="bg-white rounded-xl shadow-lg p-8 max-w-2xl mx-auto text-center">
            <p className="text-gray-600">{emptyMessage}</p>
          </div>
        ) : (
          <div 
            className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto"
            role="region"
            aria-label="Historie sukcesu naszych klientów"
          >
            {visibleCases.map((caseStudy, index) => (
              <article 
                key={index}
                className="bg-white rounded-xl shadow-lg overflow-hidden group hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
              >
                <div className="relative h-48">
                  <img 
                    src={caseStudy.image || '/api/placeholder/300/300'} 
                    alt={caseStudy.client || 'Klient'} 
                    className="w-full h-full object-cover"
                    width="300"
                    height="300"
                    loading="lazy"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                  <div className="absolute bottom-4 left-4 text-white">
                    <h3 className="font-bold text-lg">{caseStudy.client || 'Klient'}</h3>
                    {caseStudy.clientOccupation && caseStudy.clientLocation && (
                      <p className="text-sm text-gray-200 mt-1">{caseStudy.clientOccupation} • {caseStudy.clientLocation}</p>
                    )}
                    <div className="flex mt-1">
                      {Array(5).fill(0).map((_, i) => (
                        <Star 
                          key={i} 
                          className={`w-4 h-4 ${i < (caseStudy.rating || 5) ? 'text-[#CBA331] fill-[#CBA331]' : 'text-gray-300'}`} 
                        />
                      ))}
                    </div>
                  </div>
                  
                  {caseStudy.clientTypeTag && (
                    <div className="absolute top-4 right-4 bg-blue-600 text-white text-xs font-bold px-3 py-1.5 rounded-full shadow-lg">
                      {caseStudy.clientTypeTag}
                    </div>
                  )}
                </div>
                
                <div className="p-6">
                  <Quote 
                    className="w-8 h-8 text-[#CBA331] mb-4" 
                    aria-hidden="true"
                  />
                  <h4 className="text-lg font-bold text-[#003A30] mb-2">{caseStudy.title || 'Historia sukcesu'}</h4>
                  {caseStudy.description && <p className="text-gray-600 mb-4">{caseStudy.description}</p>}
                  {caseStudy.result && (
                    <div 
                      className="flex items-center gap-2 bg-emerald-50 px-4 py-2 rounded-lg mb-4"
                      role="status"
                      aria-label={`Rezultat: ${caseStudy.result}`}
                    >
                      <Star 
                        className="w-5 h-5 text-[#CBA331]" 
                        aria-hidden="true"
                      />
                      <p className="text-emerald-700 font-medium">{caseStudy.result}</p>
                    </div>
                  )}
                  <button
                    onClick={() => setSelectedCaseIndex(index)}
                    className="inline-flex items-center text-[#003A30] font-medium hover:text-[#CBA331] transition-colors duration-300 group-hover:underline"
                  >
                    Zobacz szczegóły
                    <svg 
                      className="w-4 h-4 ml-1 transition-transform duration-300 group-hover:translate-x-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M9 5l7 7-7 7" 
                      />
                    </svg>
                  </button>
                </div>
              </article>
            ))}
          </div>
        )}

        {/* Loading indicator */}
        {isLoading && (
          <div className="mt-8 text-center">
            <div className="inline-block w-8 h-8 border-4 border-[#CBA331] border-t-transparent rounded-full animate-spin"></div>
            <p className="text-gray-600 mt-2">Ładowanie więcej historii...</p>
          </div>
        )}

        {/* Load more button */}
        {!isLoading && loadedCount < cases.length && (
          <div className="mt-8 text-center">
            <button
              onClick={loadMoreCases}
              className="px-6 py-3 bg-white text-[#003A30] border border-[#003A30] rounded-lg hover:bg-[#003A30] hover:text-white transition-colors duration-300 font-medium"
            >
              Pokaż więcej historii
            </button>
          </div>
        )}
      </div>

      {/* Modal */}
      {selectedCaseIndex !== null && cases.length > 0 && (
        <CaseStudyModal
          isOpen={selectedCaseIndex !== null}
          onClose={handleClose}
          caseStudy={cases[selectedCaseIndex]}
          onPrevious={handlePrevious}
          onNext={handleNext}
          hasPrevious={selectedCaseIndex > 0}
          hasNext={selectedCaseIndex < cases.length - 1}
        />
      )}
    </section>
  );
}