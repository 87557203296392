import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NavLogo from './NavLogo';
import NavMobileButton from './NavMobileButton';
import NavMobileMenu from './NavMobileMenu';
import NavDesktopMenu from './NavDesktopMenu';

export default function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState('');
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Obsługa scrollowania
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Blokowanie przewijania body gdy menu jest otwarte
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMobileMenuOpen]);

  // Zamykanie menu przy zmianie trasy
  useEffect(() => {
    setIsMobileMenuOpen(false);
    setActiveSubmenu('');
  }, [location.pathname]);

  // Obsługa nawigacji
  const handleNavigation = useCallback((path: string) => {
    if (path.startsWith('#')) {
      const sectionId = path.slice(1);
      if (location.pathname !== '/') {
        navigate('/', { state: { scrollTo: sectionId } });
      } else {
        const element = document.getElementById(sectionId);
        if (element) {
          const headerOffset = 80;
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
      }
    } else {
      navigate(path);
    }
    setIsMobileMenuOpen(false);
    setActiveSubmenu('');
  }, [location.pathname, navigate]);

  // Obsługa przewijania po przekierowaniu na stronę główną
  useEffect(() => {
    if (location.pathname === '/' && location.state?.scrollTo) {
      // Opóźnienie, aby poczekać na załadowanie komponentów
      setTimeout(() => {
        const element = document.getElementById(location.state.scrollTo);
        if (element) {
          const headerOffset = 80;
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
        // Wyczyść state po przewinięciu
        window.history.replaceState({}, document.title);
      }, 100);
    }
  }, [location]);

  return (
    <nav
      className={`
        fixed top-0 left-0 right-0 z-50 
        transition-all duration-300
        ${isScrolled ? 'bg-white/95 backdrop-blur-md shadow-lg' : 'bg-white shadow-md'}
      `}
    >
      <div className="container mx-auto px-4">
        <div className="flex items-center h-20">
          {/* Logo */}
          <div className="flex-shrink-0">
            <NavLogo />
          </div>
          
          {/* Menu mobilne */}
          <div className="md:hidden ml-auto">
            <NavMobileButton 
              isOpen={isMobileMenuOpen} 
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} 
            />
          </div>

          {/* Menu mobilne - panel */}
          <NavMobileMenu 
            isOpen={isMobileMenuOpen}
            activeSubmenu={activeSubmenu}
            setActiveSubmenu={setActiveSubmenu}
            onNavigate={handleNavigation}
          />

          {/* Menu desktopowe */}
          <NavDesktopMenu onNavigate={handleNavigation} />
        </div>
      </div>
    </nav>
  );
}