import React, { useState, useEffect, useRef } from 'react';
import { Calculator, BarChart, ArrowRight, Clock, Percent, Info } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function Calculators() {
  const [visibleCalculators, setVisibleCalculators] = useState<number[]>([]);
  const calculatorsRef = useRef<(HTMLDivElement | null)[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const calculatorIndex = calculatorsRef.current.findIndex(ref => ref === entry.target);
            if (calculatorIndex !== -1 && !visibleCalculators.includes(calculatorIndex)) {
              setVisibleCalculators(prev => [...prev, calculatorIndex].sort((a, b) => a - b));
            }
          }
        });
      },
      {
        threshold: 0.2,
        rootMargin: '-10% 0px -10% 0px'
      }
    );

    calculatorsRef.current.forEach(calculator => {
      if (calculator) observer.observe(calculator);
    });

    return () => observer.disconnect();
  }, []);

  const calculators = [
    {
      icon: Calculator,
      title: "Kalkulator kredytowy",
      description: "Oblicz dokładną ratę kredytu hipotecznego i zaplanuj najniższe możliwe odsetki.",
      features: [
        "Symulacja zmian WIBOR",
        "Kalkulacja nadpłat miesięcznych i jednorazowych",
        "Czytelna wizualizacja i harmonogram spłat"
      ],
      stats: {
        icon: Clock,
        value: "2 min",
        label: "Szybka kalkulacja"
      },
      gradient: "from-emerald-500/20 to-emerald-500/5",
      link: "/kalkulator-kredytowy",
      tooltip: "Oblicz ratę kredytu hipotecznego z uwzględnieniem wszystkich kosztów"
    },
    {
      icon: BarChart,
      title: "Zdolność kredytowa",
      description: "Sprawdź swoją zdolność kredytową i poznaj dostępne możliwości.",
      features: [
        "Prosta obsługa",
        "Uwzględnienie zobowiązań",
        "Realna ocena możliwości"
      ],
      stats: {
        icon: Percent,
        value: "97%",
        label: "Dokładność wyliczeń"
      },
      gradient: "from-blue-500/20 to-blue-500/5",
      link: "/zdolnosc-kredytowa",
      tooltip: "Sprawdź maksymalną kwotę kredytu, na jaką możesz liczyć"
    }
  ];

  return (
    <section 
      id="kalkulatory" 
      className="relative py-12 md:py-16 lg:py-20 bg-gradient-to-b from-gray-200 via-gray-100 to-white" 
      aria-labelledby="calculators-heading"
    >
      {/* Decorative Elements */}
      <div className="absolute inset-0 pointer-events-none" aria-hidden="true">
        <div className="absolute top-0 left-1/4 w-64 h-64 bg-[#003A30]/10 rounded-full blur-3xl opacity-30 transform-gpu" />
        <div className="absolute bottom-0 right-1/4 w-64 h-64 bg-[#CBA331]/10 rounded-full blur-3xl opacity-30 transform-gpu" />
      </div>

      <div className="relative container mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header */}
        <div className="max-w-3xl mx-auto text-center mb-12 lg:mb-16">
          <span className="inline-block text-[#CBA331] font-semibold mb-2 text-sm sm:text-base">
            Narzędzia dla Ciebie
          </span>
          <h2 
            id="calculators-heading" 
            className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#003A30] mb-3"
          >
            Profesjonalne kalkulatory finansowe
          </h2>
          <p className="text-gray-600 text-base sm:text-lg md:text-xl">
            Wykorzystaj nasze narzędzia do lepszego planowania finansowego
          </p>
        </div>

        {/* Calculators Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8 max-w-[900px] mx-auto">
          {calculators.map((calc, index) => (
            <div 
              key={index}
              ref={el => calculatorsRef.current[index] = el}
              className={`
                group relative bg-white rounded-2xl shadow-[0_4px_20px_rgba(0,0,0,0.08)] overflow-hidden
                transform transition-all duration-700 ease-out
                ${visibleCalculators.includes(index) 
                  ? 'translate-y-0 scale-100 opacity-100' 
                  : 'translate-y-8 scale-95 opacity-0'
                }
                hover:shadow-[0_8px_30px_rgba(0,0,0,0.12)] hover:-translate-y-1 flex flex-col
              `}
              style={{
                transitionDelay: `${index * 100}ms`
              }}
              role="region"
              aria-label={calc.title}
            >
              {/* Top Gradient Bar */}
              <div 
                className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-[#003A30] to-[#CBA331] transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100" 
                aria-hidden="true"
              />
              
              {/* Calculator Content */}
              <div className="p-6 sm:p-8 flex flex-col h-full">
                {/* Icon and Title Section */}
                <div className="text-center mb-6">
                  <div className={`
                    w-16 h-16 rounded-2xl bg-gradient-to-br ${calc.gradient}
                    flex items-center justify-center mx-auto mb-4
                    transform transition-all duration-500
                    ${visibleCalculators.includes(index) ? 'scale-100' : 'scale-90'}
                    group-hover:scale-110
                  `}>
                    <calc.icon className="w-8 h-8 text-[#003A30] group-hover:text-[#CBA331] transition-colors duration-300" />
                  </div>
                  <div className="relative group/tooltip">
                    <h3 className="text-xl font-bold text-[#003A30]">{calc.title}</h3>
                    <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-3 py-1 bg-gray-900 text-white text-sm rounded-lg opacity-0 invisible group-hover/tooltip:opacity-100 group-hover/tooltip:visible transition-all duration-200 whitespace-nowrap">
                      {calc.tooltip}
                      <div className="absolute top-full left-1/2 -translate-x-1/2 border-4 border-transparent border-t-gray-900"></div>
                    </div>
                  </div>
                </div>

                {/* Description */}
                <p className={`
                  text-gray-600 text-center mb-6
                  transform transition-all duration-500 delay-100
                  ${visibleCalculators.includes(index) ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
                `}>
                  {calc.description}
                </p>

                {/* Features List */}
                <ul className="space-y-3 mb-6 flex-grow" role="list">
                  {calc.features.map((feature, idx) => (
                    <li 
                      key={idx} 
                      className={`
                        flex items-start gap-2 text-gray-600
                        transform transition-all duration-500
                        ${visibleCalculators.includes(index) ? 'translate-x-0 opacity-100' : 'translate-x-4 opacity-0'}
                      `}
                      style={{
                        transitionDelay: `${200 + idx * 100}ms`
                      }}
                    >
                      <div className="w-1.5 h-1.5 rounded-full bg-[#CBA331] mt-2 flex-shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>

                {/* Stats */}
                <div className={`
                  flex items-center justify-center gap-3 p-4 bg-gray-50 rounded-lg mb-6
                  transform transition-all duration-500 delay-500
                  ${visibleCalculators.includes(index) ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
                `}>
                  <calc.stats.icon className="w-5 h-5 text-[#CBA331]" />
                  <div className="text-center">
                    <span className="block text-lg font-bold text-[#003A30]">{calc.stats.value}</span>
                    <span className="text-sm text-gray-600">{calc.stats.label}</span>
                  </div>
                </div>

                {/* CTA Link */}
                <button 
                  onClick={() => navigate(calc.link)}
                  className={`
                    inline-flex items-center justify-center px-6 py-3 bg-[#003A30] text-white rounded-lg 
                    hover:bg-[#CBA331] transition-all duration-300 group/link text-base font-medium w-full
                    transform transition-all duration-500 delay-600
                    ${visibleCalculators.includes(index) ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
                  `}
                >
                  Oblicz teraz
                  <ArrowRight className="w-5 h-5 ml-2 group-hover/link:translate-x-1 transition-transform duration-300" />
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Trust Indicators */}
        <div className="mt-12 lg:mt-16 text-center">
          <p className="text-gray-500 text-sm sm:text-base">
            Zaufało nam już ponad <span className="text-[#003A30] font-semibold">150 000</span> użytkowników
          </p>
        </div>
      </div>
    </section>
  );
}