import React, { useState, useEffect } from 'react';
import { Calculator as CalculatorIcon, ArrowRight, BadgePercent, Shield, Sparkles } from 'lucide-react';

export default function Calculator() {
  const [amount, setAmount] = useState<number>(1000000);
  const [period, setPeriod] = useState<number>(25);
  const [currentRate, setCurrentRate] = useState<number>(8.3);
  const [isVisible, setIsVisible] = useState(false);

  const WIBOR = 5.81;
  const MARGIN = 1.60;
  const FIXED_RATE = 6.58;
  const VARIABLE_RATE = WIBOR + MARGIN;

  const calculateMonthlyPayment = (principal: number, annualRate: number, years: number) => {
    const monthlyRate = annualRate / 100 / 12;
    const numberOfPayments = years * 12;
    const monthlyPayment = (principal * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / 
                          (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
    return Math.round(monthlyPayment);
  };

  const variableRate = calculateMonthlyPayment(amount, VARIABLE_RATE, period);
  const fixedRate = calculateMonthlyPayment(amount, FIXED_RATE, period);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.2 }
    );

    const element = document.getElementById('calculator-section');
    if (element) {
      observer.observe(element);
    }

    return () => observer.disconnect();
  }, []);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  return (
    <section id="calculator-section" className="py-8 sm:py-12 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <div className={`
          max-w-6xl mx-auto bg-white rounded-2xl shadow-lg overflow-hidden
          transform transition-all duration-700 ease-out backdrop-blur-sm
          hover:shadow-xl hover:shadow-[#003A30]/5
          ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'}
        `}>
          <div className="p-4 sm:p-6 md:p-8 relative">
            {/* Dekoracyjne elementy tła */}
            <div className="absolute inset-0 bg-gradient-to-br from-[#003A30]/[0.02] via-transparent to-[#CBA331]/[0.02] pointer-events-none" />
            <div className="absolute -top-24 -right-24 w-48 h-48 bg-[#003A30]/5 rounded-full blur-3xl opacity-20" />
            <div className="absolute -bottom-24 -left-24 w-48 h-48 bg-[#CBA331]/5 rounded-full blur-3xl opacity-20" />

            {/* Nagłówek */}
            <div className="flex items-center justify-center gap-2 sm:gap-3 mb-6 sm:mb-8 relative">
              <div className="relative">
                <CalculatorIcon className="w-6 h-6 sm:w-8 sm:h-8 text-[#CBA331] transform transition-transform duration-300 hover:scale-110" />
                <Sparkles className="w-3 h-3 sm:w-4 sm:h-4 text-[#CBA331]/50 absolute -top-1 -right-1 animate-pulse" />
              </div>
              <h2 className="text-xl sm:text-2xl font-bold text-[#003A30] relative">
                Oblicz ratę kredytu
                <div className="absolute -bottom-1 left-0 w-full h-0.5 bg-gradient-to-r from-[#CBA331]/0 via-[#CBA331] to-[#CBA331]/0" />
              </h2>
            </div>

            <div className="grid md:grid-cols-2 gap-6 md:gap-8 items-start">
              {/* Lewa kolumna - Kwota kredytu */}
              <div className="relative group">
                <div className="flex justify-between items-center mb-2 sm:mb-3">
                  <label className="text-xs sm:text-sm font-medium text-gray-700 transition-colors group-hover:text-[#003A30]">
                    Kwota kredytu
                  </label>
                  <span className="text-lg sm:text-2xl font-bold text-[#003A30] transition-all duration-300 group-hover:text-[#CBA331]">
                    {formatCurrency(amount)}
                  </span>
                </div>
                <div className="relative touch-none">
                  <input
                    type="range"
                    min="100000"
                    max="2000000"
                    step="10000"
                    value={amount}
                    onChange={(e) => setAmount(Number(e.target.value))}
                    className="w-full h-2 appearance-none cursor-pointer bg-gray-200 rounded-lg
                      [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-6 [&::-webkit-slider-thumb]:h-6
                      [&::-webkit-slider-thumb]:bg-[#003A30] [&::-webkit-slider-thumb]:rounded-full
                      [&::-webkit-slider-thumb]:cursor-pointer [&::-webkit-slider-thumb]:transition-all
                      [&::-webkit-slider-thumb]:duration-300 [&::-webkit-slider-thumb]:hover:scale-125
                      [&::-webkit-slider-thumb]:hover:bg-[#CBA331] [&::-webkit-slider-thumb]:shadow-lg
                      [&::-moz-range-thumb]:appearance-none [&::-moz-range-thumb]:w-6 [&::-moz-range-thumb]:h-6
                      [&::-moz-range-thumb]:bg-[#003A30] [&::-moz-range-thumb]:border-0
                      [&::-moz-range-thumb]:rounded-full [&::-moz-range-thumb]:cursor-pointer
                      [&::-moz-range-thumb]:transition-all [&::-moz-range-thumb]:duration-300
                      [&::-moz-range-thumb]:hover:scale-125 [&::-moz-range-thumb]:hover:bg-[#CBA331]
                      [&::-moz-range-thumb]:shadow-lg [&::-moz-range-progress]:bg-[#003A30]
                      [&::-moz-range-progress]:h-2 [&::-moz-range-progress]:rounded-l-lg
                    "
                  />
                </div>
                <div className="flex justify-between text-xs text-gray-500 mt-2">
                  <span className="transition-colors duration-300 hover:text-[#003A30]">100 tys.</span>
                  <span className="transition-colors duration-300 hover:text-[#003A30]">2 mln</span>
                </div>
              </div>

              {/* Prawa kolumna - Raty */}
              <div className="grid grid-cols-2 gap-3 sm:gap-4">
                {/* Oprocentowanie zmienne */}
                <div className="group bg-gradient-to-br from-[#003A30]/5 to-transparent rounded-xl p-3 sm:p-4 transition-all duration-300 hover:shadow-lg hover:from-[#003A30]/10">
                  <div className="flex items-center gap-2 mb-1 sm:mb-2">
                    <BadgePercent className="w-4 h-4 sm:w-5 sm:h-5 text-[#CBA331] transition-transform duration-300 group-hover:scale-110" />
                    <h3 className="text-xs sm:text-sm font-semibold text-[#003A30]">Rata zmienna</h3>
                  </div>
                  <p className="text-lg sm:text-2xl font-bold text-[#003A30] mb-1 transition-colors duration-300 group-hover:text-[#CBA331]">
                    {formatCurrency(variableRate)}
                  </p>
                  <p className="text-[10px] sm:text-xs text-gray-500">
                    WIBOR {WIBOR}% + marża {MARGIN}%
                  </p>
                </div>

                {/* Oprocentowanie stałe */}
                <div className="group bg-gradient-to-br from-[#CBA331]/5 to-transparent rounded-xl p-3 sm:p-4 transition-all duration-300 hover:shadow-lg hover:from-[#CBA331]/10">
                  <div className="flex items-center gap-2 mb-1 sm:mb-2">
                    <Shield className="w-4 h-4 sm:w-5 sm:h-5 text-[#CBA331] transition-transform duration-300 group-hover:scale-110" />
                    <h3 className="text-xs sm:text-sm font-semibold text-[#003A30]">Rata stała</h3>
                  </div>
                  <p className="text-lg sm:text-2xl font-bold text-[#003A30] mb-1 transition-colors duration-300 group-hover:text-[#CBA331]">
                    {formatCurrency(fixedRate)}
                  </p>
                  <p className="text-[10px] sm:text-xs text-gray-500">
                    Stała stopa {FIXED_RATE}%
                  </p>
                </div>
              </div>
            </div>

            {/* CTA */}
            <div className="mt-6 sm:mt-8 text-center">
              <button
                onClick={() => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })}
                className="inline-flex items-center justify-center px-6 sm:px-8 py-2.5 sm:py-3 bg-[#003A30] text-white rounded-lg hover:bg-[#CBA331] transition-all duration-300 group text-sm sm:text-base"
              >
                Otrzymaj szczegółową ofertę
                <ArrowRight className="w-4 h-4 sm:w-5 sm:h-5 ml-2 group-hover:translate-x-1 transition-transform duration-300" />
              </button>
              <p className="mt-4 text-xs text-gray-500 max-w-xl mx-auto">
                Przedstawione wyliczenia mają charakter orientacyjny. Szczegółowa oferta kredytowa zostanie przygotowana po przeprowadzeniu pełnej analizy Twojej sytuacji finansowej i zdolności kredytowej.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}