import type { CaseStudy } from '../types';

export const FIRST_HOME_CASE: CaseStudy = {
  id: "first-home",
  client: "Natalia",
  image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?w=300&h=300&fit=crop&auto=format",
  title: "Kredyt na pierwsze mieszkanie dla senior programistki",
  description: "Pomogliśmy uzyskać kredyt hipoteczny na atrakcyjny apartament dla specjalistki IT pracującej na etacie w korporacji.",
  result: "Kredyt przyznany mimo wysokiej wartości nieruchomości",
  service: "mortgage",
  challenges: [
    "Znaczna część dochodów w formie premii i bonusów",
    "Krótki staż w obecnej korporacji (1.5 roku)",
    "Wcześniejsza praca za granicą (historia kredytowa w innym kraju)",
    "Niestandardowa wartość nieruchomości (apartament w modnej dzielnicy Zabłocie)",
    "Rygorystyczna polityka kredytowa banków w 2025 roku"
  ],
  solutions: [
    "Znalezienie banku doceniającego stabilność zatrudnienia w korporacji",
    "Odpowiednie udokumentowanie premii i bonusów do zdolności kredytowej",
    "Negocjacja preferencyjnych warunków dzięki profilowi zawodowemu",
    "Wsparcie w procesie zakupu nieruchomości premium",
    "Skrócenie procesu decyzyjnego dzięki przygotowaniu pełnej dokumentacji"
  ],
  directQuote: "Obawiałam się, że mój krótki staż w korporacji i mieszkanie o takiej wartości będą problemem. Dzięki profesjonalnemu wsparciu nie tylko uzyskałam finansowanie, ale wynegocjowałam świetne warunki!",
  financialBenefit: "Oszczędność 27 000 zł na kosztach okołokredytowych",
  savingsPercentage: 15,
  savingsAmount: "650 zł miesięcznie mniej niż w standardowej ofercie",
  whatIfScenario: "Bez profesjonalnego wsparcia Natalia mogłaby mieć trudności z uwzględnieniem całości dochodów (w tym premii i bonusów). Ryzykowałaby uzyskanie znacznie niższej zdolności kredytowej, co wykluczyłoby zakup wymarzonego mieszkania, a w międzyczasie ceny na rynku krakowskim wzrosłyby o kolejne 5-7%.",
  clientTypeTag: "Specjalista IT",
  ctaText: "Sprawdź możliwości kredytu dla pracowników korporacji",
  ctaLink: "/kredyty-dla-specjalistow",
  difficultyLevel: 3,
  timelineInfo: "Kredyt w 18 dni",
  rating: 5,
  date: "Luty 2025",
  creditType: "Kredyt hipoteczny",
  clientOccupation: "Senior Frontend Developer",
  clientLocation: "Kraków, Zabłocie",
  beforeAfter: {
    before: "Obawy związane z krótkim stażem pracy w obecnej firmie, niepewność co do uwzględnienia premii do zdolności kredytowej, standardowa marża 2.1%, wysokie koszty dodatkowe.",
    after: "Kredyt przyznany bez problemu, wynegocjowana marża 1.65%, ubezpieczenie 40% tańsze, przejrzysty i szybki proces na każdym etapie."
  },
  reasoning: "Zdecydowałam się na tę firmę ze względu na ich specjalizację w obsłudze klientów z branży IT. Ich doświadczenie w pracy z osobami o złożonej strukturze wynagrodzenia i umiejętność negocjacji z bankami okazały się bezcenne. Najbardziej doceniam indywidualne podejście i pełne zrozumienie specyfiki mojej pracy w korporacji.",
  fullStory: {
    situation: "Senior Frontend Developer pracująca na etacie w międzynarodowej korporacji od 1.5 roku, wcześniej pracująca za granicą, planująca zakup pierwszego mieszkania w modnej dzielnicy Zabłocie w Krakowie.",
    challenge: "Krótki staż pracy w obecnej firmie, znaczna część wynagrodzenia w formie kwartalnych premii i bonusów, oraz historia kredytowa w innym kraju utrudniająca standardową ocenę zdolności kredytowej.",
    solution: "Znaleźliśmy bank, który właściwie ocenił stabilność zatrudnienia w renomowanej korporacji oraz w pełni uwzględnił premie i bonusy do zdolności kredytowej.",
    details: {
      property: {
        price: "700 000 zł",
        type: "Apartament 68m²",
        location: "Kraków, Zabłocie"
      },
      loan: {
        amount: "560 000 zł",
        downPayment: "140 000 zł (20%)",
        period: "25 lat",
        rate: "6.85%"
      },
      timeline: {
        approval: "18 dni",
        total: "35 dni"
      }
    }
  }
};