import { RefreshCw, BadgePercent, Clock, PiggyBank } from 'lucide-react';
import type { SocialProofStats } from './types';

export const refinancingStats: SocialProofStats = {
  clients: {
    icon: RefreshCw,
    value: 500,
    showPlus: true,
    label: "Refinansowanych kredytów"
  },
  financing: {
    icon: BadgePercent,
    value: 35,
    suffix: "%",
    label: "Średnia oszczędność"
  },
  experience: {
    icon: Clock,
    value: 30,
    suffix: " dni",
    label: "Średni czas procesu"
  },
  satisfaction: {
    icon: PiggyBank,
    value: 850,
    suffix: " zł",
    label: "Średnia ulga miesięcznie"
  }
};