import React from 'react';
import { Phone, Mail, Building2, Facebook, Youtube, Linkedin, ArrowRight, ChevronRight } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Footer() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    if (path.startsWith('#')) {
      if (location.pathname !== '/') {
        navigate('/' + path);
      } else {
        const element = document.getElementById(path.substring(1));
        if (element) {
          const headerOffset = 80;
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
      }
    } else {
      navigate(path);
    }
  };

  const mainLinks = [
    { label: 'Usługi', section: '#services' },
    { label: 'Kalkulatory', section: '#calculators' },
    { label: 'Proces', section: '#process' },
    { label: 'Opinie', section: '#testimonials' },
    { label: 'Kontakt', section: '#contact' },
  ];

  const services = [
    { label: 'Kredyty hipoteczne', href: '/kredyty-hipoteczne' },
    { label: 'Kredyty firmowe', href: '/kredyty-firmowe' },
    { label: 'Refinansowanie', href: '/refinansowanie' },
    { label: 'Optymalizacja', href: '/optymalizacja' },
  ];

  const calculators = [
    { label: 'Kalkulator kredytowy', href: '/kalkulator-kredytowy' },
    { label: 'Zdolność kredytowa', href: '/zdolnosc-kredytowa' },
  ];

  const knowledge = [
    { label: 'Blog', href: '/blog' },
    { label: 'Poradniki', href: '/poradniki' },
    { label: 'Dokumenty', href: '/dokumenty' },
  ];

  return (
    <footer className="relative bg-gradient-to-b from-[#003A30] to-[#002920]" role="contentinfo">
      {/* Decorative Elements */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        <div className="absolute top-0 left-1/4 w-64 h-64 bg-[#CBA331]/10 rounded-full blur-3xl opacity-30" />
        <div className="absolute bottom-0 right-1/4 w-64 h-64 bg-[#CBA331]/10 rounded-full blur-3xl opacity-30" />
      </div>

      {/* Main Footer Content */}
      <div className="relative">
        <div className="container mx-auto px-4 py-12 md:py-16">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-8 md:gap-12 mb-12">
            {/* Company Info */}
            <div className="md:col-span-4">
              <div className="mb-6">
                <img src="/logo-white.svg" alt="Ekspert Kredytowy - Logo" className="w-[200px]" />
              </div>
              <p className="text-gray-400 text-sm leading-relaxed mb-6">
                Fundamenty Majątku Sp. z o.o.<br />
                NIP: 6751795919<br />
                REGON: 527991221<br />
                KRS: 0001092308
              </p>
              <div className="flex space-x-4">
                <a 
                  href="https://www.facebook.com/zychartur"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-10 h-10 bg-white/5 rounded-lg flex items-center justify-center hover:bg-[#CBA331] transition-colors duration-300 group"
                  aria-label="Facebook"
                >
                  <Facebook className="w-5 h-5 text-white" />
                </a>
                <a 
                  href="https://www.youtube.com/@ArturZychPL"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-10 h-10 bg-white/5 rounded-lg flex items-center justify-center hover:bg-[#CBA331] transition-colors duration-300 group"
                  aria-label="YouTube"
                >
                  <Youtube className="w-5 h-5 text-white" />
                </a>
                <a 
                  href="https://www.linkedin.com/in/zychartur"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-10 h-10 bg-white/5 rounded-lg flex items-center justify-center hover:bg-[#CBA331] transition-colors duration-300 group"
                  aria-label="LinkedIn"
                >
                  <Linkedin className="w-5 h-5 text-white" />
                </a>
              </div>
            </div>

            {/* Quick Links */}
            <div className="md:col-span-2">
              <h4 className="text-lg font-semibold text-white mb-4">Menu</h4>
              <ul className="space-y-2">
                {mainLinks.map((link, index) => (
                  <li key={index}>
                    <button 
                      onClick={() => handleNavigation(link.section)}
                      className="text-gray-400 hover:text-[#CBA331] transition-colors duration-300 flex items-center group"
                    >
                      <ChevronRight className="w-4 h-4 opacity-0 -ml-4 group-hover:opacity-100 group-hover:ml-0 transition-all duration-300" />
                      {link.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            {/* Services */}
            <div className="md:col-span-2">
              <h4 className="text-lg font-semibold text-white mb-4">Usługi</h4>
              <ul className="space-y-2">
                {services.map((service, index) => (
                  <li key={index}>
                    <button 
                      onClick={() => handleNavigation(service.href)}
                      className="text-gray-400 hover:text-[#CBA331] transition-colors duration-300 flex items-center group"
                    >
                      <ChevronRight className="w-4 h-4 opacity-0 -ml-4 group-hover:opacity-100 group-hover:ml-0 transition-all duration-300" />
                      {service.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            {/* Calculators */}
            <div className="md:col-span-2">
              <h4 className="text-lg font-semibold text-white mb-4">Kalkulatory</h4>
              <ul className="space-y-2">
                {calculators.map((calc, index) => (
                  <li key={index} className="w-full">
                    <button 
                      onClick={() => handleNavigation(calc.href)}
                      className="text-gray-400 hover:text-[#CBA331] transition-colors duration-300 flex items-center group w-full text-left"
                    >
                      <ChevronRight className="w-4 h-4 opacity-0 -ml-4 group-hover:opacity-100 group-hover:ml-0 transition-all duration-300 flex-shrink-0" />
                      <span className="whitespace-normal">{calc.label}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            {/* Contact */}
            <div className="md:col-span-2">
              <h4 className="text-lg font-semibold text-white mb-4">Kontakt</h4>
              <ul className="space-y-4">
                <li>
                  <a 
                    href="tel:+48539211881" 
                    className="flex items-center text-gray-400 hover:text-[#CBA331] transition-colors duration-300 group"
                  >
                    <Phone className="w-4 h-4 mr-2 group-hover:scale-110 transition-transform duration-300" />
                    +48 539 211 881
                  </a>
                </li>
                <li>
                  <a 
                    href="mailto:artur@arturzych.pl" 
                    className="flex items-center text-gray-400 hover:text-[#CBA331] transition-colors duration-300 group"
                  >
                    <Mail className="w-4 h-4 mr-2 group-hover:scale-110 transition-transform duration-300" />
                    artur@arturzych.pl
                  </a>
                </li>
                <li className="flex items-start text-gray-400">
                  <Building2 className="w-4 h-4 mr-2 mt-1 flex-shrink-0" />
                  <span>
                    Aleja Pokoju 18D<br />
                    31-546 Kraków
                  </span>
                </li>
              </ul>
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="pt-8 border-t border-white/10">
            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
              <p className="text-sm text-gray-400">
                &copy; 2025 Fundamenty Majątku Sp. z o.o. Wszelkie prawa zastrzeżone.
              </p>
              <div className="flex flex-wrap justify-center md:justify-end gap-6">
                <button className="text-sm text-gray-400 hover:text-[#CBA331] transition-colors duration-300">
                  Polityka prywatności
                </button>
                <button className="text-sm text-gray-400 hover:text-[#CBA331] transition-colors duration-300">
                  Regulamin
                </button>
                <button className="text-sm text-gray-400 hover:text-[#CBA331] transition-colors duration-300">
                  RODO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}