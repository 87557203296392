import React, { useState, useEffect } from 'react';
import { Star, ArrowRight, DivideIcon as LucideIcon } from 'lucide-react';

interface HeroProps {
  title: string;
  highlightedWord?: string;
  description: string;
  image: string;
  badgeText?: string;
  stats: Array<{
    icon: LucideIcon;
    value: string;
    label: string;
  }>;
  primaryCta: {
    label: string;
    action: () => void;
  };
  secondaryCta: {
    label: string;
    action: () => void;
  };
}

export default function Hero({
  title,
  highlightedWord,
  description,
  image,
  badgeText = "Zaufało nam już ponad 1200 klientów",
  stats,
  primaryCta,
  secondaryCta
}: HeroProps) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <section className="relative min-h-[600px] sm:min-h-[700px] md:min-h-[750px] lg:min-h-[800px] xl:min-h-[900px] 2xl:min-h-[1000px] pt-20 overflow-hidden">
      {/* Background with image */}
      <div className="absolute inset-0" aria-hidden="true">
        {/* Mobile image */}
        <div className="block md:hidden h-full">
          <img 
            src={`${image}?w=750&h=1000&fit=crop&crop=faces,center&auto=format&q=80`}
            alt="" 
            role="presentation"
            className="h-full w-full object-cover scale-105 transition-transform duration-[2000ms]"
            width="750"
            height="1000"
            fetchpriority="high"
            style={{ transform: isVisible ? 'scale(100%)' : 'scale(105%)' }}
          />
        </div>
        {/* Desktop image */}
        <img 
          src={`${image}?w=1920&h=1080&fit=crop&crop=faces,center&auto=format&q=80`}
          alt="" 
          role="presentation"
          className="hidden md:block w-full h-full object-cover object-center scale-105 transition-transform duration-[2000ms]"
          width="1920"
          height="1080"
          fetchpriority="high"
          style={{ transform: isVisible ? 'scale(100%)' : 'scale(105%)' }}
        />
        
        {/* Enhanced gradient overlay with multiple layers */}
        <div className="absolute inset-0">
          {/* Base gradient */}
          <div className="absolute inset-0 bg-gradient-to-r from-black/95 via-black/90 to-transparent" />
          
          {/* Subtle texture overlay */}
          <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDQwIDQwIj48cGF0aCBkPSJNMjAgMjBtLTIwIDBhMjAgMjAgMCAxIDAgNDAgMCAyMCAyMCAwIDEgMC00MCAwIiBmaWxsPSIjZmZmIiBmaWxsLW9wYWNpdHk9IjAuMDUiLz48L3N2Zz4=')] opacity-30" />
          
          {/* Accent color highlights */}
          <div className="absolute inset-0 bg-gradient-to-t from-[#003A30]/10 via-transparent to-transparent" />
          <div className="absolute inset-0 bg-gradient-to-b from-[#CBA331]/5 via-transparent to-transparent" />
        </div>
      </div>

      <div className="relative container mx-auto px-4 h-full">
        <div className="flex flex-col justify-center h-full pt-12 sm:pt-16 lg:pt-20 pb-24">
          {/* Zmiana szerokości kontenera na różnych rozmiarach ekranu */}
          <div className="w-full md:max-w-2xl lg:max-w-3xl xl:max-w-4xl 2xl:max-w-5xl">
            {/* Enhanced Badge */}
            <div 
              className={`
                inline-flex items-center px-3 py-1.5 sm:px-4 sm:py-2 md:px-5 md:py-2.5 rounded-full
                bg-gradient-to-r from-white/10 via-white/20 to-white/10
                backdrop-blur-sm border border-white/10
                transform transition-all duration-1000 ease-out
                ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
              `}
            >
              <Star className="w-4 h-4 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-2 text-[#CBA331] animate-pulse" />
              <span className="text-xs sm:text-sm md:text-base lg:text-lg text-white/90">{badgeText}</span>
            </div>

            {/* Enhanced Title */}
            <h1 
              className={`
                text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl 
                font-bold text-white mt-4 sm:mt-6 md:mt-8 mb-3 sm:mb-4 md:mb-6
                leading-tight tracking-tight
                transform transition-all duration-1000 delay-300
                ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
              `}
            >
              {highlightedWord ? (
                <>
                  {title.split(highlightedWord)[0]}
                  <span className="relative inline-block">
                    <span className="relative text-[#CBA331]">{highlightedWord}</span>
                    <span className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-[#CBA331]/0 via-[#CBA331] to-[#CBA331]/0 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500" />
                  </span>
                  {title.split(highlightedWord)[1]}
                </>
              ) : (
                title
              )}
            </h1>

            {/* Enhanced Description */}
            <p 
              className={`
                text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-white/90 
                mb-6 sm:mb-8 md:mb-10 lg:mb-12
                max-w-xl sm:max-w-2xl md:max-w-3xl lg:max-w-4xl
                transform transition-all duration-1000 delay-500
                ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
              `}
            >
              {description}
            </p>

            {/* Przyciski CTA - zmodyfikowane dla pełnej szerokości na telefonach */}
            <div 
              className={`
                flex flex-col w-full sm:flex-row gap-3 sm:gap-4 md:gap-5 lg:gap-6 
                mb-8 sm:mb-10 md:mb-12 lg:mb-16
                transform transition-all duration-1000 delay-700
                ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
              `}
            >
              {/* Primary CTA z pełną szerokością na telefonach */}
              <button
                onClick={primaryCta.action}
                className="group w-full sm:w-auto relative inline-flex items-center justify-center 
                  px-6 sm:px-7 md:px-8 lg:px-10 
                  py-3 sm:py-3.5 md:py-4 lg:py-5
                  text-sm sm:text-base md:text-lg lg:text-xl
                  bg-[#CBA331] text-white rounded-lg overflow-hidden 
                  transition-all duration-300 hover:shadow-lg hover:shadow-[#CBA331]/20"
              >
                {/* Animated background gradient */}
                <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-transparent via-white/10 to-transparent -translate-x-full group-hover:translate-x-full transition-transform duration-1000 ease-out" />
                
                {/* Text with hover effect */}
                <span className="relative flex items-center justify-center w-full gap-2">
                  <span className="relative">
                    {primaryCta.label}
                    <span className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-white to-transparent transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500" />
                  </span>
                  <ArrowRight className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 group-hover:translate-x-1 transition-transform duration-300" />
                </span>
              </button>
              
              {/* Secondary CTA z pełną szerokością na telefonach */}
              <button
                onClick={secondaryCta.action}
                className="group w-full sm:w-auto relative inline-flex items-center justify-center 
                  px-6 sm:px-7 md:px-8 lg:px-10 
                  py-3 sm:py-3.5 md:py-4 lg:py-5
                  text-sm sm:text-base md:text-lg lg:text-xl
                  rounded-lg overflow-hidden transition-all duration-300"
              >
                {/* Glass background */}
                <span className="absolute inset-0 bg-white/10 backdrop-blur-sm border border-white/10 rounded-lg" />
                
                {/* Hover gradient */}
                <span className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                
                {/* Text */}
                <span className="relative text-white">
                  {secondaryCta.label}
                </span>
              </button>
            </div>

            {/* Statystyki - zmodyfikowane do pełnej szerokości na telefonach */}
            <div 
              className={`
                grid grid-cols-1 md:grid-cols-3 gap-3 sm:gap-4 md:gap-5 lg:gap-6
                transform transition-all duration-1000 delay-1000
                ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
              `}
            >
              {stats.map((stat, index) => (
                <div 
                  key={index}
                  className="group relative overflow-hidden rounded-lg transition-all duration-300 w-full"
                >
                  {/* Glass background */}
                  <div className="absolute inset-0 bg-white/10 backdrop-blur-sm border border-white/10 rounded-lg" />
                  
                  {/* Hover gradient */}
                  <div className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/5 to-white/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  
                  {/* Content - rozszerzone dla lepszego wyglądu na telefonach */}
                  <div className="relative flex items-center justify-between gap-2 sm:gap-3 p-4 sm:p-4 md:p-5">
                    <div className="flex items-center gap-2">
                      <stat.icon className="w-5 h-5 sm:w-5 sm:h-5 md:w-6 md:h-6 lg:w-7 lg:h-7 text-[#CBA331] transition-transform duration-300 group-hover:scale-110" />
                      <div>
                        <div className="text-sm sm:text-base md:text-lg font-semibold text-white group-hover:text-[#CBA331] transition-colors duration-300">
                          {stat.value}
                        </div>
                        <div className="text-xs sm:text-sm md:text-base text-white/80">
                          {stat.label}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}