import React, { useState, useEffect, useRef } from 'react';
import type { SocialProofStats } from '@/data/stats/types';

interface SocialProofProps {
  stats: SocialProofStats;
  className?: string;
}

export default function SocialProof({ stats, className = '' }: SocialProofProps) {
  const [hasAnimated, setHasAnimated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !hasAnimated) {
          setIsVisible(true);
          setHasAnimated(true);
        }
      },
      { threshold: 0.3 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, [hasAnimated]);

  const Counter = ({ end, suffix = '', duration = 2000, decimals = 0, showPlus = false }) => {
    const [count, setCount] = useState(0);
    
    useEffect(() => {
      if (!isVisible) return;

      let startTime: number;
      const startValue = 0;
      
      const animate = (currentTime: number) => {
        if (!startTime) startTime = currentTime;
        const progress = (currentTime - startTime) / duration;

        if (progress < 1) {
          const currentCount = startValue + (end - startValue) * progress;
          setCount(currentCount);
          requestAnimationFrame(animate);
        } else {
          setCount(end);
        }
      };

      requestAnimationFrame(animate);
    }, [isVisible, end, duration]);

    return (
      <div className="relative">
        <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-[#003A30] mb-2 flex items-baseline justify-center" aria-live="polite">
          {decimals > 0 ? count.toFixed(decimals) : Math.floor(count)}
          {showPlus && <span className="text-[#CBA331] text-xl sm:text-2xl md:text-3xl ml-1 font-medium">+</span>}
          {suffix && <span className="text-[#CBA331] text-xl sm:text-2xl md:text-3xl ml-1 font-medium">{suffix}</span>}
        </div>
      </div>
    );
  };

  return (
    <section 
      ref={sectionRef} 
      className={`relative py-8 sm:py-10 md:py-12 lg:py-16 bg-gradient-to-b from-white to-gray-50 ${className}`}
      aria-labelledby="social-proof-heading"
    >
      <h2 id="social-proof-heading" className="sr-only">Nasze osiągnięcia</h2>

      {/* Decorative Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none" aria-hidden="true">
        <div className="absolute -top-10 -left-10 w-40 h-40 bg-[#003A30]/5 rounded-full blur-3xl transform-gpu" />
        <div className="absolute -bottom-10 -right-10 w-40 h-40 bg-[#CBA331]/5 rounded-full blur-3xl transform-gpu" />
      </div>

      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 sm:gap-6 md:gap-8 lg:gap-10">
          {Object.entries(stats).map(([key, stat], index) => (
            <div 
              key={key}
              className={`
                group relative bg-white rounded-2xl p-4 sm:p-6 md:p-8 shadow-[0_4px_20px_rgba(0,0,0,0.08)] 
                hover:shadow-[0_8px_30px_rgba(0,0,0,0.12)] transition-all duration-300 hover:-translate-y-1
                transform ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
                transition-all duration-700
              `}
              style={{ transitionDelay: `${index * 100}ms` }}
              role="region"
              aria-label={stat.label}
            >
              <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-[#003A30] to-[#CBA331] transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100 rounded-t-2xl" />
              <div className="flex items-center justify-center w-10 h-10 sm:w-12 sm:h-12 md:w-14 md:h-14 bg-[#003A30]/5 rounded-2xl mb-4 group-hover:scale-110 transition-transform duration-300">
                <stat.icon className="w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 text-[#003A30] group-hover:text-[#CBA331] transition-colors duration-300" aria-hidden="true" />
              </div>
              <Counter 
                end={stat.value} 
                suffix={stat.suffix} 
                decimals={stat.decimals} 
                showPlus={stat.showPlus} 
              />
              <p className="text-gray-600 text-sm sm:text-base md:text-lg text-center">
                {stat.label}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}