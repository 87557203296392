import { Search, ListChecks, FileText, Building2, CheckCircle2, Clock, BadgePercent, Percent } from 'lucide-react';
import type { ProcessStepsData } from './types';

export const refinancingStepsData: ProcessStepsData = {
  title: "Prosty i przejrzysty proces",
  subtitle: "Przeprowadzimy Cię przez cały proces kredytowy, zapewniając profesjonalne wsparcie na każdym etapie",
  steps: [
    {
      number: 1,
      icon: Search,
      title: "Analiza oszczędności",
      description: "Sprawdzamy potencjał redukcji kosztów",
      features: [
        "Analiza obecnych rat",
        "Kalkulacja oszczędności",
        "Ocena możliwości"
      ],
      stats: {
        icon: BadgePercent,
        value: "-35%",
        label: "Średnia oszczędność"
      }
    },
    {
      number: 2,
      icon: ListChecks,
      title: "Plan optymalizacji",
      description: "Projektujemy strategię maksymalnych korzyści",
      features: [
        "Porównanie ofert",
        "Kalkulacja zysków",
        "Wybór najlepszej opcji"
      ],
      stats: {
        icon: Percent,
        value: "3-4",
        label: "Warianty oszczędności"
      }
    },
    {
      number: 3,
      icon: FileText,
      title: "Prosta dokumentacja",
      description: "Minimum formalności, maksimum korzyści",
      features: [
        "Uproszczona procedura",
        "Wsparcie w przygotowaniu",
        "Szybka weryfikacja"
      ],
      stats: {
        icon: Clock,
        value: "2-3 dni",
        label: "Oszczędność czasu"
      }
    },
    {
      number: 4,
      icon: Building2,
      title: "Korzystna zamiana",
      description: "Negocjujemy najlepsze warunki",
      features: [
        "Niższe oprocentowanie",
        "Mniejsze raty",
        "Lepsze warunki"
      ],
      stats: {
        icon: BadgePercent,
        value: "850 zł",
        label: "Średnia ulga miesięcznie"
      }
    },
    {
      number: 5,
      icon: CheckCircle2,
      title: "Realne oszczędności",
      description: "Finalizacja procesu i start oszczędzania",
      features: [
        "Bezpieczna zmiana",
        "Natychmiastowe korzyści",
        "Mniejsze obciążenie"
      ],
      stats: {
        icon: Clock,
        value: "21 dni",
        label: "Do pierwszej oszczędności"
      }
    }
  ]
};