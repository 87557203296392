import type { BlogCategory } from './types';

export const BLOG_CATEGORIES: Record<BlogCategory, {
  id: BlogCategory;
  name: string;
  description: string;
  slug: string;
}> = {
  'kredyty-hipoteczne': {
    id: 'kredyty-hipoteczne',
    name: 'Kredyty hipoteczne',
    description: 'Wszystko o kredytach hipotecznych - warunki, wymagania, proces i porady',
    slug: 'kredyty-hipoteczne'
  },
  'kredyty-firmowe': {
    id: 'kredyty-firmowe', 
    name: 'Kredyty firmowe',
    description: 'Finansowanie dla firm - rodzaje kredytów, warunki i możliwości',
    slug: 'kredyty-firmowe'
  },
  'refinansowanie': {
    id: 'refinansowanie',
    name: 'Refinansowanie',
    description: 'Jak zamienić obecny kredyt na lepszy - poradniki i analizy',
    slug: 'refinansowanie'
  },
  'porady-finansowe': {
    id: 'porady-finansowe',
    name: 'Porady finansowe',
    description: 'Praktyczne porady dotyczące finansów osobistych i firmowych',
    slug: 'porady-finansowe'
  },
  'aktualnosci': {
    id: 'aktualnosci',
    name: 'Aktualności',
    description: 'Najnowsze informacje ze świata finansów i kredytów',
    slug: 'aktualnosci'
  }
};