import type { ConcernsData } from './types';

export const optimizationConcernsData: ConcernsData = {
  title: "Rozwiewamy wątpliwości",
  subtitle: "Poznaj rozwiązania typowych obaw związanych z optymalizacją",
  concerns: [
    {
      concern: "Proces jest zbyt skomplikowany",
      solution: "Zajmujemy się wszystkimi formalnościami i prowadzimy Cię przez cały proces"
    },
    {
      concern: "Obawa przed zmianą banków",
      solution: "Gwarantujemy bezpieczne przeprowadzenie procesu i zachowanie ciągłości finansowania"
    },
    {
      concern: "Koszty optymalizacji",
      solution: "Oszczędności znacznie przewyższają koszty już w pierwszych miesiącach"
    },
    {
      concern: "Długi czas wdrożenia",
      solution: "Większość procesów optymalizacyjnych kończymy w ciągu 30 dni"
    }
  ]
};