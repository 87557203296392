import { FileCheck, Clock, Calculator, Coins } from 'lucide-react';
import type { ProblemsSolutionsData } from './types';

export const homeProblemsData: ProblemsSolutionsData = {
  title: "Wyzwania i rozwiązania",
  subtitle: "Pokonujemy przeszkody na drodze do Twojego kredytu",
  problems: [
    {
      icon: FileCheck,
      problem: "Przytłaczająca biurokracja bankowa",
      solution: "Kompleksowa obsługa dokumentacji",
      details: [
        "Przygotowanie wszystkich niezbędnych dokumentów",
        "Koordynacja procesu między instytucjami",
        "Eliminacja zbędnych formalności bankowych"
      ]
    },
    {
      icon: Clock,
      problem: "Brak czasu na formalności kredytowe",
      solution: "Oszczędność Twojego cennego czasu",
      details: [
        "Pełna obsługa zdalna bez wizyt w banku",
        "Szybka weryfikacja dokumentacji kredytowej",
        "Elastyczne terminy konsultacji doradczych"
      ]
    },
    {
      icon: Calculator,
      problem: "Niezadowalająca zdolność kredytowa",
      solution: "Strategiczne zwiększenie zdolności",
      details: [
        "Analiza i poprawa Twojego profilu kredytowego",
        "Dobór banku z korzystnymi kryteriami oceny",
        "Optymalizacja wniosku pod kątem akceptacji"
      ]
    },
    {
      icon: Coins,
      problem: "Wysokie koszty kredytu i ukryte opłaty",
      solution: "Realna redukcja kosztów finansowania",
      details: [
        "Negocjacje niższych marż i oprocentowania",
        "Eliminacja lub redukcja prowizji bankowych",
        "Identyfikacja i usunięcie ukrytych opłat"
      ]
    }
  ]
};