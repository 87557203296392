import React, { useState, useEffect } from 'react';
import Hero from '@/components/shared/Hero';
import { Calculator, BadgePercent, Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import InputPanel from './components/InputPanel';
import ResultsPanel from './components/ResultsPanel';
import AnalysisChart from './components/AnalysisChart';
import InstructionPanel from './components/InstructionPanel';
import IncreaseCapacitySection from './components/IncreaseCapacitySection';
import Contact from '@/components/shared/Contact';
import FloatingHelpButton from '@/components/shared/FloatingHelpButton';
import { calculateCreditworthiness } from './utils/calculations';
import type { FormData, CalculationResults } from './types';

export default function CreditworthinessCalculator() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    income: 12000,
    householdMembers: 1,
    existingLoans: 0,
    creditLimits: 0
  });
  const [results, setResults] = useState<CalculationResults | null>(null);

  // Calculate results whenever form data changes
  useEffect(() => {
    const results = calculateCreditworthiness(formData);
    setResults(results);
  }, [formData]);

  const handleFormDataChange = (newData: Partial<FormData>) => {
    setFormData(prev => ({
      ...prev,
      ...newData
    }));
  };

  return (
    <div className="pt-20 bg-gray-50">
      <FloatingHelpButton />
      
      <Hero 
        title="Kalkulator zdolności kredytowej"
        highlightedWord="zdolności"
        description="Sprawdź swoją zdolność kredytową i poznaj dostępne możliwości. Profesjonalna analiza wszystkich źródeł dochodu."
        image="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?auto=format&fit=crop&q=80"
        stats={[
          {
            icon: Calculator,
            value: "99%",
            label: "Dokładność wyliczeń"
          },
          {
            icon: BadgePercent,
            value: "100k+",
            label: "Analiz zdolności"
          },
          {
            icon: Clock,
            value: "5 min",
            label: "Szybka weryfikacja"
          }
        ]}
        primaryCta={{
          label: "Sprawdź zdolność",
          action: () => navigate('#calculator')
        }}
        secondaryCta={{
          label: "Skonsultuj się z ekspertem",
          action: () => navigate('#contact')
        }}
      />

      {/* 1. Input and Results */}
      <div id="calculator" className="py-8 sm:py-12 md:py-16 lg:py-20">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-8">
              <div className="lg:sticky lg:top-24 lg:self-start">
                <InputPanel 
                  formData={formData}
                  onFormDataChange={handleFormDataChange}
                />
              </div>
              <div>
                <ResultsPanel 
                  results={results}
                  isCalculated={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 2. Data Visualization */}
      <div className="py-8 sm:py-12 md:py-16 lg:py-20 bg-gray-50">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="max-w-7xl mx-auto">
            <AnalysisChart formData={formData} />
          </div>
        </div>
      </div>

      {/* 3. How to use calculator */}
      <div id="instructions" className="py-8 sm:py-12 md:py-16 lg:py-20 bg-white">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="max-w-7xl mx-auto">
            <InstructionPanel />
          </div>
        </div>
      </div>

      {/* 4. Increase Capacity Section */}
      <IncreaseCapacitySection />

      {/* 5 {/* 5. Contact Form */}
      <Contact />
    </div>
  );
}