import type { CaseStudy } from '../types';

export const HOME_CHANGE_CASE: CaseStudy = {
  id: "home-upgrade",
  client: "Agnieszka i Paweł",
  image: "https://plus.unsplash.com/premium_photo-1676667573156-7d14e8b79ad3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8Y291cGxlfGVufDB8fDB8fHww",
  title: "Zamiana mieszkania na większe dla rodziny 2+1",
  description: "Pomogliśmy rodzinie z małym dzieckiem sprzedać mieszkanie 2-pokojowe i kupić przestronne 4-pokojowe w zielonej okolicy.",
  result: "Bezproblemowa zamiana mieszkania z decyzją warunkową",
  service: "mortgage",
  challenges: [
    "Konieczność sprzedaży obecnego mieszkania przed zakupem nowego",
    "Spłata istniejącego kredytu hipotecznego",
    "Synchronizacja terminów: sprzedaż, zakup, przeprowadzka",
    "Uzyskanie decyzji warunkowej na finansowanie nowego mieszkania",
    "Wybór nieruchomości dostosowanej do potrzeb rodziny z dzieckiem"
  ],
  solutions: [
    "Strategia przygotowania i szybkiej sprzedaży dotychczasowego mieszkania",
    "Uzyskanie decyzji warunkowej z banku na finansowanie nowego mieszkania",
    "Negocjacja z deweloperem elastycznych warunków rezerwacji i płatności",
    "Koordynacja terminów z notariuszem i stronami obu transakcji",
    "Wsparcie w procesie wyboru nowego mieszkania z uwzględnieniem przyszłych potrzeb"
  ],
  directQuote: "Dzięki profesjonalnemu wsparciu udało nam się przeprowadzić całość operacji bez stresu. Decyzja warunkowa banku dała nam pewność przy rezerwacji nowego mieszkania, a przeniesienie się odbyło się płynnie i bez przykrych niespodzianek.",
  financialBenefit: "Oszczędność 32 000 zł w porównaniu do standardowej oferty",
  savingsPercentage: 8,
  savingsAmount: "420 zł miesięcznie mniej niż w pierwotnej ofercie banku",
  whatIfScenario: "Bez profesjonalnego wsparcia Agnieszka i Paweł musieliby samodzielnie znaleźć kupca na swoje mieszkanie, co mogłoby wydłużyć proces o kilka miesięcy. Istniało również ryzyko utraty wymarzonej nieruchomości z powodu braku decyzji warunkowej banku na czas.",
  clientTypeTag: "Zamiana mieszkania",
  ctaText: "Zamień mieszkanie na większe",
  ctaLink: "/zamiana-mieszkania",
  difficultyLevel: 4,
  timelineInfo: "Przeprowadzka w 75 dni",
  rating: 5,
  date: "Styczeń 2025",
  creditType: "Kredyt hipoteczny z decyzją warunkową",
  clientOccupation: "Nauczycielka i inżynier budownictwa",
  clientLocation: "Kraków, z Dębnik do Zwierzyńca",
  beforeAfter: {
    before: "Mieszkanie 52m² w Dębnikach, 2 pokoje, mały balkon, głośna okolica, rata kredytu 2100 zł, trudności z miejscem dla rodziny z 3-letnim dzieckiem.",
    after: "Mieszkanie 85m² w Zwierzyńcu, 4 pokoje, duży taras, blisko parku, rata kredytu 2900 zł (wzrost o 800 zł), pełen komfort dla rozwijającej się rodziny."
  },
  reasoning: "Wybraliśmy tę firmę po rekomendacji znajomych, którzy przeszli podobny proces. Zależało nam na kompleksowej obsłudze, która odciąży nas z formalności w czasie, gdy oboje pracujemy i zajmujemy się małym dzieckiem. Cenimy sobie szczególnie transparentność procesu i jasne wyjaśnianie każdego kroku.",
  fullStory: {
    situation: "Para z 3-letnim dzieckiem, mieszkająca od 6 lat w 2-pokojowym mieszkaniu w dzielnicy Dębniki, spłacająca kredyt hipoteczny. Z uwagi na potrzeby rozwojowe rodziny i plany posiadania drugiego dziecka, postanowili przenieść się do większego mieszkania w spokojniejszej, zielonej okolicy.",
    challenge: "Głównym wyzwaniem była konieczność sprzedaży obecnego mieszkania i spłaty kredytu jako warunku uzyskania finansowania na nowy lokal. Para musiała również znaleźć odpowiednie nowe mieszkanie, które spełniałoby ich oczekiwania dotyczące lokalizacji, metrażu i ceny.",
    solution: "Kompleksowa obsługa transakcji sprzedaży obecnego mieszkania wraz z uzyskaniem decyzji warunkowej banku na finansowanie nowego lokalu. Dzięki temu para mogła bezpiecznie zarezerwować wymarzone mieszkanie jeszcze przed finalizacją sprzedaży starego.",
    details: {
      property: {
        oldProperty: "Mieszkanie 52m², wartość 550 000 zł, pozostały kredyt 250 000 zł",
        newProperty: "Mieszkanie 85m², cena 890 000 zł, nowe budownictwo z 2022 roku",
        location: "Kraków, z Dębnik do Zwierzyńca (blisko Lasu Wolskiego)"
      },
      loan: {
        amount: "590 000 zł",
        equity: "300 000 zł (ze sprzedaży poprzedniego)",
        period: "25 lat",
        rate: "6.5%"
      },
      timeline: {
        sale: "45 dni od wystawienia do aktu notarialnego",
        purchase: "30 dni od decyzji warunkowej do zakupu",
        coordination: "Tymczasowy wynajem na 1 miesiąc podczas wykończenia nowego mieszkania"
      }
    }
  }
};