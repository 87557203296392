import { Users, Building2, Clock, Star } from 'lucide-react';
import type { SocialProofStats } from './types';

export const homeStats: SocialProofStats = {
  clients: {
    icon: Users,
    value: 1547,
    showPlus: true,
    label: "Zadowolonych klientów"
  },
  financing: {
    icon: Building2,
    value: 798,
    suffix: " mln",
    label: "Wartość finansowania"
  },
  experience: {
    icon: Clock,
    value: 15,
    suffix: "+",
    label: "Lat doświadczenia"
  },
  satisfaction: {
    icon: Star,
    value: 99,
    suffix: "%",
    decimals: 0,
    label: "Skuteczności"
  }
};