import React from 'react';
import { BarChart, BadgePercent, Clock, Sparkles, ArrowRight, Zap } from 'lucide-react';
import { CalculationResults, LoanData } from '../MortgageCalculator';

interface ResultsPanelProps {
  results: CalculationResults | null;
  loanData: LoanData;
  onLoanDataChange: (newData: Partial<LoanData>) => void;
}

export default function ResultsPanel({ results, loanData, onLoanDataChange }: ResultsPanelProps) {
  if (!results) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-8 h-full flex items-center justify-center">
        <div className="flex items-center">
          <p className="text-gray-500 mr-2">Obliczanie wyników</p>
          <div className="w-2 h-2 bg-blue-500 rounded-full animate-pulse"></div>
        </div>
      </div>
    );
  }

  // Format currency
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  // Format percentage
  const formatPercentage = (value: number) => {
    return new Intl.NumberFormat('pl-PL', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value / 100);
  };

  // Format period reduction
  const formatPeriodReduction = (years: number) => {
    const wholeYears = Math.floor(years);
    const months = Math.round((years - wholeYears) * 12);
    
    if (wholeYears === 0) {
      return `${months} mies.`;
    } else if (months === 0) {
      return `${wholeYears} ${wholeYears === 1 ? 'rok' : wholeYears < 5 ? 'lata' : 'lat'}`;
    } else {
      return `${wholeYears} ${wholeYears === 1 ? 'rok' : wholeYears < 5 ? 'lata' : 'lat'} i ${months} mies.`;
    }
  };

  // Check if there are any overpayments
  const hasOverpayments = loanData.monthlyOverpayment > 0 || loanData.oneTimePayments.length > 0;

  // Calculate interest percentage of total payment
  const totalPayment = loanData.amount + results.totalInterest;
  const interestPercentage = (results.totalInterest / totalPayment) * 100;

  // Scroll to contact section
  const scrollToContact = () => {
    document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden h-full relative">
      {/* Decorative background elements */}
      <div className="absolute -top-24 -right-24 w-48 h-48 bg-[#003A30]/5 rounded-full blur-3xl opacity-20 pointer-events-none" aria-hidden="true" />
      <div className="absolute -bottom-24 -left-24 w-48 h-48 bg-[#CBA331]/5 rounded-full blur-3xl opacity-20 pointer-events-none" aria-hidden="true" />
      
      <div className="p-6 md:p-8 relative">
        <div className="flex items-center justify-center gap-3 mb-8">
          <div className="relative">
            <BarChart className="w-8 h-8 text-[#CBA331]" />
            <div className="absolute -top-1 -right-1 w-3 h-3 bg-[#003A30] rounded-full animate-pulse"></div>
          </div>
          <h2 className="text-2xl font-bold text-[#003A30] relative">
            Wyniki kalkulacji
            <div className="absolute -bottom-1 left-0 w-full h-0.5 bg-gradient-to-r from-[#CBA331]/0 via-[#CBA331] to-[#CBA331]/0"></div>
          </h2>
        </div>

        {/* Payment Type Selection - Updated Design with full width */}
        <div className="mb-8">
          <h3 className="text-sm font-semibold text-[#003A30] mb-3">Typ rat</h3>
          <div className="flex w-full p-1 bg-gray-100 rounded-lg shadow-inner">
            <button
              onClick={() => onLoanDataChange({ paymentType: 'equal' })}
              className={`flex-1 px-4 py-3 text-sm font-medium rounded-md transition-all duration-300 ${
                loanData.paymentType === 'equal'
                  ? 'bg-white text-[#003A30] shadow-md transform scale-105'
                  : 'text-gray-600 hover:text-[#003A30] hover:bg-white/50'
              }`}
            >
              Raty równe
            </button>
            <button
              onClick={() => onLoanDataChange({ paymentType: 'decreasing' })}
              className={`flex-1 px-4 py-3 text-sm font-medium rounded-md transition-all duration-300 ${
                loanData.paymentType === 'decreasing'
                  ? 'bg-white text-[#003A30] shadow-md transform scale-105'
                  : 'text-gray-600 hover:text-[#003A30] hover:bg-white/50'
              }`}
            >
              Raty malejące
            </button>
          </div>
          <p className="mt-2 text-xs text-gray-500">
            {loanData.paymentType === 'equal' 
              ? 'Stała rata przez cały okres kredytowania'
              : 'Wyższe raty na początku, niższe na końcu okresu'}
          </p>
        </div>

        {/* Main Results Grid */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          {/* Monthly Payment */}
          <div className="group bg-gradient-to-br from-[#003A30]/5 to-transparent rounded-xl p-4 transition-all duration-300 hover:shadow-lg hover:from-[#003A30]/10 hover:transform hover:scale-105 border border-transparent hover:border-[#003A30]/10">
            <div className="flex items-center gap-2 mb-2">
              <BadgePercent className="w-5 h-5 text-[#CBA331] transition-transform duration-300 group-hover:scale-110 group-hover:rotate-12" />
              <h3 className="text-sm font-semibold text-[#003A30]">Miesięczna rata</h3>
            </div>
            <p className="text-2xl font-bold text-[#003A30] mb-1 transition-colors duration-300 group-hover:text-[#CBA331]">
              {formatCurrency(results.monthlyPayment)}
            </p>
            <p className="text-xs text-gray-500">
              Rata kredytu miesięcznie
            </p>
          </div>

          {/* Rate after WIBOR change */}
          <div className="group bg-gradient-to-br from-[#CBA331]/5 to-transparent rounded-xl p-4 transition-all duration-300 hover:shadow-lg hover:from-[#CBA331]/10 hover:transform hover:scale-105 border border-transparent hover:border-[#CBA331]/10">
            <div className="flex items-center gap-2 mb-2">
              <Sparkles className="w-5 h-5 text-[#CBA331] transition-transform duration-300 group-hover:scale-110 group-hover:rotate-12" />
              <h3 className="text-sm font-semibold text-[#003A30]">
                {loanData.wiborChangeYear 
                  ? `Rata od ${loanData.wiborChangeYear}. roku` 
                  : "Rata po zmianie WIBOR"}
              </h3>
            </div>
            <p className="text-2xl font-bold text-[#003A30] mb-1 transition-colors duration-300 group-hover:text-[#CBA331]">
              {loanData.wiborChangeYear 
                ? formatCurrency(results.monthlyPaymentAfterWiborChange)
                : "---"}
            </p>
            <p className="text-xs text-gray-500">
              {loanData.wiborChangeYear 
                ? `Przy oprocentowaniu ${formatPercentage(loanData.wiborChangeRate || 0)}`
                : "Brak zmiany WIBOR"}
            </p>
          </div>

          {/* Total Interest */}
          <div className="group bg-gradient-to-br from-[#003A30]/5 to-transparent rounded-xl p-4 transition-all duration-300 hover:shadow-lg hover:from-[#003A30]/10 hover:transform hover:scale-105 border border-transparent hover:border-[#003A30]/10">
            <div className="flex items-center gap-2 mb-2">
              <BadgePercent className="w-5 h-5 text-[#CBA331] transition-transform duration-300 group-hover:scale-110 group-hover:rotate-12" />
              <h3 className="text-sm font-semibold text-[#003A30]">Suma odsetek</h3>
            </div>
            <p className="text-2xl font-bold text-[#003A30] mb-1 transition-colors duration-300 group-hover:text-[#CBA331]">
              {formatCurrency(results.totalInterest)}
            </p>
            <p className="text-xs text-gray-500">
              {formatPercentage(interestPercentage)} całkowitej kwoty
            </p>
          </div>

          {/* Real Interest */}
          <div className="group bg-gradient-to-br from-[#CBA331]/5 to-transparent rounded-xl p-4 transition-all duration-300 hover:shadow-lg hover:from-[#CBA331]/10 hover:transform hover:scale-105 border border-transparent hover:border-[#CBA331]/10">
            <div className="flex items-center gap-2 mb-2">
              <BadgePercent className="w-5 h-5 text-[#CBA331] transition-transform duration-300 group-hover:scale-110 group-hover:rotate-12" />
              <h3 className="text-sm font-semibold text-[#003A30]">Realne odsetki</h3>
            </div>
            <p className="text-2xl font-bold text-[#003A30] mb-1 transition-colors duration-300 group-hover:text-[#CBA331]">
              {formatCurrency(results.realTotalInterest)}
            </p>
            <p className="text-xs text-gray-500">
              Z uwzględnieniem 3% inflacji
            </p>
          </div>
        </div>

        {/* Overpayment Savings (conditional) */}
        {hasOverpayments && (
          <div className="mt-6 mb-6">
            <h3 className="text-sm font-semibold text-[#003A30] mb-3 flex items-center gap-2">
              <Sparkles className="w-4 h-4 text-[#CBA331] animate-pulse" />
              Oszczędności z nadpłat
            </h3>
            
            <div className="grid grid-cols-2 gap-4">
              <div className="group bg-gradient-to-br from-emerald-50 to-emerald-50/50 rounded-xl p-4 transition-all duration-300 hover:shadow-lg hover:transform hover:scale-105 border border-transparent hover:border-emerald-200">
                <div className="flex items-center gap-2 mb-2">
                  <BadgePercent className="w-5 h-5 text-emerald-500 transition-transform duration-300 group-hover:scale-110 group-hover:rotate-12" />
                  <h4 className="text-xs font-semibold text-emerald-700">Oszczędność na odsetkach</h4>
                </div>
                <p className="text-xl font-bold text-emerald-700 transition-colors duration-300 group-hover:text-[#CBA331]">
                  {formatCurrency(results.interestSavings)}
                </p>
              </div>
              
              <div className="group bg-gradient-to-br from-emerald-50 to-emerald-50/50 rounded-xl p-4 transition-all duration-300 hover:shadow-lg hover:transform hover:scale-105 border border-transparent hover:border-emerald-200">
                <div className="flex items-center gap-2 mb-2">
                  <Clock className="w-5 h-5 text-emerald-500 transition-transform duration-300 group-hover:scale-110 group-hover:rotate-12" />
                  <h4 className="text-xs font-semibold text-emerald-700">Skrócenie okresu</h4>
                </div>
                <p className="text-xl font-bold text-emerald-700 transition-colors duration-300 group-hover:text-[#CBA331]">
                  {formatPeriodReduction(results.periodReduction)}
                </p>
              </div>
            </div>
          </div>
        )}

        {/* CTA Button - Enhanced with animation and effects */}
        <div className="mt-8 relative">
          {/* Pulsing background effect */}
          <div className="absolute inset-0 bg-gradient-to-r from-[#003A30] to-[#CBA331] rounded-lg opacity-70 blur-md animate-pulse" aria-hidden="true"></div>
          
          <button
            onClick={scrollToContact}
            className="relative w-full inline-flex items-center justify-center px-6 py-5 bg-gradient-to-r from-[#003A30] to-[#003A30]/90 text-white rounded-lg shadow-lg hover:shadow-xl hover:from-[#CBA331] hover:to-[#CBA331]/90 transition-all duration-500 group font-bold text-lg overflow-hidden z-10"
          >
            {/* Subtle background animation */}
            <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-transparent via-white/10 to-transparent -translate-x-full group-hover:translate-x-full transition-transform duration-1000 ease-in-out" aria-hidden="true"></span>
            
            <Zap className="w-6 h-6 mr-3 text-[#CBA331] group-hover:text-white transition-colors duration-300 animate-pulse" />
            ZAPLANUJ Z NAMI NAJNIŻSZE ODSETKI
            <ArrowRight className="w-5 h-5 ml-3 group-hover:translate-x-2 transition-transform duration-300" />
          </button>
          
          {/* Subtle shadow effect */}
          <div className="absolute -bottom-2 inset-x-4 h-2 bg-black/10 blur-md rounded-full" aria-hidden="true"></div>
        </div>
      </div>
    </div>
  );
}