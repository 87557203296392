import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Home, Building2, RefreshCw, PiggyBank, BadgePercent, ArrowRight, Info } from 'lucide-react';
import Modal from './Modal';
import ModalContent from './ModalContent';
import { Card, CardDetails } from '@/data/modal/types';

interface ComparisonCardsProps {
  cards: Card[];
  details: Record<string, CardDetails>;
  title: string;
  subtitle: string;
  cardsPerPage?: number;
}

export default function ComparisonCards({ 
  cards, 
  details, 
  title, 
  subtitle, 
  cardsPerPage = 6 
}: ComparisonCardsProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const [visibleCards, setVisibleCards] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const cardsRef = useRef<(HTMLDivElement | null)[]>([]);

  // Memoize the current cards to display based on pagination
  const displayedCards = useMemo(() => {
    const startIndex = (currentPage - 1) * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;
    return cards.slice(startIndex, endIndex);
  }, [cards, currentPage, cardsPerPage]);

  // Calculate total number of pages
  const totalPages = useMemo(() => Math.ceil(cards.length / cardsPerPage), [cards.length, cardsPerPage]);

  // Initialize the refs array
  useEffect(() => {
    cardsRef.current = cardsRef.current.slice(0, displayedCards.length);
    
    // Make sure we have the right number of refs
    while (cardsRef.current.length < displayedCards.length) {
      cardsRef.current.push(null);
    }
  }, [displayedCards]);

  // Reset visible cards when page changes
  useEffect(() => {
    setVisibleCards([]);
  }, [currentPage]);

  // Setup the intersection observer
  useEffect(() => {
    // Ensure we have refs before setting up observer
    if (cardsRef.current.length === 0) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const cardIndex = cardsRef.current.findIndex(ref => ref === entry.target);
            if (cardIndex !== -1 && !visibleCards.includes(cardIndex)) {
              setVisibleCards(prev => [...prev, cardIndex].sort((a, b) => a - b));
            }
          }
        });
      },
      {
        threshold: 0.2,
        rootMargin: '-10% 0px -10% 0px'
      }
    );

    cardsRef.current.forEach(card => {
      if (card) observer.observe(card);
    });

    return () => observer.disconnect();
  }, [visibleCards, cardsRef.current.length]);

  const handleOpenModal = (cardId: string) => {
    setSelectedCard(cardId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedCard(null);
  };

  const changePage = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      // Scroll to top of the section
      window.scrollTo({ top: document.getElementById('comparison-cards-section')?.offsetTop || 0, behavior: 'smooth' });
    }
  };

  // Fallback if no cards
  if (!cards || cards.length === 0) {
    return (
      <section id="comparison-cards-section" className="py-12 md:py-20 bg-gradient-to-b from-white to-gray-50">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-2xl md:text-4xl font-bold text-[#003A30] mb-4">{title}</h2>
          <p className="text-gray-600">Brak dostępnych kart do wyświetlenia.</p>
        </div>
      </section>
    );
  }

  return (
    <section id="comparison-cards-section" className="py-12 md:py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-12">
          <h2 className="text-2xl md:text-4xl font-bold text-[#003A30] mb-4">
            {title}
          </h2>
          <p className="text-gray-600 text-lg">
            {subtitle}
          </p>
        </div>

        {/* Cards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {displayedCards.map((card, index) => (
            <div 
              key={card.id}
              ref={el => cardsRef.current[index] = el}
              className={`
                group relative bg-white rounded-2xl shadow-lg overflow-hidden
                transform transition-all duration-700 ease-out
                ${visibleCards.includes(index) 
                  ? 'translate-y-0 scale-100 opacity-100' 
                  : 'translate-y-8 scale-95 opacity-0'
                }
                hover:shadow-xl hover:-translate-y-1
                flex flex-col h-full
              `}
              style={{
                transitionDelay: `${index * 100}ms`
              }}
            >
              {/* Top Gradient Bar */}
              <div 
                className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-[#003A30] to-[#CBA331] transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100" 
                aria-hidden="true"
              />
              
              <div className="p-6 flex flex-col flex-grow">
                {/* Content Area (takes available space) */}
                <div className="flex-grow">
                  {/* Icon and Title */}
                  <div className="text-center mb-6">
                    <div className={`
                      w-16 h-16 bg-[#003A30]/5 rounded-2xl flex items-center justify-center mx-auto mb-4
                      transform transition-all duration-500
                      ${visibleCards.includes(index) ? 'scale-100' : 'scale-90'}
                      group-hover:scale-110
                    `}>
                      <card.icon className="w-8 h-8 text-[#003A30] group-hover:text-[#CBA331] transition-colors duration-300" aria-hidden="true" />
                    </div>
                    <h3 className="text-xl font-bold text-[#003A30] flex items-center justify-center">
                      {card.title}
                    </h3>
                  </div>

                  {/* Description */}
                  <p className={`
                    text-gray-600 text-center mb-6
                    transform transition-all duration-500 delay-100
                    ${visibleCards.includes(index) ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
                  `}>
                    {card.description}
                  </p>

                  {/* Benefits */}
                  <div className="mb-6">
                    <ul className="space-y-3" role="list" aria-label="Lista korzyści">
                      {card.benefits.map((benefit, idx) => (
                        <li 
                          key={idx} 
                          className={`
                            flex items-start gap-2
                            transform transition-all duration-500
                            ${visibleCards.includes(index) ? 'translate-x-0 opacity-100' : 'translate-x-4 opacity-0'}
                          `}
                          style={{
                            transitionDelay: `${200 + idx * 100}ms`
                          }}
                        >
                          <div className="w-1.5 h-1.5 rounded-full bg-[#CBA331] mt-2 flex-shrink-0" aria-hidden="true" />
                          <span className="text-gray-600">{benefit}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Stats */}
                  <div className={`
                    flex items-center justify-center gap-3 p-4 bg-emerald-50 rounded-lg mb-6
                    transform transition-all duration-500 delay-500
                    ${visibleCards.includes(index) ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
                  `}>
                    <BadgePercent className="w-5 h-5 text-[#CBA331]" aria-hidden="true" />
                    <div>
                      <span className="block text-lg font-bold text-[#003A30]">{card.stats.value}</span>
                      <span className="text-sm text-gray-600">{card.stats.label}</span>
                    </div>
                  </div>
                </div>

                {/* Actions - always at the bottom */}
                <div className={`
                  flex flex-col gap-3 mt-auto
                  transform transition-all duration-500 delay-600
                  ${visibleCards.includes(index) ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
                `}>
                  <button
                    onClick={card.cta.action}
                    className="inline-flex items-center justify-center px-6 py-3 bg-[#003A30] text-white rounded-lg hover:bg-[#CBA331] transition-all duration-300 group/btn"
                    aria-label={card.cta.label}
                  >
                    {card.cta.label}
                    <ArrowRight className="w-5 h-5 ml-2 group-hover/btn:translate-x-1 transition-transform duration-300" aria-hidden="true" />
                  </button>
                  <button
                    onClick={() => handleOpenModal(card.id)}
                    className="inline-flex items-center justify-center px-6 py-3 border-2 border-[#003A30] text-[#003A30] rounded-lg hover:bg-[#003A30] hover:text-white transition-all duration-300"
                    aria-label="Zobacz szczegóły"
                  >
                    <Info className="w-5 h-5 mr-2" aria-hidden="true" />
                    Szczegóły
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination (if we have more than one page) */}
        {totalPages > 1 && (
          <div className="flex justify-center items-center mt-12 space-x-2" role="navigation" aria-label="Nawigacja stron">
            <button
              onClick={() => changePage(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-4 py-2 border border-[#003A30] rounded-lg text-[#003A30] disabled:opacity-50 disabled:cursor-not-allowed hover:bg-[#003A30] hover:text-white transition-colors"
              aria-label="Poprzednia strona"
            >
              &#8592;
            </button>
            
            <div className="flex space-x-1">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                <button
                  key={page}
                  onClick={() => changePage(page)}
                  className={`
                    w-10 h-10 rounded-lg flex items-center justify-center transition-colors
                    ${currentPage === page 
                      ? 'bg-[#003A30] text-white' 
                      : 'border border-[#003A30] text-[#003A30] hover:bg-[#003A30] hover:text-white'}
                  `}
                  aria-label={`Strona ${page}`}
                  aria-current={currentPage === page ? 'page' : undefined}
                >
                  {page}
                </button>
              ))}
            </div>
            
            <button
              onClick={() => changePage(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-4 py-2 border border-[#003A30] rounded-lg text-[#003A30] disabled:opacity-50 disabled:cursor-not-allowed hover:bg-[#003A30] hover:text-white transition-colors"
              aria-label="Następna strona"
            >
              &#8594;
            </button>
          </div>
        )}
      </div>

      {/* Modal */}
      {modalOpen && selectedCard && (
        <Modal
          isOpen={modalOpen}
          onClose={handleCloseModal}
          title={cards.find(c => c.id === selectedCard)?.title || ''}
        >
          <ModalContent details={details[selectedCard]} />
        </Modal>
      )}
    </section>
  );
}