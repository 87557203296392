import React, { useState } from 'react';
import { Phone, Smartphone, Mail, Building2, Facebook, Youtube, Linkedin, Send, CheckCircle2, AlertCircle, Home, RefreshCw, Building, PiggyBank } from 'lucide-react';
import emailjs from '@emailjs/browser';

export default function Contact() {
  const [formStatus, setFormStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    from_phone: '',
    service: '',
    message: '',
    privacy_policy: false,
    newsletter: false
  });

  const services = [
    { value: 'mortgage', label: 'Kredyt hipoteczny', Icon: Home },
    { value: 'business', label: 'Kredyt firmowy', Icon: Building },
    { value: 'refinance', label: 'Refinansowanie', Icon: RefreshCw },
    { value: 'optimization', label: 'Optymalizacja', Icon: PiggyBank },
  ];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (formStatus === 'loading') return;

    try {
      setFormStatus('loading');

      // Log the data being sent for debugging
      console.log('Sending form data:', {
        from_name: formData.from_name,
        from_email: formData.from_email,
        from_phone: formData.from_phone,
        service: services.find(s => s.value === formData.service)?.label || formData.service,
        message: formData.message,
        privacy_policy: formData.privacy_policy ? 'Tak' : 'Nie',
        newsletter: formData.newsletter ? 'Tak' : 'Nie'
      });

      const response = await emailjs.send(
        'service_6xtedx8',  // Service ID
        'template_gj9fc0n', // Template ID
        {
          from_name: formData.from_name,
          from_email: formData.from_email,
          from_phone: formData.from_phone,
          service: services.find(s => s.value === formData.service)?.label || formData.service,
          message: formData.message,
          privacy_policy: formData.privacy_policy ? 'Tak' : 'Nie',
          newsletter: formData.newsletter ? 'Tak' : 'Nie'
        },
        'k2tuWEEyE_72qP5Oy' // Public Key
      );

      // Log the response for debugging
      console.log('EmailJS Response:', response);

      setFormStatus('success');
      setFormData({
        from_name: '',
        from_email: '',
        from_phone: '',
        service: '',
        message: '',
        privacy_policy: false,
        newsletter: false
      });
    } catch (error) {
      console.error('Error sending form:', error);
      setFormStatus('error');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const value = e.target.type === 'checkbox' ? (e.target as HTMLInputElement).checked : e.target.value;
    setFormData(prev => ({
      ...prev,
      [e.target.name]: value
    }));
  };

  return (
    <section 
      id="contact" 
      className="relative py-12 sm:py-16 md:py-20 lg:py-24 overflow-hidden bg-gradient-to-br from-[#003A30]/5 via-white to-[#CBA331]/5" 
      aria-labelledby="contact-heading"
    >
      {/* Decorative Elements */}
      <div className="absolute inset-0 pointer-events-none" aria-hidden="true">
        <div className="absolute top-0 left-1/4 w-64 h-64 bg-[#003A30]/10 rounded-full blur-3xl opacity-30 transform-gpu" />
        <div className="absolute bottom-0 right-1/4 w-64 h-64 bg-[#CBA331]/10 rounded-full blur-3xl opacity-30 transform-gpu" />
      </div>

      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header */}
        <div className="max-w-3xl mx-auto text-center mb-12">
          <span className="inline-block text-[#CBA331] font-semibold mb-2 text-sm sm:text-base">
            Skontaktuj się z nami
          </span>
          <h2 
            id="contact-heading" 
            className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#003A30] mb-3"
          >
            Rozpocznij współpracę już dziś
          </h2>
          <p className="text-gray-600 text-base sm:text-lg">
            Umów się na bezpłatną konsultację i poznaj możliwości finansowania
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {/* Contact Info Column */}
          <div className="lg:col-span-1">
            <div className="bg-white rounded-2xl shadow-[0_4px_20px_rgba(0,0,0,0.08)] h-full p-6 md:p-8">
              <h3 className="text-xl font-bold text-[#003A30] mb-6">Dane kontaktowe</h3>
              
              {/* Contact Details */}
              <div className="space-y-6">
                <a 
                  href="tel:+48539211881" 
                  className="flex items-center group hover:bg-[#003A30]/5 p-3 rounded-lg transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-[#003A30] focus:ring-offset-2"
                >
                  <div className="w-12 h-12 bg-[#003A30]/5 rounded-xl flex items-center justify-center group-hover:scale-110 transition-transform duration-300 mr-4">
                    <Phone className="w-6 h-6 text-[#CBA331]" />
                  </div>
                  <div>
                    <span className="block text-sm text-gray-500">Telefon komórkowy</span>
                    <span className="text-[#003A30] font-medium text-lg">+48 539 211 881</span>
                  </div>
                </a>

                <a 
                  href="tel:+48729116166" 
                  className="flex items-center group hover:bg-[#003A30]/5 p-3 rounded-lg transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-[#003A30] focus:ring-offset-2"
                >
                  <div className="w-12 h-12 bg-[#003A30]/5 rounded-xl flex items-center justify-center group-hover:scale-110 transition-transform duration-300 mr-4">
                    <Smartphone className="w-6 h-6 text-[#CBA331]" />
                  </div>
                  <div>
                    <span className="block text-sm text-gray-500">Biuro</span>
                    <span className="text-[#003A30] font-medium text-lg">+48 729 116 166</span>
                  </div>
                </a>

                <a 
                  href="mailto:artur@arturzych.pl" 
                  className="flex items-center group hover:bg-[#003A30]/5 p-3 rounded-lg transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-[#003A30] focus:ring-offset-2"
                >
                  <div className="w-12 h-12 bg-[#003A30]/5 rounded-xl flex items-center justify-center group-hover:scale-110 transition-transform duration-300 mr-4">
                    <Mail className="w-6 h-6 text-[#CBA331]" />
                  </div>
                  <div>
                    <span className="block text-sm text-gray-500">Email</span>
                    <span className="text-[#003A30] font-medium text-lg">artur@arturzych.pl</span>
                  </div>
                </a>

                <div className="flex items-center group hover:bg-[#003A30]/5 p-3 rounded-lg transition-colors duration-300">
                  <div className="w-12 h-12 bg-[#003A30]/5 rounded-xl flex items-center justify-center group-hover:scale-110 transition-transform duration-300 mr-4">
                    <Building2 className="w-6 h-6 text-[#CBA331]" />
                  </div>
                  <div>
                    <span className="block text-sm text-gray-500">Adres</span>
                    <span className="text-[#003A30] font-medium text-lg">
                      Aleja Pokoju 18D<br />31-546 Kraków
                    </span>
                  </div>
                </div>

                {/* Social Media */}
                <div className="pt-6 border-t border-gray-100">
                  <h4 className="text-base font-semibold text-[#003A30] mb-4">Media społecznościowe</h4>
                  <div className="flex space-x-4">
                    <a 
                      href="https://www.facebook.com/zychartur"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="w-12 h-12 bg-[#003A30]/5 rounded-xl flex items-center justify-center hover:bg-[#003A30] hover:text-white transition-colors duration-300 group focus:outline-none focus:ring-2 focus:ring-[#003A30] focus:ring-offset-2"
                    >
                      <Facebook className="w-6 h-6 text-[#003A30] group-hover:text-white" />
                    </a>
                    <a 
                      href="https://www.youtube.com/@ArturZychPL"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="w-12 h-12 bg-[#003A30]/5 rounded-xl flex items-center justify-center hover:bg-[#003A30] hover:text-white transition-colors duration-300 group focus:outline-none focus:ring-2 focus:ring-[#003A30] focus:ring-offset-2"
                    >
                      <Youtube className="w-6 h-6 text-[#003A30] group-hover:text-white" />
                    </a>
                    <a 
                      href="https://www.linkedin.com/in/zychartur"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="w-12 h-12 bg-[#003A30]/5 rounded-xl flex items-center justify-center hover:bg-[#003A30] hover:text-white transition-colors duration-300 group focus:outline-none focus:ring-2 focus:ring-[#003A30] focus:ring-offset-2"
                    >
                      <Linkedin className="w-6 h-6 text-[#003A30] group-hover:text-white" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Contact Form Column */}
          <div className="lg:col-span-2">
            <div className="bg-white rounded-2xl shadow-[0_4px_20px_rgba(0,0,0,0.08)] h-full p-6 md:p-8">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label htmlFor="from_name" className="block text-sm font-medium text-gray-700 mb-2">
                      Imię i nazwisko
                    </label>
                    <input
                      type="text"
                      id="from_name"
                      name="from_name"
                      value={formData.from_name}
                      onChange={handleChange}
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-[#003A30] focus:border-[#003A30]"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="from_email" className="block text-sm font-medium text-gray-700 mb-2">
                      Email
                    </label>
                    <input
                      type="email"
                      id="from_email"
                      name="from_email"
                      value={formData.from_email}
                      onChange={handleChange}
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-[#003A30] focus:border-[#003A30]"
                      required
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label htmlFor="from_phone" className="block text-sm font-medium text-gray-700 mb-2">
                      Telefon
                    </label>
                    <input
                      type="tel"
                      id="from_phone"
                      name="from_phone"
                      value={formData.from_phone}
                      onChange={handleChange}
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-[#003A30] focus:border-[#003A30]"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="service" className="block text-sm font-medium text-gray-700 mb-2">
                      Interesująca usługa
                    </label>
                    <select
                      id="service"
                      name="service"
                      value={formData.service}
                      onChange={handleChange}
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-[#003A30] focus:border-[#003A30] bg-white"
                      required
                    >
                      <option value="">Wybierz usługę</option>
                      {services.map((service) => (
                        <option key={service.value} value={service.value}>
                          {service.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-2">
                    Wiadomość
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows={4}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-[#003A30] focus:border-[#003A30] resize-none"
                    required
                  ></textarea>
                </div>

                {/* Checkboxes */}
                <div className="space-y-4">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="privacy_policy"
                        name="privacy_policy"
                        type="checkbox"
                        checked={formData.privacy_policy}
                        onChange={handleChange}
                        className="w-4 h-4 text-[#003A30] border-gray-300 rounded focus:ring-[#003A30]"
                        required
                      />
                    </div>
                    <label htmlFor="privacy_policy" className="ml-3 text-sm text-gray-600">
                      Zapoznałem się z{' '}
                      <a href="/polityka-prywatnosci" className="text-[#003A30] hover:text-[#CBA331] font-medium underline">
                        polityką prywatności
                      </a>{' '}
                      i wyrażam zgodę na przetwarzanie moich danych osobowych.
                    </label>
                  </div>
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="newsletter"
                        name="newsletter"
                        type="checkbox"
                        checked={formData.newsletter}
                        onChange={handleChange}
                        className="w-4 h-4 text-[#003A30] border-gray-300 rounded focus:ring-[#003A30]"
                      />
                    </div>
                    <label htmlFor="newsletter" className="ml-3 text-sm text-gray-600">
                      Chcę otrzymywać newsletter z poradami i aktualnościami ze świata kredytów.
                    </label>
                  </div>
                </div>

                {formStatus === 'success' && (
                  <div className="flex items-center gap-2 text-emerald-600 bg-emerald-50 px-4 py-3 rounded-lg text-sm">
                    <CheckCircle2 className="w-5 h-5 flex-shrink-0" />
                    <span>Wiadomość została wysłana. Skontaktujemy się wkrótce!</span>
                  </div>
                )}
                {formStatus === 'error' && (
                  <div className="flex items-center gap-2 text-red-600 bg-red-50 px-4 py-3 rounded-lg text-sm">
                    <AlertCircle className="w-5 h-5 flex-shrink-0" />
                    <span>Wystąpił błąd. Spróbuj ponownie później.</span>
                  </div>
                )}

                <button
                  type="submit"
                  disabled={formStatus === 'loading'}
                  className={`
                    w-full inline-flex items-center justify-center px-8 py-4 
                    bg-[#003A30] text-white rounded-lg transition-all duration-300 
                    group text-lg font-medium
                    ${formStatus === 'loading' 
                      ? 'opacity-75 cursor-not-allowed' 
                      : 'hover:bg-[#CBA331]'
                    }
                  `}
                >
                  {formStatus === 'loading' ? (
                    <>
                      <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                      Wysyłanie...
                    </>
                  ) : (
                    <>
                      Wyślij wiadomość
                      <Send className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                    </>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}