import React, { Suspense, lazy, useState, useEffect } from 'react';
import type { BlogPost } from '@/blog/types';
import ArticleHeader from './ArticleHeader';
import ArticleContent from './ArticleContent';
import ArticleAuthor from './ArticleAuthor';
import ArticleSEO from './ArticleSEO';
import TableOfContents from '../ui/TableOfContents';
import ReadingProgressBar from '../ui/ReadingProgressBar';
import SocialShare from '../ui/SocialShare';
import MobileArticleView from '../ui/MobileArticleView';
import ShareButtons from '../ui/ShareButtons';
import { useReadingProgress } from '../../hooks/useReadingProgress';
import { useHeadingsObserver } from '../../hooks/useHeadingsObserver';

// Lazy load components that are not critical for initial render
const ArticleRelatedPosts = lazy(() => import('./ArticleRelatedPosts'));
const StickyCTA = lazy(() => import('../ui/StickyCTA'));

interface ArticleTemplateProps {
  post: BlogPost;
}

export default function ArticleTemplate({ post }: ArticleTemplateProps) {
  const progress = useReadingProgress();
  const { activeHeading, headings } = useHeadingsObserver();
  const [isMobile, setIsMobile] = useState(false);
  
  // Check if we're on mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <div className="pt-20 bg-gray-50">
      {/* SEO Component */}
      <ArticleSEO post={post} />
      
      {/* Reading Progress Bar */}
      <ReadingProgressBar progress={progress} />
      
      {/* Hero Section */}
      <ArticleHeader post={post} />

      <section className="py-8 sm:py-12 md:py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-7xl mx-auto">
            {isMobile ? (
              // Mobile view with collapsible sidebar
              <MobileArticleView
                tableOfContents={
                  <TableOfContents 
                    headings={headings} 
                    activeHeading={activeHeading} 
                  />
                }
                articleActions={
                  <>
                    <ShareButtons 
                      articleId={post.id}
                      articleTitle={post.title}
                      articleUrl={window.location.href}
                    />
                    <Suspense fallback={<div className="h-20 bg-gray-100 animate-pulse rounded-xl mt-4"></div>}>
                      <StickyCTA 
                        title="Potrzebujesz pomocy z kredytem?" 
                        description="Skontaktuj się z naszym ekspertem" 
                        buttonText="Umów konsultację" 
                        buttonUrl="/kontakt" 
                      />
                    </Suspense>
                  </>
                }
              >
                <ArticleContent post={post} />
                <ArticleAuthor post={post} />
              </MobileArticleView>
            ) : (
              // Desktop view with sidebar
              <div className="flex flex-col lg:flex-row gap-6 sm:gap-8">
                {/* Main Content */}
                <div className="lg:w-2/3">
                  <ArticleContent post={post} />
                  <ArticleAuthor post={post} />
                </div>
                
                {/* Sidebar */}
                <div className="lg:w-1/3">
                  <div className="lg:sticky lg:top-24 space-y-4 sm:space-y-6">
                    <TableOfContents 
                      headings={headings} 
                      activeHeading={activeHeading} 
                    />
                    
                    <SocialShare 
                      title={post.title} 
                      url={window.location.href} 
                    />
                    
                    <Suspense fallback={<div className="h-20 bg-gray-100 animate-pulse rounded-xl"></div>}>
                      <StickyCTA 
                        title="Potrzebujesz pomocy z kredytem?" 
                        description="Skontaktuj się z naszym ekspertem" 
                        buttonText="Umów konsultację" 
                        buttonUrl="/kontakt" 
                      />
                    </Suspense>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      
      {/* Related Posts - lazy loaded */}
      <div id="related-posts-trigger" className="h-10"></div>
      <Suspense fallback={<div className="h-40 bg-gray-50"></div>}>
        <ArticleRelatedPosts post={post} />
      </Suspense>
    </div>
  );
}