import { Home, Building2, RefreshCw } from 'lucide-react';
import type { Card, CardDetails } from '../types';

// Eksport karty produktu dla większego mieszkania rodzinnego
export const HOME_CHANGE_CARD: Card = {
  id: "home-change",
  icon: RefreshCw,
  title: "Większe mieszkanie dla Twojej rodziny",
  description: "Stwórz komfortową przestrzeń dla rosnących potrzeb Twojej rodziny",
  benefits: [
    "Dopasowana oferta kredytowa dla powiększającej się rodziny",
    "Kredyt uwzględniający wartość obecnej nieruchomości",
    "Koordynacja finansowania z procesem kupna i sprzedaży"
  ],
  stats: {
    value: "30 dni",
    label: "Średni czas uzyskania decyzji kredytowej"
  },
  cta: {
    label: "Zaplanuj przeprowadzkę do nowego domu",
    action: () => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })
  }
};

// Eksport szczegółów karty dla większego mieszkania rodzinnego
export const HOME_CHANGE_DETAILS: CardDetails = {
  title: "Większe mieszkanie dla Twojej rodziny",
  tagline: "Komfortowa przestrzeń dla nowego etapu życia",
  description: "Powiększająca się rodzina potrzebuje więcej przestrzeni. Pomożemy Ci bezpiecznie przejść od obecnego do nowego, większego mieszkania poprzez optymalne rozwiązania kredytowe, doradztwo finansowe i koordynację z Twoim biurem nieruchomości.",
  cta: {
    phone: "539 211 881",
    text: "Zadzwoń i dowiedz się, jak możemy pomóc Ci w finansowaniu większego mieszkania"
  },
  benefitHighlights: [
    "Efektywne wykorzystanie kapitału z obecnej nieruchomości",
    "Optymalizacja rozwiązań kredytowych dla zmieniających mieszkanie",
    "Minimalizacja okresu podwójnych zobowiązań finansowych",
    "Dobór najkorzystniejszej oferty bankowej dla rodzin"
  ],
  whyUs: [
    "Specjalizujemy się w doradztwie kredytowym dla rodzin zmieniających mieszkanie",
    "Współpracujemy z najlepszymi biurami nieruchomości, zapewniając pełną koordynację",
    "Mamy dostęp do ofert kredytowych niedostępnych dla klientów indywidualnych",
    "Doradzamy w zakresie optymalnego finansowania całego procesu"
  ],
  // NOWY PUNKT 1 - Elastyczny model współpracy
  partnershipModel: {
    title: "Elastyczny model wsparcia - z biurem nieruchomości lub samodzielnie",
    description: "Naszą rolą jest doradztwo kredytowe i finansowe, które dostosowujemy do Twojego wyboru sposobu przeprowadzenia transakcji - z biurem nieruchomości lub samodzielnie.",
    options: [
      {
        title: "Opcja 1: Współpraca z biurem nieruchomości",
        description: "Działamy w ścisłej koordynacji z Twoim agentem, aby wszystkie aspekty transakcji przebiegały sprawnie."
      },
      {
        title: "Opcja 2: Wsparcie przy samodzielnej sprzedaży i zakupie",
        description: "Oferujemy rozszerzone wsparcie doradcze dla klientów, którzy samodzielnie prowadzą proces sprzedaży i zakupu nieruchomości."
      }
    ],
    responsibilities: [
      {
        role: "Doradca kredytowy (my) - zawsze w Twojej ofercie",
        tasks: [
          "Analiza zdolności kredytowej i możliwości finansowych",
          "Dobór najkorzystniejszej oferty kredytowej",
          "Przygotowanie i złożenie wniosków kredytowych",
          "Monitoring procesu kredytowego",
          "Koordynacja finansowania z terminami transakcji"
        ]
      },
      {
        role: "Agent nieruchomości (opcjonalnie)",
        tasks: [
          "Wycena i przygotowanie obecnego mieszkania do sprzedaży",
          "Poszukiwanie nowego mieszkania według Twoich potrzeb",
          "Negocjacje cen zakupu i sprzedaży",
          "Przygotowanie umów przedwstępnych i finalnych",
          "Koordynacja procesu przeprowadzki"
        ]
      },
      {
        role: "Rozszerzone wsparcie przy samodzielnej transakcji",
        tasks: [
          "Doradztwo w zakresie wyceny nieruchomości i ustalania ceny",
          "Wskazówki dotyczące przygotowania mieszkania do sprzedaży",
          "Konsultacje przy negocjacjach cenowych",
          "Doradztwo przy umowach przedwstępnych (aspekty finansowe)",
          "Wsparcie w koordynacji terminów transakcji i finansowania"
        ]
      }
    ]
  },
  // NOWY PUNKT 2 - Korzyści z modelu współpracy
  collaborationBenefits: {
    title: "Korzyści z elastycznego modelu wsparcia",
    description: "Niezależnie od wybranego sposobu przeprowadzenia transakcji - z biurem nieruchomości lub samodzielnie - nasze wsparcie kredytowe zapewnia bezpieczeństwo finansowe procesu zamiany mieszkania.",
    benefits: [
      {
        title: "Synchronizacja procesów",
        description: "Doradca kredytowy i agent nieruchomości działają w porozumieniu, zapewniając zgodność terminów kredytowania z terminami transakcji",
        icon: "Clock"
      },
      {
        title: "Minimalizacja ryzyka",
        description: "Profesjonalna ocena możliwości kredytowych przed podjęciem zobowiązań związanych z zakupem nowej nieruchomości",
        icon: "Shield"
      },
      {
        title: "Oszczędność czasu",
        description: "Równoległe prowadzenie procesów kredytowych i transakcyjnych skraca całkowity czas realizacji",
        icon: "Hourglass"
      },
      {
        title: "Kompleksowe doradztwo",
        description: "Każdy aspekt procesu jest obsługiwany przez specjalistów w swojej dziedzinie, co zapewnia najwyższą jakość usług",
        icon: "Users"
      }
    ]
  },
  processComparison: {
    withUs: [
      {
        step: "Analiza możliwości finansowych",
        description: "Kompleksowa ocena zdolności kredytowej i opcji finansowania",
        icon: "Calculator",
        highlight: true
      },
      {
        step: "Koordynacja z biurem nieruchomości",
        description: "Synchronizacja działań kredytowych z procesem sprzedaży i zakupu",
        icon: "Users"
      },
      {
        step: "Optymalizacja oferty kredytowej",
        description: "Dopasowanie finansowania do specyfiki transakcji",
        icon: "FileText"
      },
      {
        step: "Bezpieczne przejście do nowego mieszkania",
        description: "Zabezpieczenie finansowe całego procesu",
        icon: "CheckCircle",
        highlight: true
      }
    ],
    withoutUs: [
      {
        step: "Niepewność finansowa",
        description: "Brak jasnej strategii kredytowej i ryzyka finansowe",
        icon: "HelpCircle",
        warning: true
      },
      {
        step: "Brak koordynacji procesów",
        description: "Samodzielne próby synchronizacji kredytu i transakcji nieruchomości",
        icon: "AlertTriangle",
        warning: true
      },
      {
        step: "Problemy z podwójnym finansowaniem",
        description: "Ryzyko niekorzystnych warunków kredytowych lub braku finansowania",
        icon: "Clock",
        warning: true
      },
      {
        step: "Stres i nieprzewidziane koszty",
        description: "Dodatkowe opłaty i wyższe oprocentowanie przez brak negocjacji",
        icon: "DollarSign",
        warning: true
      }
    ]
  },
  // NOWY PUNKT 3 - Usługi doradcy kredytowego
  advisorServices: {
    title: "Zakres usług doradcy kredytowego przy zmianie mieszkania",
    description: "Jako doradcy kredytowi specjalizujący się w obsłudze transakcji zamiany mieszkań, oferujemy kompleksowe wsparcie w obszarze finansowania.",
    services: [
      {
        title: "Analiza opcji kredytowych",
        description: "Szczegółowe porównanie ofert różnych banków pod kątem Twojej sytuacji, z uwzględnieniem specyfiki zamiany mieszkania",
        icon: "Search"
      },
      {
        title: "Ocena możliwości przeniesienia kredytu",
        description: "Analiza istniejącej umowy kredytowej pod kątem możliwości przeniesienia zabezpieczenia na nową nieruchomość (zgodnie z art. 46 Ustawy o kredycie hipotecznym)",
        icon: "RefreshCw"
      },
      {
        title: "Doradztwo w zakresie warunkowej zgody kredytowej",
        description: "Pomoc w uzyskaniu warunkowej zgody banku na nowy kredyt z zastrzeżeniem wcześniejszej spłaty istniejącego zobowiązania ze środków ze sprzedaży obecnie posiadanej nieruchomości",
        icon: "FileCheck"
      },
      {
        title: "Zabezpieczenie stałego oprocentowania",
        description: "Analiza i rekomendacja ofert ze stałym oprocentowaniem, zgodnie z art. 7b Ustawy o kredycie hipotecznym",
        icon: "Lock"
      },
      {
        title: "Optymalizacja kosztów finansowania",
        description: "Minimalizacja kosztów okołokredytowych i negocjacje lepszych warunków w oparciu o status klienta zmieniającego mieszkanie",
        icon: "TrendingDown"
      },
      {
        title: "Koordynacja finansowa z procesem transakcyjnym",
        description: "Dopasowanie harmonogramu kredytowego do terminów transakcji ustalonych z biurem nieruchomości",
        icon: "Calendar"
      }
    ]
  },
  calculations: {
    example: "Zamiana mieszkania 50m² na 85m² w dużym mieście (2025)",
    before: "Standardowy proces bez wsparcia doradcy kredytowego:\n• Wartość obecnego mieszkania: 480 000 zł\n• Pozostały kredyt: 280 000 zł\n• Koszt nowego mieszkania: 850 000 zł\n• Aktualne oprocentowanie: około 6,7% (WIBOR + marża 2,2%)\n• Rata obecnego kredytu: około 2 100 zł miesięcznie\n• Szacowana rata nowego kredytu: około 4 750 zł miesięcznie\n• Dodatkowe koszty kredytowe: ok. 25 000 zł (prowizja, ubezpieczenia, wycena)\n• Typowy czas procesu kredytowego: 2-3 miesiące\n• Ryzyko braku synchronizacji finansowania z transakcją: wysokie",
    after: "Z naszym wsparciem w zakresie kredytowym:\n• Wartość obecnego mieszkania: 480 000 zł (wycena przez biuro nieruchomości)\n• Pozostały kredyt: 280 000 zł\n• Koszt nowego mieszkania: 850 000 zł (negocjowany przez biuro nieruchomości)\n• Wynegocjowane oprocentowanie: około 6,2-6,5% (z uwzględnieniem prognozowanych obniżek stóp)\n• Rata nowego kredytu: około 4 500 - 4 650 zł miesięcznie\n• Zoptymalizowane koszty kredytowe: ok. 15 000 - 18 000 zł\n• Czas procesu kredytowego: 30-45 dni\n• Ryzyko braku synchronizacji: minimalne dzięki koordynacji",
    savings: "Potencjalne korzyści finansowe: 7 000 - 10 000 zł oszczędności na kosztach kredytowych, niższa rata miesięczna o 100-250 zł dzięki lepszym warunkom, bezpieczeństwo finansowe dzięki synchronizacji procesów",
    extra: "Dodatkowo: możliwość zabezpieczenia stałej stopy procentowej na pierwsze lata nowego kredytu (zgodnie z art. 7b Ustawy o kredycie hipotecznym), dostęp do ofert specjalnych dla rodzin z dziećmi, profesjonalne doradztwo w zakresie optymalnego wykorzystania kapitału z obecnej nieruchomości"
  },
  benefits: [
    "Maksymalne wykorzystanie kapitału z obecnej nieruchomości jako wkład własny",
    "Koordynacja finansowania z terminami transakcji ustalonymi przez biuro nieruchomości",
    "Analiza możliwości przeniesienia obecnego kredytu na nową nieruchomość (zgodnie z warunkami banku)",
    "Sprawdzenie wszystkich kosztów wcześniejszej spłaty obecnego kredytu",
    "Wybór kredytu ze stałym oprocentowaniem na pierwsze lata jako zabezpieczenie",
    "Koordynacja aspektów finansowych z procesem transakcyjnym prowadzonym przez biuro nieruchomości"
  ],
  limitedTimeOffer: {
    text: "Darmowa analiza warunków obecnego kredytu i możliwości jego przeniesienia lub refinansowania",
    expiry: "31.05.2025"
  },
  faq: [
    {
      question: "Czy muszę najpierw sprzedać obecne mieszkanie, żeby kupić nowe?",
      answer: "Niekoniecznie. Istnieje kilka strategii finansowych, które możemy zastosować: 1) Warunkowa zgoda banku - możliwość wnioskowania o nowy kredyt z deklaracją spłaty istniejącego zobowiązania ze środków pochodzących ze sprzedaży obecnej nieruchomości (warunkiem wypłaty środków lub zawarcia umowy kredytowej będzie przedstawienie zaświadczenia o całkowitej spłacie pierwszego kredytu), 2) Kredyt hipoteczny z zabezpieczeniem na obu nieruchomościach, 3) Przeniesienie istniejącego kredytu na nową nieruchomość (w niektórych bankach, zgodnie z art. 46 Ustawy o kredycie hipotecznym), 4) Dokładne zsynchronizowanie finansowania z terminami transakcji. Każda sytuacja jest inna - podczas konsultacji omówimy, które rozwiązanie finansowe będzie optymalne w Twoim przypadku."
    },
    {
      question: "Jak wpłynie obecny kredyt na możliwość uzyskania nowego finansowania?",
      answer: "Banki uwzględniają obecne zobowiązanie kredytowe przy obliczaniu zdolności kredytowej, zgodnie z wymogami Rekomendacji T Komisji Nadzoru Finansowego. Istnieją jednak dwa scenariusze: 1) Jeśli planujesz sprzedać obecne mieszkanie - niektóre banki potrafią warunkowo 'nie uwzględniać' obecnego kredytu przy wyliczaniu zdolności, 2) Jeśli chcesz zachować obecne mieszkanie (np. na wynajem) - wpływ na zdolność będzie większy. Przy aktualnym oprocentowaniu około 6,7% (marzec 2025, z prognozowanymi obniżkami w kolejnych miesiącach), dla mieszkania wartego 850 000 zł potrzebujesz zdolności kredytowej na około 4 500 - 4 750 zł miesięcznie."
    },
    {
      question: "Jakie dokumenty będą potrzebne przy zamianie mieszkania na większe?",
      answer: "Na potrzeby procesu kredytowego będziesz potrzebować: 1) Dokumentację obecnego kredytu hipotecznego (umowa, aktualny harmonogram spłat), 2) Dokumenty potwierdzające dochody (zaświadczenie o zarobkach, PIT-y za ostatni rok), 3) Historię spłat obecnego kredytu za ostatnie 12 miesięcy, 4) Dokumenty dotyczące obecnej nieruchomości (akt notarialny, księga wieczysta), 5) W przypadku ubiegania się o warunkową zgodę kredytową - operat szacunkowy obecnej nieruchomości potwierdzający jej wartość rynkową (możemy rekomendować sprawdzonych rzeczoznawców). Pomożemy Ci przygotować wszystkie dokumenty kredytowe i ustalimy, które z nich są wymagane w Twojej konkretnej sytuacji."
    },
    {
      question: "Jakie są opłaty za wcześniejszą spłatę obecnego kredytu hipotecznego?",
      answer: "Wysokość opłat zależy od warunków Twojej umowy kredytowej oraz czasu, który minął od jej zawarcia. Zgodnie z art. 40-41 Ustawy o kredycie hipotecznym, obecnie (2025) większość banków pobiera opłaty tylko w pierwszych 3 latach kredytu (maksymalnie 3% spłacanej kwoty, a po 36 miesiącach maksymalnie 1%). Po tym okresie wcześniejsza spłata jest zazwyczaj bezpłatna. Niektóre banki oferują także promocje z całkowitym zniesieniem tych opłat. Dokładnie przeanalizujemy Twoją umowę, aby sprawdzić potencjalne koszty i zaproponować najkorzystniejsze rozwiązanie finansowe."
    },
    {
      question: "Co się stanie, jeśli ceny nieruchomości zmienią się w trakcie procesu zamiany mieszkania?",
      answer: "Ryzyko zmiany cen nieruchomości istnieje zawsze, ale dzięki szybszej realizacji procesu kredytowego (30-45 dni zamiast 2-3 miesięcy) jest ono znacząco ograniczone. W zakresie finansowania stosujemy strategie zabezpieczające: 1) Rekomendację odpowiednich zapisów w umowach przedwstępnych dotyczących finansowania, 2) Przygotowanie wariantów finansowania na wypadek zmian cenowych, 3) Elastyczne rozwiązania kredytowe dostosowane do ewentualnych zmian. W marcu 2025 rynek jest względnie stabilny, z prognozowanym umiarkowanym wzrostem cen o 3-5% w skali roku, co przy sprawnym przeprowadzeniu procesu kredytowego nie powinno znacząco wpłynąć na całość transakcji."
    },
    {
      question: "Czy mogę skorzystać ze stałego oprocentowania przy nowym kredycie?",
      answer: "Tak, zgodnie z art. 7b Ustawy o kredycie hipotecznym, obecnie większość banków oferuje kredyty ze stałym oprocentowaniem na pierwsze 5-7 lat. Przy obecnym poziomie stóp procentowych (6,7% z prognozowanymi obniżkami) może to być korzystne rozwiązanie, zabezpieczające przed ewentualnymi wzrostami w przyszłości. Stałe oprocentowanie w marcu 2025 wynosi średnio 6,3-6,8% w zależności od banku, okresu stabilizacji i wysokości wkładu własnego. Pomożemy porównać oferty kredytowe i wybrać najkorzystniejszą opcję dopasowaną do Twojej sytuacji i preferencji dotyczących bezpieczeństwa finansowego."
    }
  ],
  nextSteps: [
    "Skontaktuj się z nami telefonicznie lub przez formularz",
    "Umów się na bezpłatną konsultację w zakresie finansowania",
    "Przygotuj podstawową dokumentację kredytową",
    "Omów z biurem nieruchomości aspekty sprzedaży i zakupu",
    "Otrzymaj od nas analizę możliwości finansowych i dostępnych opcji kredytowych",
    "Opracowanie indywidualnej strategii finansowania zmiany mieszkania",
    "Koordynacja procesu kredytowego z działaniami biura nieruchomości"
  ]
};