import React from 'react';
import Hero from '@/components/shared/Hero';
import ProblemsSolutions from '@/components/shared/ProblemsSolutions';
import Process from '@/components/shared/Process';
import CaseStudyList from '@/components/shared/CaseStudyList';
import ComparisonCards from '@/components/shared/ComparisonCards';
import ServicePageNavigation from '@/components/shared/ServicePageNavigation';
import { mortgageProblemsData } from '@/data/ProblemsSolutionsData';
import { mortgageCases } from '@/data/caseStudies/mortgage';
import { MORTGAGE_CARDS, MORTGAGE_DETAILS } from '@/data/modal/mortgage';
import Calculator from './sections/Calculator';
import Concerns from './sections/Concerns';
import TrustBar from './sections/TrustBar';
import Contact from '@/components/shared/Contact';
import { Shield, Star, Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function MortgageLoan() {
  const navigate = useNavigate();

  const scrollToCalculator = () => {
    document.getElementById('calculator')?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
    <div className="pt-20 bg-gray-50">
      <ServicePageNavigation />
      
      <Hero 
        title="Kredyt hipoteczny na wymarzony dom"
        highlightedWord="wymarzony"
        description="Znajdziemy dla Ciebie idealny kredyt hipoteczny, oszczędzając Twój czas i pieniądze. Porównujemy oferty wszystkich banków, negocjujemy warunki i prowadzimy Cię przez cały proces - bez ukrytych kosztów."
        image="https://images.unsplash.com/photo-1600585154340-be6161a56a0c?auto=format&fit=crop&q=80"
        badgeText="Zaufało nam już ponad 1200 klientów"
        stats={[
          {
            icon: Shield,
            value: "Gwarancja",
            label: "Najlepszych warunków"
          },
          {
            icon: Clock,
            value: "24h",
            label: "Na decyzję"
          },
          {
            icon: Star,
            value: "98%",
            label: "Zadowolonych klientów"
          }
        ]}
        primaryCta={{
          label: "Rozpocznij proces",
          action: () => navigate('#contact')
        }}
        secondaryCta={{
          label: "Oblicz ratę kredytu",
          action: scrollToCalculator
        }}
      />
      <ProblemsSolutions 
        problems={mortgageProblemsData.problems}
        title={mortgageProblemsData.title}
        subtitle={mortgageProblemsData.subtitle}
      />
      <section id="calculator">
        <Calculator />
      </section>
      <section id="types">
        <ComparisonCards 
          cards={MORTGAGE_CARDS}
          details={MORTGAGE_DETAILS}
          title="Rodzaje kredytów hipotecznych"
          subtitle="Wybierz rodzaj kredytu hipotecznego dopasowany do Twoich potrzeb"
        />
      </section>
      <Process />
      <section id="testimonials">
        <CaseStudyList 
          cases={mortgageCases}
          title="Historie sukcesu kredytów hipotecznych"
          subtitle="Zobacz, jak pomogliśmy innym w spełnieniu marzeń o własnym mieszkaniu"
        />
      </section>
      <Concerns />
      <TrustBar />
      <section id="contact">
        <Contact />
      </section>
    </div>
  );
}