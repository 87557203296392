import React, { useEffect } from 'react';
import type { BlogPost } from '@/blog/types';
import { setAllMetaTags } from '../../utils/meta-tags';
import { generateAllSchemas } from '../../utils/schema-builder';

interface ArticleSEOProps {
  post: BlogPost;
}

export default function ArticleSEO({ post }: ArticleSEOProps) {
  useEffect(() => {
    // Set meta tags
    setAllMetaTags(post);
    
    // Add schema.org markup
    const schemas = generateAllSchemas(post);
    
    // Add each schema as a script tag
    schemas.forEach(schema => {
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.text = JSON.stringify(schema);
      document.head.appendChild(script);
    });
    
    // Clean up function to remove scripts when component unmounts
    return () => {
      const scripts = document.querySelectorAll('script[type="application/ld+json"]');
      scripts.forEach(script => {
        document.head.removeChild(script);
      });
    };
  }, [post]);

  // This component doesn't render anything visible
  return null;
}