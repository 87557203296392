import React from 'react';
import Hero from '@/components/shared/Hero';
import { PiggyBank, BadgePercent, Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import FloatingHelpButton from '@/components/shared/FloatingHelpButton';

export default function SavingsCalculator() {
  const navigate = useNavigate();

  return (
    <div className="pt-20 bg-gray-50">
      <FloatingHelpButton />
      
      <Hero 
        title="Kalkulator oszczędności na kredycie"
        highlightedWord="oszczędności"
        description="Oblicz, ile możesz zaoszczędzić dzięki refinansowaniu kredytu. Sprawdź potencjalne korzyści i zacznij oszczędzać już dziś."
        image="https://images.unsplash.com/photo-1579621970588-a35d0e7ab9b6?auto=format&fit=crop&q=80"
        stats={[
          {
            icon: PiggyBank,
            value: "-35%",
            label: "Średnia oszczędność"
          },
          {
            icon: BadgePercent,
            value: "75k+",
            label: "Analiz refinansowania"
          },
          {
            icon: Clock,
            value: "3 min",
            label: "Szybka kalkulacja"
          }
        ]}
        primaryCta={{
          label: "Oblicz oszczędności",
          action: () => navigate('#calculator')
        }}
        secondaryCta={{
          label: "Skonsultuj się z ekspertem",
          action: () => navigate('#contact')
        }}
      />

      {/* Calculator Section */}
      <section id="calculator" className="py-12 md:py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6 md:p-8">
            <h2 className="text-2xl font-bold text-[#003A30] mb-6">Oblicz potencjalne oszczędności</h2>
            <form className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="currentAmount" className="block text-sm font-medium text-gray-700 mb-1">
                    Obecne saldo kredytu (PLN)
                  </label>
                  <input
                    type="number"
                    id="currentAmount"
                    name="currentAmount"
                    min="0"
                    step="1000"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-[#003A30] focus:border-[#003A30]"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="currentRate" className="block text-sm font-medium text-gray-700 mb-1">
                    Obecne oprocentowanie (%)
                  </label>
                  <input
                    type="number"
                    id="currentRate"
                    name="currentRate"
                    step="0.01"
                    min="0"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-[#003A30] focus:border-[#003A30]"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="newRate" className="block text-sm font-medium text-gray-700 mb-1">
                    Nowe oprocentowanie (%)
                  </label>
                  <input
                    type="number"
                    id="newRate"
                    name="newRate"
                    step="0.01"
                    min="0"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-[#003A30] focus:border-[#003A30]"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="remainingPeriod" className="block text-sm font-medium text-gray-700 mb-1">
                    Pozostały okres (lata)
                  </label>
                  <input
                    type="number"
                    id="remainingPeriod"
                    name="remainingPeriod"
                    min="1"
                    max="35"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-[#003A30] focus:border-[#003A30]"
                    required
                  />
                </div>
              </div>
              <button
                type="submit"
                className="w-full bg-[#003A30] text-white px-6 py-3 rounded-md hover:bg-[#CBA331] transition duration-300"
              >
                Oblicz oszczędności
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}