import React, { useEffect, useCallback, useState } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
import type { CaseStudy } from '@/data/caseStudies/types';
import CaseStudyModalContent from './CaseStudyModalContent';

interface CaseStudyModalProps {
  isOpen: boolean;
  onClose: () => void;
  caseStudy: CaseStudy;
  onPrevious: () => void;
  onNext: () => void;
  hasPrevious: boolean;
  hasNext: boolean;
}

export default function CaseStudyModal({
  isOpen,
  onClose,
  caseStudy,
  onPrevious,
  onNext,
  hasPrevious,
  hasNext
}: CaseStudyModalProps) {
  // Stany do zarządzania animacjami
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [direction, setDirection] = useState<'right' | 'left' | null>(null);
  const [transitioning, setTransitioning] = useState(false);
  const [currentCaseStudy, setCurrentCaseStudy] = useState<CaseStudy>(caseStudy);

  // Ustawienie początkowej animacji po otwarciu modalu
  useEffect(() => {
    if (isOpen) {
      // Niewielkie opóźnienie, aby dać przeglądarce czas na renderowanie
      setTimeout(() => setIsVisible(true), 50);
    }
  }, [isOpen]);

  // Aktualizacja case study po zakończeniu nawigacji
  useEffect(() => {
    if (!transitioning) {
      setCurrentCaseStudy(caseStudy);
    }
  }, [transitioning, caseStudy]);
  
  // Handler dla klawisza Escape
  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleClose();
    } else if (event.key === 'ArrowLeft' && hasPrevious && !transitioning) {
      handlePrevious();
    } else if (event.key === 'ArrowRight' && hasNext && !transitioning) {
      handleNext();
    }
  }, [onClose, onPrevious, onNext, hasPrevious, hasNext, transitioning]);

  // Płynne zamykanie modalu
  const handleClose = () => {
    setIsClosing(true);
    setIsVisible(false);
    // Opóźnienie, aby animacja miała czas się wykonać
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300);
  };

  // Obsługa płynnego przejścia do poprzedniego case study
  const handlePrevious = () => {
    if (transitioning || !hasPrevious) return;
    
    setDirection('left');
    setTransitioning(true);
    
    // Opóźnienie zgodne z czasem trwania animacji
    setTimeout(() => {
      onPrevious();
      // Dodatkowe opóźnienie przed zakończeniem zmiany
      setTimeout(() => {
        setTransitioning(false);
        setDirection(null);
      }, 50);
    }, 300);
  };
  
  // Obsługa płynnego przejścia do następnego case study
  const handleNext = () => {
    if (transitioning || !hasNext) return;
    
    setDirection('right');
    setTransitioning(true);
    
    // Opóźnienie zgodne z czasem trwania animacji
    setTimeout(() => {
      onNext();
      // Dodatkowe opóźnienie przed zakończeniem zmiany
      setTimeout(() => {
        setTransitioning(false);
        setDirection(null);
      }, 50);
    }, 300);
  };

  // Dodanie i usunięcie event listenera dla klawisza Escape
  useEffect(() => {
    if (isOpen) {
      // Blokowanie przewijania body gdy modal jest otwarty
      document.body.style.overflow = 'hidden';
      // Nasłuchiwanie klawiszy
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.body.style.overflow = '';
    }

    // Cleanup
    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, handleKeyDown]);

  // Jeśli modal nie jest otwarty, nie renderuj nic
  if (!isOpen) return null;

  // Walidacja dla brakującego caseStudy
  if (!currentCaseStudy) {
    return (
      <div 
        className="fixed inset-0 z-50 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div 
          className={`fixed inset-0 bg-black transition-opacity duration-300 ${isVisible ? 'bg-opacity-50 backdrop-blur-sm' : 'bg-opacity-0'}`}
          aria-hidden="true"
          onClick={handleClose}
        />
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <div 
            className={`relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all duration-300 sm:my-8 sm:w-full sm:max-w-2xl ${isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-95'}`}
          >
            <button
              onClick={handleClose}
              className="absolute top-4 right-4 p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors duration-200 z-50"
              aria-label="Zamknij"
            >
              <X className="w-6 h-6 text-gray-500" />
            </button>
            <div className="bg-white px-6 py-12 text-center">
              <p className="text-gray-600">Nie znaleziono danych dla tej historii.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Określenie klasy animacji w zależności od kierunku
  const getAnimationClass = () => {
    if (transitioning) {
      return direction === 'right' 
        ? 'translate-x-full opacity-0' 
        : '-translate-x-full opacity-0';
    } else if (!isVisible || isClosing) {
      return 'opacity-0 scale-95';
    }
    return 'opacity-100 scale-100 translate-x-0';
  };

  return (
    <div 
      className="fixed inset-0 z-50 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* Backdrop with blur effect */}
      <div 
        className={`fixed inset-0 bg-black transition-opacity duration-300 ${isVisible ? 'bg-opacity-50 backdrop-blur-sm' : 'bg-opacity-0'}`}
        aria-hidden="true"
        onClick={handleClose}
      />

      {/* Modal positioning */}
      <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
        {/* Modal content */}
        <div 
          className={`relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all duration-300 sm:my-8 sm:w-full sm:max-w-7xl ${getAnimationClass()}`}
          onClick={(e) => e.stopPropagation()}
        >
          {/* Close button */}
          <button
            onClick={handleClose}
            className="absolute top-4 right-4 p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors duration-200 z-50"
            aria-label="Zamknij"
          >
            <X className="w-6 h-6 text-gray-500" />
          </button>

          {/* Content */}
          <div className="bg-white px-6 py-4 max-h-[calc(100vh-100px)] overflow-y-auto">
            <CaseStudyModalContent caseStudy={currentCaseStudy} />
          </div>

          {/* Navigation buttons */}
          <div className="flex justify-between items-center p-4 border-t border-gray-100">
            <button
              onClick={handlePrevious}
              disabled={!hasPrevious || transitioning}
              className={`
                flex items-center gap-2 px-4 py-2 rounded-lg transition-colors duration-200
                ${hasPrevious && !transitioning
                  ? 'text-[#003A30] hover:bg-gray-100' 
                  : 'text-gray-400 cursor-not-allowed'
                }
              `}
              aria-label="Poprzednia historia"
            >
              <ChevronLeft className={`w-5 h-5 transition-transform duration-300 ${hasPrevious && !transitioning ? 'group-hover:-translate-x-1' : ''}`} />
              Poprzednia historia
            </button>
            <button
              onClick={handleNext}
              disabled={!hasNext || transitioning}
              className={`
                flex items-center gap-2 px-4 py-2 rounded-lg transition-colors duration-200
                ${hasNext && !transitioning
                  ? 'text-[#003A30] hover:bg-gray-100 group' 
                  : 'text-gray-400 cursor-not-allowed'
                }
              `}
              aria-label="Następna historia"
            >
              Następna historia
              <ChevronRight className={`w-5 h-5 transition-transform duration-300 ${hasNext && !transitioning ? 'group-hover:translate-x-1' : ''}`} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}