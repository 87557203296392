import React from 'react';
import { User } from 'lucide-react';
import type { BlogPost } from '@/blog/types';

interface ArticleAuthorProps {
  post: BlogPost;
}

export default function ArticleAuthor({ post }: ArticleAuthorProps) {
  return (
    <div className="bg-white rounded-xl shadow-lg p-6 mt-8">
      <div className="flex items-center gap-4">
        {post.author.avatar ? (
          <img
            src={post.author.avatar}
            alt={post.author.name}
            className="w-16 h-16 rounded-full object-cover"
          />
        ) : (
          <div className="w-16 h-16 rounded-full bg-gray-200 flex items-center justify-center">
            <User className="w-8 h-8 text-gray-500" />
          </div>
        )}
        <div>
          <h3 className="text-xl font-bold text-[#003A30]">{post.author.name}</h3>
          {post.author.title && (
            <p className="text-gray-600">{post.author.title}</p>
          )}
        </div>
      </div>
    </div>
  );
}