import { Building2 } from 'lucide-react';
import type { Card, CardDetails } from '../types';

export const WORKING_CAPITAL_CARD: Card = {
  id: "working-capital",
  icon: Building2,
  title: "Kredyt obrotowy ratalny",
  description: "Finansowanie bieżącej działalności firmy",
  benefits: [
    "Stałe, przewidywalne raty",
    "Możliwość wcześniejszej spłaty",
    "Proste zabezpieczenia"
  ],
  stats: {
    value: "120",
    label: "Miesięcy spłaty"
  },
  cta: {
    label: "Zapewnij płynność",
    action: () => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })
  }
};

export const WORKING_CAPITAL_DETAILS: CardDetails = {
  title: "Kredyt obrotowy ratalny",
  tagline: "Stabilne finansowanie bieżącej działalności",
  description: "Kredyt obrotowy ratalny to rozwiązanie zapewniające stałe, przewidywalne finansowanie działalności operacyjnej Twojej firmy. Dzięki spłacie w równych ratach możesz lepiej planować przepływy finansowe.",
  cta: {
    phone: "539 211 881",
    text: "Zadzwoń i dowiedz się więcej o możliwościach finansowania obrotowego"
  },
  benefitHighlights: [
    "Stałe, przewidywalne raty miesięczne",
    "Okres kredytowania do 120 miesięcy",
    "Możliwość wcześniejszej spłaty bez dodatkowych kosztów",
    "Minimum formalności i proste zabezpieczenia"
  ],
  whyUs: [
    "Specjalizujemy się w finansowaniu firm z różnych branż",
    "Znamy specyfikę różnych modeli biznesowych",
    "Oferujemy indywidualne podejście do każdego klienta",
    "Zapewniamy szybką decyzję kredytową"
  ],
  processComparison: {
    withUs: [
      {
        step: "Szybka analiza potrzeb",
        description: "Dokładne zrozumienie potrzeb finansowych Twojej firmy",
        icon: "Search",
        highlight: true
      },
      {
        step: "Minimum formalności",
        description: "Uproszczona dokumentacja i szybki proces",
        icon: "FileText"
      },
      {
        step: "Najlepsza oferta",
        description: "Porównanie i wybór optymalnego rozwiązania",
        icon: "CheckCircle"
      },
      {
        step: "Szybka decyzja",
        description: "Decyzja kredytowa w ciągu 24-48h",
        icon: "Clock",
        highlight: true
      }
    ],
    withoutUs: [
      {
        step: "Czasochłonne poszukiwania",
        description: "Samodzielne porównywanie wielu ofert",
        icon: "Search",
        warning: true
      },
      {
        step: "Skomplikowana dokumentacja",
        description: "Ryzyko błędów w dokumentacji",
        icon: "FileX",
        warning: true
      },
      {
        step: "Ograniczone możliwości",
        description: "Dostęp tylko do standardowych ofert",
        icon: "AlertTriangle",
        warning: true
      },
      {
        step: "Długi czas oczekiwania",
        description: "Wydłużony proces decyzyjny",
        icon: "Clock",
        warning: true
      }
    ]
  },
  calculations: {
    example: "Przykład dla kredytu 500 000 zł na 8 lat (96 miesięcy)",
    before: "Standardowa oferta:\n• Oprocentowanie: 10,8-12,5%\n• Prowizja: 2-3%\n• Rata miesięczna: około 8 000 zł\n• Koszt całkowity: około 783 000 zł",
    after: "Z naszym wsparciem:\n• Oprocentowanie: od 2,5% (marża)\n• Prowizja: 0-1%\n• Rata miesięczna: około 7 070 zł\n• Koszt całkowity: około 681 000 zł",
    savings: "Oszczędności:\n• Na racie: około 960 zł miesięcznie\n• Na prowizji: około 10 000 zł\n• Całkowita oszczędność: około 102 000 zł"
  },
  benefits: [
    "Stałe, przewidywalne raty miesięczne",
    "Okres kredytowania do 120 miesięcy",
    "Możliwość wcześniejszej spłaty bez dodatkowych kosztów",
    "Minimum formalności i proste zabezpieczenia",
    "Szybka decyzja kredytowa",
    "Elastyczne warunki spłaty"
  ],
  limitedTimeOffer: {
    text: "Promocja: 0% prowizji dla firm działających powyżej 24 miesięcy",
    expiry: "30.04.2025"
  },
  faq: [
    {
      question: "Jakie dokumenty są potrzebne do uzyskania kredytu obrotowego?",
      answer: "Do uzyskania kredytu obrotowego ratalnego potrzebne są podstawowe dokumenty: 1) Dokumenty rejestrowe firmy (KRS/CEIDG), 2) Dokumenty finansowe za ostatni rok (PIT/CIT, rachunki zysków i strat, bilanse), 3) Zaświadczenia o niezaleganiu z ZUS i US, 4) Historia rachunku bankowego za ostatnie 6 miesięcy. W przypadku firm działających krócej niż 24 miesiące może być wymagany uproszczony biznesplan. Pomagamy w przygotowaniu i weryfikacji wszystkich dokumentów, co znacząco przyspiesza proces kredytowy."
    },
    {
      question: "Jak szybko mogę otrzymać decyzję kredytową?",
      answer: "Proces uzyskania decyzji kredytowej dla kredytu obrotowego ratalnego jest znacznie szybszy niż w przypadku kredytów inwestycyjnych. Standardowo składa się z następujących etapów: 1) Wstępna analiza i przygotowanie dokumentacji: 1-2 dni, 2) Złożenie wniosku i analiza bankowa: 1-2 dni, 3) Decyzja kredytowa: 1-2 dni, 4) Przygotowanie umowy i uruchomienie środków: 1-2 dni. Łącznie proces trwa 4-8 dni roboczych. Dla stałych klientów z dobrą historią kredytową możliwe jest uzyskanie decyzji nawet w 24 godziny."
    },
    {
      question: "Czy mogę wcześniej spłacić kredyt obrotowy ratalny?",
      answer: "Tak, oferowane przez nas kredyty obrotowe ratalne zawsze posiadają możliwość wcześniejszej spłaty bez dodatkowych kosztów. W przypadku wcześniejszej spłaty: 1) Nie są naliczane prowizje za wcześniejszą spłatę, 2) Odsetki są naliczane tylko do dnia rzeczywistej spłaty, 3) Możliwa jest zarówno całkowita spłata kredytu, jak i częściowe nadpłaty. Wcześniejsza spłata może przynieść znaczące oszczędności - przykładowo, przy kredycie 500 000 zł na 120 miesięcy, wcześniejsza spłata po 60 miesiącach może przynieść oszczędność na odsetkach nawet do 75 000 zł."
    },
    {
      question: "Jakie zabezpieczenia są wymagane?",
      answer: "Kredyt obrotowy ratalny wymaga prostszych zabezpieczeń niż kredyty inwestycyjne. Standardowe formy zabezpieczeń to: 1) Weksel własny in blanco, 2) Poręczenie właścicieli firmy (przy spółkach), 3) Zastaw rejestrowy na zapasach lub maszynach (opcjonalnie), 4) Cesja należności z kontraktów (opcjonalnie). Dla firm z dobrą historią kredytową i stabilnymi wynikami finansowymi możliwe jest uzyskanie kredytu nawet tylko na podstawie weksla. W przypadku wyższych kwot (powyżej 500 000 zł) lub krótszej historii działalności, bank może wymagać dodatkowych zabezpieczeń."
    },
    {
      question: "Czy moja firma kwalifikuje się do kredytu obrotowego ratalnego?",
      answer: "Kwalifikacja do kredytu obrotowego ratalnego opiera się na kilku kluczowych kryteriach: 1) Minimum 12 miesięcy działalności (w niektórych przypadkach 6 miesięcy), 2) Stabilne przychody w ostatnich miesiącach, 3) Brak zaległości w ZUS i US, 4) Pozytywna historia kredytowa (jeśli istnieje). Ważne jest również, aby firma generowała odpowiednią nadwyżkę finansową pozwalającą na spłatę rat. W marcu 2025 banki szczególnie pozytywnie oceniają firmy z branż: produkcyjnej, usługowej, handlowej oraz IT. Nawet jeśli Twoja firma nie spełnia wszystkich standardowych kryteriów, możemy pomóc znaleźć alternatywne rozwiązania finansowe."
    }
  ],
  nextSteps: [
    "Skontaktuj się z nami telefonicznie lub przez formularz",
    "Przygotuj podstawowe dokumenty finansowe",
    "Otrzymaj wstępną decyzję kredytową",
    "Podpisz umowę i uzyskaj finansowanie"
  ]
};