import React, { useState, useEffect, useRef } from 'react';
import { AlertCircle, CheckCircle2, ArrowRight } from 'lucide-react';

export interface Problem {
  problem: string;
  solution: string;
  details: string[];
}

interface ProblemsSolutionsProps {
  title?: string;
  subtitle?: string;
  problems: Problem[];
  className?: string;
}

export default function ProblemsSolutions({
  title = "Problemy i rozwiązania",
  subtitle = "Pomagamy przejść przez proces, eliminując typowe problemy",
  problems,
  className = ''
}: ProblemsSolutionsProps) {
  const [hasAnimated, setHasAnimated] = useState(false);
  const [visibleCards, setVisibleCards] = useState<number[]>([]);
  const sectionRef = useRef<HTMLElement | null>(null);
  const cardsRef = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const cardIndex = cardsRef.current.findIndex(ref => ref === entry.target);
            if (cardIndex !== -1 && !visibleCards.includes(cardIndex)) {
              setVisibleCards(prev => [...prev, cardIndex].sort((a, b) => a - b));
            }
          }
        });
      },
      {
        threshold: 0.2,
        rootMargin: '-10% 0px -10% 0px'
      }
    );

    cardsRef.current.forEach(card => {
      if (card) observer.observe(card);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section 
      ref={sectionRef}
      className={`py-12 md:py-20 bg-gradient-to-b from-white to-gray-50 ${className}`}
      aria-labelledby="problems-solutions-heading"
    >
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-12">
          <h2 
            id="problems-solutions-heading" 
            className="text-2xl md:text-4xl font-bold text-[#003A30] mb-4"
          >
            {title}
          </h2>
          <p className="text-lg text-gray-600">
            {subtitle}
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-6xl mx-auto">
          {problems.map((item, index) => (
            <div 
              key={index}
              ref={el => cardsRef.current[index] = el}
              className={`
                relative bg-white rounded-xl shadow-lg overflow-hidden
                transform transition-all duration-700 ease-out
                ${visibleCards.includes(index) 
                  ? 'translate-y-0 scale-100 opacity-100' 
                  : 'translate-y-8 scale-95 opacity-0'
                }
                hover:shadow-xl hover:-translate-y-1 group
              `}
              style={{
                transitionDelay: `${index * 100}ms`
              }}
            >
              {/* Top Gradient Bar */}
              <div 
                className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-[#003A30] to-[#CBA331] transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100" 
                aria-hidden="true"
              />
              
              <div className="p-6">
                <div className="flex items-start gap-4 mb-6">
                  <div className={`
                    flex-shrink-0 w-12 h-12 rounded-full bg-red-50 flex items-center justify-center
                    transform transition-transform duration-500
                    ${visibleCards.includes(index) ? 'scale-100' : 'scale-90'}
                  `}>
                    <AlertCircle className="w-6 h-6 text-red-500" />
                  </div>
                  <div>
                    <h3 className="text-xl font-bold text-red-600 mb-2">{item.problem}</h3>
                    <div className={`
                      flex items-center gap-2 bg-emerald-50 px-4 py-2 rounded-lg
                      transform transition-all duration-500 delay-200
                      ${visibleCards.includes(index) ? 'translate-x-0 opacity-100' : 'translate-x-4 opacity-0'}
                    `}>
                      <CheckCircle2 className="w-5 h-5 text-emerald-500" />
                      <p className="text-emerald-700 font-medium">{item.solution}</p>
                    </div>
                  </div>
                </div>
                <ul className="space-y-3">
                  {item.details.map((detail, idx) => (
                    <li 
                      key={idx} 
                      className={`
                        flex items-center gap-2 text-gray-600
                        transform transition-all duration-500
                        ${visibleCards.includes(index) 
                          ? 'translate-x-0 opacity-100' 
                          : 'translate-x-4 opacity-0'
                        }
                      `}
                      style={{
                        transitionDelay: `${300 + idx * 100}ms`
                      }}
                    >
                      <ArrowRight className="w-4 h-4 text-[#CBA331]" />
                      <span>{detail}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}